import { create } from 'zustand';
import {ModifyUserProfileCOVO} from "@client/site/api/models/ModifyUserProfileCOVO";

export type EditType = {
	nickName: boolean;
	job: boolean;
	height: boolean;
	drinking: boolean;
	smoking: boolean;
	religion: boolean;
	introduce: boolean;
	interest: boolean;
	lifeStyle: boolean;
};

interface EditProfileState {
	focusItem: { label: string; value: string | Array<string> };
	setFocusItem: (focusItem: { label: string; value: string }) => void;
	clearFocusItem: () => void;

	editPop: EditType;
	setEditPop: (editPop: EditType) => void;
	clearEditPop: () => void;

	// 내 프로필 수정
	myModifyProfile: ModifyUserProfileCOVO;
	setMyModifyProfile: (myModifyProfile: ModifyUserProfileCOVO) => void;

	modifyProfileUrl: boolean;
	setModifyProfileUrl: (modifyProfileUrl: boolean) => void;
}

export const useEditProfileRepo = create<EditProfileState>((set) => ({
	focusItem: { label: '', value: [] },
	setFocusItem: (focusItem) => set({ focusItem }),
	clearFocusItem: () =>
		set({
			focusItem: { label: '', value: [] },
		}),

	editPop: {
		nickName: false,
		job: false,
		height: false,
		drinking: false,
		smoking: false,
		religion: false,
		introduce: false,
		interest: false,
		lifeStyle: false,
	},
	setEditPop: (editPop: EditType) => set({ editPop }),
	clearEditPop: () =>
		set({
			editPop: {
				nickName: false,
				job: false,
				height: false,
				drinking: false,
				smoking: false,
				religion: false,
				introduce: false,
				interest: false,
				lifeStyle: false,
			},
		}),

	myModifyProfile: {
		userUid: "",
		nickName: "",
		age: "",
		birthday: "",
		location: "",
		region1: null,
		region2: null,
		profileFiles: [],
		genderCode: "",
		gender: "",
		introduce: "",
		job: "",
		jobCode: "",
		height: 0,
		interest: [],
		lifeStyle: [],
		extraInterest: [],
		extraLifeStyle: [],
		interestCode: [],
		lifeStyleCode: [],
		smoking: "",
		smokingCode: "",
		drink: "",
		drinkCode: "",
		religion: "",
		religionCode: "",
		ceo: false,
		house: false,
		salary: false,
		profession: false,
		car: false,
	},
	setMyModifyProfile: (myModifyProfile: ModifyUserProfileCOVO) =>
		set({ myModifyProfile }),

	modifyProfileUrl: false,
	setModifyProfileUrl: (modifyProfileUrl: boolean) => set({ modifyProfileUrl }),
}));
