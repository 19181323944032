import React from "react";
import RegisterHeaderView from "@dating/ui/pages/register/common/registerHeader/views/RegisterHeaderView";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import {useTranslation} from "react-i18next";
import {useTagInviteCodeSerivce} from "@dating/ui/pages/register/tagInviteCode/useTagInviteCodeSerivce";

export const TagInviteCodeContainer = () => {
    const {
        step,
        skipHandler,
        interestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setDrinkingValue,
        lifeStyleValue,
        interestValue,
        setRecommendCode,
        fnSaveTagInviteCode,
        codeFailed,
        btnActivate,
        skipBtnActivate,
        codeErrorMessage,
    } = useTagInviteCodeSerivce();

    const {t} = useTranslation('register')
    return (
        <div className="wrap">
            <RegisterHeaderView/>
            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">{t('interestLifeStyle.header')}</h2>
                </div>
                <div className="content margin-t16">
                    <div className="cateTitBox">
                        <span className="cateTit01 like">{t('interestLifeStyle.interest')}</span>
                    </div>
                    <div className="myCateList">
                        {
                            interestList.length > 0 ?
                                interestList.map((item, i) => {
                                    return (
                                        <button
                                            type="button"
                                            key={item.id + item.styleValue}
                                            className={
                                                interestValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                interestHandler(item.id);
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }
                    </div>
                    <div className="cateTitBox">
                        <span className="cateTit01 life">{t('interestLifeStyle.lifeStyle')}</span>
                    </div>
                    <div className="myCateList">
                        {
                            lifeStyleList.length > 0 ?
                                lifeStyleList.map((item) => {
                                    return (
                                        <button
                                            key={item.id + item.styleValue}
                                            type="button"
                                            className={
                                                lifeStyleValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                lifeStyleHandler(item.id);
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }
                    </div>
                    <div className="cateTitBox">
                        <span className="cateTit01 smoking">{t('interestLifeStyle.smoking')}</span>
                    </div>
                    <div className="myCateList">
                        {
                            initSmokingState.map((item) => {
                                return (
                                    <button
                                        key={item.id + item.styleValue}
                                        className={item.id === smokingValue.toString() ? "active" : ""}
                                        onClick={() => {
                                            setSmokingValue(item.id);
                                        }}>
                                        {item.styleValue}
                                    </button>
                                )
                            })
                        }
                    </div>
                    <div className="cateTitBox">
                        <span className="cateTit01 drink">{t('interestLifeStyle.drinking')}</span>
                    </div>
                    <div className="myCateList">
                        {
                            initDrinkingState.map((item) => {
                                return (
                                    <button
                                        key={item.id + item.styleValue}
                                        className={item.id === drinkingValue.toString() ? "active" : ""}
                                        onClick={() => {
                                            setDrinkingValue(item.id);
                                        }}>
                                        {item.styleValue}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="codeCon margin-t8">
                    <div className="contentsWrap">
                        {/*추천인 코드*/}
                        <p className="titleSub01 margin-b8">{t('codeStep.title')}</p>
                        <div className="content">
                            <div className="inputWrap">
                                <div className="input">
                                    {/*추천인 코드를 입력해 주세요 (선택)*/}
                                    <input id="firstFocus" type="text" placeholder={t('codeStep.inputCode')}
                                           onChange={(e) => {
                                               setRecommendCode(e.target.value)
                                           }}
                                    />
                                </div>
                                {
                                    codeFailed ?
                                        (
                                            <p className="sub-color fz12 padding-t8">
                                                {codeErrorMessage}
                                            </p>
                                        )
                                        : ""
                                }
                            </div>
                            <div className="exTxtWrap margin-t24">
                                <ul className="exTxtList margin-t8">
                                    <li>{t('codeStep.codeGuide')}</li>
                                    {/*가입 완료후에는 마이페이지에서 관심, 스타일, 추천인 코드 입력이 가능합니다.*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="harf-btnWrap">
                    <button className="btn btnharf btnwhite"
                            disabled={skipBtnActivate}
                            onClick={() => skipHandler()}
                    >
                        {/*건너뛰기*/}
                        <span>{t('codeStep.pass')}</span>

                    </button>
                    <button className="btn btnharf btnBlack"
                            disabled={btnActivate}
                            onClick={() => fnSaveTagInviteCode()}
                    >
                        {/*다음*/}
                        <span>{t('common.next')}</span>
                    </button>
                </div>
            </footer>
        </div>
    );
};