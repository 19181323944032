import { formatDate } from '@virtual-space/utils/messageUtils';
import { useTranslation } from 'react-i18next';

interface ProductInfo {
	seq: number;
	useDate: number;
	useStatus: string;
}

interface ManagerHistoryItem {
	purchaseDate: number;
	availableCount: number;
	price: number;
	productName: string;
	productInfo: ProductInfo[];
	result: boolean;
}

interface PurchaseListViewProps {
	managerHistoryList: ManagerHistoryItem[];
}

const PurchaseListView = ({ managerHistoryList }: PurchaseListViewProps) => {
	const { t } = useTranslation('messenger');

	if (managerHistoryList[0]?.result === false) {
		return (
			<div className="dataNoneTxt">
				<p className="txt01">{t('noManagerHistory')}</p>
			</div>
		);
	}
	return (
		<div>
			{managerHistoryList.map((item, index) => (
				<div className="leftRightTxt" key={index}>
					<div>
						<span className="date">
							{t('purchaseDate')} : {item.purchaseDate}
						</span>
						<p className="txt01">{item.productName}</p>
						<div className="d-flex flex-column">
							<span className="fz12 color-666">
								{t('remainingUses', { count: item.availableCount })}
							</span>
							<div className="margin-t5">
								{[...item.productInfo]
									.sort((a, b) => b.seq - a.seq)
									.map((info, idx) => (
										<p key={idx} className="fz10 color-999">
											{t('useTicketCount', { seq: info.seq })}{' '}
											{formatDate(info.useDate)}
										</p>
									))}
							</div>
						</div>
					</div>
					<span className="ic-berith md bold">
						{item.price.toLocaleString()}
					</span>
				</div>
			))}
		</div>
	);
};
export default PurchaseListView;
