import { Link } from 'react-router-dom';
import { ReceiveCommentPostCOVO } from '@client/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';

interface props {
	commentPost: ReceiveCommentPostCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (userUid: string) => void;
	currentUserUid: string;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	setSingleFeedId: (singleFeedId: string) => void;
}

const PostWithCommentListView = ({
	commentPost,
	srcPrefix,
	setUserProfileOpen,
	currentUserUid,
	setMyProfileOpen,
	setSingleFeedId,
}: props) => {
	return (
		<div className="likeProfileList">
			{/*content*/}
			{commentPost.map((item, i) => {
				if (item.profileUrl) {
					return (
						<div
							key={item.commentPostId + item.likeViewId}
							className="profileType"
						>
							<div className="proLeft">
								<div className="proImg">
									<Link
										to={`/metaverse/dating/profile${
											item.userUid !== currentUserUid
												? '/userProfile'
												: '/myProfile'
										}`}
										onClick={() => {
											item.userUid !== currentUserUid
												? setUserProfileOpen(item.userUid)
												: setMyProfileOpen(true);
										}}
										state={{
											isOtherProfile: item.userUid !== currentUserUid,
										}}
									>
										<img src={srcPrefix + item.profileUrl} alt="profile-img" />
									</Link>
								</div>
								<div className="proName margin-l12">
									<p className="fz12Fw500">
										{item.nickName}
										<span className="padding-l8 color-999 fz12">
											{item.commentCreateAt}
										</span>
									</p>
									<p className="fz14Fw500 color-666 margin-t2">
										{item.comment}
									</p>
								</div>
							</div>
							<div className="ProRight">
								<div className="likeSmImg">
									<Link
										to={PATH.LOUNGE.SINGLE}
										onClick={() => {
											setSingleFeedId(item.commentPostId);
										}}
									>
										<img
											src={srcPrefix + item.postPhotoUrl}
											alt={item.nickName}
										/>
										<span className="likeBadge"></span>
									</Link>
								</div>
							</div>
						</div>
					);
				}
			})}
		</div>
	);
};

export default PostWithCommentListView;
