import { debugDraw } from '@virtual-space/utils/debug';
import GameScene from '../GameScene';

export class CreateCollisionLayer {
	constructor() {}

	// 충돌 레이어 생성
	execute(gameScene: GameScene) {
		// "Collision" 레이어 생성
		const collisionLayer = gameScene.map.createLayer('Collision', 'null');

		if (!collisionLayer) return;

		// 충돌 설정
		collisionLayer?.setCollisionByExclusion([-1]);
		// 플레이어와 충돌 설정
		gameScene.physics.add.collider([gameScene.myPlayer], collisionLayer);

		// collisionLayer를 GameScene에 저장
		gameScene.collisionLayer = collisionLayer;

		// 디버그용 그래픽 생성
		debugDraw(collisionLayer, gameScene);
	}
}
