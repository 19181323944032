import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { secondsToHMS } from '@virtual-space/utils/util';

const RoomListItemBtnView = ({
	t,
	createdBy,
	myMetaUser,
	roomKey,
	myGender,
	headCount,
	players,
	locked,
	roomTheme,
	hasPassword,
	joinedRoomData,
	remainingTime,
	handleClickMeta,
	handleJoinClick,
}) => {
	const {
		availableRooms,
		setCurrentRoomData,
		setShowPasswordInput,
		setPasswordError,
	} = useRoomRepo();

	const maxPerGender = headCount === 4 ? 2 : headCount === 3 ? 1 : 3;

	// 일반 유저만 필터링해서 성별 카운트
	const menCount = players.filter(
		(player: { gender: string; role: string }) =>
			player.gender === 'M' && player.role === 'NORMAL',
	).length;

	const womenCount = players.filter(
		(player: { gender: string; role: string }) =>
			player.gender === 'F' && player.role === 'NORMAL',
	).length;

	const isMenFull = menCount >= maxPerGender;
	const isWomenFull = womenCount >= maxPerGender;
	const isFull = isMenFull && isWomenFull;

	const isManager = myMetaUser.role === 'MANAGER';
	const isUserGenderFull =
		myMetaUser.role === 'MANAGER' // 매니저인 경우만 성별 제한 무시
			? false
			: myGender === 'M'
			? isMenFull
			: isWomenFull;

	const buttonDisabled = locked || (!isManager && (isFull || isUserGenderFull));
	const getButtonText = () => {
		if (!isManager) {
			if (isFull) return t('roomIsFull');
			if (isUserGenderFull)
				return t(myGender === 'M' ? 'menSlotFull' : 'womenSlotFull');
		}
		if (locked) return t('inProgress');
		return t('join');
	};
	const handleRoomJoin = () => {
		if (!buttonDisabled) {
			if (hasPassword) {
				setShowPasswordInput(true);
				setPasswordError(null);
				setCurrentRoomData({
					roomKey,
					roomTheme,
				});
			} else {
				handleJoinClick(roomKey, undefined, roomTheme, myMetaUser.userUid);
			}
		}
	};
	// createdBy에 따른 시간 텍스트 설정
	const timeText = t(createdBy === 'MANAGER' ? '24hours' : '2hours');
	return (
		<>
			<div className="RoomListItemBtm">
				<div className="RoomTimeBox text-c">
					<p className="fz20">{secondsToHMS(remainingTime)}</p>
					<p className="fz12 color-999 margin-t8">
						{t('autoDeleteRoom', { time: timeText })}
					</p>
				</div>
				{roomKey !== joinedRoomData?.id && (
					<button
						className={`btnBig ${buttonDisabled ? 'btnCCC' : 'btnBlack'}`}
						disabled={buttonDisabled}
						onClick={handleRoomJoin}
					>
						<span>{getButtonText()}</span>
					</button>
				)}
				{roomKey === joinedRoomData?.id && (
					<button
						className="btnBig btnBlack"
						onClick={() => {
							const publicRoom = availableRooms.find(
								(room) => room.metadata.name.toUpperCase() === 'PUBLIC',
							);

							if (publicRoom) {
								// 퍼블릭룸으로 바로 입장
								handleJoinClick(
									publicRoom.roomId,
									undefined,
									publicRoom.metadata.roomTheme,
									myMetaUser.userUid,
								);
							} else {
								// 퍼블릭룸이 없으면 생성
								handleClickMeta(true);
							}
						}}
					>
						<span>{t('exit')}</span>
					</button>
				)}
			</div>
		</>
	);
};

export default RoomListItemBtnView;
