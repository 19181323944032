import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import { MyPlayerState, StateParams } from '../../MyPlayerState';

export class HelloState implements MyPlayerState {
	constructor(private param: StateParams) {}
	update() {
		const { myPlayer, myKeyboard } = this.param;

		// R 키 입력 상태를 초기화
		// myKeyboard.resetR();
		myKeyboard.resetClickActionR();
		myPlayer.playerBehavior = PlayerBehavior.HELLO;
		myPlayer.playWrab(`${myPlayer.playerTexture}_idle_hello`, true);

		// R 키 입력 상태를 초기화
		// myKeyboard.resetR();
		myKeyboard.resetClickActionR();
	}
}
