import Network from '@virtual-space/services/Network';
import { RoomType } from '../../../types/Rooms';
import WebRTC from './WebRTC';

class PermissionManager {
	constructor(private webRTCInstance: WebRTC, private network: Network) {}

	checkPermissions() {
		// 퍼블릭 룸인 경우 WebRTC 사용을 완전히 막음
		if (this.network.currentRoomType === RoomType.PUBLIC) {
			return;
		}

		const cameraPermissionName = 'camera' as PermissionName;
		const microphonePermissionName = 'microphone' as PermissionName;

		// 브라우저 권한 API가 지원되는 경우에만 실행
		if (navigator.permissions) {
			navigator.permissions
				.query({ name: cameraPermissionName })
				.then((cameraPermission) => {
					if (cameraPermission.state === 'granted') {
						return navigator.permissions?.query({
							name: microphonePermissionName,
						});
					} else {
						// 권한이 없거나 결정되지 않은 경우, 자동으로 요청하지 않고 버튼 클릭 시 처리되도록 함
						return null;
					}
				})
				.then((microphonePermission) => {
					if (
						microphonePermission &&
						microphonePermission.state === 'granted'
					) {
						// 두 권한이 모두 허용된 경우에만 미디어 시작 (알림 없이)
						this.webRTCInstance.getUserMedia(false);
					}
					// 그 외 경우에는 아무 작업도 하지 않음 - 버튼 클릭 시 처리됨
				})
				.catch((error) => {
					console.error('Permission API error:', error.message);
					// 오류 시에도 자동으로 권한 요청 안함
				});
		}
		// 브라우저가 Permissions API를 지원하지 않는 경우에도 자동으로 권한 요청 안함
	}

	// 권한 상태를 강제로 다시 확인하고 필요하면 재요청하는 메서드
	requestPermissionsAgain() {
		// 기존 권한 상태 초기화를 위해 새 미디어 요청 시도
		return this.webRTCInstance.forceNewPermissionRequest();
	}
}

export default PermissionManager;
