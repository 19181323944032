import {useState} from 'react';
import {JobListCOVO} from '@client/site/api';

export const useEditProfilePopStateRepo = () => {
    const [heightValue, setHeightValue] = useState<string[]>(Array(3).fill(''));
    const [activeHeightIndex, setActiveHeightIndex] = useState(0);
    const [jobList, setJobList] = useState<Array<JobListCOVO>>([]);
    const [selectedJob, setSelectedJob] = useState<string>('');
    const [nicknameValue, setNicknameValue] = useState<string>('');
    const [introduceValue, setIntroduceValue] = useState<string>('');


    const [religionValue, setReligionValue] = useState<string>('');

    // 공통 관심사 리스트 상태 관리
    const [interestList, setInterestList] = useState<string[] | { id: string; styleValue: string }[]>([]);

    // 선택한 공통 관심사
    const [interestValue, setInterestValue] = useState<{ id: string; styleValue: string }[]>([]);

    // 공통 라이프 스타일 리스트 상태 관리
    const [lifeStyleList, setLifeStyleList] = useState<string[] | { id: string; styleValue: string }[]>([]);

    // 선택한 공통 라이프스타일
    const [lifeStyleValue, setLifeStyleValue] = useState<{ id: string; styleValue: string }[]>([]);

    // all smoking list
    const [smokingList, setSmokingList] = useState<string[] | { id: string; styleValue: string }[]>([]);

    // selected smoking value
    const [drinkingValue, setDrinkingValue] = useState<string>('');

    // all drinking list
    const [drinkingList, setDrinkingList] = useState<string[] | { id: string; styleValue: string }[]>([]);

    // selected drinking value
    const [smokingValue, setSmokingValue] = useState<string>('');

    const [lengthOver, setLengthOver] = useState<boolean>(false);
    const [isValueEntered, setIsValueEntered] = useState<boolean>(false);
    const [isAvailable, setIsAvailable] = useState<boolean>(true);
    const [nickError, setNickError] = useState<boolean>(true);

    return {
        drinkingList,
        setDrinkingList,
        smokingList,
        setSmokingList,
        nickError,
        setNickError,
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        heightValue,
        setHeightValue,
        activeHeightIndex,
        setActiveHeightIndex,
        interestList,
        setInterestList,
        lifeStyleList,
        setLifeStyleList,
        jobList,
        setJobList,
        selectedJob,
        setSelectedJob,
        nicknameValue,
        setNicknameValue,
        introduceValue,
        setIntroduceValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        lengthOver,
        setLengthOver,
        isValueEntered,
        setIsValueEntered,
        isAvailable,
        setIsAvailable,
    };
};
