import { Link } from 'react-router-dom';
import { UserProfileCOVO } from '@client/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';

type props = {
	myProfile: UserProfileCOVO;
	srcPrefix: string;
};
const MyProfileBigImg = ({ myProfile, srcPrefix }: props) => {
	return (
		<>
			<div className="profileBigImg">
				{myProfile && myProfile.profileUrl && (
					<>
						<img
							src={srcPrefix + myProfile.profileUrl[0]}
							alt={myProfile.nickName}
						/>
						<Link
							to={PATH.PROFILE.EDIT_PROFILE_IMG}
							className="myprofileCam"
						></Link>
					</>
				)}
			</div>
		</>
	);
};
export default MyProfileBigImg;
