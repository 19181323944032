import { Cropper, ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ImgCropHeaderView from '@dating/ui/pages/imgCrop/views/ImgCropHeaderView';
import { useImgCropService } from '@dating/ui/pages/imgCrop/service/useImgCropService';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { bridgeOffLoading } from '@client/utils/bridgeCallHandler';

const ImgCropContainer = () => {
	const { imgCropFile, setCropData, showImgCrop, setShowImgCrop } =
		useImgCropService();

	const navigate = useNavigate();
	useEffect(() => {
		if (!showImgCrop) {
			if (!(window as any).flutter_inappwebview) {
				// 웹
				navigate(-2);
			} else {
				// 앱
				bridgeOffLoading().then((value) => {
					navigate(-2);
				});
			}
		}
	}, [showImgCrop]);

	// Cropper 컴포넌트
	const cropperRef = useRef<ReactCropperElement>(null);

	return (
		<>
			<div className="wrap h-fixed footerNone">
				<ImgCropHeaderView
					setShowImgCrop={setShowImgCrop}
					setCropData={setCropData}
					cropperRef={cropperRef}
				/>

				<div className="imgEditBox">
					<Cropper
						ref={cropperRef}
						aspectRatio={1}
						src={imgCropFile}
						viewMode={1} //확대,축소로 이미지 영역 바깥도 선택할 것인지 여부. 0이면 이미지 바깥영역도 선택
						background={false} //배경 회색 처리, false하면 투명으로 처리
						// responsive
						autoCropArea={1} // 자동으로 선택할 영역 (이미지 원래 크기 기준)
						// checkOrientation={false}
						// guides={false} //크롭가이드격자(기본값true)
						zoomOnWheel={true}
					/>
				</div>

				{/* <footer className="appFooterWrap footerBlack">
          <div className="footerWrap">
            <div className="f-left">
              <button>
                <span className="color-fff">원본 복원</span>
              </button>
            </div>
            <div className="f-right">
              <button className="re-front">
                <span className="hide">앞으로 돌리기</span>
              </button>
              <button className="re-back active">
                <span className="hide">뒤로 돌리기</span>
              </button>
            </div>
          </div>
        </footer> */}
			</div>
		</>
	);
};

export default ImgCropContainer;
