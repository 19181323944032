import { CommentCOVO, UserProfileCOVO } from '@client/site/api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface props {
	focusedPostId: string;
	myProfile: UserProfileCOVO;
	fnSaveComment: (id: string, comment: string) => void;
	commentResponse: CommentCOVO[] | undefined;
	srcPrefix: string;
	commentValue: string;
	setCommentValue: (commentValue: string) => void;
	commentViewHandler: (state: boolean) => void;
	postUser: { userUid: string; nickname: string };
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	setUserProfileOpen: (userProfileOpen: string) => void;
	currentUserUid: string;
	fnCommonConfirmPopOn: (
		message: string,
		okFunc: any,
		okParam: any,
		noFunc: any,
		noParam: any,
	) => any;
	fnDeleteComment: (param: { postId: string; commentId: number }) => void;
}

const FeedCommentView = ({
	focusedPostId,
	myProfile,
	fnSaveComment,
	commentResponse,
	srcPrefix,
	commentValue,
	setCommentValue,
	commentViewHandler,
	postUser,
	setMyProfileOpen,
	setUserProfileOpen,
	currentUserUid,
	fnCommonConfirmPopOn,
	fnDeleteComment,
}: props) => {
	const { t } = useTranslation('lounge');

	return (
		<>
			<div className="commentInfo">
				<p className="commonTit">
					<span>{t('comment')}</span>
				</p>
				<div className="commentList">
					{commentResponse && commentResponse.length > 0 ? (
						commentResponse.map((comment, i) => {
							return (
								<div className="profileType" key={i}>
									<div className="proLeft">
										<Link
											to={`/metaverse/dating/profile${
												comment.commentUserUid !== currentUserUid
													? '/userProfile'
													: '/myProfile'
											}`}
											onClick={() => {
												if (myProfile?.userUid === comment.commentUserUid) {
													setMyProfileOpen(true);
												} else {
													comment.commentUserUid
														? setUserProfileOpen(comment.commentUserUid)
														: null;
												}

												commentViewHandler(false);
											}}
											state={{
												isOtherProfile:
													comment.commentUserUid !== currentUserUid,
											}}
										>
											<div className="proImg Sm">
												<img
													src={srcPrefix + comment.commentUserProfileUrl}
													alt="유저프로필"
												/>
											</div>
										</Link>
										<div className="proName margin-l12">
											<p className="fz12Fw500">
												{comment.commentUserNickName}
												<span className="padding-l8 color-999 fz12">
													{comment.createTime}
												</span>
											</p>
											<p className="fz14Fw500 color-666 margin-t2">
												{comment.comment}
											</p>
										</div>
									</div>
									<div className="ProRight">
										{comment.isDeletable}
										{comment.isDeletable ? (
											<button
												type="button"
												className="btnClose"
												onClick={() => {
													fnCommonConfirmPopOn(
														t('deleteComment'),
														fnDeleteComment,
														{
															postId: comment.postId,
															commentId: comment.commentId,
														},
														null,
														null,
													);
												}}
											/>
										) : null}
									</div>
								</div>
							);
						})
					) : (
						<div className="dataNoneTxt">
							<p
								className="txt01"
								dangerouslySetInnerHTML={{ __html: t('emptyCommentQuote') }}
							></p>
						</div>
					)}

					{/*댓글작성영역*/}
					{myProfile.role === 'NORMAL' ? (
						<div className="chatInput input">
							<input
								type="text"
								placeholder={t('addCommentFor', { member: postUser.nickname })}
								value={commentValue}
								onChange={(e) => {
									setCommentValue(e.target.value);
								}}
							/>
							<button
								className="submitBtn"
								type="button"
								onClick={(e) => {
									if (commentValue.length > 0) {
										fnSaveComment(focusedPostId, commentValue);
									}
								}}
							></button>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};

export default FeedCommentView;
