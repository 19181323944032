import Peer, { MediaConnection } from 'peerjs';
import PermissionManager from './PermissionManager';
import WebRTCButtons from './WebRTCButtons';

import Network from '../services/Network';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useVideoRepo } from '@virtual-space/stores/useVideoRepo';
import { replaceInvalidId } from '@virtual-space/utils/util';

export default class WebRTC {
	private myPeer: Peer;
	private peers = new Map<
		string,
		{ call: MediaConnection; video: HTMLVideoElement }
	>();
	private onCalledPeers = new Map<
		string,
		{ call: MediaConnection; video: HTMLVideoElement }
	>();
	private myVideo = document.createElement('video');
	private myStream?: MediaStream;
	private network: Network;
	private webRtcButtons?: WebRTCButtons;
	private permissionManager: PermissionManager;
	private isAlertShown = false; // alert 표시 여부 추적
	private isGettingMedia = false; // getUserMedia 호출 중복 방지

	constructor(userId: string, network: Network) {
		const sanitizedId = replaceInvalidId(userId);
		this.myPeer = new Peer(sanitizedId);
		this.network = network;
		this.myPeer.on('error', (err) => {
			console.log('WebRTC err.type', (err as any).type);
			console.error(err);
		});

		// 자신의 비디오 스트림 음소거 처리
		this.myVideo.muted = true;
		this.myVideo.id = sanitizedId;

		// PeerJS 설정
		this.initialize();

		this.permissionManager = new PermissionManager(this, this.network);
		this.permissionManager.checkPermissions();
		const { setAddedVideos } = useVideoRepo.getState();
		setAddedVideos(this.myVideo.id, this.myVideo);
	}

	initialize() {
		this.myPeer.on('call', (call) => {
			if (!this.onCalledPeers.has(call.peer)) {
				call.answer(this.myStream);
				const video = document.createElement('video');
				this.onCalledPeers.set(call.peer, { call, video });
				call.on('stream', (otherPlayerStream) => {
					this.addVideoStream(video, otherPlayerStream);
				});
			}
		});
	}

	// 권한 상태를 초기화하고 권한을 강제로 다시 요청하는 메서드
	public forceNewPermissionRequest(): Promise<MediaStream> {
		// 기존 스트림 정리
		if (this.myStream) {
			this.myStream.getTracks().forEach((track) => track.stop());
			this.myStream = undefined;
		}

		// 상태 초기화
		this.isAlertShown = false;
		this.isGettingMedia = false;

		try {
			// 약간 다른 제약 조건으로 요청하여 브라우저가 새로운 권한 대화 상자를 표시하도록 유도
			const uniqueConstraints = {
				video: {
					width: { ideal: 1280 + Math.floor(Math.random() * 10) },
					height: { ideal: 720 + Math.floor(Math.random() * 10) },
					frameRate: { ideal: 30 + Math.floor(Math.random() * 5) },
				},
				audio: {
					echoCancellation: Math.random() > 0.5,
					noiseSuppression: Math.random() > 0.5,
					autoGainControl: Math.random() > 0.5,
				},
			};

			// 새 미디어 요청 보내기
			return navigator.mediaDevices
				.getUserMedia(uniqueConstraints)
				.then((stream) => {
					// 성공 - 스트림 설정
					const { setMyVideoStream } = useVideoRepo.getState();
					const { setMediaConnected } = useMetaUserRepo.getState();

					this.myStream = stream;
					setMyVideoStream(stream);

					if (this.myVideo) {
						this.myVideo.srcObject = stream;
						this.myVideo.addEventListener('loadedmetadata', () => {
							this.myVideo.play();
							this.myVideo.style.transform = 'scaleX(-1)';
						});
					}

					// WebRTCButtons 초기화 (새 스트림으로)
					if (this.webRtcButtons) {
						this.webRtcButtons.cleanup();
					}
					this.webRtcButtons = new WebRTCButtons(this.myStream, this);

					setMediaConnected(true);
					this.network.mediaConnected();

					return stream;
				})
				.catch((error) => {
					console.error('권한 재요청 실패:', error);
					throw error;
				});
		} catch (error) {
			console.error('권한 재요청 중 오류:', error);
			return Promise.reject(error);
		}
	}

	// isAlertShown 상태를 리셋하는 메서드
	public resetAlertState() {
		this.isAlertShown = false;
	}

	getUserMedia(alertOnError = true): Promise<MediaStream> {
		// 이미 스트림이 있으면 그대로 반환
		if (this.myStream) {
			return Promise.resolve(this.myStream);
		}

		// 이미 요청 중이면 대기
		if (this.isGettingMedia) {
			return new Promise((resolve, reject) => {
				const checkInterval = setInterval(() => {
					if (!this.isGettingMedia) {
						clearInterval(checkInterval);
						if (this.myStream) {
							resolve(this.myStream);
						} else {
							reject(new Error('Failed to get media stream'));
						}
					}
				}, 100);
			});
		}

		this.isGettingMedia = true;
		const { setMediaConnected } = useMetaUserRepo.getState();
		const constraints = {
			video: true,
			audio: true,
		};

		return navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				const { setMyVideoStream } = useVideoRepo.getState();
				setMyVideoStream(stream);
				this.myStream = stream;

				if (this.myVideo) {
					this.myVideo.srcObject = stream;
					this.myVideo.addEventListener('loadedmetadata', () => {
						this.myVideo.play();
						this.myVideo.style.transform = 'scaleX(-1)';
					});
				}
				this.webRtcButtons = new WebRTCButtons(this.myStream, this);
				setMediaConnected(true);
				this.network.mediaConnected();

				// 성공 시 isAlertShown 초기화
				this.isAlertShown = false;

				return stream;
			})
			.catch((error) => {
				// 한 번에 한 번만 alert 표시
				if (alertOnError && !this.isAlertShown) {
					this.isAlertShown = true;

					// alert가 닫히면 다시 false로 설정 (0.1초 후)
					setTimeout(() => {
						this.isAlertShown = false;
					}, 100);

					window.alert(
						'카메라와 마이크 접근 권한이 필요합니다. 설정에서 권한을 확인해주세요.',
					);
				}
				console.error('미디어 접근 오류:', error.name, error.message);
				throw error;
			})
			.finally(() => {
				this.isGettingMedia = false;
			});
	}

	// peer에 연결
	connectToNewUser(userId: string) {
		if (this.myStream) {
			const sanitizedId = replaceInvalidId(userId);
			if (!this.peers.has(sanitizedId)) {
				console.log('calling', sanitizedId);
				const call = this.myPeer.call(sanitizedId, this.myStream);
				const video = document.createElement('video');
				video.id = sanitizedId;

				this.peers.set(sanitizedId, {
					call,
					video,
				});
				call.on('stream', (otherPlayerStream) => {
					this.addVideoStream(video, otherPlayerStream);
				});
			}
		}
	}

	// 새로운 비디오 스트림을 videoGrid 요소에 추가
	addVideoStream(video: HTMLVideoElement, stream: MediaStream) {
		const { addedVideos, setAddedVideos } = useVideoRepo.getState();
		if (!addedVideos?.has(video.id)) {
			if (stream) {
				if (this.myVideo === video) {
					this.myVideo.srcObject = stream;
					this.myVideo.addEventListener('loadedmetadata', () => {
						this.myVideo.play();
						this.myVideo.style.transform = 'scaleX(-1)';
					});
				} else {
					video.srcObject = stream;
					video.addEventListener('loadedmetadata', () => {
						video.play();
						video.style.transform = 'scaleX(-1)';
					});
				}
			}
			setAddedVideos(video.id, video);
		}
	}

	// 비디오 스트림을 제거(호스트인 경우)
	deleteVideoStream(userId: string) {
		const { setDeletedVideos } = useVideoRepo.getState();
		const sanitizedId = replaceInvalidId(userId);
		if (this.peers.has(sanitizedId)) {
			const peer = this.peers.get(sanitizedId);
			peer?.call.close();
			const video = peer?.video;
			setDeletedVideos(video!.id);
			peer?.video.remove();
			this.peers.delete(sanitizedId);
		}
	}

	// 비디오 스트림을 제거(게스트인 경우)
	deleteOnCalledVideoStream(userId: string) {
		const { setDeletedVideos } = useVideoRepo.getState();
		const sanitizedId = replaceInvalidId(userId);
		if (this.onCalledPeers.has(sanitizedId)) {
			const onCalledPeer = this.onCalledPeers.get(sanitizedId);
			onCalledPeer?.call.close();
			const video = onCalledPeer?.video;
			setDeletedVideos(video!.id);
			onCalledPeer?.video.remove();
			this.onCalledPeers.delete(sanitizedId);
		}
	}

	cleanup() {
		const { setMediaConnected } = useMetaUserRepo.getState();
		const { clearAddedVideos, setDeletedVideos, setMyVideoStream } =
			useVideoRepo.getState();
		// 모든 피어 연결 종료
		this.peers.forEach((peer, key) => {
			peer.call.close();
			peer.video.remove();
			this.peers.delete(key);
		});

		this.onCalledPeers.forEach((peer, key) => {
			peer.call.close();
			peer.video.remove();
			this.onCalledPeers.delete(key);
		});

		// 내 비디오 스트림 정지
		if (this.myStream) {
			this.myStream.getTracks().forEach((track) => track.stop());
			this.myStream = undefined;
		}

		// 내 비디오 요소 제거
		if (this.myVideo) {
			this.myVideo.remove();
			setDeletedVideos(this.myVideo.id);
		}

		// PeerJS 연결 종료
		if (this.myPeer) {
			this.myPeer.disconnect();
			this.myPeer.destroy();
		}

		// WebRTCButtons 정리
		if (this.webRtcButtons) {
			this.webRtcButtons.cleanup();
			this.webRtcButtons = undefined;
		}

		// Redux 스토어 상태 초기화
		setMediaConnected(false);
		setMyVideoStream(null);
		clearAddedVideos();
	}
}
