import { useOrderUtils } from '@client/utils/orderUtils';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useState } from 'react';

export const useOfflineManagerService = () => {
	const [openOfflineProducts, setOpenOfflineProducts] = useState(false);
	const { managerProducts } = useProductsRepo();

	const { fnValidateOrder } = useOrderUtils();

	/////////////////////////////

	/*
	 * fnOpenOfflineProducts
	 * 오프라인 매니저 구매목록 호출
	 */
	const fnOpenOfflineProducts = () => {
		setOpenOfflineProducts(true);
	};

	/*
	 * fnCloseOfflineProducts
	 * 오프라인 매니저 구매목록 닫기
	 */
	const fnCloseOfflineProducts = () => {
		setOpenOfflineProducts(false);
	};

	return {
		openOfflineProducts,
		fnOpenOfflineProducts,
		managerProducts,
		fnCloseOfflineProducts,
		fnValidateOrder,
	};
};
