import {
	CreateMessageRoomCIVO,
	MessageInviteCIVO,
	MessageReadStatusCIVO,
	MessageService,
} from '@client/site/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MessageRoomCIVO, MessageCIVO } from '@client/site/api';
export const useMessageAdapter = () => {
	const queryClient = useQueryClient();

	/**
	 * createMessageRoomId
	 * 메시지 방 아이디 생성
	 */
	const createMessageRoomId = () => {
		return MessageService.createMessageRoomId();
	};

	/**
	 * 메시지 방 여부 확인
	 * isMessageRoom
	 */
	const isMessageRoom = (toUserUid: string) => {
		return MessageService.isMessageRoom(toUserUid);
	};

	/**
	 * 메시지 방 리스트 조회
	 * getMessageRoomList
	 */
	const getMessageRoomList = (userUid: string) => {
		return MessageService.getMessageRoomList(userUid);
	};
	/**
	 * 메시지 내용 저장 (단건)
	 * `save`Message
	 */
	const saveMessage = useMutation({
		mutationFn: (map: MessageCIVO) => MessageService.saveMessage(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageList'] });
			return data;
		},
	});

	/**
	 * 초대 이력 저장
	 * @param requestBody 초대자, 초대 받은 사람 정보
	 * @returns any
	 * @throws ApiError
	 */
	const saveInvite = useMutation({
		mutationFn: (map: MessageInviteCIVO) => MessageService.saveInvite(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageRoomList'] });
			return data;
		},
	});

	/**
	 * 메시지 방 나가기
	 * leaveMessageRoom
	 */
	const leaveMessageRoom = (roomId: string) => {
		return MessageService.leaveMessageRoom(roomId);
	};

	/**
	 * 메시지 읽음 상태 수정
	 * updateMessageReadStatus
	 */
	const updateMessageReadStatus = useMutation({
		mutationFn: (map: MessageReadStatusCIVO) =>
			MessageService.updateMessageReadStatus(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageList'] });
			return data;
		},
	});

	/**
	 * 메시지 방 정보 조회
	 * getMessageRoomInfo
	 */
	const getMessageRoomInfo = (roomId: string) => {
		return MessageService.getMessageRoomInfo(roomId);
	};

	/**
	 * 메시지 보내기 (포인트 상품)
	 * sendMessage
	 */
	const sendMessage = useMutation({
		mutationFn: (map: CreateMessageRoomCIVO) => MessageService.sendMessage(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageRoomList'] });
			return data;
		},
		onError: (error) => {
			console.error('sendMessage 실패:', error); // 실패 확인
		},
	});

	/**
	 * 대화 열기 (포인트 상품)
	 * openMessageRoom
	 */
	const openMessageRoom = useMutation({
		mutationFn: (map: MessageRoomCIVO) => MessageService.openMessageRoom(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageRoomList'] });
			return data;
		},
		onError: (error) => {
			console.error('openMessageRoom 실패:', error); // 실패 확인
		},
	});

	/**
	 * 금칙어 조회
	 * getForbiddenWords
	 */
	const getForbiddenWords = () => {
		return MessageService.getForbiddenWords();
	};

	return {
		createMessageRoomId,
		isMessageRoom,
		getMessageRoomList,

		sendMessage: sendMessage.mutate,
		statusSendMessage: sendMessage.status,
		dataSendMessage: sendMessage.data,

		openMessageRoom: openMessageRoom.mutate,
		statusOpenMessageRoom: openMessageRoom.status,
		dataOpenMessageRoom: openMessageRoom.data,

		saveMessage: saveMessage.mutate,
		statusSaveMessage: saveMessage.status,
		dataSaveMessage: saveMessage.data,

		saveInvite: saveInvite.mutate,
		statusSaveInvite: saveInvite.status,
		dataSaveInvite: saveInvite.data,

		leaveMessageRoom: leaveMessageRoom,

		updateMessageReadStatus: updateMessageReadStatus.mutate,
		statusUpdateMessageReadStatus: updateMessageReadStatus.status,
		dataUpdateMessageReadStatus: updateMessageReadStatus.data,
		getMessageRoomInfo,

		getForbiddenWords,
	};
};
