import { useNavigate } from 'react-router-dom';
import { useReferralCodeService } from '@dating/ui/pages/content/event/referralCode/useReferralCodeService';
import {
	bridgeCopyToClipboard,
	bridgeKakaoShare,
	bridgeShowToast,
} from '@client/utils/bridgeCallHandler';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ReferralCodeContainer = () => {
	const { myInviteCode } = useReferralCodeService();

	const navigate = useNavigate();

	const { t } = useTranslation('setting');

	useEffect(() => {
		// if ((window as any).Kakao) {
		// 	if (!(window as any).Kakao.isInitialized()) {
		// 		(window as any).Kakao.init(import.meta.env.VITE_KAKAO_APP_KEY);
		// 	}
		// }
	}, []);

	const shareKakao = () => {
		// try {
		// 	Kakao.Share.sendDefault({
		// 		objectType: 'feed',
		// 		content: {
		// 			title: 'HUSH에서 시작되는 우리 인연',
		// 			description: `{신규 가입 시 Hush 추천인 코드 ${myInviteCode}를 입력하고 베리드 포인트를 받으세요.}`,
		// 			imageUrl:
		// 				'https://mud-kage.kakao.com/dn/NTmhS/btqfEUdFAUf/FjKzkZsnoeE4o19klTOVI1/openlink_640x640s.jpg',
		// 			link: {},
		// 		},
		// 		buttons: [
		// 			{
		// 				title: '설치하기',
		// 				link: {
		// 					mobileWebUrl: 'https://developers.kakao.com',
		// 					webUrl: 'https://developers.kakao.com',
		// 				},
		// 			},
		// 		],
		// 	});
		// } catch (e) {
		// 	alert(e)
		// }
		const param = {
			title: t('share1'),
			description: t('share2', { myInviteCode: myInviteCode }),
			imageUrl:
				'https://mud-kage.kakao.com/dn/NTmhS/btqfEUdFAUf/FjKzkZsnoeE4o19klTOVI1/openlink_640x640s.jpg',
			webUrl: '',
			mobileWebUrl: '',
			buttons: [
				{
					title: t('share3'),
					webUrl: null,
					mobileWebUrl: null,
					androidExecutionParams: {
						key1: 'value1',
						key2: 'value2',
					},
					iosExecutionParams: {
						key1: 'value1',
						key2: 'value2',
					},
				},
			],
		};

		// if ((window as any).flutter_inappwebview) {
		bridgeKakaoShare(JSON.stringify(param))
			.then((value) => {
				if (value) {
					alert(t('share4'));
				}
			})
			.catch((reason) => {
				alert(reason.body);
			});
		// }
	};

	return (
		<div className="wrap rightShow profileWrap footerNone active">
			<header>
				<div className="headerWrap">
					<div className="h-left"></div>
					<div className="h-center">
						<p className="title02">{t('inviteEvent2')}</p>
					</div>
					<div className="h-right">
						<button
							type="button"
							className="btnClose"
							onClick={() => {
								navigate(-1);
							}}
						>
							<span className="hide">닫기</span>
						</button>
					</div>
				</div>
			</header>

			<div className="popConWrap padding-b0">
				<div className="newUserWrap">
					<div className="inner">
						<div
							className="newUserTitBox"
							dangerouslySetInnerHTML={{ __html: t('inviteGuideQuote') }}
						></div>

						<div className="myNewUserCodeBox">
							<p>{t('myInviteCode')}</p>
							<a
								className="ic-copy"
								onClick={(e) => {
									e.preventDefault();
									if (!(window as any).flutter_inappwebview) {
										// 웹
										navigator.clipboard.writeText(`${myInviteCode}`);
									} else {
										// 앱
										bridgeCopyToClipboard(myInviteCode);
									}
								}}
							>
								{myInviteCode}
							</a>
						</div>

						<div
							className="kakaoCodeBtnBox"
							onClick={(e) => {
								shareKakao();
							}}
						>
							<a className="kakaoCodeBtn">{t('inviteCodeShare')}</a>
						</div>
					</div>
				</div>
				<div className="inner">
					<div
						className="exBox"
						dangerouslySetInnerHTML={{ __html: t('attendGuide2') }}
					></div>
				</div>
			</div>
			{/*<script>*/}
			{/*	$('.attendCheckItem').on('click', function() {*/}
			{/*	$(this).addClass('active');*/}
			{/*});*/}
			{/*</script>*/}
		</div>
	);
};
