export enum MetaMessageType {
  UPDATE_PLAYER,
  UPDATE_PLAYER_NAME,
  UPDATE_PLAYER_UUID,
  UPDATE_PLAYER_STATUS_MESSAGE,
  READY_TO_CONNECT,
  DISCONNECT_STREAM,
  CONNECT_TO_COMPUTER,
  DISCONNECT_FROM_COMPUTER,
  STOP_SCREEN_SHARE,
  CONNECT_TO_WHITEBOARD,
  DISCONNECT_FROM_WHITEBOARD,
  UPDATE_CHAIR_STATUS,
  VIDEO_CONNECTED,
  ADD_CHAT_MESSAGE,
  SEND_ROOM_DATA,
  SEND_EMOJI_MESSAGE,
  AUDIO_STATUS,
  DELETE_PLAYER,
  UPDATE_PLAYER_PROFILE_URL,
  ACCESS_TOKEN_UPDATE,
}
