import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useCallback, useEffect } from 'react';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import {
	PostIdCIVO,
	PostInfoCOVO,
	PostLikeCIVO,
	UserAuthBadgeCOVO,
	UserProfileCOVO,
} from '@client/site/api';
import { useQuery } from '@tanstack/react-query';
import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useEditProfileRepo } from '@dating/repository/profile/useEditProfileRepo';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import useProfileRepo from '@dating/repository/profile/useProfileRepo';
import { useStoreRepo } from '@dating/repository/store/useStoreRepo';
import { usePointAdapter } from '@dating/adapter/point/usePointAdapter';
import { useMyProfileStateRepo } from '@dating/ui/pages/myProfile/useMyProfileStateRepo';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useHeaderRepo } from '@dating/repository/dating/useHeaderRepo';
import { useTranslation } from 'react-i18next';
import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";

export const useMyProfileService = () => {
	const {
		fadeIn,
		setFadeIn,
		setScrollOn,
		isClickedFeedMenu,
		setIsClickedFeedMenu,
		setProfileDetails,
	} = useMyProfileStateRepo();

	const {
		setModifyPostId,
		feedEditPops,
		setFeedEditPops,
		myPosts,
		setMyPosts,
	} = useLoungeRepo();

	const { myBerith, setMyBerith } = useStoreRepo();

	const {
		srcPrefix,
		myProfile,
		myProfileOpen,
		setMyProfileOpen,
		setMyProfile,
	} = useDatingRepo();

	const {clearPreviewURLs} = useImgCropRepo();

	const { setSettingOpen } = useHeaderRepo();

	const { modifyProfileUrl, setModifyProfileUrl } = useEditProfileRepo();

	const { badgeData, setBadgeData } = useProfileRepo();

	const { removePost } = useLoungeAdapter();

	const { getMyProfile, getBadge, getMyPosts } = useProfileAdapter();

	const { savePostLike } = useLikeAdapter();

	const { getPoint } = usePointAdapter();

	const { t } = useTranslation('profile');

	const { status: statusGetMyPosts, data: dataGetMyPosts } = useQuery({
		queryKey: ['getMyPosts'],
		queryFn: () => {
			return getMyPosts();
		},
		enabled: myProfileOpen,
	});
	////////////////////////////////////////////////////////////

	/*
	 * initProfileDetail
	 * StepLastBoxView content list set
	 */
	function initProfileDetail(profile: UserProfileCOVO) {
		const lifeStyleValues = profile.lifeStyle.concat(profile.extraLifeStyle);
		const interestValues = profile.interest.concat(profile.extraInterest);

		const list = [
			{
				key: 'job',
				title: '직업',
				value: profile.job ? profile.job : '',
			},
			{
				key: 'height',
				title: '키',
				value: profile.height ? profile.height : '',
			},
			{
				key: 'life',
				title: '라이프 스타일',
				value: lifeStyleValues.join(', '),
			},
			{
				key: 'like',
				title: '관심사',
				value: interestValues.join(', '),
			},
			{
				key: 'smoking',
				title: '흡연여부',
				value: profile.smoking ? profile.smoking : '',
			},
		];

		setProfileDetails(list);
	}

	useEffect(() => {
		return setScrollOn(false);
	}, []);

	function scrollHandler(y: number, rectTop: number) {
		if (y > 0 && rectTop <= 134) {
			setScrollOn(true);
		} else {
			setScrollOn(false);
		}
	}

	/*
	 * deleteContent
	 * 피드삭제
	 */
	const deleteContent = (id: string) => {
		const map: PostIdCIVO = {
			postId: id.toString(),
		};
		removePost(map);
	};

	useEffect(() => {
		if (statusGetMyPosts === 'success' && dataGetMyPosts && myProfileOpen) {
			setMyPosts(dataGetMyPosts);
			// 케밥 control list set
			initFeedEditPops(dataGetMyPosts);
		}
	}, [statusGetMyPosts, dataGetMyPosts]);

	useEffect(() => {
		getBadge().then((list: UserAuthBadgeCOVO[]) => {
			const addMyRow: UserAuthBadgeCOVO[] = [
				{
					badgeUrl: '/assets/img/common/icon/ico-auth-plus.svg',
					badgeId: '',
					badgeNm: '',
					badgeMiddleId: '',
					badgeMiddleNm: '',
					badgeSubId: '',
					badgeSubNm: t('badge.addBadge'),
					badgeLevel: 0,
					status: '',
				},
			];
			setBadgeData([...list, ...addMyRow]);
		});
	}, [myProfileOpen]);

	/*
	 * fnSavePostLike
	 * 게시글 좋아요
	 */
	const fnSavePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		savePostLike(map);
	};

	/*
	 * initProfile
	 * 뒤로가기 클릭 시 프로필 관련 설정값 초기화
	 */
	const initProfile = () => {
		setMyProfileOpen(false);
		setProfileDetails([]);
	};

	const navigate = useNavigate();
	useEffect(() => {
		// 내 프로필일 때
		getMyProfile(myProfile.phoneNumber).then((value) => {
			if (value.profileUrl.length > 0) {
				setFadeIn(true);
				setMyProfile(value);
				initProfileDetail(value);
			} else {
				// profileUrl.length === 0 인 경우 에러처리
				// 토큰초기화 및 메인으로 이동
				// initAuthToken();
				navigate(PATH.REGISTER.REGISTER);
				return;
			}
		});

		setFadeIn(false);
		getPoint().then((value) => {
			if (value) {
				setMyBerith(value.point);
			}
		});
	}, []);

	// 메타버스 Room 안에서 프로필 수정 시 프로필 이미지 변경
	const handleProfileUpdate = useCallback(() => {
		getMyProfile(myProfile.phoneNumber)
			.then((value) => {
				console.log('Profile update value:', value);
				if (value.profileUrl.length > 0) {
					setMyProfile(value);
					initProfileDetail(value);
					reactEventEmitter.emit(
						'react-player-change-profile-url',
						value.profileUrl[0],
					);
					dmEventEmitter.emit('react-update-dm-profile-url', {
						userUid: myProfile.userUid,
						profileUrl: value.profileUrl[0],
					});
				} else {
					// profileUrl.length === 0 인 경우 에러처리
					// 토큰초기화 및 메인으로 이동
					// initAuthToken();
					navigate(PATH.REGISTER.REGISTER);
					return;
				}
			})
			.catch((error) => {
				console.error('Error in getMyProfile:', error);
			})
			.finally(() => {
				setModifyProfileUrl(false);
			});
	}, []); // 의존성 배열 비움

	useEffect(() => {
		if (modifyProfileUrl) {
			handleProfileUpdate();
		}
	}, [modifyProfileUrl, handleProfileUpdate]);

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feeds: PostInfoCOVO[]) => {
		let newItem = {};
		feeds.map((feed, i) => {
			newItem[feed.postId] = false;
		});
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		initFeedEditPops(myPosts);
		setIsClickedFeedMenu({ postId: postId, state: true });
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);

	const formatDate = (isoDate: string): string => {
		return new Date(isoDate).toISOString().split('T')[0];
	};

	return {
		fadeIn,
		scrollHandler,
		myPosts,
		deleteContent,
		myProfile,
		setModifyPostId,
		initProfile,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		fnSavePostLike,
		badgeData,
		setBadgeData,
		myBerith,
		setSettingOpen,
		srcPrefix,
		myProfileOpen,
		formatDate,
		clearPreviewURLs,
		setMyProfileOpen,
	};
};
