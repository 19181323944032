import { useStoreRepo } from '@dating/repository/store/useStoreRepo';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useEffect } from 'react';
import { usePointAdapter } from '@dating/adapter/point/usePointAdapter';
import { bridgePurchase } from '@client/utils/bridgeCallHandler';
import { useOrderAdapter } from '@dating/adapter/order/useOrderAdapter';
import {
	type PurchaseResultCIVO,
	type TemporayPointChargeCIVO,
} from '@client/site/api';
import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useTranslation } from 'react-i18next';

export const useStoreService = () => {
	const { myBerith, setMyBerith, managerPack } = useStoreRepo();

	const { managerProducts, pointProducts } = useProductsRepo();

	const { isSuccess, setIsSuccess, currencyCode } = useOrderRepo();

	const { getPoint } = usePointAdapter();

	const { temporaryPointCharge } = useOrderAdapter();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	const { t } = useTranslation('store');
	/////////////////////////

	/*
	 * fnGetPoint
	 * 포인트 충전(입앱결제 호출)
	 */
	const fnGetPoint = () => {
		getPoint().then((value) => {
			setMyBerith(value.point);
		});
	};

	useEffect(() => {
		fnGetPoint();
	}, []);

	useEffect(() => {
		if (isSuccess == 'success') fnGetPoint();
	}, [isSuccess]);

	/*
	 * fnChargePoint
	 * 포인트 충전(인앱결제 호출)
	 */
	const fnChargePoint = (productId: number, productKey: string) => {
		if (import.meta.env.MODE == 'prd') {
			// 운영
			fnCommonAlertPopOn(t('serviceReady'));
		} else {
			// 개발
			if (!(window as any).flutter_inappwebview) {
				// 웹
				const map: TemporayPointChargeCIVO = {
					productId: productId,
				};
				temporaryPointCharge(map)
					.then((value) => {
						if (value) {
							setMyBerith(value.myPoint);
						}
					})
					.catch((reason) => {
						alert(reason.body.message);
					});
			} else {
				// 앱
				bridgePurchase(productKey).then((response) => {
					if (response) {
						console.log(response);
					}
				});
			}
		}
	};

	return {
		managerProducts,
		pointProducts,
		myBerith,
		managerPack,
		fnChargePoint,
		currencyCode,
	};
};
