import OtherPlayer from '@virtual-space/metaverse/characters/otherplayer/OtherPlayer';
import GameScene from '../GameScene';

export class SetupPlayer {
	private roomTheme;

	constructor(roomTheme: any) {
		this.roomTheme = roomTheme;
	}
	execute(gameScene: GameScene) {
		if (gameScene.myPlayer) {
			gameScene.myPlayer.destroy();
		}
		// 다른 플레이어 그룹 생성
		gameScene.otherPlayers = gameScene.physics.add.group({
			classType: OtherPlayer,
		});
		// 내 플레이어 생성 및 초기 위치 설정
		gameScene.myPlayer = gameScene.add.myPlayer(
			this.roomTheme.setUpPlayer.x,
			this.roomTheme.setUpPlayer.y,
			'b1c1f1h1cc1fc1hc1',
			gameScene.roomService.sessionId,
			'',
		);

		// 나와 다른 플레이어그룹의 충돌을 감지
		gameScene.physics.add.overlap(
			gameScene.myPlayer,
			gameScene.otherPlayers,
			gameScene.handlePlayersOverlap,
			undefined,
			gameScene,
		);

		gameScene.myPlayer.setDepth(2000);
	}
}
