import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {useReferralCodeRepo} from "@dating/repository/register/useReferralCodeRepo";
import { useRegisterRepo } from "@dating/repository/register/useRegisterRepo";

export const useRegisterHeaderService = () => {
    const {
        referralCodeOpen,
        setReferralCodeOpen,
    } = useReferralCodeRepo();

    const {
        step,
        phoneNumber,
    } = useRegisterRepo();

    const {
        skipRecommender,
    } = useRegisterAdapter();
    ///////////////////////////////////////

    /*
     * skipHandler
     * 건너뛰기 클릭 시 버튼 hide, api 요청
     */
    const skipHandler = () => {
        skipRecommender();
    };

    return {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
        phoneNumber,
    };
};