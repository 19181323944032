import React from 'react';
import {
	MessageGroup,
	MessageGroupListProps,
} from '../../types/navigationTypes';
import MessageItemView from './MessageItemView';

/**
 * 메시지 그룹 리스트 컴포넌트
 * 메시지 그룹들을 모아서 표시합니다.
 */
const MessageGroupListView: React.FC<MessageGroupListProps> = ({
	groups,
	myProfile,
	srcPrefix,
	isOtherUserLeft,
	blockStatus,
	setUserProfileOpen,
	navigationRoom,
	handleRoomMessageClick,
}) => {
	const renderMessageGroup = (group: MessageGroup, index: number) => {
		const isMyMessage = group?.sender === myProfile?.userUid;

		return group.messages.map((message, msgIndex) => {
			const isFirstInGroup = msgIndex === 0;
			const isLastInGroup = msgIndex === group.messages.length - 1;

			return (
				<MessageItemView
					key={`${message.messageId}-${msgIndex}`}
					message={message}
					isMyMessage={isMyMessage}
					isFirstInGroup={isFirstInGroup}
					isLastInGroup={isLastInGroup}
					srcPrefix={srcPrefix}
					isOtherUserLeft={isOtherUserLeft}
					blockStatus={blockStatus}
					setUserProfileOpen={setUserProfileOpen}
					navigationRoom={navigationRoom}
					myProfile={myProfile}
					handleRoomMessageClick={handleRoomMessageClick}
				/>
			);
		});
	};

	return (
		<>
			{groups.map((group, groupIndex) => renderMessageGroup(group, groupIndex))}
		</>
	);
};

export default MessageGroupListView;
