import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
interface props {
	isOnline: boolean;
	setIsOnline: (isOnline: boolean) => void;
	setShowOnlineMessageList: (show: boolean) => void;
	setShowOfflineMessageList: (show: boolean) => void;
}

const MyManagerTabView = ({
	setIsOnline,
	isOnline,
	setShowOnlineMessageList,
	setShowOfflineMessageList,
}: props) => {
	const { t } = useTranslation('messenger');

	const TABS = {
		ONLINE: {
			title: t('online'),
			path: PATH.MESSENGER.MANAGER_ONLINE,
			type: '온라인',
		},
		OFFLINE: {
			title: t('offline'),
			path: PATH.MESSENGER.MANAGER_OFFLINE,
			type: '오프라인',
		},
	} as const;

	const navigate = useNavigate();

	const handleOnlineClick = (isOnline: boolean) => {
		setIsOnline(isOnline);
		setShowOfflineMessageList(false);
		setShowOnlineMessageList(true);
		navigate(isOnline ? TABS.ONLINE.path : TABS.OFFLINE.path);
	};

	const handleOfflineClick = (isOnline: boolean) => {
		setIsOnline(isOnline);
		setShowOfflineMessageList(true);
		setShowOnlineMessageList(false);
		navigate(isOnline ? TABS.ONLINE.path : TABS.OFFLINE.path);
	};

	return (
		<div className="myCateList">
			<button
				className={isOnline ? 'active' : ''}
				onClick={() => handleOnlineClick(true)}
			>
				{TABS.ONLINE.title}
			</button>
			<button
				className={!isOnline ? 'active' : ''}
				onClick={() => handleOfflineClick(false)}
			>
				{TABS.OFFLINE.title}
			</button>
		</div>
	);
};

export default MyManagerTabView;
