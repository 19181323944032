import { PostInfoCOVO, UserProfileCOVO } from '@client/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	post: PostInfoCOVO;
	commentListHandler: (
		postId: string,
		postUser: { userUid: string; nickname: string },
	) => void;
	myProfile: UserProfileCOVO;
	srcPrefix: string;
	commentCount: number | undefined;
	commentViewHandler: (state: boolean) => void;
	setFocusedPostId: (val: string) => void;
}

const FeedContentView = ({
	post,
	commentListHandler,
	myProfile,
	srcPrefix,
	commentCount,
	commentViewHandler,
	setFocusedPostId,
}: props) => {
	const { t } = useTranslation('lounge');

	return (
		<div className="protxt01">
			{/*내용*/}
			<p className="fz14 color-666">{post.content}</p>

			{/*태그*/}
			<div className="tagList">
				{post.tag?.map((item, i) => {
					return (
						<a key={item + i} href="#n">
							{item}
						</a>
					);
				})}
			</div>

			{/*코멘트*/}
			<div className="commentWrap">
				{commentCount && commentCount > 0 ? (
					<button
						type="button"
						className="fz12 color-666 commentAdd"
						onClick={() => {
							commentListHandler(post.postId, {
								userUid: post.postUserUid,
								nickname: post.postNickName,
							});
							commentViewHandler(true);
							setFocusedPostId(post.postId);
						}}
					>
						{t('seeComment', { commentCount: commentCount })}
					</button>
				) : null}

				{/*댓글 추가*/}
				{myProfile.role === 'NORMAL' ? (
					<div className="commentPro margin-t8">
						{/*<Link to="/metaverse/dating/profile">*/}
						<div className="proImg Sm">
							<img
								src={
									myProfile && myProfile?.profileUrl[0]
										? srcPrefix + myProfile?.profileUrl[0]
										: '/assets/img/test/Rectangle93.jpg'
								}
								alt="테스트이미지"
							/>
						</div>
						{/*</Link>*/}
						<button
							type="button"
							className="commentAdd"
							onClick={() => {
								commentListHandler(post.postId, {
									userUid: post.postUserUid,
									nickname: post.postNickName,
								});
								commentViewHandler(true);
							}}
						>
							<p className="fz14 color-666">{t('addComment')}</p>
						</button>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default FeedContentView;
