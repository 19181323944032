import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { useState, useEffect } from 'react';

/** 방 상태 관리를 위한 서비스 */
export const useRoomStateService = () => {
	const { setPreLeftRoomId } = useRoomRepo();
	const [isRoomDataReady, setIsRoomDataReady] = useState(false);
	const [shouldCreateRoom, setShouldCreateRoom] = useState(false);
	const [joinedRoomId, setJoinedRoomId] = useState<string | null>(null);

	useEffect(() => {
		const handleRoomDataChange = (isReady: boolean) => {
			setIsRoomDataReady(isReady);
		};
		const handleLeaveRoomId = (roomId: string) => {
			setPreLeftRoomId(roomId);
		};
		reactEventEmitter.on('react-leave-room', handleLeaveRoomId);
		reactEventEmitter.on('react-change-room-data', handleRoomDataChange);
		return () => {
			reactEventEmitter.off('react-leave-room', handleLeaveRoomId);
			reactEventEmitter.off('react-change-room-data', handleRoomDataChange);
		};
	}, [setPreLeftRoomId]);

	return {
		isRoomDataReady,
		setIsRoomDataReady,
		shouldCreateRoom,
		setShouldCreateRoom,
		joinedRoomId,
		setJoinedRoomId,
	};
};
