import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {
    type ProfileDrinkCIVO,
    type ProfileHeightCIVO,
    type ProfileInterestCIVO,
    type ProfileIntroCIVO,
    type ProfileJobCIVO,
    type ProfileLifeStyleCIVO,
    type ProfileNicknameCIVO,
    type ProfileReligionCIVO,
    ProfileService,
    type ProfileSmokingCIVO,
    type UserScoreCIVO,
    UserService,
} from '@client/site/api';
import useProfileRepo from "@dating/repository/profile/useProfileRepo";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";

export const useProfileAdapter = () => {
    const queryClient = useQueryClient();

    const {
        myProfileOpen,
    } = useDatingRepo();

    /*
     * getMyProfile
     * 내 프로필 상세조회
     */
    const getMyProfile = (phoneNumber: string) => {
        return ProfileService.getMyProfile(phoneNumber);
    };

    /*
     * getModifyMyProfile
     * 내 프로필 수정 조회
     */
    const getModifyMyProfile = useQuery({
        queryKey: ['getModifyMyProfile'],
        queryFn: () => {
            return ProfileService.getModifyMyProfile();
        },
        enabled: myProfileOpen,
    });

    /*
     * getUserProfile
     * 유저 프로필 상세조회
     */
    const getUserProfile = (userProfileOpen: string) => {
        return ProfileService.getUserProfile(userProfileOpen);
    };

    /*
    * getMyPosts
    * 내 프로필 화면에서 내가 작성한 포스트 조회
    */
    const getMyPosts = () => {
        return ProfileService.getMyPosts();
    };

    /*
     * modifyNickName
     * 닉네임 수정
     */
    const modifyNickName = useMutation({
        mutationFn: (map: ProfileNicknameCIVO) => {
            return UserService.saveNickname(map);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
        },
    });

    /*
     * modifyJob
     * 직업 수정
     */
    const modifyJob = useMutation({
        mutationFn: (map: ProfileJobCIVO) => {
            return ProfileService.modifyProfileJob(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * modifyHeight
     * 키 수정
     */
    const modifyHeight = useMutation({
        mutationFn: (map: ProfileHeightCIVO) => {
            return ProfileService.modifyProfileHeight(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * modifyProfileIntro
     * 자기소개 수정
     */
    const modifyProfileIntro = useMutation({
        mutationFn: (map: ProfileIntroCIVO) => {
            return ProfileService.modifyProfileIntro(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            // return data;
        },
    });

    /*
     * modifyProfileLifeStyle
     * 라이프스타일 수정
     */
    const modifyProfileLifeStyle = useMutation({
        mutationFn: (map: ProfileLifeStyleCIVO) => {
            return ProfileService.modifyProfileLifeStyle(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * modifyProfileInterest
     * 관심사 수정
     */
    const modifyProfileInterest = useMutation({
        mutationFn: (map: ProfileInterestCIVO) => {
            return ProfileService.modifyProfileInterest(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * modifyDrink
     * 음주 정보 수정
     */
    const modifyDrink = useMutation({
        mutationFn: (map: ProfileDrinkCIVO) => {
            return ProfileService.modifyProfileDrink(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
        },
    });

    /*
     * modifySmoking
     * 흡연 정보 수정
     */
    const modifySmoking = useMutation({
        mutationFn: (map: ProfileSmokingCIVO) => {
            return ProfileService.modifyProfileSmoking(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * modifyReligion
     * 종교 정보 수정
     */
    const modifyReligion = useMutation({
        mutationFn: (map: ProfileReligionCIVO) => {
            return ProfileService.modifyProfileReligion(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * getUserPrefer
     * 이상형조회
     */
    const getUserPrefer = () => {
        return ProfileService.getUserPrefer();
    };

    /*
     * modifyProfileImage
     * 프로필 이미지 수정
     */
    const modifyProfileImage = useMutation({
        mutationFn: (map: { files?: Array<Blob> }) => {
            return ProfileService.modifyProfileImage(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getModifyMyProfile']});
            return data;
        },
    });

    /*
     * saveProfileAuth
     * Badge 신청
     */
    const saveProfileAuth = useMutation({
        mutationFn: (contents: {}) => {
            return ProfileService.saveProfileBadge(contents);
        },
        onSuccess: (data) => {
        },
    });

    /*
     * getBadge
     * 뱃지 조회
     */
    const getBadge = () => {
        return ProfileService.getBadge();
    };

    /*
     * getUsersBadge
     * 상대방 회원 뱃지 조회
     */
    const getUsersBadge = (uuid: string) => {
        return ProfileService.getUsersBadge(uuid);
    };

    /*
     * getManagerProfile
     * 내가 구매한 매니저의 프로필 조회
     */
    const getManagerProfile = (findManagerUid: string) => {
        return ProfileService.getManagerProfile(findManagerUid);
    };

    /*
     * saveScoreUser
     * 유저프로필 내 별점평가
     */
    const saveScoreUser = (map: UserScoreCIVO) => {
        return ProfileService.saveScoreUser(map);
    };

    return {
        getManagerProfile,
        getUserProfile,
        modifyNickName: modifyNickName.mutate,
        statusModifyNickname: modifyNickName.status,
        dataModifyNickname: modifyNickName.data,
        modifyJob: modifyJob.mutate,
        statusModifyJob: modifyJob.status,
        dataModifyJob: modifyJob.data,
        modifyHeight: modifyHeight.mutate,
        statusModifyHeight: modifyHeight.status,
        dataModifyHeight: modifyHeight.data,
        modifyIntro: modifyProfileIntro.mutate,
        statusModifyIntro: modifyProfileIntro.status,
        dataModifyIntro: modifyProfileIntro.data,
        modifyProfileLifeStyle: modifyProfileLifeStyle.mutate,
        statusModifyProfileLifeStyle: modifyProfileLifeStyle.status,
        dataModifyProfileLifeStyle: modifyProfileLifeStyle.data,
        modifyProfileInterest: modifyProfileInterest.mutate,
        statusModifyProfileInterest: modifyProfileInterest.status,
        dataModifyProfileInterest: modifyProfileInterest.data,
        modifyDrink: modifyDrink.mutate,
        statusModifyDrink: modifyDrink.status,
        dataModifyDrink: modifyDrink.data,
        modifySmoking: modifySmoking.mutate,
        statusModifySmoking: modifySmoking.status,
        dataModifySmoking: modifySmoking.data,
        modifyReligion: modifyReligion.mutate,
        statusModifyReligion: modifyReligion.status,
        dataModifyReligion: modifyReligion.data,
        getUserPrefer,
        getMyProfile,
        getModifyMyProfile,
        modifyProfileImage: modifyProfileImage.mutate,
        statusModifyProfileImage: modifyProfileImage.status,
        dataModifyProfileImage: modifyProfileImage.data,
        saveProfileAuth: saveProfileAuth.mutate,
        statusProfileAuth: saveProfileAuth.status,
        dataProfileAuth: saveProfileAuth.data,
        getBadge,
        getUsersBadge,
        saveScoreUser,
        getMyPosts,
    };
};
