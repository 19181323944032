import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useMessageAdapter } from '@client/site/dating/adapter/message/useMessageAdapter';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import useLeaveChatStore from '@client/site/dating/stores/LeaveChatStore';
import { useBlockReportService } from '@client/site/dating/ui/components/blockReport/service/useBlockReportService';
import { useRef, useMemo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCommonDMService = () => {
	const { t } = useTranslation('messenger');
	const { myProfile } = useDatingRepo();
	const { openReport, setOpenReport, openBlock, setOpenBlock } =
		useBlockReportService();

	const {
		focused,
		dmSessionId,
		openDirectMessageDetail,
		showDirectMessageList,
		messageInputValue,
		directMessageList,
		unreadMessageCounts,
		groupedMessages,
		detailDirectMessages,
		directMessageRoomList,
		setDirectMessageList,
		setDetailDirectMessages,

		normalDirectMessageList,
		managerOnlineDirectMessageList,
		managerOfflineDirectMessageList,
		setNormalDirectMessageList,
		setManagerOnlineDirectMessageList,
		setManagerOfflineDirectMessageList,

		showOnlineMessageList,
		showOfflineMessageList,
	} = useDirectMessageRepo();
	const { getMessageRoomList } = useMessageAdapter();

	const [isPolling, setIsPolling] = useState(false);
	const currentPath = location.pathname;
	const isOfflineMessengerPath =
		myProfile.role === 'NORMAL'
			? currentPath === PATH.MESSENGER.MANAGER_OFFLINE
			: currentPath === PATH.MANAGER.MESSENGER.OFFLINE;
	const inputRef = useRef<HTMLInputElement>(null);
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const processedMessageList = useMemo(() => {
		if (!directMessageRoomList.length) return [];

		return directMessageRoomList
			.map((room) => {
				const messages = groupedMessages[room.roomId]?.messages;
				if (!messages) return null;

				try {
					const processedMessages = Array.isArray(messages)
						? messages // 이미 배열 형태라면 그대로 사용
						: Array.from(messages.values()); // MapSchema일 경우 배열로 변환
					return {
						roomId: room.roomId,
						user1Id: room.user1Id,
						user2Id: room.user2Id,
						user1Info: room.user1Info,
						user2Info: room.user2Info,
						dmRoomType: room.dmRoomType,
						billingStatus: room.billingStatus,
						visibleTo: room.visibleTo,
						unReadCnt1: room.unReadCnt1,
						unReadCnt2: room.unReadCnt2,
						createdAt: room.createdAt,
						productType: room.productType,
						productSubType: room.productSubType,
						useStatus: room.useStatus,
						seq: room.seq,
						validUses: room.validUses,
						orderId: room.orderId,
						matchedAt: room.matchedAt,
						messages: processedMessages,
					};
				} catch (error) {
					console.error('Error processing messages for room:', room.roomId);
					return null;
				}
			})
			.filter(Boolean);
	}, [directMessageRoomList, groupedMessages]);

	// 상세 메시지 처리를 메모이제이션
	const processedDetailMessages = useMemo(() => {
		return processedMessageList.reduce((acc, room) => {
			if (room?.messages) {
				acc[room.roomId] = {
					roomId: room.roomId,
					messages: room.messages,
				};
			}
			return acc;
		}, {} as Record<string, any>);
	}, [processedMessageList]);

	// 실제 상태 업데이트는 값이 변경되었을 때만
	useEffect(() => {
		if (processedMessageList.length > 0) {
			// 전체 메시지 리스트 업데이트
			const currentMessageListString = JSON.stringify(directMessageList);
			const newMessageListString = JSON.stringify(processedMessageList);
			if (currentMessageListString !== newMessageListString) {
				setDirectMessageList(processedMessageList);
			}
			// 일반 유저 메시지 필터링
			const normalMessages = processedMessageList.filter(
				(message) => message !== null && message.productType !== 'MANAGER',
			);
			// 매니저 온라인 메시지 필터링
			const managerOnlineMessages = processedMessageList.filter(
				(message) =>
					message !== null &&
					message !== null &&
					message.productType === 'MANAGER' &&
					message.productSubType === 'ONLINE',
			);

			// 매니저 오프라인 메시지 필터링
			const managerOfflineMessages = processedMessageList.filter(
				(message) =>
					message !== null &&
					message.productType === 'MANAGER' &&
					message.productSubType === 'OFFLINE',
			);

			// 일반 메시지 업데이트
			const currentNormalListString = JSON.stringify(normalDirectMessageList);
			const newNormalListString = JSON.stringify(normalMessages);
			if (currentNormalListString !== newNormalListString) {
				setNormalDirectMessageList(normalMessages);
			}

			// 매니저 온라인 메시지 업데이트
			const currentManagerOnlineListString = JSON.stringify(
				managerOnlineDirectMessageList,
			);
			const newManagerOnlineListString = JSON.stringify(managerOnlineMessages);
			if (currentManagerOnlineListString !== newManagerOnlineListString) {
				setManagerOnlineDirectMessageList(managerOnlineMessages);
			}

			// 매니저 오프라인 메시지 업데이트
			const currentManagerOfflineListString = JSON.stringify(
				managerOfflineDirectMessageList,
			);
			const newManagerOfflineListString = JSON.stringify(
				managerOfflineMessages,
			);
			if (currentManagerOfflineListString !== newManagerOfflineListString) {
				setManagerOfflineDirectMessageList(managerOfflineMessages);
			}
		}
	}, [processedMessageList]);

	useEffect(() => {
		if (Object.keys(processedDetailMessages).length > 0) {
			// 전체 디테일 메시지 업데이트
			const currentDetailString = JSON.stringify(detailDirectMessages);
			const newDetailString = JSON.stringify(processedDetailMessages);
			if (currentDetailString !== newDetailString) {
				setDetailDirectMessages(processedDetailMessages);
			}
		}
	}, [processedDetailMessages, processedMessageList]);

	const {
		openChatMenuPop,
		openLeaveChat,
		setOpenChatMenuPop,
		setOpenLeaveChat,
	} = useLeaveChatStore();

	function modifyMenuPop(e: { stopPropagation: () => void }) {
		e.stopPropagation();
		if (!openChatMenuPop) {
			setOpenChatMenuPop(true);
		} else {
			setOpenChatMenuPop(false);
		}
	}

	const formatMessageDate = (dateStr: string) => {
		const today = new Date();
		const messageDate = new Date(dateStr.replace(/\./g, '-'));
		const dayOfWeek = t(
			`${
				[
					'sunday',
					'monday',
					'tuesday',
					'wednesday',
					'thursday',
					'friday',
					'saturday',
				][messageDate.getDay()]
			}`,
		);
		if (
			today.getFullYear() === messageDate.getFullYear() &&
			today.getMonth() === messageDate.getMonth() &&
			today.getDate() === messageDate.getDate()
		) {
			return t('today');
		}

		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		if (
			yesterday.getFullYear() === messageDate.getFullYear() &&
			yesterday.getMonth() === messageDate.getMonth() &&
			yesterday.getDate() === messageDate.getDate()
		) {
			return t('yesterday');
		}

		return `${dateStr} ${dayOfWeek}`;
	};

	const getStatusMessage = (blockType: string) => {
		switch (blockType) {
			case 'block':
				return t('blockQuote');
			case 'blocked':
				return t('blockedQuote');
			case 'none':
			default:
				return '';
		}
	};

	useEffect(() => {
		if (openChatMenuPop) {
			document.addEventListener('click', menuDotHandler);
		}
	}, [openChatMenuPop]);

	function menuDotHandler() {
		setOpenChatMenuPop(false);
	}

	// 메시지 리스트 새로고침 함수 추가
	const refreshDirectMessageList = useCallback(async () => {
		try {
			// 현재 메시지 리스트 초기화
			setDirectMessageList([]);
			setNormalDirectMessageList([]);
			setManagerOnlineDirectMessageList([]);
			setManagerOfflineDirectMessageList([]);

			// DM 룸 리스트 새로 요청
			dmEventEmitter.emit('react-get-dm-rooms');

			// 데이터가 업데이트될 때까지 기다림
			await new Promise((resolve) => {
				let attempts = 0;
				const maxAttempts = 10;
				const checkInterval = setInterval(() => {
					attempts++;
					if (
						(processedMessageList.length > 0 &&
							Object.keys(processedDetailMessages).length > 0) ||
						attempts >= maxAttempts
					) {
						clearInterval(checkInterval);
						resolve(true);
					}
				}, 300);
			});

			return true;
		} catch (error) {
			console.error('Failed to refresh direct message list:', error);
			throw error;
		}
	}, [
		setDirectMessageList,
		setNormalDirectMessageList,
		setManagerOnlineDirectMessageList,
		setManagerOfflineDirectMessageList,
		directMessageRoomList,
		processedMessageList.length,
	]);

	useEffect(() => {
		if (!isOfflineMessengerPath) return;

		const abortController = new AbortController();
		let timeoutId: NodeJS.Timeout | null = null;

		const fetchAndProcessData = async () => {
			if (isPolling) return;

			try {
				setIsPolling(true);
				const response = await getMessageRoomList(myProfile.userUid);

				if (abortController.signal.aborted) return;

				const newData = Array.isArray(response) ? response : [response];
				const newOfflineMessages = newData.filter(
					(message) =>
						message.productType === 'MANAGER' &&
						message.productSubType === 'OFFLINE',
				);

				newOfflineMessages.forEach((newMessage) => {
					const existingMessage = managerOfflineDirectMessageList.find(
						(msg) => msg.roomId === newMessage.roomId,
					);

					if (
						existingMessage?.useStatus !== 'DONE' &&
						newMessage.useStatus === 'DONE'
					) {
						dmEventEmitter.emit('react-change-dm-use-status', {
							roomId: newMessage.roomId,
							useStatus: newMessage.useStatus,
							matchedAt: newMessage.matchedAt,
						});
					}
				});

				if (!abortController.signal.aborted) {
					timeoutId = setTimeout(fetchAndProcessData, 2000);
				}
			} catch (error) {
				console.error('Error checking offline message rooms:', error);
			} finally {
				setIsPolling(false);
			}
		};

		fetchAndProcessData();

		return () => {
			abortController.abort();
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			setIsPolling(false);
		};
	}, []); // 의존성 배열을 비워서 마운트 시 한 번만 실행

	return {
		openReport,
		openBlock,
		groupedMessages,
		detailDirectMessages,
		focused,
		openDirectMessageDetail,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		messagesEndRef,
		unreadMessageCounts,
		setOpenReport,
		setOpenBlock,
		modifyMenuPop,
		setOpenLeaveChat,
		setDirectMessageList,
		setDetailDirectMessages,

		normalDirectMessageList,
		managerOnlineDirectMessageList,
		managerOfflineDirectMessageList,
		setNormalDirectMessageList,
		setManagerOnlineDirectMessageList,
		setManagerOfflineDirectMessageList,

		showOnlineMessageList,
		showOfflineMessageList,
		refreshDirectMessageList,

		formatMessageDate,
		getStatusMessage,
	};
};
