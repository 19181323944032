import { useEventAdapter } from '@dating/adapter/event/useEventAdapter';
import { useEffect, useState } from 'react';
import { AttendanceCOVO } from '@client/site/api';

export const useAttendCheckService = () => {
	const [attendChecks, setAttendChecks] = useState<AttendanceCOVO>({
		attendCnt: 0,
		status: '',
	});

	const {
		getDailyAttendance,
		statusGetDailyAttendance,
		checkDailyAttendance,
		dataCheckDailyAttendance,
		statusCheckDailyAttendance,
	} = useEventAdapter();

	/*
	 * fnCheckDailyAttendance
	 * 출석체크하기
	 */
	const fnCheckDailyAttendance = () => {
		checkDailyAttendance();
	};

	useEffect(() => {
		if (statusGetDailyAttendance === 'success' && getDailyAttendance) {
			setAttendChecks(getDailyAttendance);
		}
	}, [statusGetDailyAttendance, getDailyAttendance]);

	useEffect(() => {
		if (statusCheckDailyAttendance === 'success' && dataCheckDailyAttendance) {
		}
	}, [dataCheckDailyAttendance, statusCheckDailyAttendance]);

	return {
		attendChecks,
		fnCheckDailyAttendance,
	};
};
