import { Link } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';

const UserProfileImageView = ({
	isOtherUserLeft,
	otherUserInfo,
	srcPrefix,
	onClick,
}) => {
	if (
		isOtherUserLeft ||
		(otherUserInfo.blockType && otherUserInfo.blockType !== 'none')
	) {
		return (
			<div className="proImg">
				<img src="/assets/img/common/profile_no.svg" alt="기본 프로필" />
			</div>
		);
	}

	return (
		<Link
			to={PATH.PROFILE.USER_PROFILE}
			onClick={onClick}
			state={{ isOtherProfile: true }}
		>
			<div className="proImg">
				<img
					src={srcPrefix + otherUserInfo.profileUrl}
					alt={otherUserInfo.nickName}
				/>
			</div>
		</Link>
	);
};

export default UserProfileImageView;
