import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoomListService } from '../components/room/roomList/service/useRoomListService';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useProductsRepo } from '@client/site/dating/repository/products/useProductsRepo';
import { useChatRepo } from '@virtual-space/stores/useChatRepo';
import { useItemRepo } from '@virtual-space/stores/useItemRepo';
import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { useVideoRepo } from '@virtual-space/stores/useVideoRepo';
import { useHeaderService } from '@client/site/dating/ui/pages/header/useHeaderService';
import useImgCropRepo from '@client/site/dating/repository/imgCrop/useImgCropRepo';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';

export const usePhaserAppService = () => {
	const {
		passwordError,
		joinedRoomData,
		roomDisposedPop,
		showPasswordInput,
		setPasswordError,
		setRoomDisposedPop,
		setShowPasswordInput,
	} = useRoomRepo();
	const isPublicRoom = joinedRoomData?.name.toLowerCase() === 'public';
	const { modal } = useItemRepo();
	const { showChat, focused, mobileChatInputClick, setFocused } = useChatRepo();
	const { openCamExpansion } = useVideoRepo();
	const isOpen = openCamExpansion.isOpen;
	const { myProfile, srcPrefix } = useHeaderService();
	const { showImgCrop } = useImgCropRepo();
	const { setMyProfileOpen } = useDatingRepo();
	const { handlePasswordSubmit } = useRoomListService();

	const { managerProducts } = useProductsRepo();
	const navigate = useNavigate();

	useEffect(() => {
		if (showImgCrop) {
			navigate(PATH.COMMON.IMG_CROP);
		}
	}, [showImgCrop]);

	return {
		modal,
		isOpen,
		focused,
		showChat,
		myProfile,
		srcPrefix,
		isPublicRoom,
		passwordError,
		roomDisposedPop,
		showPasswordInput,
		mobileChatInputClick,
		setFocused,
		setMyProfileOpen,
		setPasswordError,
		setRoomDisposedPop,
		setShowPasswordInput,
		handlePasswordSubmit,
		managerProducts,
	};
};
