import { create } from 'zustand';
import { BadgeFormType, BadgeFormTypeAll } from '@dating/model/ProfileModel';
import { UserAuthBadgeCOVO } from '@client/site/api';

interface profileState {
	authBadgeForm: BadgeFormType;
	setAuthBadgeForm: (badgeForm: BadgeFormTypeAll) => void;
	clearAuthBadgeForm: () => void;
	btnActivate: boolean;
	setBtnActivate: (val: boolean) => void;
	badgeTypeKor: string;
	setBadgeTypeKor: (val: string) => void;
	badgeData: UserAuthBadgeCOVO[];
	setBadgeData: (list: UserAuthBadgeCOVO[]) => void;
}

const useProfileRepo = create<profileState>((set) => ({
	authBadgeForm: {
		files: [],
		badgeType: '',
		contents: '',
	},
	setAuthBadgeForm: (val: BadgeFormTypeAll) =>
		set((state) => ({ authBadgeForm: { ...state.authBadgeForm, ...val } })),
	clearAuthBadgeForm: () =>
		set({
			authBadgeForm: {
				files: [],
				badgeType: '',
				contents: '',
			},
		}),
	btnActivate: false,
	setBtnActivate: (val: boolean) => set({ btnActivate: val }),
	badgeTypeKor: '',
	setBadgeTypeKor: (val: string) => set({ badgeTypeKor: val }),
	badgeData: [],
	setBadgeData: (list: UserAuthBadgeCOVO[]) => set({ badgeData: list }),
}));

export default useProfileRepo;
