import { Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Spinner from '@dating/ui/components/spinner/Spinner';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';

const UserProfileContainer = lazy(
	() => import('@dating/ui/pages/userProfile/UserProfileContainer'),
);

export default function UserProfileRouter() {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Suspense fallback={<Spinner />}>
				<Routes location={location}>
					<Route path="*" element={<UserProfileContainer />} />
				</Routes>
			</Suspense>
		</ErrorBoundary>
	);
}
