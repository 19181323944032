import { SettingTermsCOVO } from '@client/site/api';
import { Link } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

interface props {
	allAgreement: boolean;
	checkboxHandler: (id: string, checked: boolean) => void;
	checkedAgreements: {};
	handleAllCheckBox: (any) => void;
	termsList: SettingTermsCOVO[];
	setSelectedTerm: (selectedTerm) => void;
}

const AgreementTermsContentsView = ({
	allAgreement,
	checkboxHandler,
	checkedAgreements,
	handleAllCheckBox,
	termsList,
	setSelectedTerm,
}: props) => {
	const { t } = useTranslation('register');

	return (
		<div className="contentsWrap">
			<div className="titleWrap">
				<h2 className="title01">{t('termsStep.consentTerms')}</h2>
				<p
					className="color-999 fz16 margin-t16"
					dangerouslySetInnerHTML={{ __html: t('termsStep.termsQuote') }}
				></p>
			</div>
			<div className="checkWrap checkList">
				{termsList?.map((term, i) => {
					if (term.required) {
						return (
							<div key={term.type + term.termsId}>
								<input
									id={term.termsId}
									className="checkbox"
									name="checkbox-group"
									type="checkbox"
									onChange={(e) => {
										checkboxHandler(e.target.id, e.target.checked);
									}}
									checked={
										checkedAgreements[term.termsId]
											? checkedAgreements[term.termsId]
											: false
									}
								/>
								<label htmlFor={term.termsId} className="checkbox-label">
									<Link
										to={`${PATH.SETTINGS.HOME}/policy0` + (i + 1).toString()}
										onClick={() => {
											setSelectedTerm(term.contents);
										}}
									>
										<span className="main-color">
											({t('termsStep.essential')})
										</span>
										{term.contents}
									</Link>
								</label>
							</div>
						);
					} else {
						return (
							<div key={term.type + term.termsId}>
								<input
									id={term.termsId}
									className="checkbox"
									name="checkbox-group"
									type="checkbox"
									onChange={(e) => {
										checkboxHandler(e.target.id, e.target.checked);
									}}
									checked={
										checkedAgreements[term.termsId]
											? checkedAgreements[term.termsId]
											: false
									}
								/>
								<label htmlFor={term.termsId} className="checkbox-label">
									<span>({t('termsStep.option')})</span>
									{term.contents}
								</label>
							</div>
						);
					}
				})}

				<div className="padding-t20">
					<input
						id="checkboxAll"
						className="checkbox"
						name="checkbox-group"
						type="checkbox"
						onChange={(e) => {
							handleAllCheckBox(e.target.checked);
						}}
						checked={allAgreement}
					/>
					<label htmlFor="checkboxAll" className="checkbox-label">
						{t('termsStep.allAgree')}
					</label>
					<span className="fz12 color-999 padding-l30 dp margin-t5">
						{t('termsStep.agreeQuote')}
					</span>
				</div>
			</div>
		</div>
	);
};

export default AgreementTermsContentsView;
