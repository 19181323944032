import { Route, Routes } from 'react-router-dom';
import { AuthSuccessContainer } from '@dating/ui/components/auth/authSuccess/AuthSuccessContainer';
import { InactiveUserContainer } from '@dating/ui/components/auth/inactive/InactiveUserContainer';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';
import {RejectUserContainer} from "@dating/ui/components/auth/reject/RejectUserContainer";

const AuthRouter = () => {
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Routes location={location}>
				{/*로그인*/}
				<Route path="success" element={<AuthSuccessContainer />} />
				{/*휴면 랜딩*/}
				<Route path="inactive" element={<InactiveUserContainer />} />
				{/*반려 랜딩*/}
				<Route path="reject" element={<RejectUserContainer />} />
			</Routes>
		</ErrorBoundary>
	);
};

export default AuthRouter;
