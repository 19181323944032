import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import {
	formatDate,
	sortMessagesByDate,
} from '@virtual-space/utils/messageUtils';

const MyManagerMessageItemView = ({
	message,
	myProfile,
	setOpenDirectMessageDetail,
	pathPrefix,
	isOnline,
}) => {
	const { t } = useTranslation('messenger');

	const {
		billingStatus,
		dmRoomType,
		roomId,
		otherUserInfo,
		messages,
		latestCreatedAt,
		visibleTo,
		isUserLeft,
		isOtherUserLeft,
		unreadCount,
		display,
		user1Info,
		productType,
		productSubType,
		useStatus,
		seq,
		matchedAt,
		validUses,
	} = message;

	// 표시되지 않아야 하는 메시지는 렌더링하지 않음
	if (isUserLeft || visibleTo === -1) {
		return null;
	}

	const createMessageListState = () => {
		const isUser1 = user1Info?.userUid === myProfile.userUid;
		const sortedMessagesData = sortMessagesByDate(messages, true);

		const state = {
			billingStatus,
			myPaymentStatus: isUser1 ? billingStatus === 1 : billingStatus === 2,
			otherPaymentStatus: isUser1 ? billingStatus === 2 : billingStatus === 1,
			dmRoomType,
			roomId,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			user1Info: user1Info || {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				blockType: '',
			},
			otherUserInfo: otherUserInfo || {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				blockType: '',
			},
			createdAt: latestCreatedAt,
			messages: sortedMessagesData,
			productType,
			productSubType,
			useStatus,
			seq,
			matchedAt,
		};

		return state;
	};

	const sortedMessages = sortMessagesByDate(messages, false);
	const latestMessage = sortedMessages[0]?.content;

	const getStatusText = (status) => {
		switch (status) {
			case 'WAIT':
				return t('waitingUse');
			case 'USING':
				return t('using');
			case 'DONE':
				return t('usageComplete');
			default:
				return '';
		}
	};

	const handleRoomClick = (e) => {
		e.stopPropagation();
		setOpenDirectMessageDetail(true, roomId);
		// 방 입장 이벤트 발생
		dmEventEmitter.emit('react-enter-dm-room', {
			roomId: roomId,
		});
		sessionStorage.setItem('isOnline', isOnline ? 'true' : 'false');
	};

	// 제품 타입 텍스트 결정
	const getProductTypeText = () => {
		if (productSubType === 'OFFLINE') {
			return t('offlineMatching');
		}
		return isOnline ? t('onlineMatching') : t('matching');
	};

	return (
		<div className={`profileType ${useStatus === 'DONE' ? 'used' : ''}`}>
			<div className="proLeft width-100p">
				<Link
					className="proName margin-l8 flex-1 margin-l8 flex-1 margin-t5"
					style={{ width: 'calc(100% - 78px)', display: 'block' }}
					to={`${pathPrefix}/${roomId}`}
					state={createMessageListState()}
					onClick={handleRoomClick}
				>
					<div className="proName width-100p">
						<p className="d-flex justify-between align-center">
							<span className="bold">
								{getProductTypeText()}{' '}
								{t('usageCount', { total: validUses, current: seq })}{' '}
								{getStatusText(useStatus)}
							</span>
						</p>
						<span className="fz10 color-999 bold-500 padding-b4">
							{messages.length > 0
								? formatDate(messages[0].createdAt)
								: formatDate(latestCreatedAt)}
						</span>
						<div className="d-flex justify-between">
							<p className={`ellipsis fz12 ${display?.className || ''}`}>
								{display?.prefix || latestMessage || ''}
							</p>
							{unreadCount > 0 && (
								<span className="chatMsgOn margin-l5">{unreadCount}</span>
							)}
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default MyManagerMessageItemView;
