import React from 'react';
import { ReactCropperElement } from 'react-cropper';
import {
	bridgeOnLoading,
	bridgeShowToast,
} from '@client/utils/bridgeCallHandler';
import { useTranslation } from 'react-i18next';

interface props {
	setCropData: (fileURL: string, file: Blob) => void;
	cropperRef: React.RefObject<ReactCropperElement> | null;
	setShowImgCrop: (showImgCrop: boolean) => void;
}

const ImgCropHeaderView = ({
	setCropData,
	cropperRef,
	setShowImgCrop,
}: props) => {
	const { t } = useTranslation('register');

	return (
		<>
			<header className="cateHeader headerBlack">
				<div className="headerWrap">
					<div className="h-left">
						<button
							type="button"
							className="btnClose_w"
							onClick={() => {
								setShowImgCrop(false);
							}}
						>
							<span className="hide">{t('common.exitBtn')}</span>
						</button>
					</div>
					<div className="h-center"></div>
					<div className="h-right">
						<button
							type="button"
							className="btn"
							onClick={() => {
								if (!(window as any).flutter_inappwebview) {
									// 웹
									if (
										cropperRef?.current &&
										typeof cropperRef.current?.cropper !== 'undefined'
									) {
										const fileURL = cropperRef.current?.cropper
											.getCroppedCanvas()
											.toDataURL();
										cropperRef.current?.cropper
											.getCroppedCanvas()
											.toBlob((file) => {
												if (file != null) {
													setCropData(fileURL, file);
												}
											});
									}
								} else {
									// 앱
									try {
										bridgeOnLoading().then((value) => {
											if (
												cropperRef?.current &&
												typeof cropperRef.current?.cropper !== 'undefined'
											) {
												const fileURL = cropperRef.current?.cropper
													.getCroppedCanvas()
													.toDataURL();
												cropperRef.current?.cropper
													.getCroppedCanvas()
													.toBlob((file) => {
														if (file != null) {
															setCropData(fileURL, file);
														}
													});
											}
										});
									} catch (e) {
										bridgeShowToast(`file is too big ${e}`);
									}
								}
							}}
						>
							<span className="color-fff">{t('common.completeBtn')}</span>
						</button>
					</div>
				</div>
			</header>
		</>
	);
};

export default ImgCropHeaderView;
