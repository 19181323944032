import { replaceInvalidId } from '@virtual-space/utils/util';
import { useEffect, useRef } from 'react';

const CamVideoView = ({
	videoElement,
	myMetaUser,
	myVideoStream,
	setOpenCamExpansion,
	audioControl,
	sessionId,
	playerInfo,
}) => {
	const isMyCamVideo = replaceInvalidId(sessionId) === videoElement?.id;
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.srcObject = videoElement.srcObject;
			videoRef.current.addEventListener('loadedmetadata', () => {
				videoRef.current!.play();
			});
		}
	}, [videoElement, myVideoStream]);
	return (
		<div
			className="video"
			id={videoElement?.id}
			onClick={() => {
				setOpenCamExpansion(true, videoElement);
			}}
		>
			<video
				ref={videoRef}
				autoPlay
				playsInline
				style={{
					transform: 'scaleX(-1)',
				}}
			/>
			<span
				className={
					isMyCamVideo
						? audioControl
							? ''
							: 'voiceBtn'
						: playerInfo?.audioStatus
						? ''
						: 'voiceBtn'
				}
			/>
			<div className="txtBox">
				<p>{isMyCamVideo ? myMetaUser.nickName : playerInfo?.name}</p>
			</div>
		</div>
	);
};

export default CamVideoView;
