import { Room } from 'colyseus.js';
import { MapSchema } from '@colyseus/schema';
import { StoreService } from '../StoreService';

import { DirectMessageType } from '../../../../types/DirectMessageType';
import {
	IDMRoom,
	IDirectMessageState,
	IDirectMessage,
	IUser,
} from '../../../../types/IDirectMessageState';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { DirectMessageSchema, UserSchema } from '@virtual-space/schemas';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';

export class DirectMessageEventSetup {
	private roomCache: Map<string, IDMRoom> = new Map();

	constructor(
		private dmRoom: Room<IDirectMessageState>,
		private storeService: StoreService,
	) {}

	setup() {
		this.setupDirectMessage();
	}

	private setupDirectMessage() {
		// 방 목록 수신 핸들러
		this.dmRoom?.onMessage(
			DirectMessageType.GET_DM_ROOMS,
			(dmRooms: IDMRoom[]) => {
				dmRooms.forEach(async (room) => {
					// 기존 메시지가 배열이면 MapSchema에 넣기
					if (Array.isArray(room.messages)) {
						const messagesSchema = new MapSchema<IDirectMessage>();

						room.messages.forEach((messageData: any) => {
							if (messageData.messageId) {
								const message = this.normalizeMessage(messageData);
								messagesSchema.set(messageData.messageId, message);
							}
						});

						// 변환된 MapSchema를 할당
						room.messages = messagesSchema;
					}

					this.setupMessageListeners(room);
					this.updateRoomMessages(room);
				});

				useDirectMessageRepo.getState().setDirectMessageRoomList(dmRooms);
				const groupedMessages = dmRooms.reduce((acc, room) => {
					acc[room.roomId] = room;
					return acc;
				}, {} as Record<string, IDMRoom>);

				useDirectMessageRepo.getState().setGroupedMessages(groupedMessages);
				this.dmRoom?.send(DirectMessageType.TOTAL_UNREAD_COUNT);
			},
		);

		this.dmRoom?.state.dmRooms.onAdd((dmRoom: IDMRoom, key: string) => {
			const myUUID = useDatingRepo.getState().myProfile.userUid;
			if (dmRoom.user1Id === myUUID || dmRoom.user2Id === myUUID) {
				// 메시지 변경 감지 설정
				this.setupMessageListeners(dmRoom);
				// Room 정보 store 업데이트
				this.storeService.addDirectMessageRoom(dmRoom);
				// 최초 메시지 상태 저장
				this.updateRoomMessages(dmRoom);
			}
		});

		// Room 입장 시
		this.dmRoom?.onMessage(
			DirectMessageType.ENTER_DM_ROOM,
			(roomId: string) => {
				useDirectMessageRepo.getState().setCurrentRoom(roomId);
			},
		);

		// Room 퇴장 시
		this.dmRoom?.onMessage(DirectMessageType.LEAVE_DM_ROOM, () => {
			useDirectMessageRepo.getState().setCurrentRoom('');
		});

		// 방 상태 체크 요청 처리
		this.dmRoom?.onMessage(
			DirectMessageType.CHECK_USER_SESSION,
			(message: { targetUid: string; roomId: string }) => {
				this.dmRoom?.send(DirectMessageType.CHECK_USER_SESSION, {
					targetUid: message.targetUid,
					roomId: message.roomId,
				});
			},
		);

		// 방 상태 응답 처리
		this.dmRoom?.onMessage(
			DirectMessageType.USER_IN_ROOM_STATUS,
			(message: { targetUid: string; isInRoom: boolean }) => {
				dmEventEmitter.emit('react-user-in-room-status', {
					targetUid: message.targetUid,
					isInRoom: message.isInRoom,
				});
			},
		);

		// Room 정보 변경 시
		this.dmRoom?.state.dmRooms.onChange((dmRoom: IDMRoom, key: string) => {
			if (!dmRoom) {
				// 캐시된 Room이 있는지 확인
				const cachedRoom = this.roomCache.get(key);
				if (cachedRoom) {
					// 캐시된 Room 사용
					this.handleRoomChange(cachedRoom);
					return;
				}
				return;
			}

			// 정상적인 Room은 캐시에 저장
			this.roomCache.set(key, dmRoom);
			this.handleRoomChange(dmRoom);
		});

		// Room 생성 완료
		this.dmRoom?.onMessage(
			DirectMessageType.CREATE_DM_ROOM,
			(response: {
				currentRoomId: string;
				relatedRoomIds: string[];
				room?: IDMRoom;
			}) => {
				const room =
					response.room ||
					this.dmRoom?.state.dmRooms.get(response.currentRoomId);
				const myUUID = useDatingRepo.getState().myProfile.userUid;

				if (room) {
					if (!room.messages || !(room.messages instanceof MapSchema)) {
						room.messages = new MapSchema<IDirectMessage>();
					}

					if (room.user1Id === myUUID || room.user2Id === myUUID) {
						this.updateRoomMessages(room);
						useDirectMessageRepo
							.getState()
							.setCurrentRoom(response.currentRoomId);
						useDirectMessageRepo
							.getState()
							.setRelatedRooms(response.relatedRoomIds);
					}
				}
				dmEventEmitter.emit('react-created-dm-room');
			},
		);

		// 메시지 전송
		this.dmRoom?.onMessage(
			DirectMessageType.SEND_DIRECT_MESSAGE,
			(message: IDirectMessage) => {
				if (!message || !message.roomId) return;

				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room && !this.isMessageExists(room, message)) {
					if (!room.messages) {
						room.messages = new MapSchema<IDirectMessage>();
					}

					// message 객체가 예상한 형태인지 확인하고 필요한 경우 변환
					const normalizedMessage = this.normalizeMessage(message);

					room.messages.set(message.createdAt, normalizedMessage);
					this.updateRoomMessages(room);
				}
			},
		);

		// 메시지 수신 처리
		this.dmRoom?.onMessage(
			DirectMessageType.RECEIVE_DIRECT_MESSAGE,
			(message: IDirectMessage) => {
				if (!message || !message.roomId) return;

				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room && !this.isMessageExists(room, message)) {
					const normalizedMessage = this.normalizeMessage(message);

					room.messages.set(message.createdAt, normalizedMessage);
					this.updateRoomMessages(room);

					// 새 메시지가 오면 전체 읽지 않은 메시지 수 요청
					this.dmRoom?.send(DirectMessageType.TOTAL_UNREAD_COUNT);
				}
			},
		);

		// 전체 읽지 않은 메시지 수 수신 핸들러
		this.dmRoom?.onMessage(
			DirectMessageType.TOTAL_UNREAD_COUNT,
			(message: { totalCount: number }) => {
				useDirectMessageRepo.getState().setTotalUnreadCount(message.totalCount);
			},
		);

		// 메시지 읽음 처리
		this.dmRoom?.onMessage(
			DirectMessageType.READ_DIRECT_MESSAGE,
			(message: { roomId: string; messageId: number; userUid: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					const isUser1 = room.user1Id === message.userUid;
					if (isUser1) {
						room.unReadCnt1 = 0;
					} else {
						room.unReadCnt2 = 0;
					}
					this.updateRoomMessages(room);
				}
			},
		);

		// 읽지 않은 메시지 수 업데이트 핸들러 추가
		this.dmRoom?.onMessage(
			DirectMessageType.REQUEST_UNREAD_COUNT,
			(message: { roomId: string; unReadCnt1: number; unReadCnt2: number }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					// user1과 user2의 읽지 않은 메시지 수를 각각 업데이트
					room.unReadCnt1 = message.unReadCnt1;
					room.unReadCnt2 = message.unReadCnt2;

					this.updateRoomMessages(room);
				}
			},
		);

		// 채팅방 나가기
		this.dmRoom?.onMessage(
			DirectMessageType.LEAVE_DIRECT_MESSAGE,
			(message: { roomId: string; leaverId: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					const isUser1 = room.user1Id === message.leaverId;
					room.visibleTo = isUser1 ? 1 : 2;
					this.updateRoomMessages(room);
				}
			},
		);

		// 채팅방 퇴장
		this.dmRoom?.onMessage(
			DirectMessageType.EXIT_DIRECT_MESSAGE,
			(message: { roomId: string; exit: boolean }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.visibleTo = -1;
					this.updateRoomMessages(room);
				}
			},
		);

		// 결제 관련
		this.dmRoom?.onMessage(
			DirectMessageType.SINGLE_PAYMENT_COMPLETED,
			(message: { roomId: string; payerId: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					const isUser1 = room.user1Id === message.payerId;
					room.billingStatus = isUser1 ? 1 : 2;
					this.updateRoomMessages(room);
				}
			},
		);

		// 쌍방 결제 완료
		this.dmRoom?.onMessage(
			DirectMessageType.MUTUAL_PAYMENT_COMPLETED,
			(message: { roomId: string; paid: boolean }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.billingStatus = 3;
					this.updateRoomMessages(room);
				}
			},
		);

		// 방 타입 변경
		this.dmRoom?.onMessage(
			DirectMessageType.CHANGE_DM_ROOM_TYPE,
			(message: { roomId: string; dmRoomType: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.dmRoomType = message.dmRoomType;
					this.updateRoomMessages(room);
				}
			},
		);

		// 사용 상태 변경
		this.dmRoom?.onMessage(
			DirectMessageType.CHANGE_DM_USE_STATUS,
			(message: { roomId: string; useStatus: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.useStatus = message.useStatus;
					this.updateRoomMessages(room);
				}
			},
		);

		// 차단/차단해제 처리
		this.dmRoom?.onMessage(
			DirectMessageType.BLOCK_USER,
			(message: {
				roomId: string;
				blockType: string;
				blockerUuid: string; // 추가: 차단한 사람
				targetUid: string; // 추가: 차단당한 사람
			}) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					// user1이 차단자인지 확인
					const isUser1Blocker = message.blockerUuid === room.user1Id;

					if (message.blockType === 'block') {
						// 차단 설정
						if (isUser1Blocker) {
							room.user1Info.blockType = 'block';
							room.user2Info.blockType = 'blocked';
						} else {
							room.user1Info.blockType = 'blocked';
							room.user2Info.blockType = 'block';
						}
					} else {
						// 차단 해제
						room.user1Info.blockType = 'none';
						room.user2Info.blockType = 'none';
					}

					this.updateRoomMessages(room);
				}
			},
		);

		// 프로필 url 변경
		this.dmRoom?.onMessage(
			DirectMessageType.UPDATE_DM_PROFILE_URL,
			(message: { userUid: string; profileUrl: string }) => {
				const room = Array.from(this.dmRoom?.state.dmRooms.values()).find(
					(room) =>
						room.user1Id === message.userUid ||
						room.user2Id === message.userUid,
				);
				if (room) {
					const isUser1 = room.user1Id === message.userUid;
					const user = isUser1 ? room.user1Info : room.user2Info;
					user.profileUrl = message.profileUrl;
					this.updateRoomMessages(room);
				}
			},
		);

		// 휴면 계정 처리
		this.dmRoom?.onMessage(
			DirectMessageType.CHANGE_DM_INACTIVE,
			(message: { userUid: string; inActive: boolean }) => {
				const room = Array.from(this.dmRoom?.state.dmRooms.values()).find(
					(room) =>
						room.user1Id === message.userUid ||
						room.user2Id === message.userUid,
				);
				if (room) {
					const isUser1 = room.user1Id === message.userUid;
					const user = isUser1 ? room.user1Info : room.user2Info;
					user.inActive = message.inActive;
					this.updateRoomMessages(room);
				}
			},
		);

		// 탈퇴 계정 처리
		this.dmRoom?.onMessage(
			DirectMessageType.CHANGE_DM_IS_DELETED,
			(message: { userUid: string; isDeleted: boolean }) => {
				const room = Array.from(this.dmRoom?.state.dmRooms.values()).find(
					(room) =>
						room.user1Id === message.userUid ||
						room.user2Id === message.userUid,
				);
				if (room) {
					const isUser1 = room.user1Id === message.userUid;
					const user = isUser1 ? room.user1Info : room.user2Info;
					user.isDeleted = message.isDeleted;
					this.updateRoomMessages(room);
				}
			},
		);
	}

	private handleRoomChange(dmRoom: IDMRoom) {
		const myUUID = useDatingRepo.getState().myProfile.userUid;
		if (dmRoom.user1Id === myUUID || dmRoom.user2Id === myUUID) {
			this.setupMessageListeners(dmRoom);
			this.storeService.updateDirectMessageRoom(dmRoom);
			this.updateRoomMessages(dmRoom);
		}
	}

	private setupMessageListeners(dmRoom: IDMRoom) {
		dmRoom.messages.onAdd((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});

		dmRoom.messages.onChange((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});

		dmRoom.messages.onRemove((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});
	}

	// Message 객체 정규화 메서드 추가
	private normalizeMessage(message: any): IDirectMessage {
		const normalizedMessage = new DirectMessageSchema();
		Object.assign(normalizedMessage, {
			roomId: message.roomId,
			sender: this.normalizeUser(message.sender),
			receiver: this.normalizeUser(message.receiver),
			content: message.content,
			messageId: message.messageId,
			createdAt: message.createdAt,
			read: message.read || false,
		});
		return normalizedMessage;
	}

	// User 객체 정규화 메서드 추가
	private normalizeUser(user: any): IUser {
		const normalizedUser = new UserSchema();
		Object.assign(normalizedUser, {
			userUid: user?.userUid || '',
			nickName: user?.nickName || '',
			profileUrl: user?.profileUrl || '',
			region1: user?.region1 || '',
			region2: user?.region2 || '',
			age: user?.age || '',
			role: user?.role || '',
			inActive: user?.inActive || false,
			isDeleted: user?.isDeleted || false,
			blockType: user?.blockType || '',
		});
		return normalizedUser;
	}
	private isMessageExists(room: IDMRoom, newMessage: IDirectMessage): boolean {
		return Array.from(room.messages.values()).some(
			(existingMessage) =>
				existingMessage.createdAt === newMessage.createdAt &&
				existingMessage.content === newMessage.content &&
				existingMessage.sender?.userUid === newMessage.sender?.userUid,
		);
	}

	// DirectMessageEventSetup.ts 수정 - updateRoomMessages 메소드 개선
	private updateRoomMessages(room: IDMRoom) {
		// 현재 상태에서 대화방 목록과 그룹화된 메시지 가져오기
		const currentGroupedMessages =
			useDirectMessageRepo.getState().groupedMessages;
		const currentRoomList =
			useDirectMessageRepo.getState().directMessageRoomList;

		// 업데이트할 대화방
		const updatedRoom = room;

		// 메시지들을 UI 표시용 배열로 변환
		const messagesArray = Array.from(updatedRoom.messages.values())
			.sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt))
			.map((message) => ({
				...message,
				sender: message.sender || {},
				receiver: message.receiver || {},
			}));

		// 중요: 그룹화된 메시지 업데이트 시 기존 객체를 복사하여 업데이트
		const updatedGroupedMessages = {
			...currentGroupedMessages, // 기존 모든 대화방 보존
			[room.roomId]: {
				...room,
				messages: messagesArray,
				billingStatus: room.billingStatus,
				visibleTo: room.visibleTo,
				dmRoomType: room.dmRoomType,
				unReadCnt1: room.unReadCnt1,
				unReadCnt2: room.unReadCnt2,
				createdAt: room.createdAt,
				productType: room.productType,
				productSubType: room.productSubType,
				useStatus: room.useStatus,
				seq: room.seq,
				validUses: room.validUses,
				orderId: room.orderId,
				matchedAt: room.matchedAt,
			},
		};

		// 그룹화된 메시지 상태 업데이트
		useDirectMessageRepo.getState().setGroupedMessages(updatedGroupedMessages);

		// 대화방 목록 업데이트 - 기존 목록 유지하면서 현재 대화방만 업데이트
		const existingRoomIndex = currentRoomList.findIndex(
			(existingRoom) => existingRoom.roomId === room.roomId,
		);

		let updatedRoomList;
		if (existingRoomIndex === -1) {
			// 새 대화방인 경우 목록에 추가
			updatedRoomList = [...currentRoomList, updatedRoom];
		} else {
			// 기존 대화방인 경우 해당 인덱스만 업데이트
			updatedRoomList = [...currentRoomList];
			updatedRoomList[existingRoomIndex] = updatedRoom;
		}

		// 중복 제거
		updatedRoomList = updatedRoomList.filter(
			(room, index, self) =>
				index === self.findIndex((r) => r.roomId === room.roomId),
		);

		// 대화방 목록 상태 업데이트 - 중복 제거 후에 상태 업데이트
		useDirectMessageRepo.getState().setDirectMessageRoomList(updatedRoomList);
	}
}
