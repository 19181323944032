import { Link } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { ManagerProfileCOVO } from '@virtual-space/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	srcPrefix: string;
	managerProfile: ManagerProfileCOVO;
}

const ManagerProfileCardView = ({ srcPrefix, managerProfile }: props) => {
	const { t } = useTranslation('messenger');

	return (
		<div className="profileType cm">
			<div className="proLeft">
				<div className="proImg cm">
					<Link to={PATH.PROFILE.MANAGER_PROFILE}>
						<img
							src={srcPrefix + managerProfile.profileUrl[0]}
							alt={managerProfile.nickName}
						/>
					</Link>
					<span className="proBlackBadge cmBadge">{t('coupleManager')}</span>
				</div>
				<div className="proName">
					<div className="d-flex justify-between align-center">
						<p className="proFz20 fz18">{managerProfile.nickName}</p>
						<div className="fz14Fw500 underline padding-r15">
							<Link to={PATH.STORE.MANAGER_PURCHASE_HISTORY}>
								{t('viewHistory')}
							</Link>
						</div>
					</div>
					<p className="fz14 bold-500">
						<span className="color-red">♥</span>{' '}
						{t('successCount', { successCnt: managerProfile.successCnt })}
					</p>
					<div className="d-flex justify-between align-center">
						<p className="fz12 color-666 margin-t8">
							{managerProfile.intro
								? managerProfile.intro
								: '성공적인 만남을 위하여'}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ManagerProfileCardView;
