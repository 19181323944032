import { bridgePurchase } from '@client/utils/bridgeCallHandler';
import { useTranslation } from 'react-i18next';

interface props {
	closeChargePop: () => void;
	productPacks: {
		id: number;
		name: string;
		price: number;
		point: number;
		bonusPoint: number;
	}[];
	fnSaveInAppPurchase: (result: {}) => void;
	fnCommonAlertPopOn: (message: string) => void;
}

const ChargePopView = ({
	closeChargePop,
	productPacks,
	fnSaveInAppPurchase,
	fnCommonAlertPopOn,
}: props) => {
	const { t } = useTranslation('common');

	return (
		<div className="logoutWrap">
			<div className="popupInfo padding-t16 padding-b16">
				<div className="leftRightTxt">
					<p>{t('chargePop.charge')}</p>
					<button
						type="button"
						className="btnClose popCloseBtn"
						onClick={() => {
							closeChargePop();
						}}
					></button>
				</div>
				<div className="refillList">
					{productPacks.map((item) => {
						return (
							<button
								key={item.id}
								className={`rfBox ${item.id === 11 ? 'bestBox' : ''}`}
								type="button"
								onClick={() => {
									if (import.meta.env.MODE == 'prd') {
										// 운영
										fnCommonAlertPopOn(t('chargePop.notYet'));
										closeChargePop();
									} else {
										// 개발
										// 인앱결제 브릿지 핸들러 호출
										if (item.id.toString() != '') {
											bridgePurchase(item.id.toString())
												.then((response) => {
													if (response) {
														fnSaveInAppPurchase(response);
													}
												})
												.finally(() => {
													// 팝업 닫기
													closeChargePop();
												});
										}
									}
								}}
							>
								<div className="d-flex">
									<p className="ic-berith md">
										{item.point + item.bonusPoint}
										{t('chargePop.piece')}
									</p>
									<p className="cancell">
										{item.point}
										{t('chargePop.piece')}
									</p>
								</div>
								<span className="price">
									{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									{t('chargePop.KRW')}
								</span>
							</button>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ChargePopView;
