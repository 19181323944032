import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import {useProfileAdapter} from '@dating/adapter/profile/useProfileAdapter';
import {useDatingRepo} from '@dating/repository/dating/useDatingRepo';
import {useEffect} from 'react';
import {useEditProfileRepo} from '@dating/repository/profile/useEditProfileRepo';
import {bufferArrayToBlobArray} from '@client/utils/fileUtils';
import {useProfileEditStateRepo} from "@dating/ui/pages/profile/editProfile/useProfileEditStateRepo";
import type {UserRecommenderCIVO} from "@client/site/api";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";

export const useProfileEditService = () => {
    const {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        codeErrorMessage,
        setCodeErrorMessage,
    } = useProfileEditStateRepo();

    const {
        setFocusItem,
        editPop,
        setEditPop,
        clearEditPop,
        myModifyProfile,
        setMyModifyProfile,
        setModifyProfileUrl,
    } = useEditProfileRepo();

    const {
        previewURLs,
        setPreviewURLs,
        clearPreviewURLs,
        setCropFiles,
    } = useImgCropRepo();

    const {srcPrefix, myProfile} = useDatingRepo();

    const {getModifyMyProfile} = useProfileAdapter();

    const {
        saveRecommender,
    } = useRegisterAdapter();
    ////////////////////////////////////////////////

    useEffect(() => {
        // 사용자 선택 태그 active
        if (myModifyProfile.smoking.length > 0) {
            setSmokingValue([myModifyProfile.smoking]);
        }
        if (myModifyProfile.drink.length > 0) {
            setDrinkingValue([myModifyProfile.drink]);
        }
        if (myModifyProfile.interest.length > 0) {
            const interestValue: string[] = [];
            myModifyProfile.interest.map(item => {
                interestValue.push(item);
            });
            setInterestValue(interestValue);
        }
        if (myModifyProfile.lifeStyle.length > 0) {
            const lifeStyleValue: string[] = [];
            myModifyProfile.lifeStyle.map(item => {
                lifeStyleValue.push(item);
            });
            setLifeStyleValue(lifeStyleValue);
        }
    }, [myModifyProfile]);

    useEffect(() => {

    }, [previewURLs]);

    /*
     * openPopupHandler
     * 항목별 팝업 호출
     */
    const openPopupHandler = (detail) => {
        if (detail.editable && editPop[detail.key] != null) {
            // popup handle
            clearEditPop();
            const newItem = {
                ...editPop,
                [detail.key]: true,
            };
            setEditPop(newItem);

            //content handle
            setFocusItem({
                label: detail.key,
                value: detail.code ? detail.code : detail.value,
            });
        }
    };

    useEffect(() => {
        if (getModifyMyProfile.status === 'success' && getModifyMyProfile.data) {
            setMyModifyProfile(getModifyMyProfile.data);
            const result = bufferArrayToBlobArray(
                getModifyMyProfile.data.profileFiles,
            );
            setPreviewURLs(result.urls);
            setCropFiles(result.blobs);

            setModifyProfileUrl(true);
        }
    }, [getModifyMyProfile.status, getModifyMyProfile.data]);

    /*
   	 * 추천인 코드 저장
   	 * fnSaveRecommender
   	 */
    const fnSaveRecommender = async () => {
        if (recommendCode.length === 0) {
            return;
        }
        const map: UserRecommenderCIVO = {
            recommenderCode: recommendCode,
        };
        const res = await saveRecommender(map)
        if (!res.result) {
            setCodeFailed(true);
            setCodeErrorMessage(res.resultCode);
        } else {
            setCodeFailed(false);
            setRecommendCode('');
        }
    };

    const getUserStyleValue = (id: string): string[] => {
        switch (id) {
            case 'drink':
                return drinkingValue;
            case 'smoking':
                return smokingValue;
            case 'like':
                return interestValue;
            case 'life':
                return lifeStyleValue;
            default:
                return [];
        }
    };

    return {
        clearPreviewURLs,
        myModifyProfile,
        openPopupHandler,
        srcPrefix,
        myProfile,
        recommendCode,
        setRecommendCode,
        codeFailed,
        fnSaveRecommender,
        codeErrorMessage,
        getUserStyleValue,
    };
};
