import { usePhoneVerificationService } from './service/usePhoneVerificationService';
import PhoneVerificationInputView from '@dating/ui/pages/register/phoneVerification/views/PhoneVerificationInputView';
import PhoneVerificationFooterView from '@dating/ui/pages/register/phoneVerification/views/PhoneVerificationFooterView';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { bridgeShowToast } from '@client/utils/bridgeCallHandler';
import { useTranslation } from 'react-i18next';

const PhoneVerificationContainer = () => {
	const {
		inputValues,
		formattedTime,
		occupancyFooterViewStyle,
		handleInputKeyDown,
		fnVerifyVerificationCode,
		activeIndex,
		fnSendVerificationCode,
		resetRegisterInfo,
		phoneVeriFailed,
		phoneVeriFailedCnt,
		inputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
		phoneNumber,
		input,
		setInput,
	} = usePhoneVerificationService();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const navigate = useNavigate();

	const { t } = useTranslation('register');

	useEffect(() => {
		if (phoneVeriFailed || phoneVeriFailedCnt > 4) {
			fnCommonAlertPopOn(t('phoneStep.tryAgain'));
			resetRegisterInfo();
			navigate(PATH.REGISTER.PHONE);
		}
	}, [phoneVeriFailed, phoneVeriFailedCnt]);

	useEffect(() => {
		if (inputTimeout) {
			fnCommonAlertPopOn(t('phoneStep.timeOver'));
			resetRegisterInfo();
			navigate(PATH.REGISTER.PHONE);
		}
	}, [inputTimeout]);

	useEffect(() => {
		if (notValidCode && phoneVeriFailedCnt < 5) {
			if (!(window as any).flutter_inappwebview) {
				// 웹
				fnCommonAlertPopOn(t('phoneStep.tryCheckAgain'));
			} else {
				// 앱
				bridgeShowToast(t('phoneStep.tryCheckAgain'));
			}
			setNotValidCode(false);
		}
	}, [notValidCode]);

	useEffect(() => {
		if (codeResend) {
			if (!(window as any).flutter_inappwebview) {
				// 웹
				fnCommonAlertPopOn(t('phoneStep.reSendNumber'));
			} else {
				// 앱
				bridgeShowToast(t('phoneStep.reSendNumber'));
			}
			setCodeResend(false);
		}
	}, [codeResend]);

	return (
		<div className="wrap">
			<header className="h-fixed">
				<div className="headerWrap">
					<div className="h-left">
						<Link
							to={PATH.REGISTER.PHONE}
							onClick={() => {
								resetRegisterInfo();
							}}
						>
							<button className="btnBack" type="button" />
						</Link>
					</div>
					<div className="h-center"></div>
				</div>
			</header>
			<div className="contentsWrap">
				<div className="titleWrap">
					<h2 className="title01">{t('occupancyStep.inputNumber')}</h2>
					<p className="titleSub01">
						{phoneNumber + t('occupancyStep.sended')}
						<span className="fz14 color-red">{formattedTime}</span>
					</p>
				</div>
				<div className="content">
					<div className="inputWrap">
						<PhoneVerificationInputView
							inputValues={inputValues}
							handleInputKeyDown={handleInputKeyDown}
							activeIndex={activeIndex}
							input={input}
							setInput={setInput}
						/>
					</div>
				</div>
			</div>
			<PhoneVerificationFooterView
				fnVerifyVerificationCode={fnVerifyVerificationCode}
				occupancyFooterViewStyle={occupancyFooterViewStyle}
				fnSendVerificationCode={fnSendVerificationCode}
			/>
		</div>
	);
};

export default PhoneVerificationContainer;
