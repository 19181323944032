import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useAuthRepo } from '@client/site/dating/repository/auth/useAuthRepo';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useCallback } from 'react';
import { IRoomData } from '../../../../../../types/Rooms';

export type RoomType = 'PUBLIC' | 'CUSTOM' | 'DM';

export interface StartRoomParams {
	roomType: RoomType;
	roomId?: string;
	password?: number;
	roomTheme?: any;
	x?: number;
	y?: number;
	name?: string;
	isSecret?: boolean;
	autoDispose?: boolean;
	headCount?: number;
	remainingTime?: number;
	createdBy?: string;
	room?: IRoomData;
}

/** 이벤트 발생 관련 로직을 담당하는 훅 */
export const useRoomEventService = () => {
	const { myMetaUser, audioControl } = useMetaUserRepo();
	const { myProfile } = useDatingRepo();
	const { sessionId } = useMetaUserRepo();
	const { langCode, authToken } = useAuthRepo();

	const emitStartRoom = useCallback(
		(params: StartRoomParams) => {
			reactEventEmitter.emit('react-start-room', {
				...params,
				uuid: myProfile.userUid,
				nickName: myProfile.nickName,
				gender: myProfile.gender,
				profileUrl: myProfile.profileUrl[0],
				role: myProfile.role,
				langCode: langCode,
				accessToken: authToken.accessJmt,
				x: params.x ?? 1024,
				y: params.y ?? 1376,
				anim: myMetaUser.avatarName,
				readyToConnect: myMetaUser.readyToConnect,
				mediaConnected: myMetaUser.mediaConnected,
				statusMessage: myProfile.introduce,
				audioStatus: audioControl,
			});
		},
		[myMetaUser, myProfile.role, audioControl],
	);
	const emitJoinRoom = useCallback(() => {
		reactEventEmitter.emit('react-join-room', {
			sessionId,
			uuid: myProfile.userUid,
			nickName: myProfile.nickName,
			gender: myProfile.gender,
			profileUrl: myProfile.profileUrl[0],
			role: myProfile.role,
			name: myProfile.nickName,
			avatarName: myMetaUser.avatarName,
			statusMessage: myMetaUser.introduce,
			audioStatus: audioControl,
			langCode: langCode,
			accessToken: authToken.accessJmt,
		});
	}, [sessionId, myMetaUser, myProfile.role, audioControl]);

	return {
		emitStartRoom,
		emitJoinRoom,
	};
};
