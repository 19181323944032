import IdealSettingContainer from '@dating/ui/pages/register/Ideal/IdealSettingContainer';
import BirthdayContainer from '@dating/ui/pages/register/birthday/BirthdayContainer';
import HeightContainer from '@dating/ui/pages/register/height/HeightContainer';
import LifeStyleContainer from '@dating/ui/pages/register/lifeStyle/LifeStyleContainer';
import LocationInfoContainer from '@dating/ui/pages/register/locationInfo/LocationInfoContainer';
import LocationInfoUnableContainer from '@dating/ui/pages/register/locationInfo/LocationInfoUnableContainer';
import RegisterMainContainer from '@dating/ui/pages/register/main/RegisterMainContainer';
import NicknameContainer from '@dating/ui/pages/register/nickname/NicknameContainer';
import PhoneVerificationContainer from '@dating/ui/pages/register/phoneVerification/PhoneVerificationContainer';
import PhoneNumberContainer from '@dating/ui/pages/register/phoneNumber/PhoneNumberContainer';
import PhotoContainer from '@dating/ui/pages/register/photo/PhotoContainer';
import RecommendCodeContainer from '@dating/ui/pages/register/recommendCode/RecommendCodeContainer';
import RegisterWaitingContainer from '@dating/ui/pages/register/waiting/RegisterWaitingContainer';
import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from './RouteTransition';
import AgreementTermsContainer from '@dating/ui/pages/register/agreementTerms/AgreementTermsContainer';
import GenderSelectContainer from '@dating/ui/pages/register/gender/GenderSelectContainer';
import JobSelectContainer from '@dating/ui/pages/register/job/JobSelectContainer';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';
import {TagInviteCodeContainer} from "@dating/ui/pages/register/tagInviteCode/TagInviteCodeContainer";

const RegisterRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();

	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<RouteTransition location={location} navigationType={navigationType}>
				<Routes location={location}>
					<Route path="/" element={<RegisterMainContainer />} />
					<Route path="/phone" element={<PhoneNumberContainer />} />
					<Route path="/occupancy" element={<PhoneVerificationContainer />} />
					<Route path="/terms" element={<AgreementTermsContainer />} />
					<Route path="/gender" element={<GenderSelectContainer />} />
					<Route path="/nickname" element={<NicknameContainer />} />
					<Route path="/birth" element={<BirthdayContainer />} />
					<Route path="/location" element={<LocationInfoContainer />} />
					<Route
						path="/locationUnable"
						element={<LocationInfoUnableContainer />}
					/>
					<Route path="/height" element={<HeightContainer />} />
					<Route path="/job" element={<JobSelectContainer />} />
					<Route path="/ideal" element={<IdealSettingContainer />} />
					<Route path="/code" element={<RecommendCodeContainer />} />
					<Route path="/photo" element={<PhotoContainer />} />
					<Route path="/waiting" element={<RegisterWaitingContainer />} />
					<Route path="/lifeStyle" element={<LifeStyleContainer />} />
					<Route path="/tag-inviteCode" element={<TagInviteCodeContainer/>} />
				</Routes>
			</RouteTransition>
		</ErrorBoundary>
	);
};

export default RegisterRouter;
