import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useSingleFeedStateRepo } from '@dating/ui/pages/lounge/singleFeed/useSingleFeedStateRepo';
import { useEffect } from 'react';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import {
	DeleteCommentCIVO,
	PostInfoCOVO,
	PostLikeCIVO,
} from '@client/site/api';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import { useNavigate } from 'react-router-dom';

export const useSingleFeedService = () => {
	const { isClickedFeedMenu, setIsClickedFeedMenu, postUser, setPostUser } =
		useSingleFeedStateRepo();

	const {
		feedDetail,
		setFeedDetail,
		singleFeedId,
		feedEditPops,
		setFeedEditPops,
		focusedPostId,
		setFocusedPostId,
		commentResponse,
		setCommentResponse,
		isHiddenDelete,
		setIsHiddenDelete,
		setOpenFeedHidePop,
		setCommentState,
	} = useLoungeRepo();

	const { sliderSettings, srcPrefix, myProfile, myProfileOpen } =
		useDatingRepo();

	const { getPostDetail, getComment, deleteComment } = useLoungeAdapter();

	const { saveSinglePostLike } = useLikeAdapter();

	const navigate = useNavigate();
	useEffect(() => {
		if (isHiddenDelete) {
			setOpenFeedHidePop(false);
			setCommentState(false);
			setIsHiddenDelete(false);
			navigate(-1);
		}
	}, [isHiddenDelete]);

	useEffect(() => {
		if (singleFeedId != '') {
			getPostDetail(singleFeedId)
				.then((value) => {
					setFeedDetail(value);
					let newItem = {};
					newItem[value.postId] = false;
					setFeedEditPops(newItem);
					setIsClickedFeedMenu({ postId: '', state: false });
				})
				.catch((reason) => {});
		}
	}, [singleFeedId]);

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feed: PostInfoCOVO) => {
		let newItem = {};
		newItem[feed.postId] = false;
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		if (feedDetail) {
			initFeedEditPops(feedDetail);
			setIsClickedFeedMenu({ postId: postId, state: true });
		}
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);

	/*
	 * fnSinglePostLike
	 * 게시글 좋아요
	 */
	const fnSinglePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		saveSinglePostLike(map)
			.then((value) => {
				if (singleFeedId != '') {
					getPostDetail(singleFeedId)
						.then((value) => {
							setFeedDetail(value);
							let newItem = {};
							newItem[value.postId] = false;
							setFeedEditPops(newItem);
							setIsClickedFeedMenu({ postId: '', state: false });
						})
						.catch((reason) => {});
				}
			})
			.catch((reason) => {});
	};

	/*
	 * commentListHandler ( menuType 분기를 위해 여기도 함수 만듬)
	 * 선택한 postId로 commentList handle,
	 * menuType : profile-댓글 모두 삭제 가능, lounge-내 댓글만 삭제 가능
	 */
	function commentListHandler(
		postId: string,
		postUser: { userUid: string; nickname: string },
	) {
		// 클릭한 postId repo에 저장
		setFocusedPostId(postId);

		getComment(postId, 'profile')
			.then((value) => {
				if (postId != '') {
					// 댓글리스트 repo에 저장
					setCommentResponse(value);
					// 작성자 닉네임 저장
					setPostUser(postUser);
				} else {
					setCommentResponse([]);
				}
			})
			.catch((reason) => {});
	}

	/*
	 * fnDeleteComment
	 * 댓글 삭제
	 */
	const fnDeleteComment = (param: { postId: string; commentId: number }) => {
		const map: DeleteCommentCIVO = {
			postId: param.postId,
			commentId: param.commentId,
		};
		deleteComment(map)
			.then((value) => {
				if (value) {
					getComment(param.postId, myProfileOpen ? 'profile' : 'lounge').then(
						(response) => {
							if (response && response.length > 0) {
								// 댓글리스트 repo에 저장
								setCommentResponse(response);
							} else {
								setCommentResponse([]);
							}

							// 작성자 닉네임 저장
							setPostUser(postUser);
						},
					);
				}
			})
			.catch((reason) => {});
	};

	return {
		singleFeedId,
		feedDetail,
		sliderSettings,
		srcPrefix,
		isClickedFeedMenu,
		initFeedEditPops,
		fnFeedEditHandler,
		fnSinglePostLike,
		myProfile,
		commentListHandler,
		focusedPostId,
		commentResponse,
		postUser,
		fnDeleteComment,
	};
};
