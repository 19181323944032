import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useManagerAdapter } from '@client/site/dating/adapter/manager/useManagerAdapter';
import { useProfileAdapter } from '@client/site/dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useManagerRepo } from '@client/site/dating/repository/manager/useManagerRepo';

export const useMyManagerService = () => {
	const { managerProfile, setManagerProfile } = useManagerRepo();

	const {
		isOnline,
		setIsOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setManagerEmpty,
	} = useDirectMessageRepo();

	const { getMyManager } = useManagerAdapter();
	const { srcPrefix } = useDatingRepo();

	const { getManagerProfile } = useProfileAdapter();

	const initManagerProfile = async () => {
		try {
			const myManager = await getMyManager();

			if (!myManager?.managerUid) {
				setManagerEmpty(true);
				return;
			}

			const profile = await getManagerProfile(myManager.managerUid);

			if (!profile) {
				setManagerEmpty(true);
				return;
			}

			setManagerProfile(profile);
			setManagerEmpty(false);
		} catch (error) {
			console.error('Failed to initialize manager profile:', error);
			setManagerEmpty(true);
		}
	};

	return {
		managerProfile,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		isOnline,
		setIsOnline,
		srcPrefix,
		initManagerProfile,
	};
};
