import { isCensored } from '@client/utils/censor';
import { useCommonAlertPopService } from '../../commonAlertPop/service/useCommonAlertPopService';
import { useTranslation } from 'react-i18next';

interface props {
	messageInputValue: string;
	handleChange: (value: string) => void;
	handlePaymentClick: (orderId: number) => void;
	messageTargetUser: string;
	fnValidateOrder: (callback: any, param: any) => void;
	productPack: {
		id: number;
		name: string;
		price: number;
		productType: string;
		productSubType: string;
	};
	fnCloseMessagePop: () => void;
	setMessageInputValue: (value: string) => void;
	existingRoomData: any;
}

const MessagePopView = ({
	messageInputValue,
	handleChange,
	handlePaymentClick,
	fnValidateOrder,
	productPack,
	fnCloseMessagePop,
	setMessageInputValue,
	existingRoomData,
}: props) => {
	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	const { t } = useTranslation('common');

	const handleClick = async () => {
		try {
			// 1. 금칙어 체크
			const hasCensoredWord = isCensored(messageInputValue.replace(/\s/g, ''));

			if (hasCensoredWord) {
				fnCommonAlertPopOn(t('msgPop.badWordQuote'));
				setMessageInputValue('');
				return;
			}

			// 2. 방 존재 여부 체크 - existingRoomData에서 roomId가 있으면 기존 방
			const isExistingRoom = existingRoomData && existingRoomData.roomId;

			if (isExistingRoom) {
				// 3. 방이 있을 경우 결제 건너 뛰고 바로 handleSubmit
				handlePaymentClick(-1); // -1을 전달하여 결제 단계를 건너뛰는 신호로 사용
			} else {
				// 4. 방이 없을 경우 결제 진행 후 handleSubmit
				await new Promise((resolve) => {
					fnValidateOrder(
						(orderId: any) => {
							handlePaymentClick(orderId.orderId);
							resolve(orderId);
						},
						{
							productId: productPack.id,
						},
					);
				});
			}

			// 성공적으로 처리된 후에만 팝업 닫기
			fnCloseMessagePop();
		} catch (error) {
			console.error('메시지 전송 실패:', error);
			fnCommonAlertPopOn(t('msgPop.msgSendFail'));
		}
	};

	// 방 존재 여부에 따라 베리드 가격 결정
	const displayPrice =
		existingRoomData && existingRoomData.roomId ? 0 : productPack?.price || 0;
	return (
		<div className="messageWrap">
			<div className="popupInfo padding-b16">
				<form
					onSubmit={(e) => {
						if (e) {
							e.preventDefault();
						}
					}}
				>
					<p
						className="fz20 bold-500 text-c"
						style={{
							padding: '16px',
						}}
					>
						{t('msgPop.msgSendUsingBerith')}
					</p>
					<div
						className="paymentBox"
						style={{
							padding: '0px 16px 24px 16px',
						}}
					>
						<input
							type="text"
							value={messageInputValue}
							maxLength={192}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							placeholder={t('msgPop.msgPlaceHolder')}
						/>
					</div>
					<button
						type="button"
						className={`btnBig radiusNone flexXYcenter ${
							messageInputValue.trim() ? 'btnBlack' : 'btnCCC'
						}`}
						onClick={() => handleClick()}
						disabled={!messageInputValue.trim()}
					>
						<span
							className="ic-berith"
							style={{
								fontSize: '14px',
							}}
						>
							{displayPrice}
						</span>
						&nbsp;&nbsp;
						<span className="fz16">{t('msgPop.sendBerith')}</span>
					</button>
				</form>
			</div>
		</div>
	);
};

export default MessagePopView;
