import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useMyManagerOfflineListService } from './service/useMyManagerOfflineListService';
import { useTranslation } from 'react-i18next';
import MyManagerMessageItemView from '../../views/MyManagerMessageItemView';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';

const MyManagerOfflineListContainer = () => {
	const { t } = useTranslation('messenger');
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { myProfile, sortedMessages } = useMyManagerOfflineListService();

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map((message) => (
					<MyManagerMessageItemView
						key={message.roomId}
						message={message}
						myProfile={myProfile}
						setOpenDirectMessageDetail={setOpenDirectMessageDetail}
						pathPrefix={PATH.MESSENGER.MANAGER_OFFLINE}
						isOnline={false}
					/>
				))
			) : (
				<div className="chatStartBox">
					<p className="partnerStart2 onoffLineBg">
						{t('noMatchHistory', { text: t('offline') })}
					</p>
				</div>
			)}
		</div>
	);
};

export default MyManagerOfflineListContainer;
