import { useCommonDMService } from '@virtual-space/ui/components/dm/service/useCommonDMService';
import { SortedMessage } from '@virtual-space/ui/components/dm/types/navigationTypes';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeToTimestamp } from '@virtual-space/utils/messageUtils';

export const useMyManagerOnlineListService = () => {
	const { t } = useTranslation('messenger');

	const {
		showDirectMessageList,
		managerOnlineDirectMessageList,
		unreadMessageCounts,
		directMessageList,
	} = useCommonDMService();

	const { myProfile, userProfile, srcPrefix, setUserProfileOpen } =
		useDatingRepo();

	// 메시지 타입에 따른 표시 방식을 결정하는 함수
	const getMessageDisplay = useCallback(
		(message: any) => {
			const blockType = message.blockType;
			const useStatus = message.useStatus;

			// 차단 상태 먼저 체크
			if (blockType === 'blocked') {
				return {
					className: 'color-red',
					prefix: t('blockedQuote'),
				};
			}

			if (blockType === 'block') {
				return {
					className: 'color-red',
					prefix: t('blockQuote'),
				};
			}

			// useStatus에 따른 분기 처리
			if (useStatus === 'WAIT') {
				return {
					className: 'color-red',
					prefix: t('waitingNewMatch'),
				};
			}

			if (useStatus === 'DONE') {
				return {
					className: 'color-red',
					prefix: t('matchingComplete'),
				};
			}

			// 기본 리턴값 (USING 포함)
			return {
				className: 'color-666',
				prefix: '',
			};
		},
		[
			directMessageList,
			managerOnlineDirectMessageList,
			myProfile.userUid,
			unreadMessageCounts,
		],
	);
	const processAndSortMessages = useCallback(() => {
		const processed = managerOnlineDirectMessageList.reduce<SortedMessage[]>(
			(result, room) => {
				// 여기부터
				try {
					const isUser1 = room.user1Id === myProfile.userUid;

					if (room.visibleTo === -1) {
						return result;
					}

					if (
						(isUser1 && room.visibleTo === 1) ||
						(!isUser1 && room.visibleTo === 2)
					) {
						return result;
					}

					const otherUserInfo = isUser1 ? room.user2Info : room.user1Info;

					// 차단 상태 결정 - 내 시점에서의 차단 상태로 변환
					let myBlockType;
					if (isUser1) {
						// 내가 user1인 경우
						myBlockType = room.user1Info.blockType;
					} else {
						// 내가 user2인 경우
						myBlockType = room.user2Info.blockType;
					}
					if (!otherUserInfo || !otherUserInfo.userUid) {
						return result;
					}

					const messagesArray = Array.isArray(room.messages)
						? room.messages.map((msg) => {
								if (
									typeof msg.content === 'string' &&
									msg.content.includes('"roomId"') &&
									msg.content.includes('"roomTheme"')
								) {
									try {
										// 이스케이프된 JSON 문자열 처리
										const cleanContent = msg.content.replace(/\\/g, '');
										const jsonData = JSON.parse(cleanContent);
										return {
											...msg,
											content: t('goToMatching'),
											originalData: jsonData,
										};
									} catch (e) {
										console.log('JSON 파싱 에러:', e);
										return msg;
									}
								}
								return msg;
						  })
						: Array.from(room.messages.values());

					const sortedMessages = [...messagesArray].sort((a, b) => {
						// 날짜 형식에 관계없이 항상 동일한 방식으로 비교
						const dateA = a.createdAt.includes('T')
							? new Date(a.createdAt).getTime()
							: Number(a.createdAt);
						const dateB = b.createdAt.includes('T')
							? new Date(b.createdAt).getTime()
							: Number(b.createdAt);
						return dateB - dateA; // 내림차순 - 최신 메시지가 index 0
					});

					if (messagesArray.length > 0) {
						const latestMessage = messagesArray[0];
						if (latestMessage?.sender && latestMessage?.receiver) {
							const messageOtherInfo =
								latestMessage.sender.userUid === otherUserInfo.userUid
									? latestMessage.sender
									: latestMessage.receiver.userUid === otherUserInfo.userUid
									? latestMessage.receiver
									: null;

							if (messageOtherInfo) {
								Object.assign(otherUserInfo, {
									nickName: otherUserInfo.nickName,
									profileUrl: otherUserInfo.profileUrl,
									region1: otherUserInfo.region1,
									region2: otherUserInfo.region2,
									age: otherUserInfo.age,
									blockType: otherUserInfo.blockType,
								});
							}
						}
					}

					const display = getMessageDisplay({
						blockType: myBlockType,
						useStatus: room.useStatus,
					});

					const unreadCount = isUser1 ? room.unReadCnt1 : room.unReadCnt2;

					const processedRoom = {
						billingStatus: room.billingStatus,
						myPaymentStatus: isUser1
							? room.billingStatus === 1
							: room.billingStatus === 2,
						otherPaymentStatus: isUser1
							? room.billingStatus === 2
							: room.billingStatus === 1,
						dmRoomType: room.dmRoomType,
						roomId: room.roomId,
						messages: sortedMessages,
						latestCreatedAt:
							sortedMessages.length > 0
								? normalizeToTimestamp(sortedMessages[0].createdAt)
								: normalizeToTimestamp(room.createdAt),
						otherUserInfo,
						visibleTo: room.visibleTo,
						isUserLeft: isUser1 ? room.visibleTo === 1 : room.visibleTo === 2,
						isOtherUserLeft: isUser1
							? room.visibleTo === 2
							: room.visibleTo === 1,
						unreadCount: unreadCount,
						display,
						userInfo1: room.user1Info,
						userInfo2: room.user2Info,
						productType: room.productType,
						productSubType: room.productSubType,
						useStatus: room.useStatus,
						seq: room.seq,
						matchedAt: room.matchedAt,
						validUses: room.validUses,
					};
					result.push(processedRoom);
					return result;
				} catch (error) {
					console.error('Error processing room:', room.roomId, error);
					return result;
				}
			},
			[],
		);

		// 메시지가 있는 경우 마지막 메시지 시간, 없는 경우 방 생성 시간으로 정렬
		return processed.sort((a, b) => b.latestCreatedAt - a.latestCreatedAt);
	}, [
		directMessageList,
		managerOnlineDirectMessageList,
		myProfile.userUid,
		unreadMessageCounts,
		getMessageDisplay,
	]);

	const sortedMessages = processAndSortMessages();

	return {
		sortedMessages,
		showDirectMessageList,
		myProfile,
		userProfile,
		srcPrefix,
		setUserProfileOpen,
	};
};
