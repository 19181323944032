import { CSSTransition } from 'react-transition-group';
import ReportPopView from '@client/site/dating/ui/components/blockReport/views/ReportPopView';
import AvatarProfileInfoView from './views/AvatarProfileInfoView';
import { useBlockReportService } from '@client/site/dating/ui/components/blockReport/service/useBlockReportService';
import { useAvatarProfileService } from './service/useAvatarProfileService';

const AvatarProfileContainer = () => {
	const {
		openMenuPop,
		openReport,
		setOpenBlock,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		reportPopClose,
		fnSaveReport,
		reportPopOpen,
	} = useBlockReportService();

	const {
		blockResult,
		myMetaUser,
		metaUser,
		getCharacterFile,
		openOtherPlayerProfile,
		handleInitAvatarProfile,
		setOpenOtherPlayerProfile,
	} = useAvatarProfileService();

	return (
		<>
			<div
				className={`${
					openOtherPlayerProfile.isOpen ? 'avatarPopOn' : 'avatarPop'
				}`}
			>
				<div
					className="avatarDim"
					onClick={() => {
						handleInitAvatarProfile();
					}}
				/>
				<CSSTransition
					in={openOtherPlayerProfile.isOpen}
					classNames="avatarProInfo-transition"
					timeout={100}
				>
					<AvatarProfileInfoView
						blockResult={blockResult}
						setOpenBlock={setOpenBlock}
						openMenuPop={openMenuPop}
						menuDotClickHandler={menuDotClickHandler}
						myMetaUser={myMetaUser}
						metaUser={metaUser}
						getCharacterFile={getCharacterFile}
						reportPopOpen={reportPopOpen}
						setOpenOtherPlayerProfile={setOpenOtherPlayerProfile}
					/>
				</CSSTransition>
			</div>
			{/* 신고하기 */}
			<CSSTransition
				in={openReport}
				classNames="popWrap-transition"
				timeout={100}
			>
				<ReportPopView
					reportPopClose={reportPopClose}
					selectedOption={selectedOption}
					handleOptionSelect={handleOptionSelect}
					commonReportCodes={commonReportCodes}
					reportValue={reportValue}
					reportInputHandler={reportInputHandler}
					inputDisabled={inputDisabled}
					fnSaveReport={fnSaveReport}
				/>
			</CSSTransition>
		</>
	);
};

export default AvatarProfileContainer;
