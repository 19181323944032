// 개발서버 웹에서 가입할때 데이터 다양하게,,
export const coords:{latitude:string,longitude:string}[] = [
    { latitude: "21.028511", longitude: "105.804817" },  // 하노이
    { latitude: "10.823098", longitude: "106.629662" },  // 호치민시
    { latitude: "14.058324", longitude: "108.277199" },  // 다낭
    { latitude: "16.054406", longitude: "108.202167" },  // 후에
    { latitude: "20.998367", longitude: "105.044231" },  // 하이퐁
    { latitude: "11.942018", longitude: "108.445754" },  // 냐짱
    { latitude: "12.228078", longitude: "109.191980" },  // 다라이
    { latitude: "9.748917", longitude: "105.264826" },   // 빈롱
    { latitude: "10.975396", longitude: "106.517760" },  // 미리안
    { latitude: "9.780652", longitude: "106.669706" },   // 껀터
    { latitude: "16.073077", longitude: "108.215952" },  // 꽝남
    { latitude: "10.315700", longitude: "105.539104" },  // 롱안
    { latitude: "20.844935", longitude: "105.771710" },  // 하동
    { latitude: "17.473509", longitude: "107.580056" },  // 푸옌
    { latitude: "10.440177", longitude: "107.455601" },  // 다리아
    { latitude: "13.978533", longitude: "108.432006" },  // 자르
    { latitude: "21.005860", longitude: "105.957127" },  // 까오방
    { latitude: "19.314258", longitude: "104.441813" },  // 응에안
    { latitude: "11.109332", longitude: "106.315642" },  // 롱탄
    { latitude: "18.801293", longitude: "105.282112" },  // 빈디응
    { latitude: "12.629957", longitude: "108.021702" },  // 푸투
    { latitude: "10.888104", longitude: "106.664268" },  // 파롱
    { latitude: "14.040314", longitude: "106.426201" },  // 바리에
    { latitude: "21.199043", longitude: "105.848481" },  // 담뽀
    { latitude: "18.849309", longitude: "106.269548" },  // 자이
    { latitude: "16.473848", longitude: "107.540409" },  // 다뀌
    { latitude: "13.596150", longitude: "108.347635" },  // 빈록
    { latitude: "9.979051", longitude: "104.472136" },   // 까마우
    { latitude: "10.587758", longitude: "107.248718" },  // 판랑
    { latitude: "16.443872", longitude: "107.678788" },  // 깐호아
    { latitude: "17.060613", longitude: "106.401237" },  // 락쏙
    { latitude: "11.395502", longitude: "106.655381" },  // 호비
    { latitude: "15.378424", longitude: "105.030298" }   // 응이익
]
