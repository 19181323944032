import { ProductService } from '@client/site/api';
import { CurrencyCodeEnum } from '@dating/constants/product/currency-code.enum';
import { PayTypeEnum } from '@dating/constants/product/pay-type.enum';

export const useProductAdapter = () => {
	/*
	 * getProducts
	 * 상품 목록 조회
	 */
	const getProducts = (
		currencyCode: CurrencyCodeEnum,
		payType?: PayTypeEnum,
		type?: Array<string>,
	) => {
		return ProductService.getProducts(currencyCode, payType, type);
	};

	return {
		getProducts,
	};
};
