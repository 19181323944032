import {
	PHASER_PLAYER_JOINED,
	PHASER_PLAYER_UPDATED,
	PHASER_UPDATE_DIALOG_BUBBLE,
	PHASER_UPDATE_EMOJI_BUBBLE,
} from '@virtual-space/events/PhaserEventEmitter';
import MyPlayer from '@virtual-space/metaverse/characters/myplayer/MyPlayer';
import OtherPlayer from '@virtual-space/metaverse/characters/otherplayer/OtherPlayer';
import GameScene from '../GameScene';

export class GameSceneHandler {
	private scene: GameScene;

	constructor(scene: GameScene) {
		this.scene = scene;
	}
	handlePlayerJoined(arg: PHASER_PLAYER_JOINED) {
		const { key, player } = arg;
		if (this.scene.otherPlayerMap.has(key)) return;
		const animationName = player.anim;
		const texture = animationName.split('_')[0];
		const otherPlayer = this.scene.add.otherPlayer(
			player.x,
			player.y,
			texture,
			key,
			player.uuid,
			player.name,
			player.statusMessage,
			player.audioStatus,
			player.profileUrl,
		);
		this.scene.otherPlayers.add(otherPlayer);
		this.scene.otherPlayerMap.set(key, otherPlayer);
	}

	handlePlayerLeft(id: string) {
		if (this.scene.otherPlayerMap.has(id)) {
			const otherPlayer = this.scene.otherPlayerMap.get(id);
			if (!otherPlayer) return;
			this.scene.otherPlayers.remove(otherPlayer, true, true);
			this.scene.otherPlayerMap.delete(id);
		}
	}

	handleMyPlayerReady() {
		this.scene.myPlayer.readyToConnect = true;
	}

	handleMyMediaConnected() {
		this.scene.myPlayer.mediaConnected = true;
	}

	handlePlayerUpdated(arg: PHASER_PLAYER_UPDATED) {
		const otherPlayer = this.scene.otherPlayerMap.get(arg.key);
		if (arg.value != undefined)
			otherPlayer?.updateOtherPlayer(arg.field, arg.value);
	}

	handlePlayersOverlap(myPlayer: MyPlayer, otherPlayer: OtherPlayer) {
		otherPlayer.makeCall(myPlayer, this.scene.network.roomService?.webRTC);
	}

	handleChatMessageAdded(arg: PHASER_UPDATE_DIALOG_BUBBLE) {
		const otherPlayer = this.scene.otherPlayerMap.get(arg.playerId);
		otherPlayer?.updateDialogBubble(arg.content);
	}

	handleEmojiAdded(arg: PHASER_UPDATE_EMOJI_BUBBLE) {
		const otherPlayer = this.scene.otherPlayerMap.get(arg.playerId);
		otherPlayer?.updateReactionBubble(arg.emoji);
	}

	handleRoomChanged() {
		this.scene.otherPlayerMap.forEach((player, id) => {
			this.handlePlayerLeft(id);
		});
	}
}
