import { useCommonDMService } from '../../../service/useCommonDMService';
import { useTranslation } from 'react-i18next';
import { useMessageOrganizer } from '../../../hooks/useMessageOrganizer';
import MessageGroupListView from '../../../views/detail/MessageGroupListView';

/**
 * 메인 채팅 뷰 컴포넌트
 * 채팅방의 전체 UI 구조를 관리하고 메시지를 표시합니다.
 */
const MessageDetailView = ({
	groupedMessagesDetail,
	srcPrefix,
	handleExitChat,
	myProfile,
	navigationRoom,
	isOtherUserLeft,
	messagesEndRef,
	setUserProfileOpen,
	blockStatus,
}) => {
	const { t } = useTranslation('messenger');
	const { getStatusMessage, formatMessageDate } = useCommonDMService();
	const { organizeMessages } = useMessageOrganizer();

	const organizedMessages = organizeMessages(groupedMessagesDetail);

	return (
		<>
			<div className="inner chatRoom">
				<div className="chatList">
					{Object.keys(organizedMessages).length === 0 && (
						<div className="chatStartBox">
							<p className="partnerStart">{t('startWarmChat')}</p>
						</div>
					)}

					{/* 날짜별 메시지 그룹 렌더링 */}
					{Object.entries(organizedMessages).map(([dateKey, dateData]) => (
						<div key={dateKey} className="messageTime">
							<span className="messageTimeSpan">
								{formatMessageDate(dateKey)}
							</span>

							{/* 해당 날짜의 메시지 그룹들 렌더링 */}
							<MessageGroupListView
								groups={dateData.groups}
								myProfile={myProfile}
								srcPrefix={srcPrefix}
								isOtherUserLeft={isOtherUserLeft}
								blockStatus={blockStatus}
								setUserProfileOpen={setUserProfileOpen}
								navigationRoom={navigationRoom}
							/>
						</div>
					))}

					{isOtherUserLeft ? (
						<>
							<p className="partnerOut">
								{t('userLeftChat', {
									nickname: navigationRoom.otherUserInfo.nickName,
								})}
							</p>
							<button
								className="roomOutBtn"
								title={t('leave')}
								onClick={() => handleExitChat()}
							>
								{t('leave')}
							</button>
						</>
					) : (
						blockStatus &&
						blockStatus !== 'none' && (
							<p
								className="partnerOut"
								dangerouslySetInnerHTML={{
									__html: getStatusMessage(blockStatus),
								}}
							/>
						)
					)}
				</div>
				<div ref={messagesEndRef} style={{ height: '1px' }} />
			</div>
		</>
	);
};

export default MessageDetailView;
