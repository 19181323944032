import { create } from 'zustand';
import type { UserProfileCOVO } from '@client/site/api';

type DatingRepoState = {
	//이미지 src prefix
	srcPrefix: string;

	// 내 프로필
	myProfile: UserProfileCOVO;
	setMyProfile: (myProfile: UserProfileCOVO) => void;

	// 유저 프로필
	userProfile: UserProfileCOVO;
	setUserProfile: (userProfile: UserProfileCOVO) => void;

	// 내 프로필 조회
	myProfileOpen: boolean;
	setMyProfileOpen: (myProfileOpen: boolean) => void;

	// 유저 프로필 조회
	userProfileOpen: string;
	setUserProfileOpen: (userProfileOpen: string) => void;

	// 확인 팝업 message
	alertPopMessage: string;
	setAlertPopMessage: (alertPopMessage: string) => void;

	// 확인 팝업 open/clolse
	alertPopOpen: boolean;
	setAlertPopOpen: (alertPopOpen: boolean) => void;

	// 확인취소 팝업 message
	confirmPopMessage: string;
	setConfirmPopMessage: (confirmPopMessage: string) => void;

	// 확인취소 팝업 open/clolse
	confirmPopOpen: boolean;
	setConfirmPopOpen: (confirmPopOpen: boolean) => void;

	// 확인취소 팝업 확인 함수
	confirmOkFunc: { param: any; func: (param?: any) => any | void };
	setConfirmOkFunc: (confirmOkFunc: { param: any; func: () => any }) => void;
	initConfirmOkFunc: () => void;

	// 확인취소 팝업 취소 함수
	confirmNoFunc: { param: any; func: (param?: any) => any | void };
	setConfirmNoFunc: (confirmNoFunc: { param: any; func: () => any }) => void;
	initConfirmNoFunc: () => void;

	// 이미지 슬라이더 설정값
	sliderSettings: {};
	authSliderSettings: {};

	// 상품주문콜백함수
	orderCallback: { func: any; param: { productId: number } & Partial<any> };
	setOrderCallback: (orderCallback: {
		func: any;
		param: { productId: number; orderId: number; any };
	}) => void;
	initOrderCallback: () => void;
	addOrderCallbackParam: (param: {}) => void;

	// 내 추천인(초대)코드
	myInviteCode: string;
	setMyInviteCode: (myInviteCode: string) => void;
};
export const useDatingRepo = create<DatingRepoState>((set) => ({
	srcPrefix:
		import.meta.env.MODE != 'prd'
			? 'https://dev-metaverse.ibizsoftware.net/imgs/'
			: 'https://hush.berith.co/imgs/',

	myProfile: {
		userUid: '',
		nickName: '',
		age: '0',
		birthday: '',
		region1: '',
		region2: '',
		profileUrl: [],
		gender: '',
		introduce: '',
		job: '',
		height: 0,
		interest: [],
		lifeStyle: [],
		extraInterest: [],
		extraLifeStyle: [],
		smoking: '',
		drink: '',
		religion: '',
		ceo: false,
		house: false,
		salary: false,
		profession: false,
		car: false,
		phoneNumber: '',
		latestScore: 0,
		role: '',
		likeYn: false,
	},
	setMyProfile: (myProfile) => set({ myProfile }),
	userProfile: {
		userUid: '',
		nickName: '',
		age: '0',
		birthday: '',
		region1: '',
		region2: '',
		profileUrl: [],
		gender: '',
		introduce: '',
		job: '',
		height: 0,
		interest: [],
		lifeStyle: [],
		extraInterest: [],
		extraLifeStyle: [],
		smoking: '',
		drink: '',
		religion: '',
		ceo: false,
		house: false,
		salary: false,
		profession: false,
		car: false,
		phoneNumber: '',
		latestScore: 0,
		role: '',
		likeYn: false,
	},
	setUserProfile: (userProfile) => set({ userProfile }),

	myProfileOpen: false,
	setMyProfileOpen: (myProfileOpen) => set({ myProfileOpen }),

	userProfileOpen: '',
	setUserProfileOpen: (userProfileOpen) => set({ userProfileOpen }),

	alertPopMessage: '',
	setAlertPopMessage: (alertPopMessage: string) => set({ alertPopMessage }),

	alertPopOpen: false,
	setAlertPopOpen: (alertPopOpen: boolean) => set({ alertPopOpen }),

	confirmPopMessage: '',
	setConfirmPopMessage: (confirmPopMessage: string) =>
		set({ confirmPopMessage }),

	confirmPopOpen: false,
	setConfirmPopOpen: (confirmPopOpen: boolean) => set({ confirmPopOpen }),

	confirmOkFunc: { param: null, func: (param: any) => null },
	setConfirmOkFunc: (confirmOkFunc: {
		param: any;
		func: (param: any) => any;
	}) => set({ confirmOkFunc }),
	initConfirmOkFunc: () =>
		set({ confirmOkFunc: { param: null, func: (param: any) => null } }),

	confirmNoFunc: { param: null, func: (param: any) => null },
	setConfirmNoFunc: (confirmNoFunc: {
		param: any;
		func: (param: any) => any;
	}) => set({ confirmNoFunc }),
	initConfirmNoFunc: () =>
		set({ confirmNoFunc: { param: null, func: (param: any) => null } }),

	sliderSettings: {
		arrows: false,
		dots: true,
		autoplay: false,
		infinite: false,
		slidestoshow: 1,
		swipesensitivity: 0.1,
	},
	authSliderSettings: {
		arrows: true,
		autoplay: false,
		infinite: false,
		speed: 300,
		slidesToShow: 4,
		dots: false,
		prevArrow: `<button className="authArrow authPrev">
            <span className="hide">이전</span>
                </button>`,
		nextArrow: `<button className="authArrow authNext">
            <span className="hide">다음</span>
                </button>`,
		responsive: [
			{
				breakpoint: 340,
				settings: { slidesToShow: 3 },
			},
		],
	},

	// 상품주문콜백함수
	orderCallback: { func: null, param: { productId: -1 } },
	setOrderCallback: (orderCallback: {
		func: any;
		param: { productId: number } & Partial<any>;
	}) => set({ orderCallback }),
	initOrderCallback: () =>
		set({ orderCallback: { func: null, param: { productId: -1 } } }),
	addOrderCallbackParam: (param: {}) =>
		set((state) => ({
			orderCallback: {
				func: state.orderCallback.func,
				param: { ...state.orderCallback.param, ...param },
			},
		})),

	// 내 추천인(초대)코드
	myInviteCode: '',
	setMyInviteCode: (myInviteCode: string) => set({ myInviteCode }),
}));
