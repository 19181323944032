import type { ProductSOVO } from '@client/site/api';

interface ManagerProductsPopViewProps {
	managerProducts: ProductSOVO[];
	productSubType: 'ONLINE' | 'OFFLINE';
	fnValidateOrder: (callback: any, param: any) => void;
	onOpenMessageRoom: (params: {
		productId: number;
		orderId: number;
	}) => Promise<void>;
}

export const ManagerProductsPopView: React.FC<ManagerProductsPopViewProps> = ({
	managerProducts,
	productSubType,
	fnValidateOrder,
	onOpenMessageRoom,
}) => {
	return (
		<div className="popWrap footPopType d-flex" id="mgHelp">
			<div className="popupInfo padding-t36 padding-b32">
				<span className="ic-footPopTop"></span>
				<div className="refillList">
					{managerProducts.map((item) => {
						if (item.productSubType === productSubType) {
							return (
								<button
									key={item.id}
									className={`rfBox ${item.id === 3 ? 'bestBox' : ''}`}
									onClick={() => {
										fnValidateOrder(onOpenMessageRoom, {
											productId: item.id,
										});
									}}
								>
									<span className="fz18 bold-500">{item.name}</span>
									<p className="ic-berith md">
										{item.price
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									</p>
								</button>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};
