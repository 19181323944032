import { create } from 'zustand';
import { SettingTermsCOVO, UserWaitingInfoCOVO } from '@client/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';

interface RegisterState {
	// 회원가입 단계
	step: number;

	// 점유 핸드폰 번호
	phoneNumber: string;

	// 인증번호 요청 횟수
	verifyReqCount: number;

	// 약관 리스트
	termsList: SettingTermsCOVO[];

	// 필수 약관 인덱스
	requiredIndexes: string[];

	// 체크한(동의한) 약관
	checkedAgreements: {}[];

	// 선택한 약관
	selectedTerm: string;

	// 선택한 성별
	selectedGender: string;

	// 생년월일
	inputBirthday: string[];

	// 키
	inputHeight: string[];

	// 선택한 직업
	selectedJob: string;

	// 회원가입 시 입력한 정보
	userWaiting: UserWaitingInfoCOVO;

	// 회원가입 단계별 타이틀 및 url
	registerSteps: { title: string; url: string }[];

	// SNS로그인 시 empty인 경우 id, email, provider 저장
	SNSInfo: {
		resultCode: string;
		id: string;
		email: string;
		socialType: string;
	};

	// 삭제된 계정 알림
	isDeleted: boolean;

	// signUpStatus
	approvedAccount: boolean;
	setApprovedAccount: (approvedAccount: boolean) => void;
}

interface RegisterAction {
	// 회원가입 단계
	setStep: (step: number) => void;

	// 점유 핸드폰 번호
	setPhoneNumber: (phoneNumber: string) => void;

	// 인증번호 요청 횟수
	setVerifyReqCount: (verifyReqCount: number) => void;

	// 약관 리스트
	setTermsList: (termsList: SettingTermsCOVO[]) => void;

	// 필수 약관 인덱스
	setRequiredIndexes: (requiredIndexes: string[]) => void;

	// 체크한(동의한) 약관
	setCheckedAgreements: (checkedAgreements: {}[]) => void;

	// 선택한 약관
	setSelectedTerm: (selectedTerm: string) => void;

	// 선택한 성별
	setSelectedGender: (selectedGender: string) => void;

	// 생년월일
	setInputBirthday: (inputBirthday: string[]) => void;

	// 키
	setInputHeight: (inputHeight: string[]) => void;

	// 선택한 직업
	setSelectedJob: (selectedJob: string) => void;

	// 회원가입 시 입력한 정보
	setUserWaiting: (userWaiting: UserWaitingInfoCOVO) => void;

	// 회원가입 단계별 타이틀 및 url
	resetRegisterInfo: () => void;

	// SNS로그인 시 empty인 경우 id, email, provider 저장
	setSNSInfo: (SNSInfo: {
		resultCode: string;
		id: string;
		email: string;
		socialType: string;
	}) => void;

	// 삭제된 계정 알림
	setIsDeleted: (isDeleted: boolean) => void;

	// signUpStatus
	setApproveAccount: (approveAccount: boolean) => void;
}

const initialState: RegisterState = {
	step: 0,
	phoneNumber: '',
	verifyReqCount: 0,
	termsList: [],
	requiredIndexes: [],
	checkedAgreements: [],
	selectedTerm: '',
	selectedGender: '',
	inputBirthday: Array(6).fill(''),
	inputHeight: Array(3).fill(''),
	selectedJob: '',
	registerSteps: [
		{
			title: '이용약관 동의',
			url: PATH.REGISTER.TERMS,
		},
		{
			title: '성별 선택',
			url: PATH.REGISTER.GENDER,
		},
		{
			title: '닉네임',
			url: PATH.REGISTER.NICKNAME,
		},
		{
			title: '생년월일 등록',
			url: PATH.REGISTER.BIRTH,
		},
		{
			title: '키 선택',
			url: PATH.REGISTER.HEIGHT,
		},
		{
			title: '직업 선택',
			url: PATH.REGISTER.JOB,
		},
		{
			title: '프로필 사진 등록',
			url: PATH.REGISTER.PHOTO,
		},
		{
			title: '이상형 선택',
			url: PATH.REGISTER.IDEAL,
		},
		{
			title: '추천인 코드 입력 및 태그 등록',
			url: PATH.REGISTER.TAG_INVITECODE,
		},
	],
	userWaiting: {
		smoke: '',
		profileUrl: [],
		lifeStyle: [],
		age: 0,
		drink: '',
		height: 0,
		job: '',
		interest: [],
		location: '',
		nickName: '',
		religion: '',
	},
	SNSInfo: { resultCode: '', id: '', email: '', socialType: '' },

	isDeleted: false,

	approvedAccount: false,
};

export const useRegisterRepo = create<RegisterState & RegisterAction>(
	(set) => ({
		...initialState,

		setStep: (step: number) => set({ step }),

		setPhoneNumber: (phoneNumber: string) => set({ phoneNumber }),

		setVerifyReqCount: (verifyReqCount: number) => set({ verifyReqCount }),

		setTermsList: (termsList: SettingTermsCOVO[]) => set({ termsList }),

		setRequiredIndexes: (requiredIndexes: string[]) => set({ requiredIndexes }),

		setCheckedAgreements: (checkedAgreements: {}[]) =>
			set({ checkedAgreements }),

		setSelectedTerm: (selectedTerm: string) => set({ selectedTerm }),

		setSelectedGender: (selectedGender: string) => set({ selectedGender }),

		setInputBirthday: (inputBirthday: string[]) => set({ inputBirthday }),

		setInputHeight: (inputHeight: string[]) => set({ inputHeight }),

		setSelectedJob: (selectedJob: string) => set({ selectedJob }),

		setUserWaiting: (userWaiting: UserWaitingInfoCOVO) => set({ userWaiting }),

		resetRegisterInfo: () => {
			set(initialState);
		},

		setSNSInfo: (SNSInfo: {
			resultCode: string;
			id: string;
			email: string;
			socialType: string;
		}) => set({ SNSInfo }),

		setIsDeleted: (isDeleted: boolean) => set({ isDeleted }),

		setApproveAccount: (approvedAccount: boolean) => set({ approvedAccount }),
	}),
);
