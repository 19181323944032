import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import type { InActiveCIVO } from '@client/site/api';
import { useState } from 'react';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { bridgeSetNativeDataToken } from '@client/utils/bridgeCallHandler';

export const useInactiveAccountService = () => {
	const [inactive, setInactive] = useState<boolean>(false);
	const { authToken, initAuthToken, initDeviceInfo, deviceId, fcmToken } =
		useAuthRepo();
	const { saveInActiveUser } = useSettingAdapter();
	const { myProfile } = useDatingRepo();

	/*
	 * fnSaveInactiveUser
	 * 휴면처리
	 */
	const fnSaveInactiveUser = () => {
		if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
			const map: InActiveCIVO = {
				inActive: true,
			};
			saveInActiveUser(authToken.accessJmt, authToken.refreshJmt, map)
				.then((value) => {
					setInactive(true);
					initAuthToken();
					initDeviceInfo();
					bridgeSetNativeDataToken({ accessJmt: '', refreshJmt: '' });
					dmEventEmitter.emit('react-change-dm-inactive', {
						userUid: myProfile.userUid,
						inActive: true,
					});
				})
				.catch((reason) => {
					console.log(reason.body);
				});
		}
	};

	/*
	 * fnSaveActiveUser
	 * 휴면해제
	 */
	const fnSaveActiveUser = () => {
		const map: InActiveCIVO = {
			inActive: false,
			deviceId: deviceId,
			fcmToken: fcmToken,
		};

		saveInActiveUser(authToken.accessJmt, authToken.refreshJmt, map)
			.then((value) => {})
			.catch((reason) => {});
	};

	return {
		fnSaveInactiveUser,
		fnSaveActiveUser,
		inactive,
	};
};
