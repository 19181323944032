import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PostInfoCOVO } from '@client/site/api';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';

interface props {
	children: ReactNode;
	fnGetPosts: () => void;
	hasMore: boolean;
	target: React.RefObject<HTMLDivElement>;
	setTarget: (ref: HTMLDivElement | null) => void;
	myProfileOpen: boolean;
	setFadeIn: (fadeIn: boolean) => void;
	postList: PostInfoCOVO[];
	setPostList: (list: PostInfoCOVO[]) => void;
	setPostListAdd: (list: PostInfoCOVO[]) => void;
	pageArray: number[];
	setPageArray: (page: number) => void;
	initFeedEditPops: (feeds: PostInfoCOVO[]) => void;
	isSearchPost: boolean;
	setIsSearchPost: (isSearch: boolean) => void;
	haveToRefresh: boolean;
}

export const InfiniteScroll = ({
	children,
	fnGetPosts,
	hasMore,
	target,
	setTarget,
	myProfileOpen,
	setFadeIn,
	postList,
	setPostList,
	setPostListAdd,
	pageArray,
	setPageArray,
	initFeedEditPops,
	isSearchPost,
	setIsSearchPost,
	haveToRefresh,
}: props) => {
	const { getPosts2 } = useLoungeAdapter();

	// fetching ing
	const [isFetching, setIsFetching] = useState<boolean>(false);
	// throttle
	const [isThrottled, setIsThrottled] = useState(false);

	const fetchData = useCallback(() => {
		if (isFetching || !useLoungeRepo.getState().hasMore) return;
		fnGetPosts();
		setIsFetching(true);
	}, [pageArray, hasMore]);

	useEffect(() => {
		if (target.current) {
			setTarget(target.current); // ref가 업데이트될 때만 Zustand 상태 갱신
		}
	}, [target.current]);

	// infinite scroll
	useEffect(() => {
		if (!target.current) return;
		const observer = new IntersectionObserver(
			([entries]) => {
				if (entries.isIntersecting && !isThrottled) {
					fetchData();
					setIsThrottled(true);
					setTimeout(() => {
						setIsThrottled(false);
					}, 2000); // 2초 지연
				}
			},
			{ threshold: 0.1 }, // 100% 보일 때 트리거
		);
		if (target.current) {
			// 로컬에서 맨 첨에 두번 렌더링 되는거때매 일단 줘놨음
			observer.observe(target.current);
		}
		return () => {
			if (target.current && !hasMore) {
				observer.unobserve(target.current);
			}
		};
	}, [target]);

	const { status: statusGetPosts2, data: dataGetPosts2 } = useQuery({
		queryKey: ['getPosts2'],
		queryFn: () => {
			setPageArray(1);
			return getPosts2(1);
		},
		enabled: (!myProfileOpen && postList.length == 0) || haveToRefresh,
	});

	useEffect(() => {
		if (postList.length != 0 && !isSearchPost && !haveToRefresh) return;
		if (statusGetPosts2 === 'pending') {
			setFadeIn(false);
		} else if (statusGetPosts2 === 'success' && dataGetPosts2) {
			// 맨 첨이면 listAdd 말고 그냥 list
			setPageArray(1);
			setIsSearchPost(false);
			setPostList(dataGetPosts2);
			// 케밥 control list set
			initFeedEditPops(dataGetPosts2);
			setFadeIn(true);
		}
	}, [statusGetPosts2, dataGetPosts2]);

	return <>{children}</>;
};
