import { Link } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { UserAuthBadgeCOVO } from '@client/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	data: UserAuthBadgeCOVO;
	i: number;
	clearPreviewURLs: () => void;
}
export const AuthAddBadgeInnerView = ({ data, i, clearPreviewURLs }: props) => {
	const { t } = useTranslation('profile');

	return (
		<div key={data.badgeUrl + i} className="authItem">
			{data.badgeSubNm === t('badge.addBadge') ? (
				<Link
					to={PATH.PROFILE.ADD_PROFILE}
					state={{ from: 'myProfile' }}
					onClick={() => clearPreviewURLs()}
				>
					<div className="imgBox">
						<img className="img01" src={data.badgeUrl} alt={data.badgeSubNm} />
					</div>
					<p>{data.badgeSubNm}</p>
				</Link>
			) : (
				<>
					<div className={`imgBox star0${data.badgeLevel}`}>
						<img className="img01" src={data.badgeUrl} alt={data.badgeSubNm} />
					</div>
					<p>{data.badgeSubNm}</p>
				</>
			)}
		</div>
	);
};
