import { CSSTransition } from 'react-transition-group';
import LikeConfirmView from './views/LikeConfirmView';
import MessagePopView from './views/MessagePopView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { useDirectMessageService } from '@virtual-space/ui/components/dm/user/service/useDirectMessageService';
import { useEffect } from 'react';
import { useOrderUtils } from '@client/utils/orderUtils';

const PaymentContainer = () => {
	const {
		fnSaveUserLike,
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		srcPrefix,
		activeItem,
		fnSendLikePopClose,
		messageTargetUser,
		fnCloseMessagePop,
		commonProducts,
		existingRoomData,
	} = usePaymentService();

	const { fnValidateOrder } = useOrderUtils();

	const {
		messageInputValue,
		handleChange,
		handlePaymentClick,
		setMessageInputValue,
	} = useDirectMessageService();

	useEffect(() => {}, [openLikeConfirm, openPaymentPop]);

	return (
		<div
			className={`${
				openPaymentPop ||
				openPaymentPopUp ||
				openMessageConfirm ||
				openLikeConfirm
					? 'paymentPopOn'
					: 'paymentPop'
			}`}
		>
			<div
				className="paymentDim"
				onClick={() => {
					setOpenPaymentPop(false);
					setOpenPaymentPopUp(false);
					setOpenMessageConfirm(false);
					setOpenLikeConfirm(false);
				}}
			/>

			{/*좋아요 보내기*/}
			<CSSTransition
				in={openLikeConfirm}
				classNames="likeWrap-transition"
				timeout={100}
			>
				<LikeConfirmView
					fnSaveUserLike={fnSaveUserLike}
					srcPrefix={srcPrefix}
					activeItem={activeItem}
					fnSendLikePopClose={fnSendLikePopClose}
					fnValidateOrder={fnValidateOrder}
					productPack={commonProducts[0]}
				/>
			</CSSTransition>

			{/*메세지 보내기*/}
			<CSSTransition
				in={openPaymentPop}
				classNames="messageWrap-transition"
				timeout={100}
			>
				<MessagePopView
					messageInputValue={messageInputValue}
					handleChange={handleChange}
					handlePaymentClick={handlePaymentClick}
					messageTargetUser={messageTargetUser}
					fnValidateOrder={fnValidateOrder}
					productPack={commonProducts[2]}
					fnCloseMessagePop={fnCloseMessagePop}
					setMessageInputValue={setMessageInputValue}
					existingRoomData={existingRoomData}
				/>
			</CSSTransition>
		</div>
	);
};

export default PaymentContainer;
