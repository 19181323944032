import { useAuthRepo } from '@client/site/dating/repository/auth/useAuthRepo';
import { getI18nMessage } from '../../../../../../types/i18n/messages';
import { SupportedLanguages } from '../../../../../../types/i18n/types';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';

const ChatMessageView = (chatMessage) => {
	const { myMetaUser } = useMetaUserRepo();
	const { langCode } = useAuthRepo();
	const isMine = chatMessage.chatMessage.uuid === myMetaUser.userUid;
	const isSystem = chatMessage.chatMessage.senderId === 'system';
	const getMessageContent = () => {
		if (
			isSystem &&
			chatMessage.chatMessage.type &&
			chatMessage.chatMessage.params
		) {
			try {
				const params = JSON.parse(chatMessage.chatMessage.params);
				if (
					chatMessage.chatMessage.type === 'welcome' &&
					params.roomName === 'square'
				) {
					// square인 경우 roomName도 번역
					params.roomName = getI18nMessage(
						'square',
						langCode as SupportedLanguages,
					);
				}
				return getI18nMessage(
					chatMessage.chatMessage.type,
					langCode as SupportedLanguages,
					params,
				);
			} catch (error) {
				console.error('Error parsing system message params:', error);
				return chatMessage.chatMessage.content;
			}
		}
		return chatMessage.chatMessage.content;
	};
	const getMessageColor = () => {
		if (isMine) return '#00FFA3';
		switch (chatMessage.chatMessage.gender) {
			case 'M':
				return '#6FC2FF';
			case 'F':
				return '#FF86C7';
			default:
				return '#fff'; // 기본 색상
		}
	};

	const messageColor = getMessageColor();
	const authorColor = { color: messageColor };

	return (
		<div data-chat-message="default">
			{chatMessage.chatMessage.author && (
				<span data-chat-message-name="true" style={authorColor}>
					{chatMessage.chatMessage.author}
				</span>
			)}
			{chatMessage.chatMessage.content && (
				<span
					style={{
						color: isSystem ? '#FFD700' : '#fff',
					}}
				>
					{getMessageContent()}
				</span>
			)}
		</div>
	);
};

export default ChatMessageView;
