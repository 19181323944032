import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface props {
	isOnline: boolean;
	setIsOnline: (isOnline: boolean) => void;
	setShowOnlineMessageList: (show: boolean) => void;
	setShowOfflineMessageList: (show: boolean) => void;
	setIsFirst: (isFirst: boolean) => void;
	isFirst: boolean;
}
const ManagerTabView = ({
	setIsOnline,
	setIsFirst,
	isFirst,
	isOnline,
	setShowOnlineMessageList,
	setShowOfflineMessageList,
}: props) => {
	const navigate = useNavigate();

	const { myProfile, srcPrefix } = useDatingRepo();

	const handleTabClick = (isOnlineTab: boolean) => {
		setIsOnline(isOnlineTab);
		setShowOnlineMessageList(isOnlineTab);
		setShowOfflineMessageList(!isOnlineTab);
		navigate(isOnlineTab ? TABS.ONLINE.path : TABS.OFFLINE.path);
	};

	// 파일을 Layout 구조로 변환하지 않고, 헤더 반응 작동시키기 위한 state
	const [isState, setIsState] = useState(isFirst ? true : !isOnline);

	useEffect(() => {
		setIsFirst(false);
		const isOnlinePath = location.pathname.includes('/online');
		setIsState(isOnlinePath ? true : false);
	}, [location.pathname]);

	const { t } = useTranslation('messenger');

	const TABS = {
		ONLINE: {
			title: t('online'),
			path: PATH.MANAGER.MESSENGER.ONLINE,
			type: '온라인',
		},
		OFFLINE: {
			title: t('offline'),
			path: PATH.MANAGER.MESSENGER.OFFLINE,
			type: '오프라인',
		},
	} as const;

	return (
		<header className="cateHeader">
			<div className="headerWrap">
				<div className="h-left">
					<div className="headerTab">
						<button
							className={`${isState ? 'active' : ''} tabButton tab01`}
							data-tab="tabCon01"
							onClick={() => handleTabClick(true)}
						>
							<span>{TABS.ONLINE.title}</span>
						</button>
						<button
							className={`${!isState ? 'active' : ''} tabButton tab02`}
							data-tab="tabCon02"
							onClick={() => handleTabClick(false)}
						>
							<span>{TABS.OFFLINE.title}</span>
						</button>
					</div>
				</div>
				<div className="h-center" />
				<div className="h-right">
					<span className="headerMgBadge">{t('manager')}</span>
					{/*내 프로필*/}
					<div>
						<Link
							to={PATH.MANAGER.MY_PROFILE}
							onClick={() => {
								//내 프로필 open
								// setMyProfileOpen(true);
							}}
						>
							<button type="button" className="profileSmImg">
								<img
									src={
										myProfile && myProfile?.profileUrl[0]
											? srcPrefix + myProfile?.profileUrl[0]
											: '/assets/img/test/boy.png'
									}
									alt="테스트이미지"
								/>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
};

export default ManagerTabView;
