/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductCOVO } from '../models/ProductCOVO';
import type { ProductListCOVO } from '../models/ProductListCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductService {
    /**
     * 상품 목록 조회
     * @param currencyCode
     * @param payType 상품 결제 타입
     * @param type 불러올 상품 타입, 단일 값 혹은 배열 형식으로 입력 가능
     * @returns ProductListCOVO 조회된 상품 목록
     * @throws ApiError
     */
    public static getProducts(
        currencyCode: string,
        payType?: 'CASH' | 'POINT',
        type?: Array<string>,
    ): CancelablePromise<ProductListCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product',
            query: {
                'currencyCode': currencyCode,
                'payType': payType,
                'type': type,
            },
        });
    }
    /**
     * 상품 상세 조회
     * @param id 조회할 상품 ID
     * @returns ProductCOVO 조회된 상품 상세 정보
     * @throws ApiError
     */
    public static getProductDetail(
        id: number,
    ): CancelablePromise<ProductCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/product/{id}',
            path: {
                'id': id,
            },
        });
    }
}
