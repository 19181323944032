import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { useTranslation } from 'react-i18next';

const RoomListWrapView = () => {
	const { roomListOpen, setRoomListOpen } = useRoomRepo();
	const { t } = useTranslation('metaverse');
	return (
		<div className="h-roomListWrap">
			<button
				className="roomListBtn on"
				onClick={() => {
					setRoomListOpen(!roomListOpen);
				}}
			>
				<span>{t('roomList')}</span>
			</button>
		</div>
	);
};

export default RoomListWrapView;
