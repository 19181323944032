import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import type { UserNicknameCIVO } from '@client/site/api';
import { useNicknameStateRepo } from '@dating/ui/pages/register/nickname/useNicknameStateRepo';
import { useEffect } from 'react';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';

export const useNicknameService = () => {
	const {
		inputValue,
		setInputValue,
		btnDisabled, //다음 버튼 disabled 처리
		setBtnDisabled,
		lengthOver, //8자리 이상 입력한 경우
		setLengthOver,
		isValueEntered, //2글자 이상 입력된 경우
		setIsValueEntered,
		isAvailable,
		setIsAvailable,
		response,
		setResponse,
	} = useNicknameStateRepo();

	const { setStep, registerSteps } = useRegisterRepo();

	const { saveNickname, statusSaveNickname, dataSaveNickname } =
		useRegisterAdapter();

	////////////////////////////////////////
	/*
	 * handleInputChange
	 * 입력값이 변경될 때 호출되는 핸들러
	 */
	const handleInputChange = (newValue: string) => {
		setInputValue(newValue);

		// 닉네임 길이 2~8자 제한
		if (newValue.length === 2) {
			setIsValueEntered(true);
			setBtnDisabled(false);
			// 이미 사용중인 닉네임입니다 한번만 노출되게 변경. 입력값 바뀌면 다시 안보이게.
			setIsAvailable(true);
			return;
		} else if (newValue.length < 2) {
			setIsValueEntered(false);
			setBtnDisabled(true);
			return;
		}

		if (newValue.length === 9) {
			setLengthOver(true);
			setBtnDisabled(true);
			return;
		} else if (newValue.length === 8) {
			setLengthOver(false);
			setBtnDisabled(false);
			return;
		}
	};

	/*
	 * fnSaveNickname
	 * 다음 버튼 - 닉네임 저장
	 */
	const fnSaveNickname = () => {
		const map: UserNicknameCIVO = {
			nickname: inputValue,
		};
		saveNickname(map);
	};

	useEffect(() => {
		if (statusSaveNickname === 'success' && dataSaveNickname) {
			if (dataSaveNickname.result) {
				setStep(3);
				setIsAvailable(true);
				setResponse(true);
			} else {
				setIsAvailable(false);
			}
		} else if (statusSaveNickname === 'error') {
		}
	}, [statusSaveNickname, dataSaveNickname]);

	return {
		inputValue,
		setInputValue,
		handleInputChange,
		fnSaveNickname,
		btnDisabled,
		setBtnDisabled,
		lengthOver,
		isValueEntered,
		isAvailable,
		response,
		registerSteps,
	};
};
