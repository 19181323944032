import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageInputViewProps } from '../../types/navigationTypes';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';

const MessageInputView = ({
	handleSubmit,
	messageInputValue,
	handleChange,
	focused,
	isOtherUserLeft = false,
	blockStatus,
	isUseStatusDone = false,
}: MessageInputViewProps) => {
	const { t } = useTranslation('messenger');
	const inputRef = useRef<HTMLInputElement>(null);
	const { setFocused } = useDirectMessageRepo();

	const getPlaceholder = () => {
		if (isUseStatusDone) return t('noChatAvailable');
		if (isOtherUserLeft) return t('endedChat');
		if (blockStatus && blockStatus !== 'none') return t('noChatAvailable');
		return t('enterChat');
	};

	// 명시적으로 boolean 타입으로 변환하여 문자열이 할당되지 않도록 함
	const isDisabled = Boolean(
		isUseStatusDone ||
			isOtherUserLeft ||
			(blockStatus && blockStatus !== 'none'),
	);

	return (
		<div className="chatInput input">
			<input
				type="text"
				ref={inputRef}
				value={messageInputValue}
				maxLength={3000}
				onChange={handleChange}
				placeholder={getPlaceholder()}
				disabled={isDisabled}
				enterKeyHint="send"
				onFocus={() => {
					if (!focused) {
						setFocused(true);
					}
				}}
				onBlur={() => {
					setFocused(false);
				}}
			/>
			<button className="submitBtn" type="button" onClick={handleSubmit} />
		</div>
	);
};

export default MessageInputView;
