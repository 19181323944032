import {useState} from "react";

export const useProfileEditStateRepo = () => {
    // 흡연 여부
    const [drinkingValue, setDrinkingValue] = useState<string[]>([]);

    // 음주 여부
    const [smokingValue, setSmokingValue] = useState<string[]>([]);

    // 선택한 공통 관심사
    const [interestValue, setInterestValue] = useState<string[]>([]);

    // 선택한 공통 라이프스타일
    const [lifeStyleValue, setLifeStyleValue] = useState<string[]>([]);

    // 입력한 추천인 코드
    const [recommendCode, setRecommendCode] = useState<string>("");

    // 추천인 코드 validation message
    const [codeFailed, setCodeFailed] = useState(false);

    // 추천인코드 error message
    const [codeErrorMessage, setCodeErrorMessage] = useState<string | boolean>('');

    return {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        codeErrorMessage,
        setCodeErrorMessage,
    };
};