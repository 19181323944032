import { useMessageListService } from '../service/useMessageListService';
import { useTranslation } from 'react-i18next';
import MessageListItemView from './MessageListItemView';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';

const MessageListView = () => {
	const { t } = useTranslation('messenger');
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { myProfile, srcPrefix, setUserProfileOpen, sortedMessages } =
		useMessageListService();

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map((message) => (
					<MessageListItemView
						key={message.roomId}
						message={message}
						myProfile={myProfile}
						srcPrefix={srcPrefix}
						setUserProfileOpen={setUserProfileOpen}
						setOpenDirectMessageDetail={setOpenDirectMessageDetail}
					/>
				))
			) : (
				<div className="dataNoneTxt">
					<p className="txt01">{t('emptyList')}</p>
				</div>
			)}
		</div>
	);
};

export default MessageListView;
