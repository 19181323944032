import useLeaveChatStore from '@client/site/dating/stores/LeaveChatStore';
import { useTranslation } from 'react-i18next';

const StartOpenChatButtonView = ({
	handlePaymentClick,
	blockStatus,
	isOtherUserLeft,
}) => {
	const { t } = useTranslation('messenger');
	const { setOpenLeaveChat } = useLeaveChatStore();

	if (isOtherUserLeft || (blockStatus && blockStatus !== 'none')) {
		return (
			<div className="chatInput input padding-16 bg-fff">
				<button
					className="btn btnBig btnCCC"
					onClick={() => {
						setOpenLeaveChat(true);
					}}
				>
					<span>{t('leaveRoom')}</span>
				</button>
			</div>
		);
	}

	return (
		<div className="chatInput input padding-16 bg-fff">
			<button
				className="btn btnBig btnBlack"
				onClick={() => {
					handlePaymentClick();
				}}
			>
				<span>
					{t('openChat')}
					<span className="ic-berith">40</span>
				</span>
			</button>
		</div>
	);
};

export default StartOpenChatButtonView;
