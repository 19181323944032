import { Route, Routes, useLocation } from 'react-router-dom';
import ProfileEditContainer from '@dating/ui/pages/profile/editProfile/ProfileEditContainer';
import ProfileAddContainer from '@dating/ui/pages/profile/addProfile/ProfileAddContainer';
import { ProfileImgEditContainer } from '@dating/ui/pages/profile/editProfile/ProfileImgEditContainer';
import { lazy, Suspense } from 'react';
import Spinner from '@dating/ui/components/spinner/Spinner';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';

const MyProfileContainer = lazy(
	() => import('@dating/ui/pages/myProfile/MyProfileContainer'),
);

export default function MyProfileRouter() {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Suspense fallback={<Spinner />}>
				<Routes location={location}>
					<Route path="*" element={<MyProfileContainer />} />
					<Route path="/editProfile" element={<ProfileEditContainer />} />
					<Route path="/editProfileImg" element={<ProfileImgEditContainer />} />
					<Route path="/addProfile" element={<ProfileAddContainer />} />
				</Routes>
			</Suspense>
		</ErrorBoundary>
	);
}
