import AvatarCateTabView from './views/AvatarCateTabView';
import ColorPickerView from './views/ColorPickerView';
import AvatarCateTabPanelView from './views/AvatarCateTabPanelView';
import AvatarItemTabPanelView from './views/AvatarItemTabPanelView';
import ButtonHeaderView from '@client/site/dating/ui/components/buttonHeader/ButtonHeaderView';
import { useCharacterService } from './service/useCharacterService';
import { useTranslation } from 'react-i18next';
import { make, characterLen } from '@virtual-space/utils/avatarUtils';

const CharacterContainer = () => {
	const {
		title,
		link,
		characterImages,
		character,
		activeTab,
		showColorPicker,
		modifyHairColor,
		modifyCostumeColor,
		modifyFaceColor,
		setModifyHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		handleTabClick,
		handleChangeAvatar,
		handleClick,
		handleSubmit,
	} = useCharacterService();
	const { t } = useTranslation('metaverse');

	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} link={link} />
			<div className="popConWrap">
				<div className="avatarWrap">
					<div className="stickyWrap">
						<div className="grayBox avatarBox">
							<div className="avatar_character" key={make(character)}>
								{characterImages.map((img, index) => (
									<span
										key={index}
										className="avatar_object"
										style={{
											background: `url('${img}')`,
										}}
									/>
								))}
							</div>
							<button className="refresh" onClick={handleClick} />
						</div>
						<div className="grayBox avatarCate margin-t24">
							{[t('hair'), t('clothing'), t('face'), t('skin')].map(
								(label, index) => (
									<AvatarCateTabView
										key={index}
										label={label}
										active={index === activeTab}
										onClick={() => handleTabClick(index)}
									/>
								),
							)}
						</div>
						{showColorPicker ? (
							<ColorPickerView
								type={
									activeTab === 0
										? 'hairs'
										: activeTab === 1
										? 'costumes'
										: activeTab === 2
										? 'faces'
										: 'body'
								}
								length={
									activeTab === 0
										? characterLen.hc
										: activeTab === 1
										? characterLen.cc
										: activeTab === 2
										? characterLen.fc
										: characterLen.b
								}
								onSelectAvatar={
									activeTab === 0
										? (idx) => handleChangeAvatar('hc', idx)
										: activeTab === 1
										? (idx) => handleChangeAvatar('cc', idx)
										: activeTab === 2
										? (idx) => handleChangeAvatar('fc', idx)
										: (idx) => handleChangeAvatar('b', idx)
								}
								initialAvatarColor={
									activeTab === 0
										? character.hc
										: activeTab === 1
										? character.cc
										: activeTab === 2
										? character.fc
										: character.b
								}
								setColor={
									activeTab === 0
										? (color) => setModifyHairColor(color)
										: activeTab === 1
										? (color) => setModifyCostumeColor(color)
										: activeTab === 2
										? (color) => setModifyFaceColor(color)
										: (color) => undefined
								}
							/>
						) : (
							<br />
						)}
					</div>
					<form onSubmit={handleSubmit}>
						{[0, 1, 2, 3].map(
							(tabIndex) =>
								activeTab === tabIndex && (
									<AvatarCateTabPanelView
										key={tabIndex}
										active={activeTab === tabIndex}
									>
										<AvatarItemTabPanelView
											type={
												tabIndex === 0
													? 'hair'
													: tabIndex === 1
													? 'costume'
													: tabIndex === 2
													? 'face'
													: 'body'
											}
											length={
												tabIndex === 0
													? characterLen.h
													: tabIndex === 1
													? characterLen.c
													: tabIndex === 2
													? characterLen.f
													: characterLen.b
											}
											initialAvatar={
												tabIndex === 0
													? character.h
													: tabIndex === 1
													? character.c
													: tabIndex === 2
													? character.f
													: character.b
											}
											onSelectAvatar={(idx) =>
												handleChangeAvatar(
													tabIndex === 0
														? 'h'
														: tabIndex === 1
														? 'c'
														: tabIndex === 2
														? 'f'
														: 'b',
													idx,
												)
											}
											modifyHairColor={
												tabIndex === 0 ? modifyHairColor : undefined
											}
											modifyCostumeColor={
												tabIndex === 1 ? modifyCostumeColor : undefined
											}
											modifyFaceColor={
												tabIndex === 2 ? modifyFaceColor : undefined
											}
										/>
									</AvatarCateTabPanelView>
								),
						)}
						<button className="btnBlack btnBig" type="submit">
							{t('save')}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CharacterContainer;
