import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useEditProfileRepo } from '@dating/repository/profile/useEditProfileRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useEffect, useState } from 'react';
import type { ProfileIntroCIVO, ProfileNicknameCIVO } from '@client/site/api';
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { SettingOnOffCIVO } from '@client/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useNavigate } from 'react-router-dom';

export const useManagerMyProfileService = () => {
	const [responseLogout, setResponseLogout] = useState<boolean>(false);
	const [nicknameValue, setNicknameValue] = useState<string>('');
	const [introduceValue, setIntroduceValue] = useState<string>('');
	const [lengthOver, setLengthOver] = useState<boolean>(false);
	const [isValueEntered, setIsValueEntered] = useState<boolean>(false);
	const [isAvailable, setIsAvailable] = useState<boolean>(true);
	const [nickError, setNickError] = useState<boolean>(true);

	const { authToken, initAuthToken, initDeviceInfo } = useAuthRepo();

	const { userSettings, setUserSettings } = useSettingRepo();

	const { focusItem, setFocusItem, editPop, setEditPop, clearEditPop } =
		useEditProfileRepo();

	const {
		logout,
		getUserSetting,
		saveSetChatMessage,
		saveSetConnectedManager,
	} = useSettingAdapter();

	const { srcPrefix, myProfile, setMyProfile } = useDatingRepo();

	const { getMyProfile, modifyIntro, statusModifyIntro, dataModifyIntro } =
		useProfileAdapter();

	const { saveNickname, statusSaveNickname, dataSaveNickname } =
		useRegisterAdapter();
	///////////////////////////////////////////////////

	useEffect(() => {
		if (editPop.nickName) {
			setNicknameValue(myProfile?.nickName);
		}

		if (editPop.introduce) {
			setIntroduceValue(myProfile?.introduce);
		}
	}, [editPop]);

	// 닉네임 수정
	useEffect(() => {
		if (statusSaveNickname === 'success' && dataSaveNickname) {
			if (dataSaveNickname.result) {
				setIsAvailable(true);
				getMyProfile(myProfile.phoneNumber).then((value) => {
					if (value) {
						setMyProfile(value);
					}
				});
			} else {
				setIsAvailable(false);
			}
		}
	}, [statusSaveNickname, dataSaveNickname]);

	// 자기소개 수정
	useEffect(() => {
		if (statusModifyIntro === 'success' && dataModifyIntro) {
			getMyProfile(myProfile.phoneNumber).then((value) => {
				if (value) {
					setMyProfile(value);
				}
			});
		}
	}, [statusModifyIntro, dataModifyIntro]);

	/*
	 * fnManagerLogout
	 * 로그아웃
	 */
	const fnManagerLogout = () => {
		if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
			logout(authToken.accessJmt, authToken.refreshJmt)
				.then((value) => {
					if (value) {
						setResponseLogout(true);
					}
				})
				.catch((reason) => {
					console.log(reason.body);
				});
		}
	};

	/*
	 * initManagerData
	 * 토큰 및 기기정보 초기화
	 */
	const initManagerData = () => {
		initAuthToken();
		initDeviceInfo();
	};

	const navigate = useNavigate();
	useEffect(() => {
		getMyProfile(myProfile.phoneNumber).then((value) => {
			if (value.role === 'MANAGER' && value.profileUrl.length > 0) {
				setMyProfile(value);
			} else {
				// profileUrl.length === 0 인 경우 에러처리
				// 토큰초기화 및 메인으로 이동
				// initAuthToken();
				navigate(PATH.REGISTER.REGISTER);
				return;
			}
		});

		getUserSetting().then((value) => {
			if (value) {
				setUserSettings(value);
			}
		});
	}, []);

	/*
	 * openPopupHandler
	 * 항목별 팝업 호출
	 */
	const openPopupHandler = (key: string, value: string) => {
		// popup handle
		clearEditPop();
		const newItem = {
			...editPop,
			[key]: true,
		};
		setEditPop(newItem);

		//content handle
		setFocusItem({
			label: key,
			value: value,
		});

		if (key === 'nickName') {
			setIsAvailable(true);
			setLengthOver(false);
		}
	};

	/*
	 * fnModifyProfileNickname
	 * 닉네임 수정
	 */
	const fnModifyProfileNickname = () => {
		if (nicknameValue != '') {
			const map: ProfileNicknameCIVO = {
				nickname: nicknameValue,
			};
			saveNickname(map);
			reactEventEmitter.emit('react-my-player-change-name', nicknameValue);

			// 팝업 닫기
			closeEditPopup('nickName');
		}
	};

	/*
	 * closeEditPopup
	 * 팝업 닫기
	 */
	const closeEditPopup = (key: string) => {
		const newItem = {
			...editPop,
			[key]: false,
		};
		setEditPop(newItem);
	};

	/*
	 * fnModifyProfileIntroduce
	 * 자기소개 수정
	 */
	const fnModifyProfileIntroduce = () => {
		if (introduceValue != undefined) {
			const map: ProfileIntroCIVO = {
				intro: introduceValue,
			};
			modifyIntro(map);
			reactEventEmitter.emit(
				'react-my-player-change-status-message',
				introduceValue,
			);

			// 팝업 닫기
			closeEditPopup('introduce');
		}
	};

	/*
	 * handleInputChange
	 * 입력값이 변경될 때 호출되는 핸들러
	 */
	const handleInputChange = (newValue: string) => {
		setNicknameValue(newValue);

		// 닉네임 길이 2~8자 제한
		if (newValue.length < 2 || newValue.length > 8) {
			setLengthOver(true);
		} else {
			setLengthOver(false);
		}

		if (!isAvailable) {
			setIsAvailable(true);
		}
	};

	const settingsSaveHandler = (type: string, state: boolean) => {
		const map: SettingOnOffCIVO = {
			onOff: state,
		};

		switch (type) {
			case 'ConnectedManager':
				saveSetConnectedManager(map);
				break;
			case 'chatMessage':
				saveSetChatMessage(map);
				break;
			default:
				break;
		}
	};

	return {
		srcPrefix,
		myProfile,
		fnManagerLogout,
		responseLogout,
		userSettings,
		authToken,
		initManagerData,
		openPopupHandler,
		editPop,
		setEditPop,
		focusItem,
		nicknameValue,
		setNicknameValue,
		introduceValue,
		setIntroduceValue,
		fnModifyProfileNickname,
		closeEditPopup,
		fnModifyProfileIntroduce,
		handleInputChange,
		isValueEntered,
		lengthOver,
		isAvailable,
		nickError,
		settingsSaveHandler,
	};
};
