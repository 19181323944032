import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import {LangCodeType} from "@dating/constants/LangCodeType";

type AuthState = {
	deviceId: string;
	setDeviceId: (deviceId: string) => void;

	fcmToken: string;
	setFcmToken: (fcmToken: string) => void;

	initDeviceInfo: () => void;

	osType: string;
	setOsType: (osType: string) => void;

	langCode: LangCodeType | 'en' | 'vi' | 'ko';
	setLangCode: (langCode: LangCodeType | 'en' | 'vi' | 'ko') => void;

	authToken: { accessJmt: string; refreshJmt: string };
	// accessToken, refreshToken 같이 저장
	setAuthToken: (authToken: { accessJmt: string; refreshJmt: string }) => void;
	// accessToken만 저장
	setAccessToken: (accessToken: string) => void;
	// refreshToken만 저장
	setRefreshToken: (refreshToken: string) => void;

	// accessToken, refreshToken 둘 다 빈값으로 초기화
	initAuthToken: () => void;

	// 휴면 시, 점유인증 성공했을때 저장. 휴면해제 처리 성공시 AuthToken에 담는다.
	tempToken: { accessJmt: string; refreshJmt: string };
	setTempToken: (tempToken: { accessJmt: string; refreshJmt: string }) => void;

	// 0: 자동로그인 조건 검사 중, 1: 자동로그인 대상, 2: 일반로그인
	loginState: 0|1|2;
	setLoginState: (loginState: 0|1|2) => void;
};

export const useAuthRepo = create(
	persist<AuthState>(
		(set) => ({
			deviceId: '',
			setDeviceId: (deviceId: string) => set({ deviceId }),

			fcmToken: '',
			setFcmToken: (fcmToken: string) => set({ fcmToken }),

			initDeviceInfo: () => set(() => ({ deviceId: '', fcmToken: '' })),

			osType: '',
			setOsType: (osType: string) => set({ osType }),

			langCode: import.meta.env.defaultLangCode,
			setLangCode: (langCode: LangCodeType | 'en' | 'vi' | 'ko') => set({ langCode }),

			authToken: { accessJmt: '', refreshJmt: '' },
			setAuthToken: (authToken: { accessJmt: string; refreshJmt: string }) =>
				set({ authToken: authToken }),
			setAccessToken: (accessToken: string) =>
				set((state) => ({
					authToken: {
						accessJmt: accessToken,
						refreshJmt: state.authToken.refreshJmt,
					},
				})),
			setRefreshToken: (refreshToken: string) =>
				set((state) => ({
					authToken: {
						accessJmt: state.authToken.accessJmt,
						refreshJmt: refreshToken,
					},
				})),
			initAuthToken: () =>
				set({ authToken: { accessJmt: '', refreshJmt: '' } }),

			// 휴면 시, 점유인증 성공했을때 저장. 휴면해제 처리 성공시 AuthToken에 담는다.
			tempToken: { accessJmt: '', refreshJmt: '' },
			setTempToken: (tempToken: { accessJmt: string; refreshJmt: string }) =>
				set({ tempToken: tempToken }),

			loginState: 0,
			setLoginState: (loginState: 0|1|2) => set({loginState:loginState}),
		}),
		{
			name: 'authInfo',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);
