import AppFooterWrapContainer from './ui/components/footer/AppFooterWrapContainer';
import { Outlet, useLocation } from 'react-router-dom';
import CommonPops from './ui/CommonPops';
import { PhaserApp } from './ui/PhaserApp';

const MetaverseLayout = () => {
	const location = useLocation();
	const shouldShowMetaverse =
		location.pathname === '/metaverse' &&
		!location.pathname.includes('/metaverse/dating/register');

	return (
		<>
			{/* 메타버스 게임 레이어 */}
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 0,
				}}
			>
				<PhaserApp />
			</div>

			{/* 라우트 컨텐츠 레이어 */}
			<div
				style={{
					position: 'relative',
					minHeight: '100vh',
					background: shouldShowMetaverse ? 'transparent' : '#ffffff',
					zIndex: 1,
					transition: 'background 0.3s',
					pointerEvents: shouldShowMetaverse ? 'none' : 'auto',
				}}
			>
				<div style={{ pointerEvents: 'auto' }}>
					<Outlet />
					<AppFooterWrapContainer />
				</div>
			</div>

			{/* 공통 팝업 레이어 - 항상 최상단에 위치 */}
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 9999,
					pointerEvents: 'none',
				}}
			>
				<div style={{ pointerEvents: 'auto' }}>
					<CommonPops />
				</div>
			</div>
		</>
	);
};

export default MetaverseLayout;
