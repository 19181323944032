import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useEffect } from 'react';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useOrderUtils } from '@client/utils/orderUtils';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useMetaverse } from '@virtual-space/hooks/useMetaverse';
import Network from '@virtual-space/services/Network';

export const useWaitingService = () => {
	const {
		step,
		userWaiting,
		setUserWaiting,
		phoneNumber,
		approvedAccount,
		setApproveAccount,
	} = useRegisterRepo();

	const { srcPrefix, sliderSettings, myProfile, setMyProfile } =
		useDatingRepo();

	const { myMetaUser, setMyMetaUser } = useMetaUserRepo();

	const { authToken, langCode, setAuthToken, tempToken } = useAuthRepo();

	const { getUserWaiting, startSignUp } = useRegisterAdapter();

	const { getMyProfile } = useProfileAdapter();

	const { saveMetaUser, getMyMetaUser } = useMetaUserAdapter();

	const { setProductItems } = useOrderUtils();

	const { initializeGame } = useMetaverse();
	/////////////////////////////////////////////////

	const navigate = useNavigate();

	useEffect(() => {
		// ios test 계정용 프리패스
		if (JSON.parse(import.meta.env.IOS_TESTER_ID).includes(phoneNumber)) {
			setApproveAccount(true);
		}
	}, []);

	useEffect(() => {
		getUserWaiting().then((response) => {
			if (response.profileUrl.length > 0) {
				setUserWaiting(response);
			} else {
				// initAuthToken();
				navigate(PATH.REGISTER.REGISTER);
			}

			// setApproveAccount(response.signUpStatus);
		});
	}, []);

	const isEmptyUser = (user) => {
		return (
			!user ||
			Object.values(user).every((x) => x === null || x === '' || x === false)
		);
	};

	/*
	 * clickStartBtn
	 * 시작하기 버튼 클릭 시
	 * myMetaUserOpen true
	 * handleCreateAvatar 호출
	 */
	const clickStartBtn = async () => {
		try {
			/**
			 * 디바이스 자동 로그인 작업
			 * 시작버튼 미 클릭 회원도 점유인증시 setAuthToken, 후 회원상태로 페이지 분기
			setAuthToken({
				accessJmt: tempToken.accessJmt,
				refreshJmt: tempToken.refreshJmt,
			});
			 */

			const result = await startSignUp();
			if (!result) {
				navigate(PATH.REGISTER.REGISTER);
				return;
			}

			// 프로필 정보 가져오기
			const profileData = await getMyProfile(myProfile.phoneNumber);
			if (!profileData?.profileUrl?.length) {
				navigate(PATH.REGISTER.REGISTER);
				return;
			}

			// 프로필 정보 설정
			setMyProfile(profileData);
			if (Network.instance) {
				await Network.instance.initializeLobby();

				// DM 이벤트 발생
				dmEventEmitter.emit('react-join-or-create-dm-room', {
					userUid: profileData.userUid,
					accessToken: authToken.accessJmt,
					langCode: langCode,
					nickName: profileData.nickName,
					profileUrl: profileData.profileUrl[0],
					age: profileData.age,
					region1: profileData.region1,
					region2: profileData.region2,
					role: profileData.role,
					inActive: false,
					isDeleted: false,
					blockType: '',
				});

				// DM 룸 정보 가져오기
				dmEventEmitter.emit('react-get-dm-rooms');
			}

			// 메타유저 처리
			if (isEmptyUser(myMetaUser)) {
				try {
					// 성별에 따른 기본 아바타 설정
					const metaUserParam = {
						userUid: profileData.userUid,
						nickName: profileData.nickName,
						introduce: profileData.introduce,
						gender: profileData.gender,
						profileUrl: profileData.profileUrl[0],
						avatarName:
							profileData.gender === '남성'
								? 'b1c1f1h1cc1fc1hc1'
								: 'b1c7f1h7cc1fc1hc1',
						readyToConnect: false,
						mediaConnected: false,
						role: profileData.role,
					};

					// 메타유저 저장 API 호출
					await saveMetaUser(metaUserParam);

					// 저장 후 잠시 대기
					await new Promise((resolve) => setTimeout(resolve, 1000));

					// 저장된 메타유저 정보 가져오기
					const metaUserData = await getMyMetaUser();

					if (metaUserData && typeof metaUserData === 'object') {
						setMyMetaUser(metaUserData);
					} else {
						setMyMetaUser(metaUserParam); // 저장했던 파라미터를 기본값으로 사용
					}
				} catch (error) {
					// 에러 발생시에도 UI에는 기본값 설정
					setMyMetaUser({
						userUid: profileData.userUid,
						nickName: profileData.nickName,
						introduce: profileData.introduce,
						gender: profileData.gender,
						profileUrl: profileData.profileUrl[0],
						avatarName:
							profileData.gender === '남성'
								? 'b1c1f1h1cc1fc1hc1'
								: 'b1c7f1h7cc1fc1hc1',
						readyToConnect: false,
						mediaConnected: false,
						role: profileData.role,
					});
				}
			}

			// 역할에 따른 페이지 이동
			if (profileData.role === 'NORMAL') {
				navigate(PATH.DATING.HOME);
			} else if (profileData.role === 'MANAGER') {
				navigate(PATH.MANAGER.HOME);
			}

			initializeGame();
			setProductItems();
		} catch (error) {
			console.error('회원가입 프로세스 중 오류:', error);
			navigate(PATH.REGISTER.REGISTER);
		}
	};
	return {
		approvedAccount,
		userWaiting,
		srcPrefix,
		clickStartBtn,
		sliderSettings,
		phoneNumber,
	};
};
