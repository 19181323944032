import FeedSearchGridContainer from '@dating/ui/pages/lounge/feedSearch/FeedSearchGridContainer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FeedFormContainer } from '@dating/ui/components/feedFrom/FeedFormContainer';
import { SingleFeedContainer } from '@dating/ui/pages/lounge/singleFeed/SingleFeedContainer';
import { lazy, Suspense } from 'react';
import Spinner from '@dating/ui/components/spinner/Spinner';
import { LoungeLayout } from '@dating/ui/components/layout/LoungeLayout';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';

const FeedListContainer = lazy(
	() => import('@dating/ui/pages/lounge/feedList/FeedListContainer'),
);
const MyFeedListContainer = lazy(
	() => import('@dating/ui/pages/lounge/myFeed/MyFeedListContainer'),
);

const LoungeRouter = () => {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Suspense fallback={<Spinner />}>
				<Routes location={location}>
					<Route element={<LoungeLayout />}>
						<Route path="/list" element={<FeedListContainer />} />
						<Route path="/search" element={<FeedSearchGridContainer />} />
						<Route path="/searchList" element={<FeedListContainer />} />
					</Route>
				</Routes>

				<Routes location={location}>
					<Route path="/myFeedList" element={<MyFeedListContainer />} />
					<Route path="/form" element={<FeedFormContainer />} />
					<Route path="/singleFeed" element={<SingleFeedContainer />} />
				</Routes>
			</Suspense>
		</ErrorBoundary>
	);
};

export default LoungeRouter;
