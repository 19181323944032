import { formatTime } from '@virtual-space/utils/messageUtils';
import {
	MessageDetailViewProps,
	MessagesByDate,
	MessageGroup,
} from '../types/navigationTypes';
export const useMessageOrganizer = () => {
	// 메시지를 날짜별, 발신자별로 그룹화하는 함수
	const organizeMessages = (
		groupedMessagesDetail: MessageDetailViewProps['groupedMessagesDetail'],
	): MessagesByDate => {
		// 1. 모든 메시지를 하나의 배열로 합치고 정렬 (메시지 ID 기준)
		const sortedMessages = [
			...groupedMessagesDetail.my.flatMap((group) => group.messages),
			...groupedMessagesDetail.other.flatMap((group) => group.messages),
		].sort((a, b) => {
			// 메시지 ID로 정렬 (적은 ID가 먼저 옴)
			return Number(a.messageId) - Number(b.messageId);
		});

		// 2. 날짜별로 먼저 그룹화
		const messagesByDate: MessagesByDate = {};
		sortedMessages.forEach((message) => {
			let timestamp;
			if (
				typeof message.createdAt === 'string' &&
				message.createdAt.includes('T')
			) {
				// ISO 형식 문자열인 경우
				const utcDate = new Date(message.createdAt);
				timestamp = utcDate.getTime();
			} else {
				// 타임스탬프 문자열인 경우
				timestamp = Number(message.createdAt);
			}

			// UTC 기준으로 날짜 추출
			const utcDate = new Date(timestamp);
			const utcYear = utcDate.getUTCFullYear();
			const utcMonth = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
			const utcDay = String(utcDate.getUTCDate()).padStart(2, '0');
			const dateKey = `${utcYear}.${utcMonth}.${utcDay}`;

			if (!messagesByDate[dateKey]) {
				messagesByDate[dateKey] = {
					date: dateKey,
					messages: [],
					groups: [],
				};
			}
			messagesByDate[dateKey].messages.push(message);
		});

		// 3. 각 날짜별로 발신자 변경 시 새로운 그룹 생성
		Object.keys(messagesByDate).forEach((dateKey) => {
			const dateMessages = messagesByDate[dateKey].messages;
			let currentGroup: MessageGroup = {
				sender: null,
				messages: [],
				time: null,
			};

			let prevSender: string | null = null;

			dateMessages.forEach((message, index) => {
				const messageTime = formatTime(message.createdAt);
				const currentSender = message.sender?.userUid ?? null;

				// 발신자가 변경되거나 첫 메시지인 경우 새 그룹 시작
				if (currentSender !== prevSender) {
					if (currentGroup.messages.length > 0) {
						messagesByDate[dateKey].groups.push({ ...currentGroup });
					}
					currentGroup = {
						sender: currentSender,
						messages: [message],
						time: messageTime,
					};
				}
				// 같은 발신자이면 현재 그룹에 추가
				else {
					currentGroup.messages.push(message);
				}

				prevSender = currentSender;

				// 마지막 메시지 처리
				if (
					index === dateMessages.length - 1 &&
					currentGroup.messages.length > 0
				) {
					messagesByDate[dateKey].groups.push({ ...currentGroup });
				}
			});
		});

		return messagesByDate;
	};

	return { organizeMessages };
};
