/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockPhoneCIVO } from '../models/BlockPhoneCIVO';
import type { BlockPostCIVO } from '../models/BlockPostCIVO';
import type { BlockUserCIVO } from '../models/BlockUserCIVO';
import type { BlockUserCOVO } from '../models/BlockUserCOVO';
import type { deleteCIVO } from '../models/deleteCIVO';
import type { InActiveCIVO } from '../models/InActiveCIVO';
import type { JobListCOVO } from '../models/JobListCOVO';
import type { ReportCIVO } from '../models/ReportCIVO';
import type { ReportUiCOVO } from '../models/ReportUiCOVO';
import type { SettingOnOffCIVO } from '../models/SettingOnOffCIVO';
import type { SettingPreferCOVO } from '../models/SettingPreferCOVO';
import type { SettingStylesCOVO } from '../models/SettingStylesCOVO';
import type { SettingTermsCOVO } from '../models/SettingTermsCOVO';
import type { UnblockContactsCOVO } from '../models/UnblockContactsCOVO';
import type { UserSettingCOVO } from '../models/UserSettingCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SettingService {
    /**
     * 유저 세팅정보 조회
     * @returns UserSettingCOVO
     * @throws ApiError
     */
    public static getUserSetting(): CancelablePromise<UserSettingCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getUserSetting',
        });
    }
    /**
     * 차단 유저 조회
     * @param blockType block: 내가 차단한 사람 조회, blocked: 나를 차단한 사람 조회
     * @returns BlockUserCOVO
     * @throws ApiError
     */
    public static getBlockUser(
        blockType?: string,
    ): CancelablePromise<Array<BlockUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getBlockUser',
            query: {
                'blockType': blockType,
            },
        });
    }
    /**
     * 서로 누가 차단했는지 확인
     * @param findUserUid 서로 차단 여부 확인할 유저 Uid
     * @returns string
     * @throws ApiError
     */
    public static getWhoBlock(
        findUserUid: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getWhoBlock',
            query: {
                'findUserUid': findUserUid,
            },
        });
    }
    /**
     * 차단 유저 저장
     * @param requestBody 차단 유저 Uid
     * @returns boolean
     * @throws ApiError
     */
    public static saveBlockUser(
        requestBody: BlockUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 공개 설정
     * @param requestBody 프로필 공개 설정 On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetProfileOpen(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetProfileOpen',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 알람/소리 설정
     * @param requestBody 알람/소리 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetSound(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetSound',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 오늘의 제안 설정
     * @param requestBody 오늘의 제안 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetTodaySuggest(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetTodaySuggest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 좋아요 받음 설정
     * @param requestBody 좋아요 받음 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetReceivedLike(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetReceivedLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 받은 관심 설정
     * @param requestBody 받은 관심 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetReceivedHighScore(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetReceivedHighScore',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 연결됨 설정
     * @param requestBody 연결됨 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetConnectedLike(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetConnectedLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 보낸 좋아요 확인 설정
     * @param requestBody 보낸 좋아요 확인 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetSendLikeChk(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetSendLikeChk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 대화 메세지 설정 설정
     * @param requestBody 대화 메세지 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetChatMessage(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetChatMessage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 라운지 포스팅 알림 설정
     * @param requestBody 라운지 포스팅 알림 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetLounge(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetLounge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 라운지 댓글 알림 설정
     * @param requestBody 라운지 포스팅 댓글 알림 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetLoungeComment(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetLoungeComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 마케팅 설정
     * @param requestBody 마케팅 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetMarketing(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetMarketing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 매니저가 회원이 연결되었을 때 알림 설정
     * @param requestBody 매니저 회원 연결 알림 설정 On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetConnectedManager(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetConnectedManager',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 신고 하기
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveReport(
        requestBody: ReportCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveReport',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 신고하기 메뉴 조회
     * @param langCode 언어 코드 (en, vi, ko)
     * @returns ReportUiCOVO
     * @throws ApiError
     */
    public static getCommonCode(
        langCode: 'en' | 'vi' | 'ko',
    ): CancelablePromise<Array<ReportUiCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getReportList',
            query: {
                'langCode': langCode,
            },
        });
    }
    /**
     * 직업 선택 리스트 조회
     * @param langCode 언어 코드 (en, vi, ko)
     * @returns JobListCOVO
     * @throws ApiError
     */
    public static getJobList(
        langCode: 'en' | 'vi' | 'ko',
    ): CancelablePromise<Array<JobListCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getJobList',
            query: {
                'langCode': langCode,
            },
        });
    }
    /**
     * 약관 정보 조회
     * @returns SettingTermsCOVO
     * @throws ApiError
     */
    public static getTerms(): CancelablePromise<Array<SettingTermsCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getTerms',
        });
    }
    /**
     * 스타일 정보 조회
     * @param langCode 언어 코드 (en, vi, ko)
     * @returns SettingStylesCOVO
     * @throws ApiError
     */
    public static getStyles(
        langCode: 'en' | 'vi' | 'ko',
    ): CancelablePromise<SettingStylesCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getStyles',
            query: {
                'langCode': langCode,
            },
        });
    }
    /**
     * 이상형 정보 조회
     * @param langCode 언어 코드 (en, vi, ko)
     * @returns SettingPreferCOVO
     * @throws ApiError
     */
    public static getPrefer(
        langCode: 'en' | 'vi' | 'ko',
    ): CancelablePromise<SettingPreferCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getPrefer',
            query: {
                'langCode': langCode,
            },
        });
    }
    /**
     * 연락처 목록 차단하기
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static saveBlockPhoneNumberUser(
        requestBody: BlockPhoneCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockPhoneNumberUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 유저 차단 해제
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveUnblockUser(
        requestBody: BlockUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveUnblockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 연락처 목록 차단 해제
     * @returns UnblockContactsCOVO
     * @throws ApiError
     */
    public static saveUnblockPhoneNumberUser(): CancelablePromise<UnblockContactsCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveUnblockPhoneNumberUser',
        });
    }
    /**
     * 게시글 숨기기
     * @param requestBody 숨긴 게시글 id
     * @returns boolean
     * @throws ApiError
     */
    public static saveBlockPost(
        requestBody: BlockPostCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockPost',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 로그 아웃
     * @param accesstoken accesstoken
     * @param refreshtoken refreshtoken
     * @returns boolean
     * @throws ApiError
     */
    public static logout(
        accesstoken: string,
        refreshtoken: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/logout',
            headers: {
                'accesstoken': accesstoken,
                'refreshtoken': refreshtoken,
            },
        });
    }
    /**
     * 휴면 계정 상태 변경 처리
     * @param accesstoken accesstoken
     * @param refreshtoken refreshtoken
     * @param requestBody 휴면 계정 상태 변경 처리
     * @returns boolean
     * @throws ApiError
     */
    public static saveInActiveUser(
        accesstoken: string,
        refreshtoken: string,
        requestBody: InActiveCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveInActiveUser',
            headers: {
                'accesstoken': accesstoken,
                'refreshtoken': refreshtoken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 계정 삭제
     * @param accesstoken accesstoken
     * @param refreshtoken refreshtoken
     * @param requestBody 계정 삭제
     * @returns deleteCIVO
     * @throws ApiError
     */
    public static saveDeleteUser(
        accesstoken: string,
        refreshtoken: string,
        requestBody: deleteCIVO,
    ): CancelablePromise<deleteCIVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveDeleteUser',
            headers: {
                'accesstoken': accesstoken,
                'refreshtoken': refreshtoken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 차단 연락처 조회
     * @returns UnblockContactsCOVO
     * @throws ApiError
     */
    public static getBlockContacts(): CancelablePromise<UnblockContactsCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getBlockContacts',
        });
    }
}
