import { useEffect } from 'react';
import { type UserHeightCIVO } from '@client/site/api';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useHeightStateRepo } from '@dating/ui/pages/register/height/useHeightStateRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';

export const useHeightService = () => {
	const {
		activeIndex,
		setActiveIndex,
		btnActivate,
		setBtnActivate,
		response,
		setResponse,
	} = useHeightStateRepo();

	const { setStep, registerSteps, inputHeight, setInputHeight } =
		useRegisterRepo();

	const { saveHeight, statusSaveHeight, dataSaveHeight } = useRegisterAdapter();
	///////////////////////////////////

	const fnSaveHeight = () => {
		let height: string = "1" + inputHeight.slice(1).join(''); //첫 번째 값("1") 고정
		const map: UserHeightCIVO = {
			height: Number(height),
		};
		saveHeight(map);
	};

	const handleInputChange = (index: number, value: string) => {
		// if (/^\d$/.test(value)) {
		if (index === 0) return; // 첫 번째 값("1")은 수정 불가능하도록
		const newInputValues = [...inputHeight];
		if (value.length > 1) {
			newInputValues[index] = value.slice(-1);
		} else {
			newInputValues[index] = value;
		}

		setInputHeight(newInputValues);

		if (index < inputHeight.length - 1 && value !== '') {
			setActiveIndex(index + 1);
		}
		// }
	};

	const handleInputKeyDown = (index: number, key: string) => {
		if (index === 0) return; // 첫 번째 자리("1")는 삭제할 수 없도록
		if (key === 'Backspace' || key === 'Delete') {
			const newInputValues = [...inputHeight];
			newInputValues[index] = '';
			setInputHeight(newInputValues);
			setActiveIndex(index - 1);
		} else if (key === 'ArrowLeft') {
			setActiveIndex(index - 1);
		} else if (key === 'ArrowRight') {
			setActiveIndex(index + 1);
		}
	};

	useEffect(() => {
		if (statusSaveHeight === 'success' && dataSaveHeight) {
			if (dataSaveHeight.result) {
				setStep(5);
				setResponse(true);
			} else {
				alert(dataSaveHeight.resultCode);
			}
		} else if (statusSaveHeight === 'error') {
		}
	}, [statusSaveHeight, dataSaveHeight]);

	useEffect(() => {
		if (inputHeight.indexOf('') == -1) {
			setBtnActivate(false);
		} else {
			setBtnActivate(true);
		}
	}, [inputHeight]);

	useEffect(() => {
		setInputHeight(['1', '', '']);
		setActiveIndex(0);
	}, []);

	return {
		inputHeight,
		setInputHeight,
		fnSaveHeight,
		handleInputChange,
		handleInputKeyDown,
		btnActivate,
		setBtnActivate,
		activeIndex,
		response,
		registerSteps,
	};
};
