import { AuthService, type GoogleLoginCIVO } from '@client/site/api';
import { AutoLoginCIVO } from '@client/site/api';

export const useAuthAdpater = () => {
	/*
	 * oauthLoginInfos
	 * SNS 로그인
	 */
	const oauthLoginInfos = (provider: string) => {
		return AuthService.oauthLoginInfos(provider);
	};

	/*
	 * autoLogin
	 * 자동로그인
	 */
	const autoLogin = (
		deviceId?: string,
		fcmToken?: string,
		langCode?: string,
	) => {
		return AuthService.autoLogin(deviceId, fcmToken, langCode);
	};

	/*
	 * deviceAutoLogin
	 * 디바이스 자동 로그인
	 */
	const deviceAutoLogin = (
		deviceId: string,
		fcmToken: string,
		langCode: string,
	) => {
		const autoLoginInfo: AutoLoginCIVO = {
			deviceId,
			fcmToken,
			langCode,
		};
		return AuthService.deviceAutoLogin(autoLoginInfo);
	};

	/*
	 * googleLogin
	 * 구글 로그인 정보 저장
	 */
	const googleLogin = (map: GoogleLoginCIVO) => {
		return AuthService.googleLogin(map);
	};

	return {
		oauthLoginInfos,
		autoLogin,
		deviceAutoLogin,
		googleLogin,
	};
};
