import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import {
	generateCharacterImages,
	getCharacter,
} from '@virtual-space/utils/avatarUtils';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

const MetaProfileView = () => {
	const { t } = useTranslation('metaverse');
	const { myMetaUser } = useMetaUserRepo();

	const {
		costumeColor,
		faceColor,
		hairColor,
		customizeAvatarOpen,
		setCostumeColor,
		setFaceColor,
		setHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		setModifyHairColor,
		setCustomizeAvatarOpen,
	} = useMetaUserRepo();

	useEffect(() => {
		const { colors } = getCharacter(myMetaUser.avatarName);
		if (customizeAvatarOpen) {
			setModifyCostumeColor(colors.costumeColor ?? '');
			setModifyFaceColor(colors.faceColor ?? '');
			setModifyHairColor(colors.hairColor ?? '');
		} else {
			setCostumeColor(colors.costumeColor ?? '');
			setFaceColor(colors.faceColor ?? '');
			setHairColor(colors.hairColor ?? '');
		}
	}, [myMetaUser.avatarName]);

	const characterImages = useMemo(() => {
		return generateCharacterImages(
			myMetaUser.avatarName,
			costumeColor,
			faceColor,
			hairColor,
		);
	}, [myMetaUser.avatarName, costumeColor, faceColor, hairColor]);

	return (
		<div className="metaProfile">
			<div className="metaProImg">
				<div className="avatar_character avatar_front">
					{characterImages.map((img, index) => (
						<img
							key={index}
							className="avatar_profile_object"
							style={{
								background: `url('${img}')`,
							}}
						/>
					))}
				</div>
			</div>
			<div className="metaProTxt">
				<p className="metaProGender">
					{myMetaUser.gender === 'M' ? (
						<span className="men">{t('male')}</span>
					) : (
						<span className="women">{t('female')}</span>
					)}
				</p>
				<p className="nickname padding-b4">{myMetaUser.nickName}</p>
				{myMetaUser.introduce === null ? (
					<p className="introTxt">{t('noStatusMsg')}</p>
				) : (
					<p className="introTxt">{myMetaUser.introduce}</p>
				)}
			</div>
			<div className="metaProBtn">
				<button
					className="fffGrayBdBtn"
					onClick={() => {
						setCustomizeAvatarOpen(true);
					}}
				>
					<Link to={PATH.SIDE_BAR.AVATAR}>{t('edit')}</Link>
				</button>
			</div>
		</div>
	);
};

export default MetaProfileView;
