import { formatTime } from '@virtual-space/utils/messageUtils';

/**
 * 시간 표시 컴포넌트
 * 메시지 전송 시간을 포맷팅하여 표시합니다.
 */
const MessageTimeView = ({ time }) => {
	return (
		<div className="chatTime">
			<span>{formatTime(time)}</span>
		</div>
	);
};

export default MessageTimeView;
