import { useAuthRepo } from '@client/site/dating/repository/auth/useAuthRepo';
import ItemAbst, { ItemConfig } from '../ItemAbst';
import {
	ItemType,
	CHAIR_MESSAGES_DESKTOP,
	CHAIR_MESSAGES,
} from '@virtual-space/types/Item';
export default class Chair extends ItemAbst {
	chairId?: string;
	currentUsers = new Set<string>();
	itemDirection?: string;

	constructor(param: ItemConfig) {
		super(param);
	}

	override readonly itemType = ItemType.CHAIR;

	private updateState() {
		if (!this.currentUsers) return;
		this.clearStatusBox();
	}

	addCurrentUser(userId: string) {
		if (!this.currentUsers || this.currentUsers.has(userId)) return;
		this.currentUsers.add(userId);
		this.updateState();
	}

	removeCurrentUser(userId: string) {
		if (!this.currentUsers || !this.currentUsers.has(userId)) return;
		this.currentUsers.delete(userId);
		this.updateState();
	}

	override onOverlapDialog() {
		const starImage = this.scene.add.image(0, 0, 'star').setScale(0.5);
		const langCode =
			useAuthRepo.getState().langCode ?? import.meta.env.defaultLangCode;

		const message =
			window.innerWidth >= 1024
				? CHAIR_MESSAGES_DESKTOP[langCode]
				: langCode === 'en'
				? ['Press', starImage, 'to sit']
				: [starImage, CHAIR_MESSAGES[langCode]];

		this.setDialogBox(message);
	}
}
