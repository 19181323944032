import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import useLocationInfoService from "@dating/ui/pages/register/locationInfo/service/useLocationInfoService";
import {useEffect} from "react";
import type {UserRecommenderCIVO, UserStyleCIVO} from "@client/site/api";
import {useTagInviteCodeStateRepo} from "@dating/ui/pages/register/tagInviteCode/useTagInviteCodeStateRepo";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useAuthRepo} from "@dating/repository/auth/useAuthRepo";
import {getStringArrByRecord} from "@client/utils/utils";

export const useTagInviteCodeSerivce = () => {
    const {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        lifeStyleList,
        setLifeStyleList,
        interestList,
        setInterestList,
        initDrinkingState,
        setInitDrinkingState,
        initSmokingState,
        setInitSmokingState,
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        btnActivate,
        setBtnActivate,
        skipBtnActivate,
        setSkipBtnActivate,
        codeErrorMessage,
        setCodeErrorMessage,
    } = useTagInviteCodeStateRepo();

    const {
        step,
    } = useRegisterRepo();

    const {langCode} = useAuthRepo();

    const {
        skipRecommender,
        activateProfile,
        saveUserStyle,
        statusSaveUserStyle,
        dataSaveUserStyle,
        saveRecommender,
    } = useRegisterAdapter();

    const {getStyles} = useSettingAdapter();

    const {fnFormatLocation} = useLocationInfoService();
    ///////////////////////////////////////

    /*
     * skipHandler
     * 건너뛰기 클릭 시 버튼 hide, api 요청
     */
    const skipHandler = async () => {
        const res = await skipRecommender();
        if (res.result) {
            fnCompleteRegistration();
        }
    };

    /*
     * 회원가입 완료 처리
     * fnCompleteRegistration
     */
    const fnCompleteRegistration = () => {
        // 위치정보 가져오기
        fnFormatLocation(true);
    };

    /*
     * 추천인 코드 저장
     * fnSaveRecommender
     */
    const fnSaveRecommender = async () => {
        if (recommendCode.length === 0) {
            const res = await skipRecommender()
            if (res.result) {
                return true;
            }
        }
        const map: UserRecommenderCIVO = {
            recommenderCode: recommendCode,
        };
        const res = await saveRecommender(map)
        if (!res.result) {
            setCodeFailed(true);
            setCodeErrorMessage(res.resultCode);
            return false;
        } else {
            setCodeFailed(false);
            return true;
        }
    };

    /*
     * 태그 및 추천인 코드 저장
     * fnSaveTagInviteCode
     */
    const fnSaveTagInviteCode = async () => {
        // 태그 저장
        await fnSaveUserStyle();

        // 추천인 코드 저장
        const res = await fnSaveRecommender();
        if (res) {
            fnCompleteRegistration();
        }
    };

    useEffect(() => {
        getStyles(langCode)
            .then((response) => {
                if (response.interest.length > 0) {
                    setInterestList(response.interest);
                }
                if (response.lifeStyle.length > 0) {
                    setLifeStyleList(response.lifeStyle);
                }
                if (response.drink.length > 0) {
                    setInitDrinkingState(response.drink);
                }
                if (response.smoke.length > 0) {
                    setInitSmokingState(response.smoke);
                }
            })
            .catch((reason) => {
            });
    }, []);

    /*
     * fnSaveUserStyle
     * 나의 라이프스타일, 관심사 저장
     */
    const fnSaveUserStyle = () => {
        // 공통 라이프 스타일
        const commonLifeStyleCodes: string[] = getStringArrByRecord(lifeStyleValue);

        // 공통 관심사
        const commonInterestCodes: string[] = getStringArrByRecord(interestValue);

        const map: UserStyleCIVO = {
            commonLifeStyleId: commonLifeStyleCodes,
            commonInterestId: commonInterestCodes,
            smokeId: smokingValue,
            drinkId: drinkingValue,
            religionId: '',
            extraLifeStyleValue: [],
            extraInterestValue: [],
        };

        saveUserStyle(map);
    };

    const interestHandler = (id: string) => {
        const newInterest = {...interestValue};
        if (interestValue[id]) {
            newInterest[id] = !newInterest[id];
        } else {
            newInterest[id] = true;
        }
        setInterestValue(newInterest);
    };

    const lifeStyleHandler = (id: string) => {
        const newLifeStyle = {...lifeStyleValue};
        if (lifeStyleValue[id]) {
            newLifeStyle[id] = !newLifeStyle[id];
        } else {
            newLifeStyle[id] = true;
        }
        setLifeStyleValue(newLifeStyle);
    };

    useEffect(() => {
       const selectedLifeStyle = getStringArrByRecord(lifeStyleValue);
       const selectedInterest = getStringArrByRecord(interestValue);

        if (selectedLifeStyle.length == 0 && selectedInterest.length == 0 && smokingValue == '' && drinkingValue == '' && recommendCode.length === 0) {
            setBtnActivate(true);
            setSkipBtnActivate(false);
        } else {
            setBtnActivate(false);
            setSkipBtnActivate(true);
        }
    }, [interestValue, lifeStyleValue, smokingValue, drinkingValue, recommendCode]);

    return {
        step,
        skipHandler,
        interestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setDrinkingValue,
        lifeStyleValue,
        interestValue,
        setRecommendCode,
        codeFailed,
        fnSaveTagInviteCode,
        btnActivate,
        skipBtnActivate,
        codeErrorMessage,
    }
};