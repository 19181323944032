import { useState } from 'react';

export const useMyProfileStateRepo = () => {
	/***** useState *****/
	const [scrollOn, setScrollOn] = useState(true);

	// StepLastBoxView content
	const [profileDetails, setProfileDetails] = useState<
		{ key: string; title: string; value: string | number }[]
	>([]);

	const [fadeIn, setFadeIn] = useState<boolean>(false);

	// fnInitFeedEditHandler이 실행되었는지 여부
	const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{
		postId: string;
		state: boolean;
	}>({ postId: '', state: false });

	return {
		isClickedFeedMenu,
		setIsClickedFeedMenu,
		fadeIn,
		setFadeIn,
		profileDetails,
		setProfileDetails,
		scrollOn,
		setScrollOn,
	};
};
