import { useEffect, useState } from 'react';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { UserProfileCOVO, type UserScoreCIVO } from '@client/site/api';
import { useLocation } from 'react-router-dom';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useUserProfileRepo } from '@dating/repository/useUserProfileRepo';
import { useUserProfileStateRepo } from '@dating/ui/pages/userProfile/useUserProfileStateRepo';
import { UserAuthBadgeCOVO } from '@client/site/api';
import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useHeaderRepo } from '@dating/repository/dating/useHeaderRepo';
import { useTranslation } from 'react-i18next';

export const useUserProfileService = () => {
	const location = useLocation();
	const isOtherProfile = location.state?.isOtherProfile ?? false;

	const {
		setFadeIn,
		setScrollOn,
		badgeData,
		setBadgeData,
		profileDetails,
		setProfileDetails,
		isChecked,
		setIsChecked,
	} = useUserProfileStateRepo();

	const { savedLikeProfile, setSavedLikeProfile } = useUserProfileRepo();

	const { setSettingOpen } = useHeaderRepo();

	const {
		srcPrefix,
		myProfile,
		myProfileOpen,
		setMyProfileOpen,
		sliderSettings,
		userProfile,
		setUserProfile,
		userProfileOpen,
		setUserProfileOpen,
	} = useDatingRepo();

	const { clearPreviewURLs } = useImgCropRepo();

	const { getUserProfile, getUsersBadge, saveScoreUser } = useProfileAdapter();

	const { t } = useTranslation('register');
	/*
	 * initUserProfile
	 * 상대프로필 조회 후 data binding
	 */
	function initUserProfile(profile: UserProfileCOVO) {
		setUserProfile(profile);
		initProfileDetail(profile);
		setIsChecked(profile.latestScore);
	}

	/*
	 * initProfileDetail
	 * StepLastBoxView content list set
	 */
	function initProfileDetail(profile: UserProfileCOVO) {
		const lifeStyleValues = profile.lifeStyle.concat(profile.extraLifeStyle);
		const interestValues = profile.interest.concat(profile.extraInterest);

		const list = [
			{
				key: 'job',
				title: t('idealDetail.job'),
				value: profile.job ? profile.job : '',
			},
			{
				key: 'height',
				title: t('idealDetail.height'),
				value: profile.height ? profile.height : '',
			},
			{
				key: 'life',
				title: t('idealDetail.life'),
				value: lifeStyleValues.join(', '),
			},
			{
				key: 'like',
				title: t('idealDetail.like'),
				value: interestValues.join(', '),
			},
			// {
			// 	key: 'smoking',
			// 	title: '흡연여부',
			// 	value: profile.smoking ? profile.smoking : '',
			// },
			{
				key: 'pen',
				title: t('idealDetail.intro'),
				value: profile.introduce ? profile.introduce : '',
			},
		];

		setProfileDetails(list);
	}

	/*
	 * initProfile
	 * 뒤로가기 클릭 시 프로필 관련 설정값 초기화
	 */
	const initProfile = () => {
		setProfileDetails([]);
		setUserProfileOpen('');
	};

	useEffect(() => {
		return setScrollOn(false);
	}, []);

	function scrollHandler(y: number, rectTop: number) {
		if (y > 0 && rectTop <= 134) {
			setScrollOn(true);
		} else {
			setScrollOn(false);
		}
	}

	useEffect(() => {
		if (!userProfileOpen) return;
		getUsersBadge(userProfileOpen).then((list: UserAuthBadgeCOVO[]) => {
			setBadgeData(list);
		});
	}, [userProfileOpen]);

	/*
	 * evalHandler
	 * 별점평가 및 애니메이션 처리
	 */
	let timer: NodeJS.Timeout;
	const evalHandler = (userUid: string, score: number) => {
		timer = setTimeout(
			() => {
				const map: UserScoreCIVO = {
					userUid: userUid,
					score: score,
				};
				saveScoreUser(map).then((valueOf) => {
					clearTimeout(timer);
					if (userProfileOpen != '' && isOtherProfile) {
						getUserProfile(userProfileOpen).then((value) => {
							initUserProfile(value);
						});
					}
				});
			},
			1000,
			userUid,
			score,
		);
	};

	useEffect(() => {
		// 상대 프로필일 때
		if (userProfileOpen != '' && isOtherProfile) {
			getUserProfile(userProfileOpen).then((value) => {
				setFadeIn(true);
				initUserProfile(value);
			});
		}
	}, [isOtherProfile, userProfileOpen]);

	return {
		userProfile,
		profileDetails,
		isChecked,
		setIsChecked,
		scrollHandler,
		evalHandler,
		initProfile,
		savedLikeProfile,
		setSavedLikeProfile,
		badgeData,
		clearPreviewURLs,
		sliderSettings,
		setSettingOpen,
		srcPrefix,
		myProfile,
		myProfileOpen,
		setMyProfileOpen,
	};
};
