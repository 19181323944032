import { useProfileAdapter } from '@client/site/dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useCallback, useEffect, useState } from 'react';
import { SortedMessage } from '../../../types/navigationTypes';
import { useCommonDMService } from '../../../service/useCommonDMService';
import { useTranslation } from 'react-i18next';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import {
	formatMatchingTime,
	normalizeToTimestamp,
} from '@virtual-space/utils/messageUtils';

export const useMessageListService = () => {
	const { t } = useTranslation('messenger');

	const {
		showDirectMessageList,
		normalDirectMessageList,
		unreadMessageCounts,
		directMessageList,
		showOnlineMessageList,
		showOfflineMessageList,
	} = useCommonDMService();

	const { managerEmpty, setShowDirectMessageList, setIsMessenger } =
		useDirectMessageRepo();

	const {
		myProfile,
		userProfile,
		srcPrefix,
		userProfileOpen,
		setUserProfile,
		setUserProfileOpen,
	} = useDatingRepo();

	const { getUserProfile } = useProfileAdapter();

	useEffect(() => {
		setFadeIn(true);
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {
					console.log(reason);
				});
		}
	}, []);

	const [fadeIn, setFadeIn] = useState<boolean>(false);

	// 메시지 타입에 따른 표시 방식을 결정하는 함수
	const getMessageDisplay = useCallback(
		(message: any) => {
			const isUser1 = message.isUser1;
			const billingStatus = message.billingStatus;
			const messagesLength = message.messages.length;
			const dmRoomType = message.dmRoomType;
			const matchingTime = message.matchingTime;
			const blockType = message.blockType;

			// 차단 상태 먼저 체크
			if (blockType === 'blocked') {
				// 내가 차단당한 경우
				return {
					className: 'color-red',
					prefix: t('blockedQuote'),
				};
			}

			if (blockType === 'block') {
				// 내가 차단한 경우
				return {
					className: 'color-red',
					prefix: t('blockQuote'),
				};
			}

			switch (dmRoomType) {
				case 'FIRST':
					if (
						(isUser1 && billingStatus === 2) ||
						(!isUser1 && billingStatus === 1)
					) {
						return {
							className: 'color-red',
							prefix: t('msgQuote'),
						};
					}
					break;

				case 'LIKE':
					// billingStatus가 0이고 메시지가 없는 경우
					if (billingStatus === 0 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: t('matchedAlready', { matchingTime: matchingTime }),
						};
					}

					// billingStatus가 3이고 메시지가 없는 경우
					if (billingStatus === 3 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: t('matched'),
						};
					}

					// 내가 결제했고 메시지가 없는 경우
					if (billingStatus === 1 && isUser1 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: t('startMsg'),
						};
					}

					// 상대방이 과금했고 메시지가 없는 경우
					if (
						((isUser1 && billingStatus === 2) ||
							(!isUser1 && billingStatus === 1)) &&
						messagesLength === 0
					) {
						return {
							className: 'color-red',
							prefix: t('matchedAlready', { matchingTime: matchingTime }),
						};
					}

					// 상대방이 과금 후 메시지를 보냈을 때
					if (
						(isUser1 && billingStatus === 2 && messagesLength !== 0) ||
						(!isUser1 && billingStatus === 1 && messagesLength !== 0)
					) {
						return {
							className: 'color-red',
							prefix: t('msgQuote'),
						};
					}
					break;
			}

			// 기본 리턴값
			return {
				className: 'color-666',
				prefix: '',
			};
		},
		[
			directMessageList,
			normalDirectMessageList,
			myProfile.userUid,
			unreadMessageCounts,
		],
	);

	const processAndSortMessages = useCallback(() => {
		const processed = normalDirectMessageList.reduce<SortedMessage[]>(
			(result, room) => {
				// 여기부터
				try {
					const isUser1 = room.user1Id === myProfile.userUid;

					if (room.visibleTo === -1) {
						return result;
					}

					if (
						(isUser1 && room.visibleTo === 1) ||
						(!isUser1 && room.visibleTo === 2)
					) {
						return result;
					}

					const otherUserInfo = isUser1 ? room.user2Info : room.user1Info;

					// 차단 상태 결정 - 내 시점에서의 차단 상태로 변환
					let myBlockType;
					if (isUser1) {
						// 내가 user1인 경우
						myBlockType = room.user1Info.blockType;
					} else {
						// 내가 user2인 경우
						myBlockType = room.user2Info.blockType;
					}
					if (!otherUserInfo || !otherUserInfo.userUid) {
						return result;
					}

					const messagesArray = Array.isArray(room.messages)
						? room.messages.map((msg) => {
								// content가 JSON 문자열로 시작하고 roomId, roomTheme를 포함하는지 확인
								if (
									typeof msg.content === 'string' &&
									msg.content.includes('"roomId"') &&
									msg.content.includes('"roomTheme"')
								) {
									try {
										// 이스케이프된 JSON 문자열 처리
										const cleanContent = msg.content.replace(/\\/g, '');
										const jsonData = JSON.parse(cleanContent);
										return {
											...msg,
											content: t('goToMatching'),
											originalData: jsonData,
										};
									} catch (e) {
										console.log('JSON 파싱 에러:', e);
										return msg;
									}
								}
								return msg;
						  })
						: Array.from(room.messages.values());
					const sortedMessages = [...messagesArray].sort((a, b) => {
						// 날짜 형식에 관계없이 항상 동일한 방식으로 비교
						const dateA = a.createdAt.includes('T')
							? new Date(a.createdAt).getTime()
							: Number(a.createdAt);
						const dateB = b.createdAt.includes('T')
							? new Date(b.createdAt).getTime()
							: Number(b.createdAt);
						return dateB - dateA;
					});

					if (messagesArray.length > 0) {
						const latestMessage = messagesArray[0];
						if (latestMessage?.sender && latestMessage?.receiver) {
							const messageOtherInfo =
								latestMessage.sender.userUid === otherUserInfo.userUid
									? latestMessage.sender
									: latestMessage.receiver.userUid === otherUserInfo.userUid
									? latestMessage.receiver
									: null;

							if (messageOtherInfo) {
								Object.assign(otherUserInfo, {
									nickName: otherUserInfo.nickName,
									profileUrl: otherUserInfo.profileUrl,
									region1: otherUserInfo.region1,
									region2: otherUserInfo.region2,
									age: otherUserInfo.age,
									blockType: otherUserInfo.blockType,
								});
							}
						}
					}

					const matchingTime = room.createdAt;
					const createdTime = normalizeToTimestamp(matchingTime);
					const formattedTime = formatMatchingTime(createdTime.toString());

					const display = getMessageDisplay({
						dmRoomType: room.dmRoomType,
						isUser1,
						messages: sortedMessages,
						billingStatus: room.billingStatus,
						matchingTime: formattedTime,
						blockType: myBlockType,
					});

					const unreadCount = isUser1 ? room.unReadCnt1 : room.unReadCnt2;

					const processedRoom = {
						billingStatus: room.billingStatus,
						myPaymentStatus: isUser1
							? room.billingStatus === 1
							: room.billingStatus === 2,
						otherPaymentStatus: isUser1
							? room.billingStatus === 2
							: room.billingStatus === 1,
						dmRoomType: room.dmRoomType,
						roomId: room.roomId,
						messages: sortedMessages,
						latestCreatedAt:
							sortedMessages.length > 0
								? normalizeToTimestamp(sortedMessages[0].createdAt)
								: normalizeToTimestamp(room.createdAt),
						otherUserInfo,
						visibleTo: room.visibleTo,
						isUserLeft: isUser1 ? room.visibleTo === 1 : room.visibleTo === 2,
						isOtherUserLeft: isUser1
							? room.visibleTo === 2
							: room.visibleTo === 1,
						unreadCount: unreadCount,
						display,
						userInfo1: room.user1Info,
						userInfo2: room.user2Info,
					};

					result.push(processedRoom);

					return result;
				} catch (error) {
					console.error('Error processing room:', room.roomId, error);
					return result;
				}
			},
			[],
		);

		// 메시지가 있는 경우 마지막 메시지 시간, 없는 경우 방 생성 시간으로 정렬
		return processed.sort((a, b) => b.latestCreatedAt - a.latestCreatedAt);
	}, [
		directMessageList,
		normalDirectMessageList,
		myProfile.userUid,
		unreadMessageCounts,
		getMessageDisplay,
	]);

	const sortedMessages = processAndSortMessages();

	return {
		sortedMessages,
		showDirectMessageList,
		managerEmpty,
		myProfile,
		userProfile,
		srcPrefix,
		setUserProfileOpen,
		setShowDirectMessageList,
		setIsMessenger,
		showOnlineMessageList,
		showOfflineMessageList,
		fadeIn,
	};
};
