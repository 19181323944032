import {
	PlayerInfo,
	useMetaUserRepo,
} from '@virtual-space/stores/useMetaUserRepo';
import { useVideoRepo } from '@virtual-space/stores/useVideoRepo';
import { replaceInvalidId, sanitizeId } from '@virtual-space/utils/util';
import { useEffect, useRef, useState } from 'react';

export const useVideoService = () => {
	const [playerInfo, setPlayerInfo] = useState<PlayerInfo | null>(null);
	const [playersInfo, setPlayersInfo] = useState({});
	const [clickFoldBtn, setClickFoldBtn] = useState(true);
	const {
		sessionId,
		mediaConnected,
		myMetaUser,
		playerNameMap,
		audioControl,
		videoControl,
	} = useMetaUserRepo();
	const videoRef = useRef<HTMLVideoElement>(null);
	const { addedVideos, myVideoStream, openCamExpansion, setOpenCamExpansion } =
		useVideoRepo();
	const videoElement = openCamExpansion.videoElement;
	const isMyCamVideo = replaceInvalidId(sessionId) === videoElement?.id;
	useEffect(() => {
		const newPlayersInfo = {};
		[...addedVideos.values()].forEach((videoElement) => {
			if (videoElement.id) {
				const info = playerNameMap.get(videoElement.id);
				newPlayersInfo[videoElement.id] = info || null;
			}
		});
		setPlayersInfo(newPlayersInfo);
	}, [playerNameMap, addedVideos]);

	useEffect(() => {
		if (videoElement && videoElement.id) {
			const cleanPlayerId = sanitizeId(videoElement.id);
			const info = playerNameMap.get(cleanPlayerId);
			setPlayerInfo(info || null);
		}
	}, [playerNameMap, videoElement]);

	useEffect(() => {
		if (videoRef.current && videoElement) {
			videoRef.current.srcObject = videoElement.srcObject;
			videoRef.current.addEventListener('loadedmetadata', () => {
				videoRef.current!.play();
			});
		}
	}, [videoElement]);
	return {
		videoElement,
		isMyCamVideo,
		mediaConnected,
		videoControl,
		openCamExpansion,
		videoRef,
		myMetaUser,
		addedVideos,
		myVideoStream,
		audioControl,
		sessionId,
		playerNameMap,
		playersInfo,
		playerInfo,
		clickFoldBtn,
		setPlayerInfo,
		setClickFoldBtn,
		setOpenCamExpansion,
	};
};
