import { useState } from 'react';
import {
	ReceiveCommentPostCOVO,
	ReceiveLikeUserCOVO,
	ReceivePostLikeCOVO,
	ReceiveUserHighScoreCOVO,
} from '@client/site/api';

export const useLikeReceiveStateRepo = () => {
	// 받은 좋아요 더보기 버튼
	const [noDataUserLike, setNoDataUserLike] = useState<boolean>(false);

	// 받은 좋아요 리스트
	const [likeUsers, setLikeUsers] = useState<ReceiveLikeUserCOVO[]>([]);

	// 받은 좋아요 페이징
	const [likeUserPage, setLikeUserPage] = useState<number>(1);

	// 높게 받은 평가 더보기 버튼
	const [noDataHighlyRated, setNoDataHighlyRated] = useState<boolean>(false);

	// 높게 받은 평가 리스트
	const [highlyRatedUsers, setHighlyRatedUsers] = useState<
		ReceiveUserHighScoreCOVO[]
	>([]);

	// 높게 받은 평가 페이징
	const [highlyRatedPage, setHighlyRatedPage] = useState<number>(1);

	// 좋아요 받은 포스트 더보기 버튼
	const [noDataPost, setNoDataPost] = useState<boolean>(false);

	// 좋아요 받은 포스트 리스트
	const [likePosts, setLikePosts] = useState<ReceivePostLikeCOVO[]>([]);

	// 좋아요 받은 포스트 페이징
	const [likePostPage, setLikePostPage] = useState<number>(1);

	// 댓글 달린 포스트 더보기 버튼
	const [noDataComment, setNoDataComment] = useState<boolean>(false);

	// 댓글 달린 포스트 리스트
	const [commentPost, setCommentPost] = useState<ReceiveCommentPostCOVO[]>([]);

	// 댓글 달린 포스트 페이징
	const [commentPostPage, setCommentPostPage] = useState<number>(1);

	const [fadeIn, setFadeIn] = useState<boolean>(false);

	// 받은 좋아요 리턴 리스트 길이가 0인 경우 호출 그만
	const [stopGetReceivedLike, setStopGetReceivedLike] = useState(false);

	// 높게 받은 평가 리턴 리스트 길이가 0인 경우 호출 그만
	const [stopGetReceivedRating, setStopGetReceivedRating] = useState(false);

	return {
		stopGetReceivedRating,
		setStopGetReceivedRating,
		stopGetReceivedLike,
		setStopGetReceivedLike,
		fadeIn,
		setFadeIn,
		noDataUserLike,
		setNoDataUserLike,
		likeUsers,
		setLikeUsers,
		likeUserPage,
		setLikeUserPage,
		noDataHighlyRated,
		setNoDataHighlyRated,
		highlyRatedUsers,
		setHighlyRatedUsers,
		highlyRatedPage,
		setHighlyRatedPage,
		noDataPost,
		setNoDataPost,
		likePosts,
		setLikePosts,
		likePostPage,
		setLikePostPage,
		noDataComment,
		setNoDataComment,
		commentPost,
		setCommentPost,
		commentPostPage,
		setCommentPostPage,
	};
};
