import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageOrganizer } from '../../../../hooks/useMessageOrganizer';
import MessageGroupListView from '../../../../views/detail/MessageGroupListView';
import { useCommonDMService } from '@virtual-space/ui/components/dm/service/useCommonDMService';
import { MyManagerOnlineDetailViewProps } from '@virtual-space/ui/components/dm/types/navigationTypes';
import { formatMatchedTime } from '@virtual-space/utils/messageUtils';

/**
 * 나의 매니저 온라인 채팅 뷰 컴포넌트
 * 매니저의 온라인 채팅방 UI를 관리하고 메시지를 표시합니다.
 */
const MyManagerOnlineDetailView: React.FC<MyManagerOnlineDetailViewProps> = ({
	groupedMessagesDetail,
	srcPrefix,
	myProfile,
	navigationRoom,
	isOtherUserLeft,
	messagesEndRef,
	setUserProfileOpen,
	handleRoomMessageClick,
	blockStatus,
	matchedAt,
}) => {
	const { t } = useTranslation('messenger');
	const { getStatusMessage, formatMessageDate } = useCommonDMService();
	const { organizeMessages } = useMessageOrganizer();

	const organizedMessages = organizeMessages(groupedMessagesDetail);

	return (
		<>
			<div className="inner chatRoom">
				<div className="chatList">
					{Object.keys(organizedMessages).length === 0 && (
						<div className="chatStartBox">
							<p className="partnerStart">{t('startWarmChat')}</p>
						</div>
					)}

					{/* 날짜별 메시지 그룹 렌더링 */}
					{Object.entries(organizedMessages).map(([dateKey, dateData]) => (
						<div key={dateKey} className="messageTime">
							<span className="messageTimeSpan">
								{formatMessageDate(dateKey)}
							</span>

							{/* 해당 날짜의 메시지 그룹들 렌더링 */}
							<MessageGroupListView
								groups={dateData.groups}
								myProfile={myProfile}
								srcPrefix={srcPrefix}
								isOtherUserLeft={isOtherUserLeft}
								blockStatus={blockStatus}
								setUserProfileOpen={setUserProfileOpen}
								navigationRoom={navigationRoom}
								handleRoomMessageClick={handleRoomMessageClick}
							/>
						</div>
					))}

					{matchedAt && (
						<p className="matchingTrue">
							{t('onlineMatchConnected', {
								time: formatMatchedTime(matchedAt),
							})}
						</p>
					)}

					{blockStatus && blockStatus !== 'none' && (
						<p
							className="partnerOut"
							dangerouslySetInnerHTML={{
								__html: getStatusMessage(blockStatus),
							}}
						/>
					)}
				</div>
				<div ref={messagesEndRef} style={{ height: '1px' }} />
			</div>
		</>
	);
};

export default MyManagerOnlineDetailView;
