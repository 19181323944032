import { useEffect } from 'react';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import type { UserPreferCIVO } from '@client/site/api';
import { useIdealSettingStateRepo } from '@dating/ui/pages/register/Ideal/useIdealSettingStateRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useReferralCodeRepo } from '@dating/repository/register/useReferralCodeRepo';
import { useTranslation } from 'react-i18next';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

const useIdealSettingService = () => {
	const {
		isPopupVisible,
		setIsPopupVisible,
		popupType,
		setPopupType,
		ageRange,
		setAgeRange,
		nearRange,
		setNearRange,
		heightRange,
		setHeightRange,
		religion,
		setReligion,
		drinkingState,
		setDrinkingState,
		smokingState,
		setSmokingState,
		initAgeRange,
		setInitAgeRange,
		initNearRange,
		setInitNearRange,
		initHeightRange,
		setInitHeightRange,
		initReligion,
		setInitReligion,
		initDrinkingState,
		setInitDrinkingState,
		initSmokingState,
		setInitSmokingState,
		response,
		setResponse,
		nearOverRange,
		setNearOverRange,
		ageOverRange,
		setAgeOverRange,
		heightOverRange,
		setHeightOverRange,
	} = useIdealSettingStateRepo();

	const { setReferralCodeOpen } = useReferralCodeRepo();

	const { setStep, registerSteps } = useRegisterRepo();

	const { langCode } = useAuthRepo();

	const { saveUserPrefer, statusSaveUserPrefer, dataSaveUserPrefer } =
		useRegisterAdapter();

	const { getPrefer } = useSettingAdapter();

	const { t } = useTranslation('register');

	////////////////////////////////////////

	useEffect(() => {
		getPrefer(langCode).then((response) => {
			setInitAgeRange({ min: response.ageMin, max: response.ageMax });
			setInitNearRange({
				min: response.distanceMin,
				max: response.distanceMax,
			});
			setInitHeightRange({ min: response.heightMin, max: response.heightMax });

			setInitReligion(response.religion);
			setInitDrinkingState(response.drink);
			setInitSmokingState(response.smoke);

			setReligion({ code: '', value: t('idealDetail.nothing') });
			setDrinkingState({ code: '', value: t('idealDetail.nothing') });
			setSmokingState({ code: '', value: t('idealDetail.nothing') });
		});
	}, []);

	/*
	 * showPopup
	 * 종교, 음주, 흡연 팝업 열기
	 */
	const showPopup = () => {
		setIsPopupVisible(true);
	};

	/*
	 * hidePopup
	 * 종교, 음주, 흡연 팝업 닫기 및 취소버튼 클릭한 경우 선택한 값 초기화
	 */
	const hidePopup = (action: string, type: string) => {
		//팝업 닫기
		setIsPopupVisible(false);
	};

	/*
	 * fnSaveUserPrefer
	 * 이상형 설정값 저장
	 */
	const fnSaveUserPrefer = () => {
		const map: UserPreferCIVO = {
			ageMin: ageRange.min,
			ageMax: ageRange.max,
			distanceMin: nearRange.min,
			distanceMax: nearRange.max,
			heightMin: heightRange.min,
			heightMax: heightRange.max,
			religion: religion.code,
			drinking: drinkingState.code,
			smoking: smokingState.code,
		};
		saveUserPrefer(map);
	};

	const handleAgeChange = (value) => {
		setAgeRange(value);
	};

	const formatAgeValue = (value) => {
		if (value === initAgeRange.max) {
			setAgeOverRange(true);
		} else {
			setAgeOverRange(false);
		}
		return `${value}${t('modifyProfile.howOld')}`;
	};
	const handleNearChange = (value) => {
		setNearRange(value);
	};

	const formatDistanceValue = (value) => {
		if (value === initNearRange.max) {
			setNearOverRange(true);
		} else {
			setNearOverRange(false);
		}
		return `${value}km`;
	};
	const handleHeightChange = (value) => {
		setHeightRange(value);
	};

	const formatHeightValue = (value) => {
		if (value === initHeightRange.max) {
			setHeightOverRange(true);
		} else {
			setHeightOverRange(false);
		}
		return `${value}cm`;
	};

	const handleReligionSelect = (option) => {
		setReligion({
			code: option.code,
			value: option.value,
		});
	};

	const handleDrinkingStateSelect = (option) => {
		setDrinkingState({
			code: option.code,
			value: option.value,
		});
	};

	const handleSmokingStateSelect = (option) => {
		setSmokingState({
			code: option.code,
			value: option.value,
		});
	};

	useEffect(() => {
		if (statusSaveUserPrefer === 'success' && dataSaveUserPrefer) {
			if (dataSaveUserPrefer.result) {
				setStep(8);
				setResponse(true);
				setReferralCodeOpen(true);
			} else {
				alert(dataSaveUserPrefer.resultCode);
			}
		} else if (statusSaveUserPrefer === 'error') {
		}
	}, [statusSaveUserPrefer, dataSaveUserPrefer]);

	return {
		isPopupVisible,
		setIsPopupVisible,
		popupType,
		setPopupType,
		showPopup,
		hidePopup,
		fnSaveUserPrefer,
		formatAgeValue,
		ageRange,
		handleAgeChange,
		formatDistanceValue,
		nearRange,
		handleNearChange,
		formatHeightValue,
		heightRange,
		handleHeightChange,
		religion,
		handleReligionSelect,
		drinkingState,
		handleDrinkingStateSelect,
		smokingState,
		handleSmokingStateSelect,
		initAgeRange,
		initNearRange,
		initHeightRange,
		initReligion,
		initDrinkingState,
		initSmokingState,
		response,
		nearOverRange,
		ageOverRange,
		heightOverRange,
		registerSteps,
	};
};

export default useIdealSettingService;
