import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useAppFooterService } from '@virtual-space/ui/components/footer/service/useAppFooterService';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../../../../types/IDirectMessageState';
import { useCommonDMService } from '../../service/useCommonDMService';
import { MessageCIVO, CreateMessageRoomCIVO } from '@client/site/api';
import { useMessageAdapter } from '@client/site/dating/adapter/message/useMessageAdapter';
import { useProfileAdapter } from '@client/site/dating/adapter/profile/useProfileAdapter';
import { useSettingAdapter } from '@client/site/dating/adapter/setting/useSettingAdapter';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useOrderRepo } from '@client/site/dating/repository/order/useOrderRepo';
import { usePaymentRepo } from '@client/site/dating/stores/PaymentStore';
import { useCommonAlertPopService } from '@client/site/dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { isCensored } from '@client/utils/censor';
import useApiDirectMessageRepo from '@client/site/dating/repository/message/useApiDirectMessageRepo';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';

export const useDirectMessageService = () => {
	const { t } = useTranslation('messenger');
	const { setOpenOtherPlayerProfile } = useMetaUserRepo();
	const { handleMessageSendClick } = useAppFooterService();
	const { relatedRoomIds, messageInputValue, setMessageInputValue } =
		useDirectMessageRepo();
	const navigate = useNavigate();
	const { openPaymentPop, messageTargetUser, setOpenPaymentPop } =
		usePaymentRepo();
	const { myProfile, userProfile, setUserProfile } = useDatingRepo();
	const { groupedMessages } = useCommonDMService();
	const { createdDMRoomId, setCreatedDMRoomId, setIsExistingRoom } =
		useApiDirectMessageRepo();
	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	// API 호출을 통해 방 생성
	const { createMessageRoomId, sendMessage, isMessageRoom, saveMessage } =
		useMessageAdapter();
	const { getUserProfile } = useProfileAdapter();
	const { getWhoBlock } = useSettingAdapter();
	const { orderResult } = useOrderRepo();

	useEffect(() => {
		if (messageTargetUser != '') {
			getUserProfile(messageTargetUser)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {});
		}
	}, [messageTargetUser]);

	useEffect(() => {
		const getMessageRoomId = async () => {
			try {
				const data = createMessageRoomId();
				if (data) {
					setCreatedDMRoomId(await data);
				}
			} catch (err) {
				console.error('Failed to create message room ID:', err);
			}
		};
		if (openPaymentPop) {
			getMessageRoomId();
		}
	}, [openPaymentPop]);

	useEffect(() => {
		const getIsMessageRoom = async () => {
			try {
				const toUserUid: string = userProfile.userUid;
				const data = await isMessageRoom(toUserUid);
				if (data) {
					setIsExistingRoom(data);
				}
			} catch (error) {
				console.error('방 존재 여부 확인 실패:', error);
			}
		};

		if (openPaymentPop && userProfile?.userUid && myProfile?.userUid) {
			getIsMessageRoom();
		}
	}, [openPaymentPop, userProfile?.userUid, myProfile?.userUid]);

	const [messageSent, setMessageSent] = useState(false);

	const getLatestMessageId = useCallback(
		(roomId: string) => {
			const room = groupedMessages[roomId];
			if (!room || !room.messages || !room.messages.size) return 0;

			return Math.max(
				...Array.from(room.messages.values()).map((message) =>
					(message as any).messageId
						? parseInt((message as any).messageId.toString())
						: 0,
				),
			);
		},
		[groupedMessages],
	);

	const getCurrentRoomId = async () => {
		if (!myProfile?.userUid || !userProfile?.userUid) {
			console.error('User profiles not loaded:', { myProfile, userProfile });
			return null;
		}

		try {
			// 기존 방이 있는지 먼저 확인
			const existingRoomData = await isMessageRoom(userProfile.userUid);

			if (existingRoomData && existingRoomData.roomId) {
				return existingRoomData.roomId;
			}

			if (!createdDMRoomId?.roomId) {
				return null;
			}

			return createdDMRoomId.roomId;
		} catch (error) {
			console.error('방 ID 가져오기 실패:', error);
			return null;
		}
	};

	const handleChange = (value: string) => {
		setMessageInputValue(value);
	};

	const handleNavigation = async (roomId: string, orderId: number) => {
		const isExistingRoom = relatedRoomIds.includes(roomId);
		let navigationState = {};
		const roomData = groupedMessages[roomId];
		let blockStatus;
		try {
			blockStatus = await getWhoBlock(messageTargetUser);
		} catch (error) {
			console.error('Failed to get block status:', error);
			blockStatus = 'none'; // fallback to none if API fails
		}

		let user1BlockType = 'none';
		let user2BlockType = 'none';

		if (blockStatus === 'blocked') {
			user1BlockType = 'blocked';
			user2BlockType = 'block';
		} else if (blockStatus === 'block') {
			user1BlockType = 'block';
			user2BlockType = 'blocked';
		}

		// 공통으로 사용할 user 정보 객체
		const user1Info = {
			userUid: myProfile.userUid,
			nickName: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			region1: myProfile.region1,
			region2: myProfile.region2,
			age: myProfile.age,
			inActive: false,
			isDeleted: false,
			blockType: user1BlockType,
		};

		const user2Info = {
			userUid: userProfile.userUid,
			nickName: userProfile.nickName,
			profileUrl: userProfile.profileUrl[0],
			region1: userProfile.region1,
			region2: userProfile.region2,
			age: userProfile.age,
			inActive: false,
			isDeleted: false,
			blockType: user2BlockType,
		};

		if (isExistingRoom) {
			// 이미 존재하는 방일 경우
			const isUser1 = myProfile.userUid === roomData.user1Id;
			navigationState = {
				billingStatus: roomData.billingStatus,
				myPaymentStatus: isUser1
					? roomData.billingStatus === 1
					: roomData.billingStatus === 2,
				otherPaymentStatus: isUser1
					? roomData.billingStatus === 2
					: roomData.billingStatus === 1,
				dmRoomType: roomData.dmRoomType,
				roomId: roomId,
				visibleTo: roomData.visibleTo,
				isUserLeft: isUser1
					? roomData.visibleTo === 1
					: roomData.visibleTo === 2,
				isOtherUserLeft: isUser1
					? roomData.visibleTo === 2
					: roomData.visibleTo === 1,
				user1Info: roomData.user1Info || user1Info,
				user2Info: roomData.user2Info || user2Info,
				otherUserInfo: isUser1
					? roomData.user2Info || user2Info
					: roomData.user1Info || user1Info,
				createdAt: roomData.createdAt || new Date().toString(),
			};
		} else {
			// 새로운 방일 경우 (첫 메시지)
			navigationState = {
				billingStatus: 1,
				myPaymentStatus: true,
				otherPaymentStatus: false,
				dmRoomType: 'FIRST',
				roomId: roomId,
				visibleTo: 0,
				isUserLeft: false,
				isOtherUserLeft: false,
				user1Info: user1Info,
				user2Info: user2Info,
				otherUserInfo: user2Info, // 첫 메시지를 보내는 사람이 user1이므로, 상대방은 항상 user2
				createdAt: new Date().toString(),
			};
		}

		// 방 입장 이벤트 발생
		dmEventEmitter.emit('react-enter-dm-room', {
			roomId: roomId,
		});

		navigate(`${PATH.MESSENGER.ROOT}/${roomId}`, {
			state: navigationState,
		});
		setOpenPaymentPop(false);
		handleMessageSendClick();
	};

	const handlePaymentClick = async (orderId: number) => {
		if (messageInputValue.trim() === '') {
			setOpenPaymentPop(false);
			return;
		}

		const currentRoomId = await getCurrentRoomId();
		if (!currentRoomId) return;

		const isExistingRoom = relatedRoomIds.includes(currentRoomId);

		if (isExistingRoom && orderId === -1) {
			// 기존 방인 경우 메시지만 전송
			await handleSubmit(currentRoomId);
		} else {
			// 새로운 방인 경우
			// 1. 먼저 방 생성
			await fnSaveCreateMessageRoom({
				roomId: currentRoomId,
				userUid: myProfile.userUid,
				toUserUid: userProfile.userUid,
				billingStatus: 1,
				roomType: 'FIRST',
				orderId: orderId,
			});
			// 2. 그 다음 메시지 전송
			await handleSubmit(currentRoomId);
		}

		await handleNavigation(currentRoomId, orderId);
		setMessageSent(true);
	};

	const handleSubmit = async (currentRoomId: string) => {
		const trimmedValue = messageInputValue.trim();
		const hasCensoredWord = isCensored(trimmedValue.replace(/\s/g, ''));

		if (hasCensoredWord) {
			fnCommonAlertPopOn(t('forbiddenWord'));
			setMessageInputValue('');
			return;
		}

		const checkUserInRoom = () => {
			return new Promise<boolean>((resolve) => {
				// 상태 응답을 받을 이벤트 핸들러
				const handleRoomStatus = (status: {
					targetUid: string;
					isInRoom: boolean;
				}) => {
					if (status.targetUid === userProfile.userUid) {
						resolve(status.isInRoom);
						// 이벤트 리스너 제거
						dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					}
				};

				// USER_IN_ROOM_STATUS 이벤트 리스너 등록
				dmEventEmitter.on('react-user-in-room-status', handleRoomStatus);

				// 상태 확인 요청
				dmEventEmitter.emit('react-check-user-in-room', {
					targetUid: userProfile.userUid,
					roomId: currentRoomId,
				});

				// 타임아웃 설정 (3초)
				setTimeout(() => {
					dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					resolve(false);
				}, 3000);
			});
		};

		// 상대방의 채팅방 상태를 기다림
		const isInRoom = await checkUserInRoom();
		if (trimmedValue) {
			const sender: Partial<IUser> = {
				userUid: myProfile.userUid,
				nickName: myProfile.nickName,
				profileUrl: myProfile.profileUrl[0],
				region1: myProfile?.region1,
				region2: myProfile?.region2,
				age: myProfile.age,
			};

			const receiver: Partial<IUser> = {
				userUid: userProfile.userUid,
				nickName: userProfile.nickName,
				profileUrl: userProfile.profileUrl[0],
				region1: userProfile.region1,
				region2: userProfile.region2,
				age: userProfile.age,
			};

			const roomData = {
				billingStatus: 1,
				dmRoomType: 'FIRST',
				roomId: currentRoomId,
				user1Id: myProfile.userUid,
				user2Id: userProfile.userUid,
				user1Info: sender,
				user2Info: receiver,
				createdAt: Date.now().toString(),
				otherUserInfo: receiver,
				productType: 'MESSENGER',
				matchedAt: null,
			};

			if (!relatedRoomIds.includes(currentRoomId)) {
				dmEventEmitter.emit('react-create-dm-room', roomData);
			}

			const messageData = {
				roomId: currentRoomId,
				messageId: getLatestMessageId(currentRoomId) + 1,
				sender: sender,
				receiver: receiver,
				content: trimmedValue,
				createdAt: Date.now().toString(),
				read: isInRoom,
			};

			dmEventEmitter.emit('react-send-direct-message', messageData);

			if (!relatedRoomIds.includes(currentRoomId)) {
				await new Promise((resolve) => setTimeout(resolve, 100));
				dmEventEmitter.emit('react-single-payment-completed', {
					roomId: currentRoomId,
					payerId: myProfile.userUid,
				});
			}

			const map: MessageCIVO = {
				roomId: currentRoomId,
				content: trimmedValue,
				read: isInRoom,
				createdAt: new Date().toString(),
			};
			saveMessage(map);

			setMessageInputValue('');
		}
	};

	/*
	 * fnSaveCreateMessageRoom
	 * 메세지 방 생성
	 */
	const fnSaveCreateMessageRoom = async (param: {
		roomId: string;
		userUid: string;
		toUserUid: string;
		billingStatus: number;
		roomType: string;
		orderId: number;
	}) => {
		const map: CreateMessageRoomCIVO = {
			roomId: param.roomId,
			toUserUid: param.toUserUid,
			billingStatus: param.billingStatus,
			roomType: 'FIRST',
			orderId: param.orderId,
		};
		try {
			sendMessage(map);
		} catch (error) {
			console.error('방 생성 실패:', error);
		}
	};

	useEffect(() => {
		if (messageSent) {
			const handleNavigationAsync = async () => {
				const currentRoomId = await getCurrentRoomId();
				if (currentRoomId) {
					handleNavigation(currentRoomId, orderResult.orderId);
				}
				setOpenOtherPlayerProfile(false, null);
				setMessageSent(false);
			};
			handleNavigationAsync();
		}
	}, [messageSent]);

	return {
		messageInputValue,
		handleChange,
		handleSubmit,
		handlePaymentClick,
		getCurrentRoomId,
		setMessageInputValue,
	};
};
