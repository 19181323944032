import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import { DdayProfileBoxView } from '@dating/ui/pages/hushPick/views/DdayProfileBoxView';
import { useHushPickService } from '@dating/ui/pages/hushPick/services/useHushPickService';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useOrderUtils } from '@client/utils/orderUtils';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { useTranslation } from 'react-i18next';

const HushPickContainer = () => {
	const { hushHome, srcPrefix, myProfile } = useHomeHeaderService();

	const {
		recentProfiles,
		hushPickPacks,
		hushPickOn,
		hushPickPacksHandle,
		setUserProfileOpen,
		fnGetRecommendUsers,
		response,
	} = useHushPickService();

	const { recommendProducts } = usePaymentService();

	const { fnValidateOrder } = useOrderUtils();

	const navigate = useNavigate();

	const { t } = useTranslation('home');

	useEffect(() => {
		if (response) {
			navigate(PATH.DATING.HOME);
		}
	}, [response]);

	return (
		<>
			<div id="conWrap" className="metaPopWrap">
				<div className="tabConWrap">
					<div className="tabContent">
						<div className={`tabCon tabCon02 ${!hushHome ? 'active' : ''}`}>
							<div className="homeStarWrap">
								<div className="inner">
									<div className="starNone">
										<p className="title03">{t('hushPick.quote1')}</p>
										<p className="color-666 fz14">{t('hushPick.quote2')}</p>
									</div>
								</div>
							</div>

							{recentProfiles.length > 0 ? (
								// 히스토리 있을 때
								<div className="missWrap">
									<div className="inner">
										<div className="profileList imgSmList">
											{/*최근 접속 이성 추천 히스토리*/}
											{recentProfiles.map((profile, i) => {
												return (
													<DdayProfileBoxView
														key={i}
														type={'homePast'}
														profile={profile}
														srcPrefix={srcPrefix}
														setUserProfileOpen={setUserProfileOpen}
														currentUserUid={myProfile.userUid}
													/>
												);
											})}
										</div>
									</div>
								</div>
							) : (
								// 히스토리 없을 때
								<div className="pickWrap horizType">
									<div className="inner">
										<p
											className="title03 text-c color-999"
											dangerouslySetInnerHTML={{ __html: t('hushPick.empty') }}
										/>
										<div className="pickList">
											{
												// 이성 추천 과금 항목
												hushPickPacks.map((item, i) => {
													return (
														<div
															key={item.title}
															className={`pickItem ${
																hushPickOn[item.title] ? 'on' : ''
															}`}
															onClick={() => {
																hushPickPacksHandle(item.title);
															}}
														>
															<div className="pickType">
																<div className="d-flex">
																	<img
																		src={item.iconUrl}
																		alt={item.matchType}
																	/>
																	<p className="txt01">
																		{item.matchType === 'tall'
																			? myProfile.gender === '여성'
																				? t('hushPick.longTall1')
																				: t('hushPick.longTall2')
																			: item.titleLang}
																	</p>
																</div>
																<span className="pickBadge">
																	{t('hushPick.select')}
																</span>
															</div>
															<a
																onClick={(e) => {
																	e.preventDefault();
																	fnValidateOrder(fnGetRecommendUsers, {
																		productId:
																			recommendProducts[item.productIndex].id,
																		matchType: item.matchType,
																		jobCode: item.jobCode,
																	});
																}}
															>
																<span className="fz14 bold">
																	{t('hushPick.recommend')}
																</span>
																<span className="ic-berith">
																	{recommendProducts[item.productIndex]?.price}
																</span>
															</a>
														</div>
													);
												})
											}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HushPickContainer;
