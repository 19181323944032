/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPreferUserCIVO } from '../models/AddPreferUserCIVO';
import type { HomeMatchingCOVO } from '../models/HomeMatchingCOVO';
import type { HomePastUserCOVO } from '../models/HomePastUserCOVO';
import type { MatchTypeAndCodeCIVO } from '../models/MatchTypeAndCodeCIVO';
import type { RandomReviewUserCOVO } from '../models/RandomReviewUserCOVO';
import type { RandomUserOrderInfoCIVO } from '../models/RandomUserOrderInfoCIVO';
import type { RandomUserScoreCIVO } from '../models/RandomUserScoreCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HomeService {
    /**
     * 별점평가(매력평가) 5명 조회
     * @returns RandomReviewUserCOVO
     * @throws ApiError
     */
    public static getRandomUser(): CancelablePromise<Array<RandomReviewUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getRandomUser',
        });
    }
    /**
     * 별점평가(매력평가) 5명 추가 조회 시 order validation
     * @param requestBody 주문 정보
     * @returns boolean
     * @throws ApiError
     */
    public static addRandomUser(
        requestBody: RandomUserOrderInfoCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 별점평가(매력평가) 평가하기 1명
     * @param requestBody 평가할 회원 uid 및 점수
     * @returns boolean
     * @throws ApiError
     */
    public static saveScoreRandomUser(
        requestBody: RandomUserScoreCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/saveScoreRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 조건별 이성 추천(추천받기) 1명
     * @param requestBody 매칭 타입 및 코드
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getRecommendUser(
        requestBody: MatchTypeAndCodeCIVO,
    ): CancelablePromise<HomeMatchingCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/getRecommendUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 이상형 추천(Today) 3명 조회
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getPreferUser(): CancelablePromise<Array<HomeMatchingCOVO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/getPreferUser',
        });
    }
    /**
     * 이상형 추천(Today) 1명 추가 조회 시 order validation
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static addPreferUser(
        requestBody: AddPreferUserCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addPreferUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 조건별 이성 추천(추천받기) 이력 조회
     * @returns HomePastUserCOVO
     * @throws ApiError
     */
    public static getPastRecommendUsers(): CancelablePromise<Array<HomePastUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastRecommendUsers',
        });
    }
}
