import { Link } from 'react-router-dom';
import { ReceiveLikeUserCOVO } from '@client/site/api';
import { MutableRefObject } from 'react';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

interface props {
	receivedLikeIndex: MutableRefObject<number>;
	likeUsers: ReceiveLikeUserCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (UserProfileOpen: string) => void;
	fnRemoveReceiveUserLike: (id: string, type: string) => void;
	fnSendLikePopOpen: (
		userUid: string,
		profileUrl: string,
		nickName: string,
		location:
			| ''
			| 'hushPick'
			| 'today'
			| 'receive'
			| 'highRated'
			| 'send'
			| 'profile',
	) => void;
	setMessageTargetUser: (messageTargetUser: string) => void;
}

const ReceivedLikeListView = ({
	receivedLikeIndex,
	likeUsers,
	srcPrefix,
	setUserProfileOpen,
	fnRemoveReceiveUserLike,
	fnSendLikePopOpen,
	setMessageTargetUser,
}: props) => {
	const { t } = useTranslation('like');
	return (
		<div
			className={`likeProfileList ${
				likeUsers.length === 0 ? 'likeProfileList-empty' : ''
			}`}
		>
			{likeUsers.map((item, i) => {
				return (
					<div className="profileType" key={i}>
						<div className="proLeft">
							<div className="proImg">
								<Link
									to={PATH.PROFILE.USER_PROFILE}
									onClick={() => {
										setUserProfileOpen(item.likeUserUid);
									}}
									state={{
										isOtherProfile: true,
									}}
								>
									<img src={srcPrefix + item.profileUrl} />
								</Link>
							</div>
							<div className="proName margin-l12">
								<p className="bold padding-b4">{item.nickName}</p>
								<p className="fz14 color-666">
									{item.age +
										t('common.age') +
										', ' +
										(item.region1 && item.region2
											? `${item.region1} ${item.region2}`
											: t('common.noRegion'))}
								</p>
							</div>
						</div>
						<div className="ProRight">
							<button
								type="button"
								className="likeBtn fixOn"
								onClick={() => {
									if (item.likeUserUid != '') {
										receivedLikeIndex.current = i;
										fnSendLikePopOpen(
											item.likeUserUid,
											item.profileUrl,
											item.nickName,
											'receive',
										);
										setMessageTargetUser(item.likeUserUid);
									}
								}}
							/>
							<button
								type="button"
								className="closeBtn2 margin-l5"
								onClick={() => {
									fnRemoveReceiveUserLike(item.likeViewId, 'received');
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ReceivedLikeListView;
