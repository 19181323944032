import { isCensored } from '@client/utils/censor';
import { useProfileAdapter } from '@client/site/dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMessageAdapter } from '@client/site/dating/adapter/message/useMessageAdapter';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useCommonAlertPopService } from '@client/site/dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useTranslation } from 'react-i18next';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useCommonDMService } from '@virtual-space/ui/components/dm/service/useCommonDMService';
import {
	NavigationState,
	GroupedMessage,
} from '@virtual-space/ui/components/dm/types/navigationTypes';
import { useAppFooterService } from '@virtual-space/ui/components/footer/service/useAppFooterService';
import {
	IUser,
	IDirectMessage,
} from '../../../../../../../../../types/IDirectMessageState';
import { formatTime } from '@virtual-space/utils/messageUtils';

export const useMyManagerOfflineDetailService = () => {
	const { t } = useTranslation('messenger');
	const {
		openBlock,
		groupedMessages,
		focused,
		openDirectMessageDetail,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		messagesEndRef,
		setOpenReport,
		setOpenBlock,
		modifyMenuPop,
		setOpenLeaveChat,
	} = useCommonDMService();
	const { saveMessage, leaveMessageRoom, updateMessageReadStatus } =
		useMessageAdapter();
	const { setMessageInputValue, setShowDirectMessageList } =
		useDirectMessageRepo();
	const location = useLocation();
	const navigate = useNavigate();
	const { fnCommonAlertPopOn } = useCommonAlertPopService();
	const { handleMessageSendClick } = useAppFooterService();
	const locationState = location.state;
	const {
		myProfile,
		userProfile,
		srcPrefix,
		userProfileOpen,
		setUserProfileOpen,
		setUserProfile,
	} = useDatingRepo();

	useEffect(() => {
		setShowDirectMessageList(false);
	}, []);

	const { getUserProfile } = useProfileAdapter();
	useEffect(() => {
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {});
		}
	}, []);

	const navigationRoom = useMemo(() => {
		const baseNavigationState: NavigationState = {
			billingStatus: 0,
			dmRoomType: '',
			roomId: '',
			visibleTo: 0,
			isUserLeft: false,
			isOtherUserLeft: false,
			user1Info: {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				role: '',
				inActive: false,
				isDeleted: false,
				blockType: '',
			},
			otherUserInfo: {
				userUid: '',
				nickName: '',
				profileUrl: '',
				region1: '',
				region2: '',
				age: '',
				role: '',
				inActive: false,
				isDeleted: false,
				blockType: '',
			},
			createdAt: '',
			orderId: 0,
			useStatus: '',
			matchedAt: null,
		};

		// MessageListView에서 온 state 확인
		if (locationState?.messageListState) {
			return {
				...baseNavigationState,
				...locationState.messageListState,
			};
		}

		// 중첩된 navigationState 처리
		if (locationState?.navigationState?.navigationState) {
			return {
				...baseNavigationState,
				...locationState.navigationState.navigationState,
			};
		}

		// 단일 navigationState 처리
		if (locationState?.navigationState) {
			return {
				...baseNavigationState,
				...locationState.navigationState,
			};
		}

		// 기본 state 확인
		if (locationState) {
			return {
				...baseNavigationState,
				...locationState,
			};
		}

		return baseNavigationState;
	}, [locationState]);

	const roomData = useMemo(() => {
		if (!navigationRoom?.roomId) return null;

		const found = directMessageList.find(
			(room) => room.roomId === navigationRoom.roomId,
		);

		if (found) {
			return {
				...found,
				billingStatus: found.billingStatus ?? navigationRoom.billingStatus,
				dmRoomType: found.dmRoomType || navigationRoom.dmRoomType,
				visibleTo: found.visibleTo ?? navigationRoom.visibleTo,
				isUserLeft: found.isUserLeft ?? navigationRoom.isUserLeft,
				isOtherUserLeft:
					found.isOtherUserLeft ?? navigationRoom.isOtherUserLeft,
				useStatus: found.useStatus ?? navigationRoom.useStatus,
				matchedAt: found.matchedAt ?? navigationRoom.matchedAt,
			};
		}

		return navigationRoom;
	}, [directMessageList, navigationRoom]);

	const currentRoomMessages = useMemo(() => {
		const messages = roomData?.messages
			? [...roomData.messages].sort(
					(a, b) => parseInt(a.createdAt) - parseInt(b.createdAt),
			  )
			: [];

		// 메시지 내용 변환
		return messages.map((msg) => {
			return msg;
		});
	}, [roomData]);

	const getLatestMessageId = useCallback(() => {
		if (!currentRoomMessages || currentRoomMessages.length === 0) {
			return 1; // 첫 메시지인 경우
		}

		// messageId가 있는 메시지만 필터링하고 최대값 찾기
		const messageIds = currentRoomMessages
			.filter((message) => message?.messageId != null)
			.map((message) => parseInt(message.messageId.toString()));

		if (messageIds.length === 0) {
			return 1;
		}

		return Math.max(...messageIds) + 1;
	}, [currentRoomMessages]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessageInputValue(event.target.value);
	};

	const handleSubmit = async (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();

		// 금칙어 체크
		const hasCensoredWord = isCensored(messageInputValue.replace(/\s/g, ''));
		const trimmedValue = messageInputValue.trim();
		setMessageInputValue('');

		if (hasCensoredWord) {
			fnCommonAlertPopOn(t('forbiddenWord'));
			setMessageInputValue('');
			return;
		}

		if (
			!trimmedValue ||
			!myProfile?.userUid ||
			!navigationRoom?.otherUserInfo?.userUid
		) {
			return;
		}
		const checkUserInRoom = () => {
			return new Promise<boolean>((resolve) => {
				// 상태 응답을 받을 이벤트 핸들러
				const handleRoomStatus = (status: {
					targetUid: string;
					isInRoom: boolean;
				}) => {
					if (status.targetUid === navigationRoom.otherUserInfo.userUid) {
						resolve(status.isInRoom);
						// 이벤트 리스너 제거
						dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					}
				};

				// USER_IN_ROOM_STATUS 이벤트 리스너 등록
				dmEventEmitter.on('react-user-in-room-status', handleRoomStatus);

				// 상태 확인 요청
				dmEventEmitter.emit('react-check-user-in-room', {
					targetUid: navigationRoom.otherUserInfo.userUid,
					roomId: navigationRoom.roomId,
				});

				// 타임아웃 설정 (3초)
				setTimeout(() => {
					dmEventEmitter.off('react-user-in-room-status', handleRoomStatus);
					resolve(false);
				}, 3000);
			});
		};

		// 상대방의 채팅방 상태를 기다림
		const isInRoom = await checkUserInRoom();

		// 송신자 정보
		const sender: Partial<IUser> = {
			userUid: myProfile.userUid,
			nickName: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			region1: myProfile?.region1,
			region2: myProfile?.region2,
			age: myProfile.age,
		};

		// 수신자 정보
		const receiver: Partial<IUser> = {
			userUid: navigationRoom.otherUserInfo.userUid,
			nickName: navigationRoom.otherUserInfo.nickName,
			profileUrl: navigationRoom.otherUserInfo.profileUrl,
			region1: navigationRoom.otherUserInfo.region1,
			region2: navigationRoom.otherUserInfo.region2,
			age: navigationRoom.otherUserInfo.age,
		};

		// 메시지 전송
		const roomData = {
			roomId: navigationRoom.roomId,
			messageId: getLatestMessageId(),
			sender,
			receiver,
			content: trimmedValue,
			createdAt: Date.now().toString(),
			read: isInRoom,
		};
		dmEventEmitter.emit('react-send-direct-message', roomData);

		// 메시지 저장
		saveMessage({
			roomId: navigationRoom.roomId,
			content: trimmedValue,
			read: isInRoom,
			createdAt: Date.now().toString(),
		});

		// 첫 메시지일 경우 상태 업데이트
		if (currentRoomMessages.length === 0) {
			dmEventEmitter.emit('react-change-dm-use-status', {
				roomId: navigationRoom.roomId,
				useStatus: 'USING',
				matchedAt: navigationRoom.matchedAt,
			});
		}
	};

	const handleLeaveChat = () => {
		dmEventEmitter.emit('react-leave-direct-message', {
			roomId: navigationRoom.roomId,
			leaverId: myProfile?.userUid,
		});
		dmEventEmitter.emit('react-change-dm-use-status', {
			roomId: navigationRoom.roomId,
			useStatus: 'DONE',
			matchedAt: navigationRoom.matchedAt,
		});

		leaveMessageRoom(navigationRoom.roomId);
		navigate(PATH.MESSENGER.MANAGER_OFFLINE, {
			replace: true, // 히스토리 스택을 교체
		});
		setOpenLeaveChat(false);
		handleMessageSendClick();
	};

	const handleExitChat = () => {
		dmEventEmitter.emit('react-exit-direct-message', {
			roomId: navigationRoom.roomId,
			exit: true,
		});
		dmEventEmitter.emit('react-change-dm-use-status', {
			roomId: navigationRoom.roomId,
			useStatus: 'DONE',
			matchedAt: navigationRoom.matchedAt,
		});

		leaveMessageRoom(navigationRoom.roomId);
		navigate(PATH.MESSENGER.MANAGER_OFFLINE, {
			replace: true, // 히스토리 스택을 교체
		});
		setOpenLeaveChat(false);
		handleMessageSendClick();
	};

	const processGroupedMessagesDetail = useCallback(() => {
		const result = {
			my: [] as GroupedMessage[],
			other: [] as GroupedMessage[],
		};

		let lastGroupedMessage: GroupedMessage | undefined;

		// 메시지를 시간 순으로 정렬
		const sortedMessages = [...currentRoomMessages].sort((a, b) => {
			return parseInt(a.createdAt) - parseInt(b.createdAt);
		});

		sortedMessages.forEach((message: IDirectMessage) => {
			const isMyMessage = message.sender?.userUid === myProfile?.userUid;
			const groupKey = isMyMessage ? 'my' : 'other';
			const time = formatTime(message.createdAt);

			// 읽음 처리 수정
			if (
				message.receiver?.userUid === myProfile?.userUid &&
				openDirectMessageDetail &&
				myProfile?.userUid &&
				!message.read // 읽지 않은 메시지만 처리
			) {
				try {
					// 메시지 업데이트 전 읽음 상태 먼저 업데이트
					updateMessageReadStatus({
						roomId: navigationRoom.roomId,
						read: true,
					});

					// 이벤트 발생
					dmEventEmitter.emit('react-read-direct-message', {
						roomId: navigationRoom.roomId,
						messageId: message.messageId,
						userUid: myProfile.userUid,
					});
				} catch (error) {
					console.error('Error updating read status:', error);
				}
			}

			// 메시지 그룹화
			if (
				lastGroupedMessage &&
				lastGroupedMessage.time === time &&
				lastGroupedMessage.isMyMessage === isMyMessage
			) {
				lastGroupedMessage.messages.push(message);
			} else {
				lastGroupedMessage = {
					isMyMessage,
					time,
					messages: [message],
					visibleTo: roomData?.visibleTo || 0,
				};
				result[groupKey].push(lastGroupedMessage);
			}
		});

		return result;
	}, [
		currentRoomMessages,
		myProfile?.userUid,
		openDirectMessageDetail,
		navigationRoom.roomId,
		roomData?.visibleTo,
	]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
	};

	// 메시지 상세 정보
	const groupedMessagesDetail = useMemo(() => {
		return processGroupedMessagesDetail();
	}, [processGroupedMessagesDetail]);

	// 스크롤 처리
	useEffect(() => {
		if (
			groupedMessagesDetail.my.length > 0 ||
			groupedMessagesDetail.other.length > 0
		) {
			scrollToBottom();
		}
	}, [groupedMessagesDetail]);

	const shouldExitChat = useMemo(() => {
		return roomData?.visibleTo === 1 || roomData?.visibleTo === 2;
	}, [roomData?.visibleTo]);

	const isOtherUserLeft = useMemo(() => {
		const isUser1 = roomData?.user1Id === myProfile.userUid;
		const currentVisibleTo = roomData?.visibleTo;
		return (
			(isUser1 && currentVisibleTo === 2) ||
			(!isUser1 && currentVisibleTo === 1)
		);
	}, [
		groupedMessagesDetail,
		roomData?.user1Id,
		myProfile.userUid,
		roomData?.visibleTo,
	]);

	const isUseStatusDone = useMemo(() => {
		return roomData?.useStatus === 'DONE';
	}, [roomData?.useStatus]);

	useEffect(() => {
		scrollToBottom();
		inputRef.current?.focus();
	}, [groupedMessages]);

	const matchingTime = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		return currentRoom ? currentRoom.createdAt : null;
	}, [roomData]);

	const matchedAt = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		return currentRoom ? currentRoom.matchedAt : null;
	}, [roomData]);

	const currentMessageLength = useMemo(() => {
		return currentRoomMessages.length;
	}, [currentRoomMessages, navigationRoom]);
	return {
		userProfileOpen,
		isOtherUserLeft,
		navigationRoom,
		groupedMessagesDetail,
		setUserProfileOpen,
		setOpenReport,
		setOpenLeaveChat,
		setOpenBlock,
		modifyMenuPop,
		handleSubmit,
		handleLeaveChat,
		handleChange,
		handleExitChat,
		shouldExitChat,
		focused,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		openBlock,
		userProfile,
		srcPrefix,
		messagesEndRef,
		myProfile,
		matchingTime,
		currentMessageLength,
		roomData,
		isUseStatusDone,
		matchedAt,
		handleMessageSendClick,
	};
};
