import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useManagerMyProfileService } from '@dating/ui/pages/myProfile/useManagerMyProfileService';
import { bridgeLogoutData } from '@client/utils/bridgeCallHandler';
import EditNicknamePopView from '@dating/ui/pages/profile/editPop/EditNicknamePopView';
import EditIntroducePopView from '@dating/ui/pages/profile/editPop/EditIntroducePopView';
import { useTranslation } from 'react-i18next';

export const ManagerMyProfileContainer = () => {
	const {
		srcPrefix,
		myProfile,
		fnManagerLogout,
		responseLogout,
		userSettings,
		authToken,
		initManagerData,
		openPopupHandler,
		editPop,
		setEditPop,
		focusItem,
		nicknameValue,
		introduceValue,
		setIntroduceValue,
		fnModifyProfileNickname,
		closeEditPopup,
		fnModifyProfileIntroduce,
		handleInputChange,
		isValueEntered,
		lengthOver,
		isAvailable,
		nickError,
		settingsSaveHandler,
	} = useManagerMyProfileService();

	const { fnCommonConfirmPopOn, fnCommonConfirmPopOff } =
		useCommonConfirmPopService();

	const navigate = useNavigate();
	const { t } = useTranslation('profile');

	useEffect(() => {
		if (responseLogout) {
			// 로그아웃 확인 팝업 닫기
			fnCommonConfirmPopOff();

			if (!(window as any).flutter_inappwebview) {
				// 웹
				initManagerData();
			} else {
				// 앱
				bridgeLogoutData().then((value) => {
					if (value) {
						initManagerData();
					}
				});
			}
		}
	}, [responseLogout]);

	useEffect(() => {
		if (authToken.refreshJmt === '' && authToken.accessJmt === '') {
			navigate(PATH.REGISTER.REGISTER);
			window.location.reload();
		}
	}, [authToken]);

	return (
		<>
			{/*닉네임*/}
			<CSSTransition
				in={editPop.nickName}
				classNames="popWrap-transition"
				timeout={100}
			>
				<EditNicknamePopView
					focusItem={focusItem}
					nicknameValue={nicknameValue}
					fnModifyProfileNickname={fnModifyProfileNickname}
					closeEditPopup={closeEditPopup}
					handleInputChange={handleInputChange}
					lengthOver={lengthOver}
					isAvailable={isAvailable}
					isValueEntered={isValueEntered}
					nickError={nickError}
				/>
			</CSSTransition>

			{/*자기소개*/}
			<CSSTransition
				in={editPop.introduce}
				classNames="popWrap-transition"
				timeout={100}
			>
				<EditIntroducePopView
					focusItem={focusItem}
					editPop={editPop}
					setEditPop={setEditPop}
					introduceValue={introduceValue}
					setIntroduceValue={setIntroduceValue}
					fnModifyProfileIntroduce={fnModifyProfileIntroduce}
					closeEditPopup={closeEditPopup}
				/>
			</CSSTransition>

			<div className="wrap profileWrap footerNone">
				<header>
					<div className="headerWrap">
						<div className="h-left">
							<button
								type="button"
								className="btnBack"
								onClick={() => {
									navigate(-1);
								}}
							>
								<span className="hide">
									{t('goBack')}
									{/* 뒤로가기 */}
								</span>
							</button>
						</div>
						<div className="h-center">
							<p className="title02">
								{t('myPage')}
								{/* 마이페이지 */}
							</p>
						</div>
						<div className="h-right"></div>
					</div>
				</header>

				<div id="conWrap">
					<div className="myProfileTop">
						<div className="inner">
							<div className="profileBigImg">
								<img
									src={srcPrefix + myProfile.profileUrl[0]}
									alt={myProfile.nickName}
								/>
								{/*<a href="../inc/M_Img_Edit.html" className="myprofileCam"></a>*/}
								<Link
									to={PATH.PROFILE.EDIT_PROFILE_IMG}
									className="myprofileCam"
								/>
							</div>

							<div className="profileTxtBox text-c">
								<p className="txt01 fz16 bold">{myProfile.nickName}</p>
								<p className="txt02 fz14 color-999">
									{t('coupleManager')}
									{/* 커플 매니저 */}
								</p>
							</div>
						</div>
					</div>

					<div className="myProfileInfo mgProfileBox">
						<div className="stepLastBox">
							<ul className="infoList">
								<li className="ic-user on">
									<span className="fz14">
										{t('nickname')}
										{/* 닉네임 */}
									</span>
									<span
										className="fz16Fw500"
										onClick={() => {
											openPopupHandler('nickName', myProfile.nickName);
										}}
									>
										{myProfile.nickName}
									</span>
								</li>

								<li className="ic-pen on">
									{t('selfIntroduction')}
									{/* 자기소개 */}
								</li>
								<li
									className="letter fz16"
									onClick={() => {
										openPopupHandler('introduce', myProfile.introduce);
									}}
								>
									{myProfile.introduce
										? myProfile.introduce
										: t('registerSelfIntroduction')}
									{/* 자기소개를 등록해 주세요 */}
								</li>
							</ul>
						</div>
						<div className="inner">
							<div className="settingList padding-t20">
								<p className="title02 padding-b16">
									{t('notifications')}
									{/* 알림 */}
								</p>
								<div className="leftRightTxt">
									<span className="fz16Fw500">
										{t('connectedManager')}
										{/* 매니저 연결됨 */}
									</span>
									<input
										type="checkbox"
										className="switch"
										checked={
											userSettings?.connectedManagerYn
												? userSettings?.connectedManagerYn
												: false
										}
										onChange={() => {
											settingsSaveHandler(
												'ConnectedManager',
												!userSettings?.connectedManagerYn,
											);
										}}
									/>
								</div>
								<div className="leftRightTxt">
									<span className="fz16Fw500">
										{t('managerChatMessages')}
										{/* 매니저 대화 메시지 */}
									</span>
									<input
										type="checkbox"
										className="switch"
										checked={
											userSettings?.chatMessageYn
												? userSettings?.chatMessageYn
												: false
										}
										onChange={() => {
											settingsSaveHandler(
												'chatMessage',
												!userSettings?.chatMessageYn,
											);
										}}
									/>
								</div>
							</div>
							<div className="settingList padding-t16">
								<p className="title02 padding-b16">
									{t('accountSettings')}
									{/* 계정설정 */}
								</p>
								<div className="leftRightTxt">
									<span>
										{t('phoneNumber')}
										{/* 휴대전화번호 */}
									</span>
									<button type="button" className="color-999">
										{myProfile?.phoneNumber}
									</button>
								</div>
								<div className="leftRightTxt">
									<span>
										{t('snsIntegration')}
										{/* SNS 계정 연동 */}
									</span>
									<button type="button" className="color-999">
										{userSettings?.snsConnect}
									</button>
								</div>
								<div className="leftRightTxt">
									<button
										type="button"
										className="bold-500 popOnBtn"
										onClick={() => {
											{
												/* 로그아웃 하시겠습니까? */
											}
											fnCommonConfirmPopOn(
												t('confirmLogout'),
												fnManagerLogout,
												null,
												null,
												null,
											);
										}}
									>
										{t('logout')}
										{/* 로그아웃 */}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
