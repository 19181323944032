import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import {
	getOtherPlayerCharacter,
	getOtherPlayerCharacterFile,
	removeAfterUnderscore,
} from '@virtual-space/utils/avatarUtils';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useEffect, useState } from 'react';
import { useProfileAdapter } from '@client/site/dating/adapter/profile/useProfileAdapter';
import { useMetaUserAdapter } from '@client/site/dating/adapter/meta/useMetaUserAdapter';
import { useSettingAdapter } from '@client/site/dating/adapter/setting/useSettingAdapter';

export const useAvatarProfileService = () => {
	const {
		myMetaUser,
		metaUser,
		openOtherPlayerProfile,
		setMyMetaUserOpen,
		setOtherMetaUser,
		setMetaUser,
		setOpenOtherPlayerProfile,
		otherMetaUser,
	} = useMetaUserRepo();
	const { userProfileOpen, setUserProfile, setUserProfileOpen } =
		useDatingRepo();
	const { getUserProfile } = useProfileAdapter();
	useEffect(() => {
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {});
		}
	}, [userProfileOpen]);

	// 차단 상태를 관리할 state 추가
	const [blockStatus, setBlockStatus] = useState('');

	const { getWhoBlock } = useSettingAdapter();
	const { getMetaUser } = useMetaUserAdapter();
	const otherPlayerAnim = metaUser.avatarName;
	const remove_idle = removeAfterUnderscore(otherPlayerAnim);
	const characterData = getOtherPlayerCharacter(remove_idle);
	const getCharacterFile = getOtherPlayerCharacterFile(characterData);

	const handleInitAvatarProfile = () => {
		setOtherMetaUser('');
		setOpenOtherPlayerProfile(false, null);
		setMetaUser({
			userUid: '',
			nickName: '',
			introduce: '',
			gender: '',
			profileUrl: '',
			avatarName: '',
			readyToConnect: false,
			mediaConnected: false,
			role: '',
		});
		setMyMetaUserOpen(false);
		setUserProfileOpen('');
	};

	useEffect(() => {
		const loadOtherPlayerProfile = async () => {
			try {
				if (otherMetaUser) {
					const blockResult = await getWhoBlock(otherMetaUser);
					setBlockStatus(blockResult);
					const otherPlayerData = await getMetaUser(otherMetaUser);
					if (otherPlayerData) {
						setMetaUser(otherPlayerData);
					}
				}
			} catch (error) {
				console.error('다른 플레이어 프로필 로드 중 오류 발생:', error);
			}
		};

		loadOtherPlayerProfile();
	}, [openOtherPlayerProfile?.isOpen]);

	return {
		blockResult: blockStatus,
		myMetaUser,
		metaUser,
		getCharacterFile,
		openOtherPlayerProfile,
		handleInitAvatarProfile,
		setOpenOtherPlayerProfile,
		setMetaUser,
	};
};
