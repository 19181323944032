import { useEffect, useState } from 'react';
import {
	CommentCIVO,
	DeleteCommentCIVO,
	PostIdCIVO,
	PostInfoCOVO,
	PostLikeCIVO,
} from '@client/site/api';
import { useQuery } from '@tanstack/react-query';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useMyFeedListStateRepo } from '@dating/ui/pages/lounge/myFeed/useMyFeedListStateRepo';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import {useProfileAdapter} from "@dating/adapter/profile/useProfileAdapter";

export const useMyFeedListService = () => {
	const { isClickedFeedMenu, setIsClickedFeedMenu, postUser, setPostUser } =
		useMyFeedListStateRepo();

	const {
		setModifyPostId,
		feedEditPops,
		setFeedEditPops,
		feedCommentOpen,
		setFeedCommentOpen,
		openComment,
		setOpenComment,
		commentState,
		setCommentState,
		commentValue,
		setCommentValue,
		focusedPostId,
		setFocusedPostId,
		commentResponse,
		setCommentResponse,
		myPosts,
		setMyPosts,
	} = useLoungeRepo();

	const { srcPrefix, myProfileOpen, sliderSettings } = useDatingRepo();

	const {
		getComment,
		deleteComment,
		removePost,
		saveComment,
	} = useLoungeAdapter();

	const {
		getMyPosts,
	} = useProfileAdapter();

	const { savePostLike, statusSavePostLike, dataSavePostLike } =
		useLikeAdapter();

	const { status: statusGetMyPosts, data: dataGetMyPosts } = useQuery({
		queryKey: ['getMyPosts'],
		queryFn: () => {
			return getMyPosts();
		},
	});
	////////////////////////////////////////////////////////////////////
	/*
	 * saveComment 하면, 창닫기 처리 => 댓글 그대로 추가해서 보여주는 처리로 변경하였음
	 * */
	/*
	useEffect(() => {
		if (statusSaveComment === 'success' && dataSaveComment) {
			commentViewHandler(false);

			// 댓글입력 input 초기화
			setCommentValue('');
		}
	}, [statusSaveComment, dataSaveComment]);
	* */

	/*
	 * commentViewHandler
	 * 댓글창 오픈 시 container로 상태값 전달, wrap에 no-scroll 클래스 추가
	 */
	const commentViewHandler = (state: boolean) => {
		// 댓글창 열기
		setOpenComment(state);

		// wrap에 no-scroll class 추가
		setCommentState(state);

		// 댓글창 활성화 시 footer menu z-index 조정
		setFeedCommentOpen(state);

		setCommentValue(''); // 댓글입력 input 초기화
	};

	/*
	 * commentListHandler
	 * postId : 선택한 postId로 commentList handle
	 * menuType : profile-댓글 모두 삭제 가능, lounge-내 댓글만 삭제 가능
	 */
	function commentListHandler(
		postId: string,
		postUser: { userUid: string; nickname: string },
	) {
		// 클릭한 postId repo에 저장
		setFocusedPostId(postId);

		getComment(postId, 'profile')
			.then((value) => {
				if (postId != '') {
					// 댓글리스트 repo에 저장
					setCommentResponse(value);

					// 작성자 닉네임 저장
					setPostUser(postUser);
				} else {
					setCommentResponse([]);
				}
			})
			.catch((reason) => {});
	}

	/*
	 * fnSavePostLike
	 * 게시글 좋아요
	 */
	const fnSavePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		savePostLike(map);
	};

	useEffect(() => {}, [statusSavePostLike, dataSavePostLike]);

	useEffect(() => {
		if (statusGetMyPosts === 'success' && dataGetMyPosts && myProfileOpen) {
			setMyPosts(dataGetMyPosts);
			// 케밥 control list set
			initFeedEditPops(dataGetMyPosts);
		}
	}, [statusGetMyPosts, dataGetMyPosts]);

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feeds: PostInfoCOVO[]) => {
		let newItem = {};
		feeds.map((feed, i) => {
			newItem[feed.postId] = false;
		});
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		initFeedEditPops(myPosts);
		setIsClickedFeedMenu({ postId: postId, state: true });
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);

	/*
	 * fnDeleteComment
	 * 댓글 삭제
	 */
	const fnDeleteComment = (param: { postId: string; commentId: number }) => {
		const map: DeleteCommentCIVO = {
			postId: param.postId,
			commentId: param.commentId,
		};
		deleteComment(map)
			.then((value) => {
				if (value) {
					getComment(param.postId, 'profile').then((response) => {
						if (response && response.length > 0) {
							// 댓글리스트 repo에 저장
							setCommentResponse(response);
						} else {
							setCommentResponse([]);
						}

						// 작성자 닉네임 저장
						setPostUser(postUser);
					});
				}
			})
			.catch((reason) => {});
	};

	/*
	 * fnRemovePost
	 * 피드삭제
	 */
	const fnRemovePost = (id: string) => {
		const map: PostIdCIVO = {
			postId: id.toString(),
		};
		removePost(map);
	};

	/*
	 * fnSaveComment
	 * 댓글 등록
	 */
	const fnSaveComment = (id: string, comment: string) => {
		if (comment != null) {
			let commentObject: CommentCIVO = {
				postId: id,
				comment: comment,
			};
			saveComment(commentObject);
		}
	};

	return {
		myPosts,
		srcPrefix,
		sliderSettings,
		fnSavePostLike,
		initFeedEditPops,
		fnFeedEditHandler,
		isClickedFeedMenu,
		commentValue,
		openComment,
		commentState,
		commentViewHandler,
		commentListHandler,
		fnDeleteComment,
		fnRemovePost,
		fnSaveComment,
		setMyPosts,
		setIsClickedFeedMenu,
		setCommentValue,
		setOpenComment,
		setCommentState,
		focusedPostId,
		setFocusedPostId,
		commentResponse,
		setCommentResponse,
		postUser,
		setPostUser,
	};
};
