import {CSSTransition} from 'react-transition-group';
import {useTranslation} from "react-i18next";

interface props {
    focusItem: { label: string, value: string | string[] };
    drinkingValue: string;
    setDrinkingValue: (drinkingValue: string) => void;
    fnModifyProfileDrink: () => void;
    closeEditPopup: (key: string) => void;
    openPop: boolean;
    drinkingList: string[] | { id: string, styleValue: string }[];
}

const EditDrinkingPopView = ({
                                 focusItem,
                                 drinkingValue,
                                 setDrinkingValue,
                                 fnModifyProfileDrink,
                                 closeEditPopup,
                                 openPop,
                                 drinkingList,
                             }: props) => {

    const {t} = useTranslation('register');

    return (
        <div className="listAddPop" id="lifestyle">
            <div className="listAddDim" onClick={() => {
                fnModifyProfileDrink();
                closeEditPopup(focusItem.label);
            }}/>
            <CSSTransition
                in={openPop}
                classNames="listAddInfo-transition"
                timeout={100}
            >
                <div className="listAddInfo">
                    <div className="listAddTit">
                        <span className="cateTit01 life">{t('idealDetail.drink')}</span>
                        <p className="titleSub01">{t('idealDetail.selectDrink')}</p>
                    </div>
                    <div className="listAdd">
                        <li className="padding-0 on">
                            <div className="myCateList">
                                {
                                    drinkingList.length > 0 ?
                                        drinkingList.map(item => {
                                            return (
                                                <button
                                                    type="button"
                                                    key={item.id.toString()}
                                                    className={
                                                        item.id.toString() === drinkingValue ? "active" : ""
                                                    }
                                                    onClick={() => setDrinkingValue(item.id.toString())}
                                                >
                                                    {item.styleValue}
                                                </button>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </li>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
};

export default EditDrinkingPopView;