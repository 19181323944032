import { useOfflineManagerListService } from '../service/useOfflineManagerListService';
import { useTranslation } from 'react-i18next';
import ManageMessageListItemView from '../../../views/ManageMessageListItemView';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';

const OfflineManagerListView = () => {
	const { t } = useTranslation('messenger');
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { myProfile, srcPrefix, setUserProfileOpen, sortedMessages } =
		useOfflineManagerListService();

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map((message) =>
					message.visibleTo !== -1 ? (
						<ManageMessageListItemView
							key={message.roomId}
							message={message}
							myProfile={myProfile}
							srcPrefix={srcPrefix}
							setUserProfileOpen={setUserProfileOpen}
							setOpenDirectMessageDetail={setOpenDirectMessageDetail}
							isOffline={true}
						/>
					) : null,
				)
			) : (
				<div className="dataNoneTxt">
					<p className="txt01">{t('noMsg')}</p>
				</div>
			)}
		</div>
	);
};

export default OfflineManagerListView;
