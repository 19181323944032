import { Key } from 'react';

const MenListView = ({ headCount, players, srcPrefix }) => {
	const maxMenCount = headCount === 4 ? 2 : headCount === 3 ? 1 : 3;
	const menPlayers = players.filter(
		(player: { gender: string; role: string }) =>
			player.gender === '남성' && player.role === 'NORMAL',
	);
	return (
		<div className="menList">
			{menPlayers.map(
				(profile: {
					uuid: Key | null | undefined;
					profileUrl: any;
					name: string;
				}) => (
					<div key={profile.uuid}>
						<img src={srcPrefix + profile.profileUrl} alt={profile.name} />
					</div>
				),
			)}

			{[...Array(maxMenCount - menPlayers.length)].map((_, index) => (
				<div key={`empty-${index}`}></div>
			))}
		</div>
	);
};

export default MenListView;
