import MessengerRouter from '@virtual-space/routers/MessengerRouter';
import MetaverseLayout from '@virtual-space/MetaverseLayout';
import datingRoutes from '@dating/routers';
import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';
import { MetaverseProvider } from '@virtual-space/MetaverseProvider';
import ManagerRouter from '@virtual-space/routers/ManagerRouter';
import { SetBridgeProvider } from './SetBridgeContextProvider';
import i18n from 'i18n';
const Root = () => (
	<MetaverseProvider i18n={i18n}>
		<SetBridgeProvider>
			<Outlet />
		</SetBridgeProvider>
	</MetaverseProvider>
);
export const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				index: true,
				loader: () => redirect('metaverse/dating/register'),
			},
			{
				path: 'metaverse',
				element: <MetaverseLayout />,
				children: [
					{
						path: 'messenger/*',
						element: <MessengerRouter />,
					},
					{
						path: 'manager/*',
						element: <ManagerRouter />,
					},
					{
						path: 'dating/*',
						children: datingRoutes,
					},
				],
			},
		],
	},
	{
		path: '*',
		loader: () => redirect('/'),
	},
]);
