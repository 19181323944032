import { useProductsRepo } from '@client/site/dating/repository/products/useProductsRepo';
import { useOrderRepo } from '@client/site/dating/repository/order/useOrderRepo';
import { useManagerAdapter } from '@client/site/dating/adapter/manager/useManagerAdapter';
import { useEffect } from 'react';
import { formatDate } from '@virtual-space/utils/messageUtils';

export const usePurchaseHistoryService = () => {
	const { managerProducts } = useProductsRepo();

	const {
		myManagerProductHistory,
		setMyManagerProductHistory,
		managerHistory,
		setManagerHistory,
	} = useOrderRepo();

	const { getManagerUsageHistory } = useManagerAdapter();

	const PRODUCT_NAMES = {
		4: '오프라인 매니저 3회',
		5: '오프라인 매니저 5회',
		6: '오프라인 매니저 10회',
		7: '온라인 매니저 3회',
		8: '온라인 매니저 5회',
		9: '온라인 매니저 10회',
	};

	useEffect(() => {
		getManagerUsageHistory().then((value) => {
			if (value.length > 0) {
				setMyManagerProductHistory(value);
				const tempArray: any = [];
				console.log(value);
				value.map((item) => {
					const temp = {
						purchaseDate: formatDate(item.purchaseDate),
						availableCount: item.availableCount,
						price: item.price,
						productSubType: item.productSubType,
						productInfo: item.productInfo,
						productName: PRODUCT_NAMES[item.productId] || '상품 정보 없음',
						result: true,
					};
					tempArray.push(temp);
				});

				tempArray.length > 0
					? setManagerHistory(tempArray)
					: setManagerHistory([
							{
								purchaseDate: 0,
								availableCount: 0,
								price: 0,
								productSubType: '',
								productInfo: [],
								productName: '구매 정보가 없습니다.',
								result: false,
							},
					  ]);
			}
		});
	}, []);

	return {
		managerProducts,
		myManagerProductHistory,
		managerHistory,
	};
};
