import { IUser } from '../../../types/IDirectMessageState';
import { MyEventEmitter } from './MyEventEmitter';

export type REACT_GET_DM_ROOMS = void;
export type REACT_CREATE_DM_ROOM = {
	billingStatus: number;
	dmRoomType: string;
	roomId: string;
	user1Id: string;
	user2Id: string;
	user1Info: Partial<IUser>;
	user2Info: Partial<IUser>;
	createdAt: string;
	productType?: string;
	productSubType?: string;
	useStatus?: string;
	seq?: number;
	validUses?: number;
	orderId?: number;
	matchedAt: string | null;
};
export type REACT_CREATED_DM_ROOM = void;
export type REACT_SEND_DIRECT_MESSAGE = {
	roomId: string;
	messageId: number;
	sender: Partial<IUser>;
	receiver: Partial<IUser>;
	content: string;
	createdAt: string;
	read: boolean;
};
export type REACT_READ_DIRECT_MESSAGE = {
	roomId: string;
	messageId: number;
	userUid: string;
};
export type REQUEST_UNREAD_COUNT = {
	roomId: string;
};
export type REACT_LEAVE_DIRECT_MESSAGE = {
	roomId: string;
	leaverId: string;
};
export type REACT_EXIT_DIRECT_MESSAGE = {
	roomId: string;
	exit: boolean;
};
export type REACT_SINGLE_PAYMENT_COMPLETED = {
	roomId: string;
	payerId: string;
};
export type REACT_MUTUAL_PAYMENT_COMPLETED = {
	roomId: string;
	paid: boolean;
};
export type REACT_CHANGE_DIRECT_ROOM_TYPE = {
	roomId: string;
	dmRoomType: string;
};
export type REACT_CHANGE_DM_USE_STATUS = {
	roomId: string;
	useStatus: string;
	matchedAt: string | null;
};

export type REACT_BLOCK_USER = {
	roomId: string;
	blockType: string;
	blockerUuid: string;
	targetUid: string;
};
export type REACT_JOIN_OR_CREATE_DM_ROOM = {
	userUid: string;
	accessToken: string;
	langCode: string;
	nickName: string;
	profileUrl: string;
	region1: string;
	region2: string;
	age: string;
	role: string;
	inActive: boolean;
	isDeleted: boolean;
	blockType: string;
};
export type REACT_DM_ROOM_JOINED = void;
export type REACT_UPDATE_DM_PROFILE_URL = {
	userUid: string;
	profileUrl: string;
};
export type REACT_CHANGE_DM_INACTIVE = {
	userUid: string;
	inActive: boolean;
};
export type REACT_CHANGE_DM_IS_DELETED = {
	userUid: string;
	isDeleted: boolean;
};
export type REACT_RESET_ALL_UNREAD = void;
export type REACT_CHECK_USER_IN_ROOM = {
	targetUid: string;
	roomId: string;
};
export type REACT_USER_IN_ROOM_STATUS = {
	targetUid: string;
	isInRoom: boolean;
};
export type REACT_ENTER_DM_ROOM = {
	roomId: string;
};
export type REACT_LEAVE_DM_ROOM = void;

// DM Event Map
type DMEventMap = {
	['react-get-dm-rooms']: [args: REACT_GET_DM_ROOMS];
	['react-create-dm-room']: [args: REACT_CREATE_DM_ROOM];
	['react-created-dm-room']: [args: REACT_CREATED_DM_ROOM];
	['react-send-direct-message']: [args: REACT_SEND_DIRECT_MESSAGE];
	['react-read-direct-message']: [args: REACT_READ_DIRECT_MESSAGE];
	['react-request-unread-count']: [args: REQUEST_UNREAD_COUNT];
	['react-leave-direct-message']: [args: REACT_LEAVE_DIRECT_MESSAGE];
	['react-exit-direct-message']: [args: REACT_EXIT_DIRECT_MESSAGE];
	['react-single-payment-completed']: [args: REACT_SINGLE_PAYMENT_COMPLETED];
	['react-mutual-payment-completed']: [args: REACT_MUTUAL_PAYMENT_COMPLETED];
	['react-change-direct-room-type']: [args: REACT_CHANGE_DIRECT_ROOM_TYPE];
	['react-change-dm-use-status']: [args: REACT_CHANGE_DM_USE_STATUS];
	['react-block-user']: [args: REACT_BLOCK_USER];
	['react-join-or-create-dm-room']: [args: REACT_JOIN_OR_CREATE_DM_ROOM];
	['react-dm-room-joined']: [args: REACT_DM_ROOM_JOINED];
	['react-update-dm-profile-url']: [args: REACT_UPDATE_DM_PROFILE_URL];
	['react-change-dm-inactive']: [args: REACT_CHANGE_DM_INACTIVE];
	['react-change-dm-is-deleted']: [args: REACT_CHANGE_DM_IS_DELETED];
	['react-reset-all-unread']: [args: REACT_RESET_ALL_UNREAD];
	['react-check-user-in-room']: [args: REACT_CHECK_USER_IN_ROOM];
	['react-user-in-room-status']: [args: REACT_USER_IN_ROOM_STATUS];
	['react-enter-dm-room']: [args: REACT_ENTER_DM_ROOM];
	['react-leave-dm-room']: [args: REACT_LEAVE_DM_ROOM];
};

export const dmEventEmitter = new MyEventEmitter<DMEventMap>();
