import { useAuthAdpater } from '@dating/adapter/auth/useAuthAdpater';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useNavigate } from 'react-router-dom';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import {
	bridgeGetCurrencyCode,
	bridgeGetNotiData,
	bridgeGoogleLogin,
	bridgeShowToast,
} from '@client/utils/bridgeCallHandler';
import type { GoogleLoginCIVO } from '@client/site/api';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useOrderUtils } from '@client/utils/orderUtils';
import { detectDevice } from '@client/utils/deviceDetection';
import { goPushPage } from '@client/SetBridgeContextProvider';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useMessageNavigation } from '@virtual-space/ui/components/dm/service/useMessageNavigation';
import { useMetaverse } from '@virtual-space/hooks/useMetaverse';
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';
import Network from '@virtual-space/services/Network';
import { useOrderRepo } from '@dating/repository/order/useOrderRepo';
import { CurrencyCodeEnum } from '@dating/constants/product/currency-code.enum';
import { getCurrencyCode } from '@virtual-space/utils/util';
import useLocationInfoService from '@dating/ui/pages/register/locationInfo/service/useLocationInfoService';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";

export const useAuthService = () => {
	const {
		setDeviceId,
		setFcmToken,
		authToken,
		setAccessToken,
		setRefreshToken,
		setAuthToken,
		osType,
		setOsType,
		langCode,
		setLangCode,
		loginState,
		setLoginState,
	} = useAuthRepo();

	const { isIOS } = detectDevice();

	const { userSettings } = useSettingRepo();

	const { SNSInfo, setSNSInfo, setStep, registerSteps, setApproveAccount } =
		useRegisterRepo();

	const { setMyProfile, myProfile } = useDatingRepo();

	const { setSingleFeedId } = useLoungeRepo();

	const { currencyCode, setCurrencyCode } = useOrderRepo();

	const { oauthLoginInfos, autoLogin, deviceAutoLogin, googleLogin } =
		useAuthAdpater();

	const { getMyProfile } = useProfileAdapter();

	const { setMyMetaUser } = useMetaUserRepo();

	const { getMyMetaUser } = useMetaUserAdapter();

	const { setProductItems } = useOrderUtils();

	const { fnCommonConfirmPopOn } = useCommonConfirmPopService();

	const { fnFormatLocation } = useLocationInfoService();

	const { handlePushNavigation, handleUserMatchPush } = useMessageNavigation();

	const { initializeGame } = useMetaverse();
	//////////////////////////////////////////////////

	const navigate = useNavigate();

	/*
	 * toMain
	 * 메인으로 이동
	 */
	function toMain() {
		navigate(PATH.REGISTER.REGISTER);
	}

	/*
	 * toPhone
	 * 핸드폰번호 입력 화면으로 이동
	 */
	function toPhone() {
		navigate(PATH.REGISTER.PHONE);
	}

	/*
	 * fnOauthLoginInfos
	 * SNS 로그인
	 */
	const fnOauthLoginInfos = (provider: string) => {
		if (provider != '') {
			if (provider === 'google' && (window as any).flutter_inappwebview) {
				// 앱에서 구글 로그인
				bridgeGoogleLogin().then((response) => {
					if (response) {
						const map: GoogleLoginCIVO = {
							email: response.email,
							socialType: 'google',
							sub: response.id,
						};

						googleLogin(map)
							.then(async (value1) => {
								if (value1.resultCode === 'success') {
									if (value1.jwt?.accessToken && value1.jwt?.refreshToken) {
										const token = {
											accessJmt: value1.jwt?.accessToken,
											refreshJmt: value1.jwt?.refreshToken,
										};
										setAuthToken(token);
									}
								} else if (value1.resultCode === 'empty') {
									if (value1.id && value1.email && value1.socialType) {
										const tmp: {
											resultCode: string;
											id: string;
											email: string;
											socialType: string;
										} = {
											resultCode: value1.resultCode,
											id: value1.id,
											email: value1.email,
											socialType: value1.socialType,
										};
										setSNSInfo(tmp);
										// 미가입 사용자 or 연동정보가 없는 사용자
										fnCommonConfirmPopOn(
											'SNS연동 로그인을 하려면\r\n' +
												'회원임이 확인되어야 합니다.\r\n' +
												'로그인을 진행해 주세요.',
											toPhone,
											null,
											toMain,
											null,
										);
									}
								}
							})
							.catch((reason) => {
								// alert(reason.body.message);
							});
					}
				});
				return;
			}

			oauthLoginInfos(provider)
				.then((value) => {
					// return url로 화면 이동
					window.location.href = value.url;
				})
				.catch((reason) => {
					console.log(reason.body);
				});
		}
	};

	/*
	 * fnLogin
	 * 클라이언트서버 로그인 처리(myProfile, message/meta init)
	 */
	const fnLogin = (accessToken: string, currentLangCode: string) => {
		getMyProfile('').then(async (value) => {
			if (value.profileUrl.length > 0) {
				setMyProfile(value);
				if (Network.instance) {
					// 항상 현재 유효한 토큰 사용
					const tokenToUse = accessToken || authToken.accessJmt;
					console.log(
						'로그인 시작: 로비 연결 시도',
						tokenToUse.substring(0, 15) + '...',
					);

					// 로비 연결
					await Network.instance.initializeLobby();

					console.log('로비 연결 성공, DM 룸 연결 시도');

					// 로비 연결 성공 후 DM 이벤트 발생
					dmEventEmitter.emit('react-join-or-create-dm-room', {
						userUid: value.userUid,
						accessToken: tokenToUse,
						langCode: currentLangCode || langCode,
						nickName: value.nickName,
						profileUrl: value.profileUrl[0],
						age: value.age,
						region1: value.region1,
						region2: value.region2,
						role: value.role,
						inActive: false,
						isDeleted: false,
						blockType: '',
					});
					dmEventEmitter.emit('react-get-dm-rooms');
				}

				try {
					const metaUserData = await getMyMetaUser();

					// 메타유저 데이터가 있고 객체인지 확인
					if (metaUserData && typeof metaUserData === 'object') {
						// 메타유저 데이터 설정 (profileUrl 체크하지 않음)
						setMyMetaUser(metaUserData);
					} else {
						console.warn('Invalid meta user data:', metaUserData);
					}
				} catch (metaError) {
					console.error('Failed to fetch meta user data:', metaError);
				}

				const getNotiData = await bridgeGetNotiData();
				if (
					getNotiData != null &&
					getNotiData != '' &&
					getNotiData.methodName != ''
				) {
					goPushPage(
						getNotiData,
						navigate,
						setSingleFeedId,
						handleUserMatchPush,
						handlePushNavigation,
					);
				} else if (value.role === 'NORMAL') {
					setLoginState(1);
					navigate(PATH.DATING.HOME);
				} else if (value.role === 'MANAGER') {
					setLoginState(1);
					navigate(PATH.MANAGER.HOME);
				}
			} else {
				setLoginState(2);
				navigate(PATH.REGISTER.REGISTER);
				return;
			}
		});

		initializeGame();
		setProductItems();
	};

	/*
	 * fnAutoLogin
	 * 서버 로그인
	 */
	const fnAutoLogin = async (
		deviceId?: string,
		fcmToken?: string,
		currentToken?: string,
	) => {
		const value = await autoLogin(
			deviceId,
			fcmToken,
			useAuthRepo.getState().langCode || import.meta.env.defaultLangCode,
		);

		handleLoginAfter(value, currentToken);
	};

	/*
	 * fnDeviceAutoLogin
	 * 디바이스 자동 로그인
	 */
	const fnDeviceAutoLogin = async () => {
		const value = await deviceAutoLogin(
			useAuthRepo.getState().deviceId,
			useAuthRepo.getState().fcmToken,
			useAuthRepo.getState().langCode || import.meta.env.defaultLangCode,
		);

		handleLoginAfter(value);
	};

	const handleLoginAfter = (value: any, currentToken?: string) => {
		console.log(`autoLogin / deviceAutoLogin Res ===> ${JSON.stringify(value)}`);

		if (value.result && value.accessToken && value.refreshToken) {
			const token = {
				accessJmt: value.accessToken,
				refreshJmt: value.refreshToken,
			};
			setAuthToken(token);

			if ((window as any).flutter_inappwebview) {
				bridgeGetCurrencyCode().then((res) => {
					if (
						res &&
						Object.keys(CurrencyCodeEnum).includes(res as CurrencyCodeEnum)
					) {
						setCurrencyCode(res);
					} else {
						setCurrencyCode(getCurrencyCode(useAuthRepo.getState().langCode)); //zustand의 최신값을 가져오기 위함
					}
				});
			}
			fnFormatLocation(false);
			if (!handleLandingPage(value)) return;
			return fnLogin(
				currentToken || value.accessToken,
				useAuthRepo.getState().langCode || import.meta.env.defaultLangCode,
			);
		}
	};

	/**
	 * 회원 상태에 따른 랜딩페이지 분기처리
	 * 휴면, 비정상(추후 관리자블랙리스트 대상 / 삭제탈퇴 회원), 임시저장, 반려 회원
	 * @param userStatus - 회원 상태 값
	 * @returns {boolean}
	 * - true : 정상 회원으로 홈으로 이동
	 * - false : 각자 걸맞는 페이지로,, 이동
	 * TODO : 자동승인 정책 및 회원가입 단계 수정 정책 나오고 개발 들어가면, 회원 상태에 따라 페이지 분기 변경 해야 함
	 */
	const handleLandingPage = (userStatus: any): boolean => {
		// 휴면회원
		if (userStatus?.inActive) {
			navigate(PATH.AUTH.IN_ACTIVE);
			return false;
		}
		// 탈퇴/삭제 회원
		if (userStatus?.isDeleted) {
			setLoginState(2);
			return false;
		}
		// 임시저장(회원가입 진행중) 회원
		if (!userStatus?.signUpStatus && userStatus?.signUpSteps < 10) {
			setStep(Number(userStatus?.signUpSteps));
			navigate(registerSteps[userStatus?.signUpSteps].url);
			return false;
		}
		// 반려
		if (userStatus?.signUpStatus === 2) {
			navigate(PATH.AUTH.REJECT);
			return false;
		}
		// 회원정보있음, 승인 대기 중
		// if (!userStatus?.signUpStatus) {
		// 	navigate(PATH.REGISTER.WAITING);
		// 	return false;
		// }
		// 회원정보 있음, 승인 완료, 시작버튼 아직
		// if (!userStatus.startButtonYn) {
		// 	setApproveAccount(true);
		// 	navigate(PATH.REGISTER.WAITING);
		// 	return false;
		// }
		return true;
	};
	/**
	 * 디바이스가 iOS인 경우에만 애플 로그인 버튼을 표시하기 위한 상태값
	 */
	const isAppleSignInVisible =
		((window as any).flutter_inappwebview && osType === 'ios') ||
		(!!(window as any).flutter_inappwebview === false && isIOS);

	return {
		authToken,
		SNSInfo,
		isIOS,
		osType,
		isAppleSignInVisible,
		fnOauthLoginInfos,
		fnLogin,
		fnAutoLogin,
		fnDeviceAutoLogin,
		setAuthToken,
		setSNSInfo,
		setFcmToken,
		setDeviceId,
		setOsType,
		langCode,
		setLangCode,
		setAccessToken,
		setRefreshToken,
		toMain,
		toPhone,
		userSettings,
		currencyCode,
		setCurrencyCode,
	};
};
