import { useState } from 'react';
import { JobListCOVO } from '@client/site/api';

export const useJobSelectStateRepo = () => {
	// 직업 조회 리스트
	const [jobList, setJobList] = useState<Array<JobListCOVO>>([]);

	// 다음 버튼 비활성화 여부
	const [btnActivate, setBtnActivate] = useState(true);

	// api 응답결과
	const [response, setResponse] = useState<boolean>(false);

	return {
		jobList,
		setJobList,
		btnActivate,
		setBtnActivate,
		response,
		setResponse,
	};
};
