import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';

const ManagerMessageDetailHeaderView = ({
	navigationRoom,
	setShowDirectMessageList,
	handleMessageSendClick,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleBackClick = () => {
		handleMessageSendClick();
		setShowDirectMessageList(true);
		if (location.pathname.includes('/manager/messenger/online')) {
			navigate(PATH.MANAGER.MESSENGER.ONLINE);
		} else if (location.pathname.includes('/manager/messenger/offline')) {
			navigate(PATH.MANAGER.MESSENGER.OFFLINE);
		}
	};

	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left d-flex">
					<button className="btnBack" onClick={handleBackClick} />
					<p className="bold margin-l3">
						{navigationRoom.otherUserInfo.nickName}
					</p>
				</div>
				<div className="h-right"></div>
			</div>
		</header>
	);
};

export default ManagerMessageDetailHeaderView;
