import Chair from '@virtual-space/metaverse/items/impl/Chair';
import ItemAbst from '@virtual-space/metaverse/items/ItemAbst';
import {
	ItemCreator,
	itemFactory,
} from '@virtual-space/metaverse/items/ItemFactory';
import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import GameScene from '../GameScene';

export class CreateObjectLayer<T extends ItemAbst> {
	public addGroupFromTiled(
		ctor: ItemCreator<T>,
		gameScene: GameScene,
		objectName: string,
		group: string,
		key: string,
	) {
		// Tiled에서 불러온 아이템들의 StaticGroup을 추가
		const items = gameScene.physics.add.staticGroup({
			classType: itemFactory(ctor),
		});

		// Tiled에서 불러온 ObjectLayer를 가져옴
		const layer = gameScene.map.getObjectLayer(objectName);
		layer!.objects.forEach((obj, i) => {
			// Tiled에서 불러온 오브젝트 정보로 아이템 추가
			const item = this.addObjectFromTiled(
				gameScene,
				items,
				obj,
				group,
				key,
				false,
			) as T;
			// 아이템의 depth 설정
			item.setDepth(item.y + item.height * 0.27);

			// 아이템이 Chair일 경우, itemDirection 설정
			if (item instanceof Chair) {
				const chairId = `${i}`;
				item.chairId = chairId;
				gameScene.chairMap.set(chairId, item);
				item.itemDirection = obj.properties[0].value;
			}
			// 아이템이 PopUp 경우, url, title 설정
			// if (item instanceof PopUp) {
			//   item.url = obj.properties.find((p) => p.name === "url")?.value;
			//   item.title = obj.properties.find((p) => p.name === "title")?.value;
			// }
			// 아이템이 YouTube일 경우, title 설정
			// if (item instanceof YouTube) {
			//   item.title = obj.properties.find((p) => p.name === "title")?.value;
			// }
			// 아이템이 Wordle일 경우, title 설정
			// if (item instanceof Wordle) {
			//   item.title = obj.properties.find((p) => p.name === "title")?.value;
			// }

			// // 아이템이 Computer일 경우, computerMap에 추가
			// if (item instanceof Computer) {
			//   const id = `${i}`;
			//   item.id = id;
			//   gameScene.computerMap.set(id, item);
			// }
			// // 아이템이 Whiteboard일 경우, whiteboardMap에 추가
			// else if (item instanceof Whiteboard) {
			//   const id = `${i}`;
			//   item.id = id;
			//   gameScene.whiteboardMap.set(id, item);
			// }
		});

		// Player와 Item이 겹치면, HandleItemSelectorOverlap 실행
		gameScene.physics.add.overlap(
			gameScene.myPlayer.collision,
			[items],
			new HandleItemSelectorOverlap(gameScene).handleItemSelectorOverlap,
			undefined,
			this,
		);
	}

	// Tiled에서 불러온 오브젝트 정보로 아이템 추가
	addObjectFromTiled(
		gameScene: GameScene,
		group: Phaser.Physics.Arcade.StaticGroup,
		object: Phaser.Types.Tilemaps.TiledObject,
		key: string,
		tilesetName: string,
		visible: boolean = true,
	) {
		const actualX = object.x! + object.width! * 0.5;
		const actualY = object.y! - object.height! * 0.5;
		const obj = group
			.get(
				actualX,
				actualY,
				key,
				object.gid! - gameScene.map.getTileset(tilesetName)!.firstgid,
				visible,
			)
			.setDepth(actualY);

		return obj;
	}
}

class HandleItemSelectorOverlap {
	constructor(private readonly scene: GameScene) {
		this.handleItemSelectorOverlap = this.handleItemSelectorOverlap.bind(this);
	}

	// 아이템 선택 시 호출
	handleItemSelectorOverlap(playerSelector, selectionItem) {
		if (!(selectionItem instanceof ItemAbst)) {
			return;
		}
		const currentItem = playerSelector.selectedItem as ItemAbst;
		// currentItem은 이전에 선택된 아이템이 없으면 undefined
		if (currentItem) {
			// 선택이 이전과 같거나, 이전에 선택된 아이템보다 더 낮은 깊이를 가지면, 메소드 실행 중지
			if (
				currentItem === selectionItem ||
				currentItem.depth >= selectionItem.depth
			) {
				return;
			}
			// 선택이 바뀌었을 경우, 이전에 선택된 아이템 대화창 닫기
			if (this.scene.myPlayer.playerBehavior !== PlayerBehavior.SITTING)
				currentItem.clearDialogBox();
		}

		// 선택된 아이템으로 currentItem 갱신 및 새로운 대화창 설정
		playerSelector.selectedItem = selectionItem;
		selectionItem.onOverlapDialog();
	}
}
