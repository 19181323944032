// https://peerjs.com/docs.html#peer-id
import { CurrencyCodeEnum } from '@client/site/dating/constants/product/currency-code.enum';
import GameScene from '@virtual-space/metaverse/scenes/GameScene';

/**
 * PeerJS에서 사용 가능한 형식으로 userId를 변환
 * 숫자와 알파벳이 아닌 모든 문자를 'G'로 대체
 * @param userId 변환할 사용자 ID
 * @returns 변환된 사용자 ID
 */
export function replaceInvalidId(userId: string) {
	return userId.replace(/[^0-9a-z]/gi, 'G');
}

/**
 * sessionId를 정제
 * '-ss'로 끝나는 9자 이상의 문자열에서 '-ss'를 제거하고,
 * 숫자와 알파벳이 아닌 모든 문자를 'G'로 대체
 * @param id 정제할 세션 ID
 * @returns 정제된 세션 ID
 */
export function sanitizeId(id: string) {
	let sanitized = id;

	if (sanitized.length > 9 && sanitized.endsWith('-ss')) {
		sanitized = sanitized.substring(0, sanitized.length - 3);
	}

	return sanitized.replace(/[^0-9a-z]/gi, 'G');
}

/**
 * 초 단위의 시간을 시:분:초 형식의 문자열로 변환
 * @param seconds 초 단위의 시간
 * @returns 시:분:초 형식의 문자열
 */
export function secondsToHMS(seconds: number) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const padZero = (num) => num.toString().padStart(2, '0');

	return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

/**
 * 게임 씬이 준비되었는지 확인
 * @param scene 확인할 게임 씬
 * @returns 게임 씬이 준비되었는지 여부
 */
export class SceneReadyUtil {
	static isSceneReady(scene: GameScene): boolean {
		return !!(
			scene &&
			scene.sys.isActive() &&
			scene.myPlayer &&
			scene.roomService
		);
	}

	static async waitForSceneReady(scene: GameScene): Promise<void> {
		return new Promise((resolve) => {
			const checkReady = () => {
				if (SceneReadyUtil.isSceneReady(scene)) {
					resolve();
				} else {
					setTimeout(checkReady, 100);
				}
			};
			checkReady();
		});
	}
}

export const getCurrencyCode = (langCode: string): CurrencyCodeEnum => {
	switch (langCode.toLowerCase()) {
		case 'ko':
			return CurrencyCodeEnum.KRW;
		case 'vi':
			return CurrencyCodeEnum.VND;
		default:
			return CurrencyCodeEnum.USD;
	}
};
