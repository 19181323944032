import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import type { InActiveCIVO } from '@client/site/api';
import { useState } from 'react';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useOrderUtils } from '@client/utils/orderUtils';
import { bridgeSetNativeDataLogin } from '@client/utils/bridgeCallHandler';
import { useMetaverse } from '@virtual-space/hooks/useMetaverse';
import Network from '@virtual-space/services/Network';

export const useInactiveUserService = () => {
	const [offInactive, setOffInactive] = useState<boolean>(false);

	const { phoneNumber } = useRegisterRepo();

	const { setMyProfile } = useDatingRepo();

	const { deviceId, fcmToken, authToken, setAuthToken, tempToken, langCode } =
		useAuthRepo();

	const { getMyProfile } = useProfileAdapter();

	const { setMyMetaUser } = useMetaUserRepo();
	const { getMyMetaUser } = useMetaUserAdapter();

	const { saveInActiveUser } = useSettingAdapter();

	const { setProductItems } = useOrderUtils();

	const { initializeGame } = useMetaverse();
	////////////////////////////////////////////////

	/*
	 * fnInactiveUserOff
	 * 휴면해제
	 */
	const fnInactiveUserOff = () => {
		const map: InActiveCIVO = {
			inActive: false,
			deviceId: deviceId,
			fcmToken: fcmToken,
		};
		// 휴면계정 로그인시, 임시로 tempToken에 담았다가, 해제 시, authToken에 담기로
		/**
		 * 디바이스 자동 로그인 작업 - 자동 로그인 시, 페이지 분기 해서 필요없음
		setAuthToken({
			accessJmt: tempToken.accessJmt,
			refreshJmt: tempToken.refreshJmt,
		});
		 */
		saveInActiveUser(authToken.accessJmt, authToken.refreshJmt, map)
			.then((value) => {
				if (value) {
					// native 자동 로그인 처리 위해, 휴면 해제 시, token set
					if ((window as any).flutter_inappwebview) {
						bridgeSetNativeDataLogin({
							accessJmt:
								authToken.accessJmt != ''
									? authToken.accessJmt
									: tempToken.accessJmt,
							refreshJmt:
								authToken.refreshJmt != ''
									? authToken.refreshJmt
									: tempToken.refreshJmt,
						}).catch((reason) => {
							alert(reason.body);
						});
					}
					setOffInactive(true);
				}
			})
			.catch((reason) => {
				console.log(reason.body);
			});
	};

	const navigate = useNavigate();
	/*
	 * fnActivatedUserLogin
	 * 휴면해제된 회원 로그인
	 */
	const fnActivatedUserLogin = async () => {
		try {
			// 프로필 정보 가져오기
			const profileData = await getMyProfile(phoneNumber);

			if (!profileData?.profileUrl?.length) {
				navigate(PATH.REGISTER.REGISTER);
				return;
			}

			// 프로필 정보 설정
			setMyProfile(profileData);

			if (Network.instance) {
				await Network.instance.initializeLobby();

				// DM 이벤트 발생
				dmEventEmitter.emit('react-join-or-create-dm-room', {
					userUid: profileData.userUid,
					accessToken: authToken.accessJmt,
					langCode: langCode,
					nickName: profileData.nickName,
					profileUrl: profileData.profileUrl[0],
					age: profileData.age,
					region1: profileData.region1,
					region2: profileData.region2,
					role: profileData.role,
					inActive: false,
					isDeleted: false,
					blockType: '',
				});

				// DM 룸 정보 가져오기
				dmEventEmitter.emit('react-get-dm-rooms');
			}

			// 메타유저 정보 가져오기
			try {
				const metaUserData = await getMyMetaUser();
				// 메타유저 데이터가 있는 경우에만 설정
				if (metaUserData && typeof metaUserData === 'object') {
					setMyMetaUser(metaUserData);
				} else {
					console.warn('Invalid meta user data:', metaUserData);
				}
			} catch (metaError) {
				console.error('Failed to fetch meta user data:', metaError);
				// 메타유저 데이터 실패해도 기본 로그인 프로세스는 계속 진행
			}

			// 역할에 따른 페이지 이동
			if (profileData.role === 'NORMAL') {
				navigate(PATH.DATING.HOME);
			} else if (profileData.role === 'MANAGER') {
				navigate(PATH.MANAGER.HOME);
			}

			initializeGame();
			setProductItems();
		} catch (error) {
			console.error('Login process failed:', error);
			navigate(PATH.REGISTER.REGISTER);
		}
	};

	return {
		fnInactiveUserOff,
		offInactive,
		fnActivatedUserLogin,
	};
};
