import { createPhaser } from '@virtual-space/createPhaser';
import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useJoystickRepo } from '@virtual-space/stores/useJoystickRepo';
import { useCallback, useRef } from 'react';

export const useMetaverse = () => {
	const { setJoystickPosition, setIsDragging } = useJoystickRepo();
	const gameRef = useRef<Phaser.Game | null>(null);
	const isGameInitialized = useRef(false);

	const handleGameClick = useCallback(
		(pointer: Phaser.Input.Pointer) => {
			const x = pointer.x;
			const y = pointer.y;
			const JOYSTICK_SIZE = 120;
			const touchX = x + window.scrollX - JOYSTICK_SIZE / 2;
			const touchY =
				window.innerHeight - (y + window.scrollY) - JOYSTICK_SIZE / 2;

			setJoystickPosition({ x: touchX, y: touchY });
			setIsDragging(true);
			reactEventEmitter.emit('react-click-screen', { x, y });
		},
		[setJoystickPosition, setIsDragging],
	);

	const addClickListener = useCallback(() => {
		if (gameRef.current && gameRef.current.scene.scenes.length > 0) {
			const scene = gameRef.current.scene.scenes[0];
			scene.input.on('pointerdown', handleGameClick);
		} else {
			setTimeout(addClickListener, 100);
		}
	}, [handleGameClick]);

	const initializeGame = useCallback(() => {
		if (!isGameInitialized.current) {
			if (gameRef.current) {
				gameRef.current.destroy(true);
				gameRef.current = null;
			}
			gameRef.current = createPhaser();
			isGameInitialized.current = true;
			addClickListener();
		}
	}, [addClickListener]);

	return {
		initializeGame,
		gameRef,
		isGameInitialized,
	};
};
