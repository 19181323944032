import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { i18n } from 'i18next';
import React, {
	createContext,
	useState,
	useMemo,
	ReactNode,
	useContext,
	useEffect,
} from 'react';
import { I18nextProvider } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface MetaverseContextType {
	isInMetaverseRoot: boolean;
	setIsInMetaverseRoot: React.Dispatch<React.SetStateAction<boolean>>;
}

const MetaverseContext = createContext<MetaverseContextType | undefined>(
	undefined,
);

interface MetaverseProviderProps {
	children: ReactNode;
	i18n: i18n;
}

export const MetaverseProvider: React.FC<MetaverseProviderProps> = ({
	children,
	i18n,
}) => {
	const [isInMetaverseRoot, setIsInMetaverseRoot] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const newIsInMetaverseRoot = location.pathname === PATH.METAVERSE.ROOT;
		if (newIsInMetaverseRoot !== isInMetaverseRoot) {
			setIsInMetaverseRoot(newIsInMetaverseRoot);
		}
	}, [location, isInMetaverseRoot]);

	const value = useMemo(
		() => ({
			isInMetaverseRoot,
			setIsInMetaverseRoot,
		}),
		[isInMetaverseRoot],
	);

	return (
		<I18nextProvider i18n={i18n}>
			<MetaverseContext.Provider value={value}>
				{children}
			</MetaverseContext.Provider>
		</I18nextProvider>
	);
};

export const useMetaverse = () => {
	const context = useContext(MetaverseContext);
	if (context === undefined) {
		throw new Error('useMetaverse must be used within a MetaverseProvider');
	}
	return context;
};
