import { JobListCOVO } from '@client/site/api';

interface props {
	setSelectedJob: (selectedJob: string) => void;
	jobList: Array<JobListCOVO>;
	selectedJob: string;
}

const JobSelectListView = ({ setSelectedJob, jobList, selectedJob }: props) => {
	return (
		<div className="content">
			<div className="txtRadio d-flex gap-16">
				{jobList?.map((job, i) => {
					return (
						<div key={job.code}>
							<input
								type="radio"
								id={job.code + i}
								name="job"
								value={job.code}
								checked={job.code === selectedJob}
								onChange={() => {
									setSelectedJob(job.code);
								}}
							/>
							<label htmlFor={job.code + i}>{job.value}</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default JobSelectListView;
