import { DirectMessageType } from '../../../../types/DirectMessageType';
import { Client, Room } from 'colyseus.js';
import {
	IDirectMessageState,
	IUser,
	IDMRoom,
} from '../../../../types/IDirectMessageState';
import { RoomType } from '../../../../types/Rooms';
import { CreateClient } from '../network/CreateClient';
import { StoreService } from '../StoreService';
import { DirectMessageEventSetup } from './DirectMessageEventSetup';
import {
	REACT_JOIN_OR_CREATE_DM_ROOM,
	dmEventEmitter,
} from '@virtual-space/events/ReactDMEventEmitter';

export default class DirectMessageManager {
	private static instance: DirectMessageManager | null = null;
	private client: Client;
	public dmRoom: Room<IDirectMessageState> | null = null;
	public storeService: StoreService;
	private constructor() {
		this.client = CreateClient.create();
		this.storeService = new StoreService();
	}
	private boundJoinOrCreateDMRoom:
		| ((args: REACT_JOIN_OR_CREATE_DM_ROOM) => void)
		| null = null;

	public static getInstance(): DirectMessageManager {
		if (!DirectMessageManager.instance) {
			DirectMessageManager.instance = new DirectMessageManager();
		}
		return DirectMessageManager.instance;
	}

	get sessionId() {
		return this.dmRoom?.sessionId ?? '';
	}

	async joinOrCreateDMRoom(
		userUid: string,
		accessToken: string,
		langCode: string,
		nickName: string,
		profileUrl: string,
		age: string,
		region1: string,
		region2: string,
		role?: string,
		inActive?: boolean,
		isDeleted?: boolean,
		blockType?: string,
	): Promise<void> {
		try {
			// 이미 DM 룸이 있는 경우
			if (this.dmRoom) {
				console.log(
					'이미 DM 룸이 연결되어 있습니다. 세션 ID:',
					this.dmRoom.sessionId,
				);
				return;
			}

			console.log('DM 룸 연결 시도:', userUid);

			// 재시도 로직 추가
			let attempts = 0;
			const maxAttempts = 3;

			while (attempts < maxAttempts) {
				try {
					this.dmRoom = await this.client.joinOrCreate(RoomType.DM, {
						userUid,
						accessToken,
						langCode,
						nickName,
						profileUrl,
						age,
						region1,
						region2,
						role,
						inActive,
						isDeleted,
						blockType,
					});

					console.log('DM 룸 연결 성공. 세션 ID:', this.dmRoom.sessionId);

					// 이벤트 설정
					const eventSetup = new DirectMessageEventSetup(
						this.dmRoom,
						this.storeService,
					);
					eventSetup.setup();

					// 성공적으로 연결되었으므로 루프 종료
					break;
				} catch (error) {
					attempts++;
					console.error(`DM 룸 연결 실패 (${attempts}/${maxAttempts}):`, error);

					if (attempts >= maxAttempts) {
						throw error;
					}

					// 재시도 전 지연
					await new Promise((resolve) => setTimeout(resolve, 1000));
				}
			}
		} catch (error) {
			console.error('DM 룸 연결 처리 중 오류:', error);
			throw error;
		}
	}

	setupEventListeners() {
		// 기존 리스너 제거하여 중복 방지
		if (this.boundJoinOrCreateDMRoom) {
			dmEventEmitter.off(
				'react-join-or-create-dm-room',
				this.boundJoinOrCreateDMRoom,
			);
		}

		// 새로운 비동기 이벤트 핸들러
		this.boundJoinOrCreateDMRoom = async (
			args: REACT_JOIN_OR_CREATE_DM_ROOM,
		) => {
			try {
				const {
					userUid,
					accessToken,
					langCode,
					nickName,
					profileUrl,
					age,
					region1,
					region2,
					role,
					inActive,
					isDeleted,
					blockType,
				} = args;

				await this.joinOrCreateDMRoom(
					userUid,
					accessToken,
					langCode,
					nickName,
					profileUrl,
					age || '',
					region1 || '',
					region2 || '',
					role,
					inActive,
					isDeleted,
					blockType,
				);

				// DM 룸 연결 완료 후 이벤트 발생
				dmEventEmitter.emit('react-dm-room-joined');
			} catch (error) {
				console.error('DM 룸 연결 실패:', error);
			}
		};

		dmEventEmitter.on(
			'react-join-or-create-dm-room',
			this.boundJoinOrCreateDMRoom,
		);
	}

	leaveDMRoom() {
		if (this.dmRoom) {
			this.dmRoom.leave();
			if (this.boundJoinOrCreateDMRoom) {
				dmEventEmitter.off(
					'react-join-or-create-dm-room',
					this.boundJoinOrCreateDMRoom,
				);
				this.boundJoinOrCreateDMRoom = null;
			}
			this.dmRoom = null;
		}
	}

	// direct message 방 생성
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
		matchedAt: string | null,
		productType?: string,
		productSubType?: string,
		useStatus?: string,
		seq?: number,
		validUses?: number,
		orderId?: number,
	) {
		this.dmRoom?.send(DirectMessageType.CREATE_DM_ROOM, {
			billingStatus,
			dmRoomType,
			roomId,
			user1Id,
			user2Id,
			user1Info,
			user2Info,
			createdAt,
			matchedAt,
			productType,
			productSubType,
			useStatus,
			seq,
			validUses,
			orderId,
		});
	}

	// direct message 방 조회
	getDirectMessageRoom() {
		if (!this.dmRoom) {
			console.warn('DM 룸이 연결되지 않았습니다. 메시지를 요청할 수 없습니다.');
			return;
		}

		console.log('DM 메시지 목록 요청');
		this.dmRoom.send(DirectMessageType.GET_DM_ROOMS);
	}

	// direct message 방 참여
	enterDirectMessageRoom(roomId: string) {
		if (!roomId) {
			console.warn('enterDirectMessageRoom: roomId is required');
			return;
		}
		this.dmRoom?.send(DirectMessageType.ENTER_DM_ROOM, { roomId }); // roomId를 객체로 전달
	}

	// direct message 방 나가기
	leaveDirectMessageRoom() {
		this.dmRoom?.send(DirectMessageType.LEAVE_DM_ROOM); // roomId를 객체로 전달
	}

	// direct message 세션 체크
	checkUserInRoom(targetUid: string, roomId: string) {
		this.dmRoom?.send(DirectMessageType.CHECK_USER_SESSION, {
			targetUid,
			roomId,
		});
	}

	// direct message 추가
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	) {
		if (!this.dmRoom) {
			console.warn('DM 룸이 연결되지 않았습니다. 메시지를 보낼 수 없습니다.');
			return;
		}

		// 메시지 전송
		this.dmRoom.send(DirectMessageType.SEND_DIRECT_MESSAGE, {
			roomId,
			messageId,
			sender,
			receiver,
			content,
			createdAt,
			read,
		});
	}

	// direct message 수신
	receiveDirectMessage() {
		this.dmRoom?.onMessage(
			DirectMessageType.RECEIVE_DIRECT_MESSAGE,
			(message) => {
				this.storeService.setDirectMessageReceived(message);
			},
		);
	}

	// direct message 읽음
	readDirectMessage(roomId: string, messageId: number, userUid: string) {
		this.dmRoom?.send(DirectMessageType.READ_DIRECT_MESSAGE, {
			roomId,
			messageId,
			userUid,
		});
	}

	// 읽지 않은 메시지 수 요청
	requestUnreadMessageCount(roomId: string) {
		this.dmRoom?.send(DirectMessageType.REQUEST_UNREAD_COUNT, { roomId });
	}

	// 전체 메시지 카운트 초기화
	resetUnreadMessageCount() {
		this.dmRoom?.send(DirectMessageType.RESET_ALL_UNREAD);
	}

	// direct message 나가기
	leaveDirectMessage(roomId: string, leaverId: string) {
		this.dmRoom?.send(DirectMessageType.LEAVE_DIRECT_MESSAGE, {
			roomId,
			leaverId,
		});
	}

	// direct message 완전히 나가기
	exitDirectMessage(roomId: string, exit: boolean) {
		this.dmRoom?.send(DirectMessageType.EXIT_DIRECT_MESSAGE, {
			roomId,
			exit,
		});
	}

	// direct message 단일 결제
	singlePaymentCompleted(roomId: string, payerId: string) {
		this.dmRoom?.send(DirectMessageType.SINGLE_PAYMENT_COMPLETED, {
			roomId,
			payerId,
		});
	}

	// direct message 쌍방 결제
	mutualPaymentCompleted(roomId: string, paid: boolean) {
		this.dmRoom?.send(DirectMessageType.MUTUAL_PAYMENT_COMPLETED, {
			roomId,
			paid,
		});
	}

	// direct message 방 타입 변경
	changeDirectRoomType(roomId: string, dmRoomType: string) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_ROOM_TYPE, {
			roomId,
			dmRoomType,
		});
	}

	// direct message 사용 상태 변경
	changeDMUseStatus(roomId: string, useStatus: string) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_USE_STATUS, {
			roomId,
			useStatus,
		});
	}

	// direct message 유저 차단
	blockUser(
		roomId: string,
		blockType: string,
		blockerUuid: string,
		targetUid: string,
	) {
		this.dmRoom?.send(DirectMessageType.BLOCK_USER, {
			roomId,
			blockType,
			blockerUuid,
			targetUid,
		});
	}

	// direct message 프로필 url 변경
	updateDMProfileUrl(userUid: string, profileUrl: string) {
		this.dmRoom?.send(DirectMessageType.UPDATE_DM_PROFILE_URL, {
			userUid,
			profileUrl,
		});
	}

	// direct message 휴면 상태 변경
	changeDMInactive(userUid: string, inActive: boolean) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_INACTIVE, {
			userUid,
			inActive,
		});
	}

	// direct message 탈퇴 상태 변경
	changeDMIsDeleted(userUid: string, isDeleted: boolean) {
		this.dmRoom?.send(DirectMessageType.CHANGE_DM_IS_DELETED, {
			userUid,
			isDeleted,
		});
	}

	// direct message 업데이트
	updateDirectMessageRoom(room: IDMRoom): void {
		this.dmRoom?.send(DirectMessageType.DM_ROOM_UPDATED, room);
	}

	// direct message 방 삭제
	removeDirectMessageRoom(roomId: string): void {
		this.dmRoom?.send(DirectMessageType.DM_ROOM_REMOVED, roomId);
	}

	// direct message AccessToken 업데이트
	updateDMToken(userUid: string, accessToken: string) {
		this.dmRoom?.send(DirectMessageType.ACCESS_TOKEN_UPDATE, {
			userUid,
			accessToken,
		});
	}
}
