import { isCensored } from '@client/utils/censor';
import { useRef, useEffect } from 'react';
import { useChatService } from '../service/useChatService';
import { useCommonAlertPopService } from '@client/site/dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { useTranslation } from 'react-i18next';
import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';

const ChatFormView = () => {
	const { t } = useTranslation('messenger');
	const ref = useRef(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const {
		focused,
		showChat,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		isInMetaverseRoot,
		handleSendChat,
		setShowChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	} = useChatService();
	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChatInputValue(event.target.value);
	};

	const handleSubmit = (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();

		// 게임으로 포커스 이동
		inputRef.current?.blur();

		// 공백 제거한 입력값에 대해 금칙어 체크
		const hasCensoredWord = isCensored(chatInputValue.replace(/\s/g, ''));
		const trimmedValue = chatInputValue.trim();

		if (hasCensoredWord) {
			fnCommonAlertPopOn(t('forbiddenWord'));
			setChatInputValue('');
		} else if (trimmedValue) {
			// 빈 문자열이 아닐 경우에만 처리
			reactEventEmitter.emit('react-add-chat-message', trimmedValue);
			handleSendChat(trimmedValue);
			setMobileChatInputClick(false);
			setChatInputValue('');
		}
	};

	useEffect(() => {
		setIsInputFilled(chatInputValue.trim().length > 0);
	}, [chatInputValue]);

	// 포커스 효과 수정
	useEffect(() => {
		if (focused && inputRef.current) {
			// 포커스는 주되 키보드는 올라오지 않게 처리
			inputRef.current.readOnly = true;
			inputRef.current.focus();
			inputRef.current.blur();
			inputRef.current.focus();
		} else {
			inputRef.current?.blur();
		}
	}, [focused]);

	const handleClickFirstInput = () => {
		if (inputRef.current) {
			// 첫 번째 인풋 클릭 시에만 readOnly 해제하고 포커스
			inputRef.current.readOnly = false;
			inputRef.current.focus();
		}
	};

	return (
		<div
			className="chatInput input metaInput"
			style={{
				display: 'block',
			}}
		>
			<form onSubmit={handleSubmit} ref={ref}>
				<div className={`chatRightBox ${mobileChatInputClick ? 'active' : ''}`}>
					<div className="chat01">
						<button
							className={`chatWindowBtn ${showChat ? 'on' : ''}`}
							type="button"
							onClick={() => {
								setShowChat(!showChat);
							}}
						/>
						<input
							className="text-l"
							value={t('inputChat')}
							type="text"
							readOnly
							onClick={() => {
								setMobileChatInputClick(true);
								handleClickFirstInput();
							}}
						/>
					</div>
					<div className="chat02">
						<button
							className="chatBackBtn"
							type="button"
							onClick={() => {
								setMobileChatInputClick(false);
								if (inputRef.current) {
									inputRef.current.readOnly = true;
									inputRef.current.blur();
								}
							}}
						/>
						<input
							type="text"
							maxLength={192}
							ref={inputRef}
							value={chatInputValue}
							onChange={handleChange}
							onFocus={() => {
								if (!focused) {
									setFocused(true);
								}
							}}
							onBlur={() => {
								// blur 시 readOnly로 설정
								if (inputRef.current) {
									inputRef.current.readOnly = true;
								}
								setFocused(true);
							}}
							onClick={(e) => {
								// 클릭 시에만 readOnly 해제
								const input = e.currentTarget;
								input.readOnly = false;
								input.focus();
							}}
							enterKeyHint="send"
							placeholder={t('inputChat')}
							disabled={!isInMetaverseRoot}
							readOnly // 초기 상태는 readOnly
						/>
						<button
							className={`chatSendBtn ${
								isInputFilled && isInMetaverseRoot ? 'active' : ''
							}`}
							type="submit"
							onClick={(e) => {
								handleSubmit(e);
								setShowChat(true);
								if (inputRef.current) {
									inputRef.current.readOnly = true;
									inputRef.current.blur();
								}
							}}
							disabled={!isInputFilled || !isInMetaverseRoot}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ChatFormView;
