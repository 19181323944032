import BlockReportButtonView from '@client/site/dating/ui/components/blockReport/views/BlockReportButtonView';
import { Link } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

const AvatarProfileInfoView = ({
	setOpenBlock,
	openMenuPop,
	menuDotClickHandler,
	myMetaUser,
	metaUser,
	getCharacterFile,
	reportPopOpen,
	setOpenOtherPlayerProfile,
	blockResult,
}) => {
	const { t } = useTranslation('metaverse');
	const renderIntroText = (blockResult, introduce) => {
		switch (blockResult) {
			case 'block':
				return (
					<p
						className="introTxt"
						dangerouslySetInnerHTML={{ __html: t('blockedUserViewProfile') }}
					/>
				);

			case 'blocked':
				return <p className="introTxt">{t('blockedInActive')}</p>;

			default: // none인 경우
				return introduce === null ? (
					<p className="introTxt">{t('noStatusMsg')}</p>
				) : (
					<p className="introTxt">{introduce}</p>
				);
		}
	};
	const renderProfileButton = () => {
		if (blockResult === 'none') {
			return (
				<Link
					to={
						metaUser.role === 'MANAGER'
							? PATH.PROFILE.MANAGER_PROFILE
							: PATH.PROFILE.USER_PROFILE
					}
					state={{
						isOtherProfile: metaUser.userUid !== myMetaUser.userUid,
					}}
				>
					<button
						className="btnBig btnBlack radiusNone"
						onClick={() => {
							setOpenOtherPlayerProfile(false, null);
							sessionStorage.setItem('fromMetaverse', 'true');
						}}
					>
						<span>{t('profileView')}</span>
					</button>
				</Link>
			);
		}

		// block 또는 blocked 상태일 때
		return (
			<button className="btnBig btnGray radiusNone" disabled>
				<span>{t('profileView')}</span>
			</button>
		);
	};
	return (
		<>
			<div className="avatarProInfo">
				<p className="commonTit">
					<span />
				</p>
				<div className="avatarProBox">
					<div className="metaProfile">
						<div className="metaProImg">
							{blockResult === 'none' ? (
								<div className="avatar_character avatar_front">
									{getCharacterFile.map((img, index) => (
										<img
											key={index}
											className="avatar_profile_object"
											style={{
												background: `url('${img}')`,
											}}
										/>
									))}
								</div>
							) : (
								<div className="proImg">
									<img
										src="/assets/img/common/profile_no.svg"
										alt="테스트이미지"
									/>
								</div>
							)}
						</div>
						<div className="metaProTxt">
							<p className="metaProGender">
								{metaUser.gender === 'M' ? (
									<span className="men">{t('male')}</span>
								) : (
									<span className="women">{t('female')}</span>
								)}
							</p>
							<p className="nickname padding-b4">{metaUser.nickName}</p>
							{renderIntroText(blockResult, metaUser.introduce)}
						</div>
						{blockResult === 'none' && myMetaUser.role !== 'MANAGER' && (
							<div className="metaProBtn menuDotWap">
								<button
									className="menuDot"
									onClick={(e) => {
										e.stopPropagation();
										menuDotClickHandler();
									}}
								/>
								<BlockReportButtonView
									setOpenBlock={setOpenBlock}
									openMenuPop={openMenuPop}
									targetUid={metaUser.userUid}
									reportPopOpen={reportPopOpen}
								/>
							</div>
						)}
					</div>
				</div>
				{renderProfileButton()}
			</div>
		</>
	);
};
export default AvatarProfileInfoView;
