import { getSitAnimationNum } from '../GetSitAnimationNum';
import { MyPlayerState, StateParams } from '../MyPlayerState';
import Chair from '@virtual-space/metaverse/items/impl/Chair';
import {
	ItemType,
	OCCUPIED_MESSAGES,
	STAND_MESSAGES,
	STAND_MESSAGES_DESKTOP,
} from '@virtual-space/types/Item';
import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import { useAuthRepo } from '@client/site/dating/repository/auth/useAuthRepo';
import { usePlayerBehaviorRepo } from '@virtual-space/stores/usePlayerBehaviorRepo';

export class IntegratedState implements MyPlayerState {
	constructor(private param: StateParams) {}
	update() {
		const { myKeyboard, item, myPlayer, roomService, scene } = this.param;
		const langCode =
			useAuthRepo.getState().langCode ?? import.meta.env.defaultLangCode;

		// myKeyboard.resetF();
		myKeyboard.resetClickActionF();
		switch (item?.itemType) {
			case ItemType.CHAIR:
				const chairItem = item as Chair;
				const chair = roomService
					.getChairState()
					?.get(String(chairItem.chairId));
				const isOccupied = roomService
					.getPlayers()
					?.has(String(chair?.clientId));
				if (chair?.occupied && isOccupied) {
					chairItem.clearDialogBox();
					chairItem.setDialogBox(OCCUPIED_MESSAGES[langCode]);
					return;
				}

				myPlayer.scene.time.addEvent({
					delay: 10,
					callback: () => {
						// 캐릭터 속도와 위치를 업데이트
						myPlayer.setVelocity(0, 0);
						if (chairItem.itemDirection) {
							myPlayer
								.setPosition(
									chairItem.x + getSitAnimationNum(chairItem.itemDirection)[0],
									chairItem.y + getSitAnimationNum(chairItem.itemDirection)[1],
								)
								.setDepth(
									chairItem.depth +
										getSitAnimationNum(chairItem.itemDirection)[2],
								);
						}

						// 플레이어가 앉은 상태의 애니메이션을 재생
						myPlayer.playWrab(
							`${myPlayer.playerTexture}_sit_${chairItem.itemDirection}`,
							true,
						);
						myPlayer.collision.selectedItem = undefined;
						if (chairItem.itemDirection === 'up') {
							myPlayer.collision.setPosition(
								myPlayer.x,
								myPlayer.y - myPlayer.height,
							);
						} else {
							myPlayer.collision.setPosition(0, 0);
						}
						roomService.updateChairStatus(chairItem.chairId, true);
					},
					loop: false,
				});
				// 앉은 상태에서 새로운 대화 상자를 설정
				if (window.innerWidth < 1024 && scene) {
					const starImage = scene.add.image(0, 0, 'star').setScale(0.5);
					const message =
						langCode === 'en'
							? [
									STAND_MESSAGES[langCode].prefix,
									starImage,
									STAND_MESSAGES[langCode].suffix,
							  ]
							: [starImage, STAND_MESSAGES[langCode]];
					chairItem.setDialogBox(message);
				} else {
					chairItem.setDialogBox(STAND_MESSAGES_DESKTOP[langCode]);
				}

				myPlayer.chairOnSit = chairItem;
				myPlayer.playerBehavior = PlayerBehavior.SITTING;
				usePlayerBehaviorRepo
					.getState()
					.setPlayerBehavior(PlayerBehavior.SITTING);

				break;
			// case ItemType.COMPUTER:
			// 	// 컴퓨터 아이템을 클릭한 경우 컴퓨터의 다이얼로그 창을 엶
			// 	const computer = item as Computer;
			// 	computer.openDialog(myPlayer.id, roomService);
			// 	break;
			// case ItemType.WHITEBOARD:
			// 	// 화이트보드 아이템을 클릭한 경우 화이트보드의 다이얼로그 창을 엶
			// 	const whiteboard = item as Whiteboard;
			// 	whiteboard.openDialog(roomService);
			// 	break;
			// case ItemType.POPUP:
			// 	const popUp = item as PopUp;
			// 	popUp.openDialog();
			// 	break;
			// case ItemType.YOUTUBE:
			// 	const youTube = item as YouTube;
			// 	youTube.openDialog();
			// 	break;
			// case ItemType.WORDLE:
			// 	const wordle = item as Wordle;
			// 	wordle.openDialog();
			// 	break;
		}
	}
}
