import { lazy, Suspense } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import Spinner from '@client/site/dating/ui/components/spinner/Spinner';
import MyManagerLayout from '@client/site/dating/ui/components/layout/MyManagerLayout';
import { useMessageRouteHandler } from '@virtual-space/hooks/useMessageRouteHandler';
import ManagerADContainer from '@virtual-space/ui/components/dm/manager/advertisement/ManagerADContainer';
import MyManagerOfflineDetailContainer from '@virtual-space/ui/components/dm/myManager/offline/detail/MyManagerOfflineDetailContainer';
import MyManagerOfflineListContainer from '@virtual-space/ui/components/dm/myManager/offline/list/MyManagerOfflineListContainer';
import MyManagerOnlineDetailContainer from '@virtual-space/ui/components/dm/myManager/online/detail/MyManagerOnlineDetailContainer';
import MyManagerOnlineListContainer from '@virtual-space/ui/components/dm/myManager/online/list/MyManagerOnlineListContainer';
import MessageDetailContainer from '@virtual-space/ui/components/dm/user/detail/MessageDetailContainer';

const MessengerLayout = lazy(
	() => import('@client/site/dating/ui/components/layout/MessengerLayout'),
);
const MessageListContainer = lazy(
	() =>
		import('@virtual-space/ui/components/dm/user/list/MessageListContainer'),
);
const MessengerRouter = () => {
	useMessageRouteHandler();
	const location = useLocation();

	return (
		<Suspense fallback={<Spinner />}>
			<Routes location={location}>
				{/*사용자-사용자 메신저*/}
				<Route path="/" element={<MessengerLayout />}>
					<Route index element={<MessageListContainer />} />
					<Route path=":roomId" element={<MessageDetailContainer />} />

					{/*구매한 매니저 있을 때*/}
					<Route element={<MyManagerLayout />}>
						{' '}
						{/*프로필카드, 온/오프라인 탭*/}
						<Route
							path="managerOnline"
							element={<MyManagerOnlineListContainer />}
						/>
						<Route
							path="managerOffline"
							element={<MyManagerOfflineListContainer />}
						/>
					</Route>

					{/*구매한 매니저 없을 때*/}
					<Route path="managerNone" element={<ManagerADContainer />} />

					{/*매니저 메신저룸*/}
					<Route
						path="managerOnline/:roomId"
						element={<MyManagerOnlineDetailContainer />}
					/>
					<Route
						path="managerOffline/:roomId"
						element={<MyManagerOfflineDetailContainer />}
					/>
				</Route>
			</Routes>
		</Suspense>
	);
};
export default MessengerRouter;
