import Phaser from 'phaser';
import BootstrapScene from './metaverse/scenes/BootstrapScene';
import GameScene from './metaverse/scenes/GameScene';

export const createPhaser = () => {
	const config: Phaser.Types.Core.GameConfig = {
		type: Phaser.AUTO,
		backgroundColor: '#ffffff',
		pixelArt: !1,
		scale: {
			mode: Phaser.Scale.RESIZE,
			parent: 'phaser-container',
			width: window.innerWidth,
			height: window.innerHeight,
		},
		input: {
			windowEvents: !1,
		},
		roundPixels: !0,
		physics: {
			default: 'arcade',
			arcade: {
				fixedStep: !1,
				gravity: { x: 0, y: 0 },
				debug: false,
			},
		},
		transparent: !1,
		autoFocus: true,
		scene: [BootstrapScene, GameScene],
		antialias: !0,
	};

	return new Phaser.Game(config);
};
