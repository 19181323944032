import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useEffect, useState } from 'react';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { SearchPostCIVO } from '@client/site/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';

export const useLoungeHeaderService = () => {
	const {
		openSearchBox,
		setOpenSearchBox,
		keyword,
		setKeyword,
		setSearchResult,
	} = useLoungeRepo();

	const { myProfile, srcPrefix, setMyProfileOpen } = useDatingRepo();

	const { getSearchPosts } = useLoungeAdapter();

	const navigate = useNavigate();

	const location = useLocation();

	// throttle
	const [isThrottled, setIsThrottled] = useState(false);

	const tabControl = () => {
		if (keyword.length > 1 && !isThrottled) {
			const map: SearchPostCIVO = { searchWord: keyword.trim() };
			getSearchPosts(map)
				.then((value) => {
					if (value) {
						navigate(PATH.LOUNGE.SEARCH);
						setSearchResult(value);
					}
				})
				.catch((reason) => {});

			setIsThrottled(true);
			setTimeout(() => {
				setIsThrottled(false);
			}, 1000); // 1초 지연
		} else if (keyword.length === 0) {
			navigate(PATH.LOUNGE.LIST);
		}
	};

	useEffect(() => {
		if (
			location.pathname == PATH.LOUNGE.SEARCH ||
			location.pathname == PATH.LOUNGE.LIST
		)
			tabControl();
	}, [keyword]);

	return {
		myProfile,
		srcPrefix,
		setMyProfileOpen,
		openSearchBox,
		setOpenSearchBox,
		keyword,
		setKeyword,
	};
};
