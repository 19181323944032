import { Link, useNavigate } from 'react-router-dom';
import { useStoreService } from '@dating/ui/pages/store/service/useStoreService';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import { useTranslation } from 'react-i18next';

const StoreContainer = () => {
	const { pointProducts, myBerith, managerPack, fnChargePoint, currencyCode } =
		useStoreService();

	const navigate = useNavigate();

	const {
		fnCommonAlertPopOn,
	} = useCommonAlertPopService();

	const { t } = useTranslation('store');

	return (
		<div className="rightShow">
			<header>
				<div className="headerWrap">
					<div className="h-left">
						<button
							type="button"
							className="btnBack"
							onClick={() => {
								navigate(-1);
							}}
						/>
					</div>
					<div className="h-center">
						<p className="title02">{t('store')}
							{/* 스토어 */}
						</p>
					</div>
					<Link to={PATH.STORE.MY_BERITH}>
						<div className="h-right">
							<p className="ic-berith sm">{myBerith ? myBerith : 0}</p>
						</div>
					</Link>
				</div>
			</header>

			<div className="popConWrap">
				<div className="inner">
					<h2 className="title02 margin-t24">{t('coupleManagerService')}
						{/* 커플 매니저 서비스 */}
					</h2>
					<div className="storeWrap">
						<div className="topBanner padding-t16">
							{/* 단순 채팅을 넘어 그 이상을 원한다면 아래의 커플 매니저 서비스를 이용해 보세요 */}
							<img
								src="/assets/img/common/cp-banner02.png"
								alt={t('coupleManagerServiceAlt')}
							/>
						</div>

						{/*매니저 구매*/}
						<div className="storeMgList">
							{managerPack.map((value, idx) => {
								return (
									<div
										key={value.type + idx}
										className={`mgBox ${value.hot ? 'hotBox' : ''}`}
										onClick={() => {
											if (import.meta.env.MODE === 'prd') {
												// 운영
												fnCommonAlertPopOn(t('serviceReady'));

											} else {
												// 개발
												if (value.type === 'off') {
													navigate(PATH.STORE.OFFLINE);
												} else {
													navigate(PATH.STORE.SCREEN);
												}
											}
										}}
									>
										<button type="button">
											<p
												className={
													value.type === 'off' ? 'ic-offline' : 'ic-screen'
												}
											>
												<strong className="en">
													{value.type === 'off' ? 'OFFLINE' : 'SCREEN'}
												</strong>{' '}
												{t('matchingManager')}
												{/* 매칭 매니저 */}
											</p>
										</button>
									</div>
								);
							})}
						</div>

						{/*베리드 구매*/}
						<div className="refillWrap">
							<h2 className="title02 margin-t24">{t('chargeBerith')}
								{/* 베리드 충전 */}
							</h2>
							<div className="refillList">
								{pointProducts.map((value, idx) => {
									return (
										<button
											key={value.id + idx}
											type="button"
											className={`rfBox ${value.id === 11 ? 'bestBox' : ''}`}
											onClick={() => {
												if (value.productKey !== '') {
													fnChargePoint(value.id, value.productKey);
												}
											}}
										>
											<div className="d-flex">
												<p className="ic-berith md">
													{value.point + value.bonusPoint} {t('pieces')}
													{/* 개 */}
												</p>
												<p className="cancell">{value.point} {t('pieces')}
													{/* 개 */}
												</p>
											</div>
											<span className="price">
                                                {value.price
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {currencyCode}
												{/* 원 */}
                                            </span>
										</button>
									);
								})}
							</div>
						</div>
						<p className="margin-t48 bold-500">{t('note')}
							{/* 유의사항 */}
						</p>
						<ul className="exTxtList">
							<li>{t('note1')}
								{/* 회원님의 발급코드로 가입이 완료된 신규회원에 따라 각각 베리드 보상이 주어집니다. */}
							</li>
							<li>{t('note2')}
								{/* 이벤트 취지에 맞지 않는 부정행위가 확인되는 경우 이벤트 회수 혹은 이벤트 참여 제한 등의 조치가 적용될 수 있습니다. */}
							</li>
							<li>{t('note3')}
								{/* 본 이벤트는 사전 예고 없이 변경되거나 종료될 수 있습니다. */}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StoreContainer;