import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import type { UserTermsCIVO } from '@client/site/api';
import { useAgreementTermsStateRepo } from '@dating/ui/pages/register/agreementTerms/useAgreementTermsStateRepo';
import { useEffect } from 'react';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useNavigate } from 'react-router-dom';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export const useAgreementTermsService = () => {
	const { btnDisabled, setBtnDisabled, allAgreement, setAllAgreement } =
		useAgreementTermsStateRepo();

	const {
		step,
		setStep,
		phoneNumber,
		resetRegisterInfo,
		checkedAgreements,
		setCheckedAgreements,
		termsList,
		setTermsList,
		requiredIndexes,
		setRequiredIndexes,
		setSelectedTerm,
		SNSInfo,
		registerSteps,
	} = useRegisterRepo();

	const { setAuthToken, langCode, setDeviceId } = useAuthRepo();

	const { saveUserTerms } = useRegisterAdapter();

	const { getTerms } = useSettingAdapter();
	//////////////////////////////////////////////////////////////

	const navigate = useNavigate();
	/*
	 * fnSaveUserTerms
	 * 동의한 약관 저장
	 */
	const fnSaveUserTerms = async () => {
		const termsIds = new Array<string>();
		const resultAgreement = checkedAgreements;
		for (let agreement in resultAgreement) {
			if (resultAgreement[agreement]) {
				termsIds.push(agreement);
			}
		}

		const map: UserTermsCIVO = {
			termsId: termsIds,
			mobilePhoneNumber: phoneNumber,
			deviceId: useAuthRepo.getState().deviceId,
			langCode: langCode,
		};

		if (SNSInfo.resultCode == 'empty') {
			map.id = SNSInfo.id;
			map.email = SNSInfo.email;
			map.socialType = SNSInfo.socialType;
		}

		saveUserTerms(map)
			.then((response) => {
				if (response.result) {
					/* 미인증회원도 토큰 저장하고 있어서 주석처리
                // 토큰값 저장
                if (response.accessToken && response.refreshToken) {
                    setAuthToken({
                        accessJmt: response.accessToken,
                        refreshJmt: response.refreshToken,
                    });
                }
                * */
					setStep(1);
					navigate(registerSteps[1].url);
				}
			})
			.catch((reason) => {
				console.log(reason.body);
			});
	};

	/*
	 * handleAllCheckBox
	 * 전체 약관 동의
	 */
	const handleAllCheckBox = (state) => {
		setAllAgreement(state);

		if (state) {
			const newItem = checkedAgreements;
			for (const type in newItem) {
				newItem[type] = true;
			}
			setCheckedAgreements(newItem);
		} else {
			const newItem = checkedAgreements;
			for (const type in newItem) {
				newItem[type] = false;
			}
			setCheckedAgreements(newItem);
		}
	};

	/*
	 * checkboxHandler
	 * 개별 약관 체크 시 useState set
	 */
	const checkboxHandler = (type: string, state: boolean) => {
		const newItem = {
			...checkedAgreements,
			[type]: state,
		};
		setCheckedAgreements(newItem);
	};

	useEffect(() => {
		// 약관 모두 체크되어있으면 전체동의에 체크
		let allChecked = false;
		if (Object.keys(checkedAgreements).length === termsList.length) {
			for (const type in checkedAgreements) {
				if (checkedAgreements[type]) {
					allChecked = true;
				} else {
					allChecked = false;
					break;
				}
			}
		}

		if (allChecked) {
			setAllAgreement(true);
		} else {
			setAllAgreement(false);
		}

		// 필수 약관에 모두 체크되어있으면 다음 버튼 활성화
		let allRequired = false;
		for (const index of requiredIndexes) {
			if (checkedAgreements[index]) {
				allRequired = true;
			} else {
				allRequired = false;
				break;
			}
		}

		// 필수 약관에 모두 체크 또는 전체 약관 체크 시 다음 버튼 활성화
		if (allRequired || allChecked) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
		}
	}, [checkedAgreements, allAgreement]);

	useEffect(() => {
		if (step === 0) {
			// 약관 리스트 api 요청 및 useState set
			getTerms()
				.then((response) => {
					if (response.length > 0 && termsList.length === 0) {
						setTermsList(response);

						let newItem: {}[] = [];
						let newIndexes: string[] = [];

						for (const [i, el] of response.entries()) {
							newItem = {
								...newItem,
								[el.termsId]: false,
							};

							if (el.required) {
								newIndexes.push(el.termsId);
							}
						}
						setCheckedAgreements(newItem);
						setRequiredIndexes(newIndexes);
					}
				})
				.catch((reason) => {});
		}
	}, [step]);

	return {
		allAgreement,
		checkedAgreements,
		btnDisabled,
		fnSaveUserTerms,
		handleAllCheckBox,
		checkboxHandler,
		termsList,
		resetRegisterInfo,
		setSelectedTerm,
	};
};
