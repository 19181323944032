import { create } from 'zustand';
import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';

interface PlayerBehaviorState {
	// 현재 플레이어의 행동 상태
	playerBehavior: PlayerBehavior;
	// 플레이어 행동 상태 설정 함수
	setPlayerBehavior: (behavior: PlayerBehavior) => void;
	// 앉아있는 상태인지 빠르게 확인하는 헬퍼 함수
	isSitting: () => boolean;
}

// 플레이어 행동 상태를 관리하는 Zustand 스토어
export const usePlayerBehaviorRepo = create<PlayerBehaviorState>(
	(set, get) => ({
		// 초기 상태는 IDLE
		playerBehavior: PlayerBehavior.IDLE,

		// 플레이어 행동 상태 설정 함수
		setPlayerBehavior: (behavior: PlayerBehavior) => {
			set({ playerBehavior: behavior });
		},

		// 현재 앉아있는 상태인지 확인하는 헬퍼 함수
		isSitting: () => {
			return get().playerBehavior === PlayerBehavior.SITTING;
		},
	}),
);
