import { Link } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';
import UserProfileImageView from '../../../views/list/UserProfileImageView';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import {
	formatDate,
	sortMessagesByDate,
} from '@virtual-space/utils/messageUtils';

const MessageListItemView = ({
	message,
	myProfile,
	srcPrefix,
	setUserProfileOpen,
	setOpenDirectMessageDetail,
}) => {
	const { t } = useTranslation('messenger');

	const {
		billingStatus,
		dmRoomType,
		roomId,
		otherUserInfo,
		messages,
		latestCreatedAt,
		visibleTo,
		isUserLeft,
		isOtherUserLeft,
		unreadCount,
		display,
		user1Info,
	} = message;

	// 표시되지 않아야 하는 메시지는 렌더링하지 않음
	if (isUserLeft || visibleTo === -1) {
		return null;
	}

	const createMessageListState = () => {
		const isUser1 = user1Info?.userUid === myProfile.userUid;
		const sortedMessagesData = sortMessagesByDate(messages, true);

		return {
			billingStatus,
			myPaymentStatus: isUser1 ? billingStatus === 1 : billingStatus === 2,
			otherPaymentStatus: isUser1 ? billingStatus === 2 : billingStatus === 1,
			dmRoomType,
			roomId,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			user1Info: user1Info || {
				userUid: '',
				nickName: '',
				profileUrl: '',
				location: '',
				age: '',
				blockType: '',
			},
			otherUserInfo: otherUserInfo || {
				userUid: '',
				nickName: '',
				profileUrl: '',
				location: '',
				age: '',
				blockType: '',
			},
			createdAt: latestCreatedAt,
			messages: sortedMessagesData,
		};
	};

	const sortedMessages = sortMessagesByDate(messages, false); // 역순 정렬
	const latestMessage = sortedMessages[0]?.content;

	const handleRoomClick = (e) => {
		e.stopPropagation();
		setOpenDirectMessageDetail(true, roomId);
		dmEventEmitter.emit('react-enter-dm-room', { roomId });
		setUserProfileOpen(otherUserInfo.userUid);
	};

	return (
		<div className="profileType">
			<div className="proLeft width-100p">
				<UserProfileImageView
					isOtherUserLeft={isOtherUserLeft}
					otherUserInfo={otherUserInfo}
					srcPrefix={srcPrefix}
					onClick={() => setUserProfileOpen(otherUserInfo.userUid)}
				/>

				<Link
					className="proName margin-l8 flex-1 margin-t5"
					style={{ width: 'calc(100% - 78px)', display: 'block' }}
					to={`${PATH.MESSENGER.ROOT}/${roomId}`}
					state={createMessageListState()}
					onClick={handleRoomClick}
				>
					<p className="d-flex justify-between align-center">
						<span className="bold">{otherUserInfo.nickName}</span>
						{!isOtherUserLeft && (
							<span className="fz10 color-999 bold-500">
								{messages.length > 0
									? formatDate(messages[0].createdAt)
									: formatDate(latestCreatedAt)}
							</span>
						)}
					</p>

					{!isOtherUserLeft && (
						<p className="fz12 color-999 padding-b4">
							{otherUserInfo.region1 ? otherUserInfo.region1 : t('noRegion')} /{' '}
							{otherUserInfo.age + t('howOld')}
						</p>
					)}

					<div
						className={`d-flex justify-between align-center ${
							isOtherUserLeft ? 'margin-t8' : ''
						}`}
					>
						<p
							className={`ellipsis ${
								isOtherUserLeft ? 'color-red fz14' : 'fz12'
							} ${display?.className || ''}`}
						>
							{isOtherUserLeft
								? t('disconnectMsg')
								: display?.prefix || latestMessage || ''}
						</p>

						{sortedMessages.length > 0 &&
							sortedMessages[0].sender.userUid === otherUserInfo.userUid &&
							unreadCount > 0 && (
								<span className="chatMsgOn margin-l5">{unreadCount}</span>
							)}
					</div>
				</Link>
			</div>
		</div>
	);
};

export default MessageListItemView;
