import { useJoystickRepo } from '@virtual-space/stores/useJoystickRepo';
import { NavKeys, Keyboard } from '@virtual-space/types/KeyboardState';

export class MyDirectionCursor {
	private cursors: NavKeys;

	constructor(private keyboard: Phaser.Input.Keyboard.KeyboardPlugin) {
		this.cursors = {
			...keyboard.createCursorKeys(),
			...(keyboard.addKeys('W,S,A,D') as Keyboard),
		};
	}

	get left() {
		return (
			this.cursors.left?.isDown ||
			useJoystickRepo.getState().moreDirection.includes('LEFT')
		);
	}

	get right() {
		return (
			this.cursors.right?.isDown ||
			useJoystickRepo.getState().moreDirection.includes('RIGHT')
		);
	}

	get up() {
		return (
			this.cursors.up?.isDown ||
			useJoystickRepo.getState().moreDirection.includes('FORWARD')
		);
	}

	get down() {
		return (
			this.cursors.down?.isDown ||
			useJoystickRepo.getState().moreDirection.includes('BACKWARD')
		);
	}
}
