import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { bridgeRequestLocation } from '@client/utils/bridgeCallHandler';
import { coords } from '@dating/constants/TempGpsCoordinates';
import { useTranslation } from 'react-i18next';

interface props {
	fnFormatLocation: (loginUpdate: boolean) => void;
}

const LocationInfoFooterView = ({ fnFormatLocation }: props) => {
	const { t } = useTranslation('register');

	return (
		<div className="inner padding-t16 padding-b16">
			<button
				type="button"
				className="btn btnBig btnBlack"
				onClick={() => {
					fnFormatLocation(true);
				}}
			>
				<span>{t('locationStep.accessBtn')}</span>
			</button>
		</div>
	);
};

export default LocationInfoFooterView;
