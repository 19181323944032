import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useChatRepo } from '@virtual-space/stores/useChatRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import Phaser from 'phaser';

export class KeyboardHandler {
	private sessionId: string;

	constructor(private input: Phaser.Input.InputPlugin) {
		// 사용자 세션 ID를 저장하는 변수
		this.sessionId = '';
	}

	execute() {
		// 사용자 세션 ID를 상태에서 가져와서 설정
		this.sessionId = useMetaUserRepo.getState().sessionId;
		// 키보드 이벤트 설정 메서드 호출
		this.setupKeyboardEvents();
	}

	private setupKeyboardEvents() {
		// 전역 키보드 이벤트 캡처 비활성화
		this.input.keyboard!.disableGlobalCapture();
		this.input.keyboard!.enabled = true;

		// 각 키와 이모지 간의 매핑
		const emojiKeyMappings: Record<string, string> = {
			ONE: '👍',
			TWO: '👏',
			THREE: '❤️',
			FOUR: '😆',
			FIVE: '😂',
		};

		// 매핑된 각 키에 대한 이벤트 핸들러 등록
		// Object.entries(emojiKeyMappings).forEach(([key, emoji]) => {
		// 	this.input.keyboard!.on(`keydown-${key}`, () => {
		// 		this.handleEmojiKeyPress(emoji);
		// 	});
		// });

		// 엔터 키 누름 이벤트 핸들러 등록
		this.input.keyboard!.on('keydown-ENTER', () => {
			// 엔터 키 누름 이벤트 핸들링 메서드 호출
			this.handleEnterKeyPress();
		});

		// ESC 키 누름 이벤트 핸들러 등록
		this.input.keyboard!.on('keydown-ESC', () => {
			// ESC 키 누름 이벤트 핸들링 메서드 호출
			this.handleEscKeyPress();
		});

		// 스페이스바 키보드 이벤트 리스너 등록
		this.input.keyboard!.on('keydown-SPACE', function (event) {
			// 스페이스바의 기본 동작 막기
			event.stopPropagation();
		});
	}

	private handleEmojiKeyPress(emoji: string) {
		// React 이벤트 발생기로 이모지 클릭 이벤트 발생
		reactEventEmitter.emit('react-click-emoji', {
			senderId: this.sessionId,
			emoji,
		});
	}

	private handleEnterKeyPress() {
		const { focused, setShowChat, setFocused } = useChatRepo.getState();

		if (!focused) {
			setShowChat(true);
			setFocused(true);
		}
	}

	private handleEscKeyPress() {
		const { setShowChat } = useChatRepo.getState();
		setShowChat(false);
	}
}
