import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useOrderUtils } from '@client/utils/orderUtils';
import { useManagerAdapter } from '@dating/adapter/manager/useManagerAdapter';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useOnlineManagerService = () => {
	const [openScreenProducts, setOpenScreenProducts] = useState(false);

	const navigate = useNavigate();
	const { managerProducts } = useProductsRepo();

	const {
		setIsOnline,
		setManagerEmpty,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
	} = useDirectMessageRepo();

	const { fnValidateOrder } = useOrderUtils();

	const { purchaseManagerProduct } = useManagerAdapter();

	// API 호출을 통해 방 생성

	/////////////////////////////

	/*
	 * fnOpenScreenProducts
	 * 스크린 매니저 구매목록 호출
	 */
	const fnOpenScreenProducts = () => {
		setOpenScreenProducts(true);
	};

	/*
	 * fnCloseScreenProducts
	 * 스크린 매니저 구매목록 닫기
	 */
	const fnCloseScreenProducts = () => {
		setOpenScreenProducts(false);
	};

	/*
	 * fnSaveCreateMessageRoom
	 * 메세지 방 생성
	 */

	// const fnSaveCreateMessageRoom = async (param: {
	// 	roomId: string;
	// 	toUserUid: string;
	// 	billingStatus: number;
	// 	roomType: string;
	// 	orderId: number;
	// }) => {
	// 	const map: CreateMessageRoomCIVO = {
	// 		roomId: param.roomId,
	// 		toUserUid: param.toUserUid,
	// 		billingStatus: param.billingStatus,
	// 		roomType: 'MANAGER',
	// 		orderId: param.orderId,
	// 	};
	// 	try {
	// 		createMessageRoom(map);
	// 	} catch (error) {
	// 		console.error('방 생성 실패:', error);
	// 	}
	// };

	/*
	 * fnOpenScreenMessageRoom
	 * 매니저 구매 완료 시 메세지방 만들고 메세지 룸으로 이동
	 */
	const fnOpenScreenMessageRoom = async (params: {
		productId: number;
		orderId: number;
	}) => {
		const matchResult = await purchaseManagerProduct(params.orderId);

		setIsOnline(true);
		setManagerEmpty(false), setShowOfflineMessageList(false);
		setShowOnlineMessageList(true);
		navigate(PATH.MESSENGER.MANAGER_ONLINE);
	};

	return {
		openScreenProducts,
		fnOpenScreenProducts,
		managerProducts,
		fnCloseScreenProducts,
		fnValidateOrder,
		fnOpenScreenMessageRoom,
	};
};
