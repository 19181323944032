import RegisterHeaderView from '../common/registerHeader/views/RegisterHeaderView';
import LocationInfoFooterView from './views/LocationInfoFooterView';
import useLocationInfoService from '@dating/ui/pages/register/locationInfo/service/useLocationInfoService';
import React from 'react';
import {
	useRegisterHeaderService,
} from '@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService';
import StepProgressView from '@dating/ui/pages/register/common/stepProgress/views/StepProgressView';
import {useTranslation} from "react-i18next";

const LocationInfoContainer = () => {
	const {
		fnFormatLocation,
	} = useLocationInfoService();

	const {
		step,
	} = useRegisterHeaderService();

	const { t } = useTranslation('register')

	return (
		<div className="wrap noFooter">
			<RegisterHeaderView/>

			<div className="contentsWrap hiddenCon padding-b0 margin-b0">
				<div className="titleWrap padding-b0">
					<StepProgressView step={step} />
					<h2 className="title01">{t('locationStep.locationQuote')}</h2>
					<p className="titleSub01" dangerouslySetInnerHTML={{ __html: t('locationStep.locationQuote2') }}>
					</p>
				</div>
				<div className="myTypeNone text-c">
					<img
						src="/assets/img/common/ico-my-locate.png"
						width="140"
						alt=""
					></img>
				</div>
			</div>

			<LocationInfoFooterView
				fnFormatLocation={fnFormatLocation}
			/>

		</div>
	);
};

export default LocationInfoContainer;
