import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useActionButtonRepo } from '@virtual-space/stores/useActionButtonRepo';
import { useChatRepo } from '@virtual-space/stores/useChatRepo';
import { useKeyPressedRepo } from '@virtual-space/stores/useKeyPressedRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { usePlayerBehaviorRepo } from '@virtual-space/stores/usePlayerBehaviorRepo';
import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import { useState, useEffect } from 'react';

export const useLowerRightButtonService = () => {
	const { showChat } = useChatRepo();
	const [clickValue, setClickValue] = useState('');
	const [closeButton, setCloseButton] = useState(false);
	const { sessionId } = useMetaUserRepo();
	const { setRKeyPressed, setEKeyPressed, setFKeyPressed, setSpaceKeyPressed } =
		useKeyPressedRepo();
	const { actionButtonActive } = useActionButtonRepo();
	const isSitting = usePlayerBehaviorRepo((state) => state.isSitting());

	/* ---------- ActionButton ---------- */
	function handleAction(event: any): void {
		const eventAction = event.type;
		setFKeyPressed(eventAction);
	}

	function handleJumpAction(event: any): void {
		const eventAction = event.type;
		setSpaceKeyPressed(eventAction);
	}

	const handleClick = (event: any) => {
		if (actionButtonActive) {
			handleAction(event);
		} else {
			handleJumpAction(event);
		}
	};

	/* ---------- ReactionButton ---------- */
	function handleHelloAction(event: any): void {
		// 앉아있는 상태인지 확인
		if (isSitting) {
			return;
		}

		const eventAction = event.type;
		setRKeyPressed(eventAction);
	}

	function handleDanceAction(event: any): void {
		// 앉아있는 상태인지 확인
		if (isSitting) {
			return;
		}

		const eventAction = event.type;
		setEKeyPressed(eventAction);
	}

	const handleReactionClick = (emoji: string) => {
		setClickValue(emoji);
	};

	useEffect(() => {
		if (clickValue) {
			reactEventEmitter.emit('react-click-emoji', {
				senderId: sessionId,
				emoji: clickValue,
			});
		}
		setClickValue('');
	}, [clickValue]);
	return {
		showChat,
		closeButton,
		actionButtonActive,
		handleClick,
		handleHelloAction,
		handleDanceAction,
		handleReactionClick,
		setCloseButton,
	};
};
