/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManagerProfileCOVO } from '../models/ManagerProfileCOVO';
import type { ModifyUserProfileCOVO } from '../models/ModifyUserProfileCOVO';
import type { PostInfoCOVO } from '../models/PostInfoCOVO';
import type { ProfileDrinkCIVO } from '../models/ProfileDrinkCIVO';
import type { ProfileHeightCIVO } from '../models/ProfileHeightCIVO';
import type { ProfileInterestCIVO } from '../models/ProfileInterestCIVO';
import type { ProfileIntroCIVO } from '../models/ProfileIntroCIVO';
import type { ProfileJobCIVO } from '../models/ProfileJobCIVO';
import type { ProfileLifeStyleCIVO } from '../models/ProfileLifeStyleCIVO';
import type { ProfileNicknameCIVO } from '../models/ProfileNicknameCIVO';
import type { ProfileReligionCIVO } from '../models/ProfileReligionCIVO';
import type { ProfileSmokingCIVO } from '../models/ProfileSmokingCIVO';
import type { UserAuthBadgeCOVO } from '../models/UserAuthBadgeCOVO';
import type { UserPreferCOVO } from '../models/UserPreferCOVO';
import type { UserProfileCOVO } from '../models/UserProfileCOVO';
import type { UserScoreCIVO } from '../models/UserScoreCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProfileService {
    /**
     * 나의 프로필 상세 조회
     * @param phoneNumber 휴대폰 번호
     * @returns UserProfileCOVO
     * @throws ApiError
     */
    public static getMyProfile(
        phoneNumber?: string,
    ): CancelablePromise<UserProfileCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getMyProfile',
            query: {
                'phoneNumber': phoneNumber,
            },
        });
    }
    /**
     * 나의 프로필 수정 화면 조회
     * @returns ModifyUserProfileCOVO
     * @throws ApiError
     */
    public static getModifyMyProfile(): CancelablePromise<ModifyUserProfileCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getModifyMyProfile',
        });
    }
    /**
     * 프로필 상세 조회
     * @param findUserUid 조회할 유저 UID
     * @returns UserProfileCOVO
     * @throws ApiError
     */
    public static getUserProfile(
        findUserUid: string,
    ): CancelablePromise<UserProfileCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getUserProfile/{findUserUid}',
            path: {
                'findUserUid': findUserUid,
            },
        });
    }
    /**
     * 프로필 이미지 수정
     * @param formData
     * @returns boolean 회원 프로필 정보 저장
     * @throws ApiError
     */
    public static modifyProfileImage(
        formData: {
            files?: Array<Blob>;
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileImage',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * 프로필 닉네임 수정
     * @param requestBody 프로필 닉네임 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileNickName(
        requestBody: ProfileNicknameCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileNickName',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 직업 수정
     * @param requestBody 프로필 직업 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileJob(
        requestBody: ProfileJobCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileJob',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 키 수정
     * @param requestBody 프로필 키 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileHeight(
        requestBody: ProfileHeightCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileHeight',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 자기소개 수정
     * @param requestBody 프로필 자기소개 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileIntro(
        requestBody: ProfileIntroCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileIntro',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 라이프스타일 수정
     * @param requestBody 프로필 라이프 스타일 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileLifeStyle(
        requestBody: ProfileLifeStyleCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileLifeStyle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 관심사 수정
     * @param requestBody 프로필 관심사 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileInterest(
        requestBody: ProfileInterestCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileInterest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 음주 수정
     * @param requestBody 프로필 음주 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileDrink(
        requestBody: ProfileDrinkCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileDrink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 흡연 수정
     * @param requestBody 프로필 흡연 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileSmoking(
        requestBody: ProfileSmokingCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileSmoking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 종교 수정
     * @param requestBody 프로필 종교 정보
     * @returns boolean
     * @throws ApiError
     */
    public static modifyProfileReligion(
        requestBody: ProfileReligionCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/modifyProfileReligion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 유저 이상형 조회
     * @returns UserPreferCOVO
     * @throws ApiError
     */
    public static getUserPrefer(): CancelablePromise<UserPreferCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getUserPrefer',
        });
    }
    /**
     * 인증 Badge 조회
     * @returns UserAuthBadgeCOVO
     * @throws ApiError
     */
    public static getBadge(): CancelablePromise<Array<UserAuthBadgeCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getBadge',
        });
    }
    /**
     * 유저의 인증 Badge 조회
     * @param findUserUid
     * @returns UserAuthBadgeCOVO
     * @throws ApiError
     */
    public static getUsersBadge(
        findUserUid: string,
    ): CancelablePromise<Array<UserAuthBadgeCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getUsersBadge/{findUserUid}',
            path: {
                'findUserUid': findUserUid,
            },
        });
    }
    /**
     * 인증 뱃지 신청
     * @param formData
     * @returns boolean
     * @throws ApiError
     */
    public static saveProfileBadge(
        formData: {
            files?: Array<Blob>;
            /**
             * 인증 신청 content
             */
            contents?: string;
            /**
             * 뱃지 대분류 : 직업(JOB), 학력(SCHOOL), 연소득(INCOME), 자가(HOUSE), 자산(ASSET), 차량(CAR), 기타(ETC)
             */
            badgeType?: string;
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/saveProfileBadge',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * 매니저 프로필 조회
     * @param findManagerUid 조회할 매니저 UID
     * @returns ManagerProfileCOVO
     * @throws ApiError
     */
    public static getManagerProfile(
        findManagerUid: string,
    ): CancelablePromise<ManagerProfileCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getManagerProfile/{findManagerUid}',
            path: {
                'findManagerUid': findManagerUid,
            },
        });
    }
    /**
     * 유저프로필 내 별점평가
     * @param requestBody 유저 회원 ID및 점수
     * @returns boolean
     * @throws ApiError
     */
    public static saveScoreUser(
        requestBody: UserScoreCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/saveScoreUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 내가 작성한 게시글 전체 조회
     * @returns PostInfoCOVO 게시글 리스트
     * @throws ApiError
     */
    public static getMyPosts(): CancelablePromise<Array<PostInfoCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/getMyPosts',
        });
    }
}
