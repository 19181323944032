import Phaser, { Scene } from 'phaser';
import { animationFrame } from './AnimationFrame';
import {
	getCharacter,
	parseAvatarString,
} from '@virtual-space/utils/avatarUtils';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';

const cacheTexture: { [key: string]: boolean } = {};
const cacheTextureStart: { [key: string]: boolean } = {};
const cache: { [key: string]: HTMLImageElement } = {};

// 캐릭터별 색상 폴더 정보 (이전 버전과 비교용)
const characterColors: {
	[key: string]: {
		costumeColor?: string;
		faceColor?: string;
		hairColor?: string;
	};
} = {};

// avatarName에서 초기 색상 폴더 정보 추출하는 함수
export const extractColorInfo = (avatarName: string) => {
	const avatarParts = parseAvatarString(avatarName);
	return {
		costumeColor: `costumes${avatarParts.cc}`,
		faceColor: `faces${avatarParts.fc}`,
		hairColor: `hairs${avatarParts.hc}`,
	};
};

// 캐시 정리 함수
export const clearCharacterCache = (scene: Scene, name?: string) => {
	const clearSingleCharacter = (charName: string) => {
		// 현재 로딩 중인 캐릭터는 건너뛰기
		if (cacheTextureStart[charName]) {
			return;
		}

		// 텍스처가 실제로 존재하는지 확인 후 제거
		if (scene.textures.exists(charName)) {
			scene.textures.remove(charName);
		}
		if (scene.textures.exists(charName + '_canvas')) {
			scene.textures.remove(charName + '_canvas');
		}

		// 캐시에서 제거
		delete cacheTexture[charName];
		delete cache[charName];
	};

	if (name) {
		clearSingleCharacter(name);
	} else {
		// 현재 사용 중이지 않은 캐릭터만 정리
		Object.keys(cacheTexture)
			.filter((key) => !cacheTextureStart[key])
			.forEach(clearSingleCharacter);
	}
};

// 캐시된 이미지를 확인하고 이미 존재한다면 즉시 Promise를 반환
export const createCharacterAnims = async (
	scene: Scene,
	name: string,
): Promise<boolean> => {
	// 캐릭터 폴더 변경 감지를 위한 변수
	let folderChanged = false;

	// 현재 캐릭터 이미지 정보
	const { parts, colors } = getCharacter(name);
	const myMetaUser = useMetaUserRepo.getState().myMetaUser;
	// 이전에 로드한 색상 폴더 정보 (없으면 빈 객체)
	const prevColors = characterColors[myMetaUser.avatarName] || {};

	// 폴더 변경 감지 (첫 로드가 아닐 경우)
	if (prevColors.costumeColor || prevColors.faceColor || prevColors.hairColor) {
		// 색상 폴더가 변경되었는지 확인
		if (
			prevColors.costumeColor !== colors.costumeColor ||
			prevColors.faceColor !== colors.faceColor ||
			prevColors.hairColor !== colors.hairColor
		) {
			folderChanged = true;
		}
	}

	// 변경이 없고 캐시에 있으면 기존 캐시 사용
	if (!folderChanged && cacheTexture[name]) {
		return true;
	}

	// 캐시 크기 체크 (30개 초과시)
	const cacheSize = Object.keys(cache).length;
	if (cacheSize > 30) {
		// 현재 로딩 중이지 않은 가장 오래된 캐릭터 10개만 정리
		const oldestKeys = Object.keys(cache)
			.filter((key) => !cacheTextureStart[key])
			.slice(0, 10);

		oldestKeys.forEach((key) => {
			if (key !== name) {
				// 현재 로드하려는 캐릭터는 제외
				clearCharacterCache(scene, key);
			}
		});
	}

	// 이미지 로딩이 진행 중인 경우 대기
	while (cacheTextureStart[name]) {
		await new Promise((resolve) => setTimeout(resolve, 100));
	}

	// 이미지 로딩 시작을 표시
	cacheTextureStart[name] = true;

	// 가져온 이미지가 없다면 로딩 실패로 처리
	if (parts.length === 0) {
		cacheTextureStart[name] = false;
		return false;
	}

	// 현재 색상 폴더 정보 저장 (다음 로드 시 비교를 위해)
	characterColors[name] = {
		costumeColor: colors.costumeColor,
		faceColor: colors.faceColor,
		hairColor: colors.hairColor,
	};

	// Phaser 로더를 생성
	const load = new Phaser.Loader.LoaderPlugin(scene);

	// 이미지를 로드
	parts.forEach((elm) => {
		try {
			if (!elm) {
				return;
			}

			// 이미 이 키로 텍스처가 있는지 확인하고 있으면 제거 (경로 변경 또는 강제 리로드 시)
			if (folderChanged && scene.textures.exists(elm)) {
				scene.textures.remove(elm);
			}

			const file = new Phaser.Loader.File(load, { type: 'image', key: elm });

			// 이미지가 로드되지 않았다면 로드
			if (!load.keyExists(file)) {
				let fileName = '';
				let colorDirectory = '';
				let typeDirectory = '';

				// 이미지의 타입에 따라 디렉토리와 파일명을 설정
				const match = elm.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
				if (match == null) return;

				switch (match[0]) {
					case 'body':
						typeDirectory = 'body';
						break;
					case 'costume':
						typeDirectory = 'costumes';
						colorDirectory = `${colors.costumeColor}`;
						break;
					case 'face':
						typeDirectory = 'faces';
						colorDirectory = `${colors.faceColor}`;
						break;
					case 'hair':
						typeDirectory = 'hairs';
						colorDirectory = `${colors.hairColor}`;
						break;
					case 'costumes':
					case 'faces':
					case 'hairs':
						colorDirectory = `${elm}`;
						return '';
					default:
						colorDirectory = '';
						break;
				}

				// 코스튬, 페이스, 헤어의 경우 컬러 디렉토리를 추가
				if (
					match[0] === 'costume' ||
					match[0] === 'face' ||
					match[0] === 'hair'
				) {
					fileName = `/assets/character/avatar/${typeDirectory}/${colorDirectory}/${elm}.png`;
				} else {
					fileName = `/assets/character/avatar/${typeDirectory}/${elm}.png`;
				}

				// 이미지를 로드
				load.image(elm, fileName);
			}
		} catch (e) {
			console.error(`이미지 로드 중 오류:`, e);
		}
	});

	// 이미지 로드가 완료되면 Promise를 반환
	return new Promise((resolve) => {
		load.on('complete', async () => {
			// 텍스처 이미지를 가져와서 애니메이션 프레임을 설정
			const image = await getTextureImage(scene.textures, name, parts);
			const configs = animationFrame(scene.anims, name);
			configs.forEach((config) => scene.anims.create(config));

			// 조합된 이미지를 새로운 텍스처로 추가
			scene.textures.addImage(name, image);

			// 이미지 로딩이 완료되었음을 표시
			cacheTextureStart[name] = false;
			cacheTexture[name] = true;
			resolve(true);
		});

		// 로더를 시작
		load.start();
	});
};

// 텍스처 이미지를 가져오는 함수
export function getTextureImage(
	textures: Phaser.Textures.TextureManager,
	name: string,
	value: string[],
): Promise<HTMLImageElement> {
	// 이미지가 캐시되어 있다면 바로 Promise를 반환
	if (cache[name]) return Promise.resolve(cache[name]);

	// 캔버스를 생성하고 2D 컨텍스트에 접근
	const texture = textures.createCanvas(name + '_canvas', 384, 320);
	value.forEach((elm, index) => {
		// 특정 인덱스는 건너뛰기 (설정에 따라 예외적으로 처리됨)
		if (index === 4 || index === 5 || index === 6) {
			return;
		}
		const body01 = textures.get(elm).getSourceImage() as HTMLImageElement;
		texture?.draw(0, 0, body01);
	});

	// 이미지를 생성하고 텍스처에 추가
	const image = document.createElement('img') as HTMLImageElement;
	image.src = (texture?.getSourceImage() as HTMLCanvasElement).toDataURL();
	cache[name] = image;

	// Promise를 반환
	return new Promise((resolve, reject) => {
		image.onload = () => {
			if (!textures.exists(name)) {
				textures.addSpriteSheet(name, image, {
					frameWidth: 48,
					frameHeight: 64,
				});
			}
			resolve(image);
		};

		image.onerror = (e) => {
			console.error(`이미지 로드 오류:`, e);
			reject(e);
		};
	});
}
