import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { MatchedResultCOVO } from '@client/site/api';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useManagerRepo } from '@client/site/dating/repository/manager/useManagerRepo';
import { useManagerProductRepo } from '@client/site/dating/repository/store/useManagerProductRepo';
import { useState } from 'react';

export const useManagerPopService = () => {
	const { relatedRoomIds } = useDirectMessageRepo();
	const { managerProfile } = useManagerRepo();
	const { myProfile } = useDatingRepo();
	const [isCreatingRoom, setIsCreatingRoom] = useState(false);

	const {
		managerProductDetailOpen,
		searchCategory,
		purchasePopOpen,
		setPurchasePopOpen,
	} = useManagerProductRepo();

	/**
	 * 매니저 구매 팝업 열기
	 */
	const fnPurchasePopOn = () => {
		setPurchasePopOpen(true);
	};

	/**
	 * 매니저 구매 팝업 닫기
	 */
	const fnPurchasePopOff = () => {
		setPurchasePopOpen(false);
	};

	/**
	 * 매니저 메시지 방 생성
	 * @param currentRoomId 현재 방 ID
	 * @returns 생성된 방 데이터
	 */
	const createManagerMessageRoom = async (
		currentRoomId: string,
		product: MatchedResultCOVO,
		isOnline: boolean,
	) => {
		if (!myProfile.userUid || !managerProfile.userUid) {
			throw new Error('사용자 프로필 정보 없음');
		}

		// 발신자 정보 설정
		const sender = {
			userUid: myProfile.userUid,
			nickName: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			region1: myProfile.region1,
			region2: myProfile.region2,
			age: myProfile.age,
			inActive: false,
			isDeleted: false,
		};

		// 수신자(매니저) 정보 설정
		const receiver = {
			userUid: managerProfile.userUid,
			nickName: managerProfile.nickName,
			profileUrl: managerProfile.profileUrl[0],
			inActive: false,
			isDeleted: false,
		};

		// 방 데이터 생성
		const roomData = {
			billingStatus: 3,
			dmRoomType: 'MANAGER',
			roomId: currentRoomId,
			user1Id: myProfile.userUid,
			user2Id: managerProfile.userUid,
			user1Info: sender,
			user2Info: receiver,
			createdAt: Date.now().toString(),
			otherUserInfo: receiver,
			productType: 'MANAGER',
			productSubType: isOnline ? 'ONLINE' : 'OFFLINE',
			useStatus: 'WAIT',
			seq:
				product?.validUses && product?.availableCnt
					? product.validUses - product.availableCnt + 1
					: 0,
			validUses: product?.validUses,
			orderId: product?.orderId,
			matchedAt: null,
		};

		// 중복 방 생성 방지
		if (!relatedRoomIds.includes(currentRoomId)) {
			dmEventEmitter.emit('react-create-dm-room', roomData);
		}

		return roomData;
	};

	/**
	 * 매니저 구매 처리
	 * 방 생성 및 관련 데이터 설정을 처리
	 * @returns 생성된 방 데이터
	 */
	const handleManagerPurchase = async (
		product: MatchedResultCOVO | undefined | null,
		isOnline: boolean,
		result: any,
	) => {
		if (isCreatingRoom) return;
		if (!product) return;
		setIsCreatingRoom(true);
		try {
			// 먼저 매니저 상품 사용 API 호출
			const roomData = await createManagerMessageRoom(
				result.roomId,
				product,
				isOnline,
			);

			return roomData;
		} catch (error) {
			console.error('매니저 구매 처리 오류:', error);
		} finally {
			setIsCreatingRoom(false);
		}
	};

	return {
		fnPurchasePopOn,
		fnPurchasePopOff,
		purchasePopOpen,
		managerProductDetailOpen,
		searchCategory,
		handleManagerPurchase,
	};
};
