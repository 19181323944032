import { useEffect, useState } from 'react';
import { usePublicRoomService } from '../../room/publicRoom/service/usePublicRoomService';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useFooterRepo } from '@client/site/dating/repository/dating/useFooterRepo';
import { useMetaUserAdapter } from '@client/site/dating/adapter/meta/useMetaUserAdapter';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useHomeRepo } from '@client/site/dating/repository/home/useHomeRepo';
import useLoungeRepo from '@client/site/dating/repository/lounge/useLoungeRepo';

export const useAppFooterService = () => {
	const [isDMRoomJoined, setIsDMRoomJoined] = useState(false);

	const {
		homeOpen,
		loungeOpen,
		likeOpen,
		metaOpen,
		setHomeOpen,
		setLoungeOpen,
		setLikeOpen,
		setMetaOpen,
	} = useFooterRepo();

	const { myProfile } = useDatingRepo();

	const {
		totalUnreadCount,
		showDirectMessageList,
		setOpenDirectMessageDetail,
		setShowDirectMessageList,
		setIsOnline,
		setIsFirst,
	} = useDirectMessageRepo();
	const { saveMetaUser, getMyMetaUser } = useMetaUserAdapter();
	const { setHushHome } = useHomeRepo();
	const { feedCommentOpen, setFeedCommentOpen, setOpenSearchBox, setKeyword } =
		useLoungeRepo();

	const { myMetaUser, setMyMetaUser, openOtherPlayerProfile } =
		useMetaUserRepo();
	const { isMetaOpen, setIsMetaOpen, handleClickMeta } = usePublicRoomService();

	const navigate = useNavigate();

	const location = useLocation();

	const isEmptyUser = (user) => {
		return (
			!user ||
			Object.values(user).every((x) => x === null || x === '' || x === false)
		);
	};

	const handleHomeClick = () => {
		setHomeOpen(true);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(false);
		setMetaOpen(false);

		if (myProfile.role === 'NORMAL') {
			navigate(PATH.DATING.HOME);
		} else {
			navigate(PATH.MANAGER.HOME);
		}
	};

	const handleLoungeClick = () => {
		setHomeOpen(false);
		setLoungeOpen(true);
		setLikeOpen(false);
		setShowDirectMessageList(false);
		setMetaOpen(false);
		setOpenSearchBox(false);
		setKeyword('');
	};

	const handleLikeClick = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(true);
		setShowDirectMessageList(false);
		setMetaOpen(false);

		if (myProfile.role === 'NORMAL') {
			navigate(PATH.LIKE.RECEIVED);
		} else {
			navigate(PATH.MANAGER.LIKE);
		}
	};

	const handleMessageClick = () => {
		setOpenDirectMessageDetail(false, '');
		handleMessageSendClick();
		if (showDirectMessageList) {
			// 전체 읽지 않은 메시지 카운트 초기화
			dmEventEmitter.emit('react-reset-all-unread');
		}

		if (myProfile.role === 'NORMAL') {
			navigate(PATH.MESSENGER.ROOT);
		} else {
			setIsOnline(true);
			setIsFirst(true);
			navigate(PATH.MANAGER.MESSENGER.ONLINE);
		}
	};

	const handleMessageSendClick = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(true);
		setMetaOpen(false);
	};

	const handleMetaClick = async () => {
		// UI 상태 업데이트
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setMetaOpen(true);
		setOpenDirectMessageDetail(false, '');
		setShowDirectMessageList(false);

		try {
			// 현재 메타유저 정보가 있는지 확인
			if (!myMetaUser?.userUid) {
				// myMetaUser가 없거나 userUid가 없는 경우에만 API 호출
				// 현재 메타유저 정보 조회
				const metaUserData = await getMyMetaUser();

				// 메타유저 데이터가 없거나 비어있는 경우
				if (!metaUserData || isEmptyUser(metaUserData)) {
					// 기본 메타유저 파라미터 설정
					const metaUserParam = {
						userUid: myProfile.userUid,
						nickName: myProfile.nickName,
						introduce: myProfile.introduce,
						gender: myProfile.gender,
						profileUrl: myProfile.profileUrl[0],
						avatarName:
							myProfile.gender === '남성'
								? 'b1c1f1h1cc1fc1hc1'
								: 'b1c7f1h7cc1fc1hc1',
						readyToConnect: false,
						mediaConnected: false,
						role: myProfile.role,
					};

					// 메타유저 저장 시도
					await saveMetaUser(metaUserParam);

					// 1초 대기
					await new Promise((resolve) => setTimeout(resolve, 1000));

					// 저장된 메타유저 정보 재조회
					const savedMetaUserData = await getMyMetaUser();

					// 저장된 데이터가 있으면 그 데이터를, 없으면 기본 파라미터 사용
					setMyMetaUser(
						savedMetaUserData && typeof savedMetaUserData === 'object'
							? savedMetaUserData
							: metaUserParam,
					);
				} else {
					// API에서 가져온 데이터로 메타유저 설정
					setMyMetaUser(metaUserData);
				}
			}

			// 방 생성/입장 로직 실행
			if (!isMetaOpen) {
				handleClickMeta();
				setIsMetaOpen(true);
			}
		} catch (error) {
			// 에러 발생 시 기본값으로 메타유저 설정
			setMyMetaUser({
				userUid: myProfile.userUid,
				nickName: myProfile.nickName,
				introduce: myProfile.introduce,
				gender: myProfile.gender,
				profileUrl: myProfile.profileUrl[0],
				avatarName:
					myProfile.gender === '남성'
						? 'b1c1f1h1cc1fc1hc1'
						: 'b1c7f1h7cc1fc1hc1',
				readyToConnect: false,
				mediaConnected: false,
				role: myProfile.role,
			});
		}
	};

	useEffect(() => {
		const handleDMRoomJoined = () => {
			setIsDMRoomJoined(true);
		};
		dmEventEmitter.on('react-dm-room-joined', handleDMRoomJoined);
		return () => {
			dmEventEmitter.off('react-dm-room-joined', handleDMRoomJoined);
		};
	}, []);

	// 메시지 버튼이 활성화 상태일 때 주기적으로 실행
	useEffect(() => {
		let intervalId;
		if (showDirectMessageList) {
			dmEventEmitter.emit('react-reset-all-unread');
			intervalId = setInterval(() => {
				dmEventEmitter.emit('react-reset-all-unread');
			}, 1000); // 1초마다 실행
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [showDirectMessageList]);

	/*
	 * initFooterWrap
	 * 하단메뉴 초기화(홈 active)
	 */
	const initFooterWrap = () => {
		setHomeOpen(true);
		setLoungeOpen(false);
		setLikeOpen(false);
		setMetaOpen(false);
		setShowDirectMessageList(false);
		setFeedCommentOpen(false);

		setHushHome(true);
	};

	useEffect(() => {
		initFooterWrap();
	}, []);

	// pathname에 따른 하단바 active 처리
	const path = {
		home: [PATH.DATING.HOME, PATH.DATING.PICK, PATH.MANAGER.HOME],
		lounge: [PATH.LOUNGE.BASE_PATH, PATH.LOUNGE.LIST],
		like: [
			PATH.LIKE.BASE_PATH,
			PATH.LIKE.RECEIVED,
			PATH.LIKE.SEND,
			PATH.MANAGER.LIKE,
		],
		messenger: [
			PATH.MESSENGER.ROOT,
			PATH.MANAGER.MESSENGER.ONLINE,
			PATH.MANAGER.MESSENGER.OFFLINE,
		],
		metaverse: [PATH.METAVERSE.ROOT],
	};
	useEffect(() => {
		if (!Object.values(path).some((arr) => arr.includes(location.pathname)))
			return; // 위에 해당하는 url아니면 return
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(false);
		setMetaOpen(false);
		switch (true) {
			case path.home.includes(location.pathname):
				setHomeOpen(true);
				break;
			case path.lounge.includes(location.pathname):
				setLoungeOpen(true);
				break;
			case path.like.includes(location.pathname):
				setLikeOpen(true);
				break;
			case path.messenger.includes(location.pathname):
				setShowDirectMessageList(true);
				break;
			case path.metaverse.includes(location.pathname):
				setMetaOpen(true);
				break;
		}
	}, [location]);

	return {
		feedCommentOpen,
		openOtherPlayerProfile,
		homeOpen,
		loungeOpen,
		likeOpen,
		metaOpen,
		showDirectMessageList,
		totalUnreadCount,
		// idealMathFinderOpen,
		setHomeOpen,
		setLoungeOpen,
		setLikeOpen,
		setMetaOpen,
		handleHomeClick,
		handleLoungeClick,
		handleLikeClick,
		handleMessageClick,
		handleMetaClick,
		handleMessageSendClick,
		initFooterWrap,
	};
};
