import { Route, Routes, useLocation } from 'react-router-dom';
import HushHomeContainer from '@dating/ui/pages/home/idealType/HushHomeContainer';
import HushPickContainer from '@dating/ui/pages/hushPick/HushPickContainer';
import { HomeLayout } from '@dating/ui/components/layout/HomeLayout';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';

export const HushHomeRouter = () => {
	const location = useLocation();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<Routes location={location}>
				<Route element={<HomeLayout />}>
					<Route path="hushHome/*" element={<HushHomeContainer />} />
					<Route path="hushPick/*" element={<HushPickContainer />} />
				</Route>
			</Routes>
		</ErrorBoundary>
	);
};
