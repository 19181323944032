import {CSSTransition} from 'react-transition-group';
import {useTranslation} from "react-i18next";

interface props {
    focusItem: { label: string, value: string | string[] };
    smokingValue: string;
    setSmokingValue: (smokingValue: string) => void;
    fnModifyProfileSmoking: () => void;
    closeEditPopup: (key: string) => void;
    openPop: boolean;
    smokingList: string[] | { id: string, styleValue: string }[];
}

const EditSmokingPopView = ({
                                focusItem,
                                smokingValue,
                                setSmokingValue,
                                fnModifyProfileSmoking,
                                closeEditPopup,
                                openPop,
                                smokingList,
                            }: props) => {

    const {t} = useTranslation('register')

    const smokings = [
        {
            label: t('idealDetail.noPreference'),
            code: 36,
        },
        {
            label: t('idealDetail.dontDo'),
            code: 37,
        },
        {
            label: t('idealDetail.occasionallyDo'),
            code: 38,
        },
        {
            label: t('idealDetail.frequentlyDo'),
            code: 39,
        },
    ];

    return (
        <div className="listAddPop" id="lifestyle">
            <div className="listAddDim" onClick={() => {
                fnModifyProfileSmoking();
                closeEditPopup(focusItem.label);
            }}/>
            <CSSTransition
                in={openPop}
                classNames="listAddInfo-transition"
                timeout={100}
            >
                <div className="listAddInfo">
                    <div className="listAddTit">
                        <span className="cateTit01 life">{t('idealDetail.smoking')}</span>
                        <p className="titleSub01">{t('idealDetail.selectSmoking')}</p>
                    </div>
                    <div className="listAdd">
                        <li className="padding-0 on">
                            <div className="myCateList">
                                {
                                    smokingList.length > 0 ?
                                        smokingList.map(item => {
                                            return (
                                                <button
                                                    type="button"
                                                    key={item.id.toString()}
                                                    className={
                                                        item.id.toString() === smokingValue ? "active" : ""
                                                    }
                                                    onClick={() => setSmokingValue(item.id.toString())}
                                                >
                                                    {item.styleValue}
                                                </button>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </li>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
};

export default EditSmokingPopView;