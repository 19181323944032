import { type UserJobCIVO } from '@client/site/api';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useJobSelectStateRepo } from '@dating/ui/pages/register/job/useJobSelectStateRepo';
import { useEffect } from 'react';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export default function useJobSelectService() {
	const {
		btnActivate,
		setBtnActivate,
		jobList,
		setJobList,
		response,
		setResponse,
	} = useJobSelectStateRepo();

	const { setStep, registerSteps, selectedJob, setSelectedJob } =
		useRegisterRepo();

	const { langCode } = useAuthRepo();

	const { saveJob, statusSaveJob, dataSaveJob } = useRegisterAdapter();

	const { getJobList } = useSettingAdapter();

	//////////////////////////////////////
	const fnSaveJob = () => {
		const map: UserJobCIVO = {
			job: selectedJob,
		};
		saveJob(map);
	};

	useEffect(() => {
		if (statusSaveJob === 'success' && dataSaveJob) {
			if (dataSaveJob.result) {
				setStep(6);
				setResponse(true);
			} else {
				alert(dataSaveJob.resultCode);
			}
		} else if (statusSaveJob === 'error') {
		}
	}, [statusSaveJob, dataSaveJob]);

	useEffect(() => {
		if (selectedJob != '') {
			setBtnActivate(false);
		}
	}, [selectedJob]);

	useEffect(() => {
		getJobList(langCode)
			.then((response) => {
				if (response.length > 0) {
					setJobList(response);
				}
			})
			.catch((reason) => {});
	}, []);

	return {
		selectedJob,
		setSelectedJob,
		btnActivate,
		setBtnActivate,
		fnSaveJob,
		jobList,
		response,
		registerSteps,
	};
}
