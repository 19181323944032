import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { usePaymentRepo } from '@dating/stores/PaymentStore';
import { useFooterRepo } from '@dating/repository/dating/useFooterRepo';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import useApiDirectMessageRepo from '@dating/repository/message/useApiDirectMessageRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';

export const useLikeMatchingService = () => {
	const { setIsExistingRoom } = useApiDirectMessageRepo();

	const {
		matchedItem,
		matchingSuccess,
		messageTargetUser,
		setMatchingSuccess,
	} = usePaymentRepo();

	const { setShowDirectMessageList, groupedMessages, relatedRoomIds } =
		useDirectMessageRepo();

	const { setHomeOpen, setLoungeOpen, setLikeOpen, setMetaOpen } =
		useFooterRepo();

	const { myProfile, userProfile, setUserProfile, srcPrefix, userProfileOpen } =
		useDatingRepo();

	const { getUserProfile } = useProfileAdapter();

	const { isMessageRoom } = useMessageAdapter();

	const { getWhoBlock } = useSettingAdapter();

	const navigate = useNavigate();
	useEffect(() => {
		if (messageTargetUser != '' && userProfileOpen === '') {
			getUserProfile(messageTargetUser)
				.then((value) => {
					setUserProfile(value);
				})
				.catch((reason) => {});
		}
	}, [messageTargetUser]);

	useEffect(() => {
		const getIsMessageRoom = async () => {
			try {
				const toUserUid: string = userProfile.userUid;
				const data = await isMessageRoom(toUserUid);
				if (data) {
					setIsExistingRoom(data);
				}
			} catch (error) {
				console.error('방 존재 여부 확인 실패:', error);
			}
		};

		if (matchingSuccess && userProfile?.userUid && myProfile.userUid) {
			getIsMessageRoom();
		}
	}, [matchingSuccess, userProfile?.userUid, myProfile?.userUid]);

	// 방 존재 여부 체크 및 적절한 roomId 반환
	const checkAndGetRoomId = async (matchedRoomId: string) => {
		if (!myProfile?.userUid || !userProfile?.userUid) {
			console.error('프로필 정보 누락:', { myProfile, userProfile });
			return matchedRoomId;
		}

		try {
			if (!userProfile.userUid) {
				await new Promise((resolve) => setTimeout(resolve, 100));
				return checkAndGetRoomId(matchedRoomId);
			}

			const existingRoomData = await isMessageRoom(userProfile.userUid);

			if (existingRoomData.roomId) {
				return existingRoomData.roomId;
			}

			return matchedRoomId;
		} catch (error) {
			return matchedRoomId;
		}
	};

	const openMessageRoom = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(true);
		setMetaOpen(false);
		setMatchingSuccess(false);
	};

	// createNavigationState 수정
	const createNavigationState = async (roomId: string) => {
		if (!myProfile?.userUid || !userProfile?.userUid) {
			return null;
		}

		const isExistingRoom = relatedRoomIds.includes(roomId);

		let blockStatus;
		try {
			blockStatus = await getWhoBlock(messageTargetUser);
		} catch (error) {
			blockStatus = 'none'; // fallback to none if API fails
		}

		let user1BlockType = 'none';
		let user2BlockType = 'none';

		if (blockStatus === 'blocked') {
			user1BlockType = 'blocked';
			user2BlockType = 'block';
		} else if (blockStatus === 'block') {
			user1BlockType = 'block';
			user2BlockType = 'blocked';
		}

		const user1Info = {
			userUid: myProfile.userUid,
			nickName: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			region1: myProfile.region1 || '',
			region2: myProfile.region2 || '',
			age: myProfile.age,
			inActive: false,
			isDeleted: false,
			blockType: user1BlockType,
		};

		const user2Info = {
			userUid: userProfile.userUid,
			nickName: userProfile.nickName,
			profileUrl: userProfile.profileUrl[0],
			region1: userProfile.region1 || '',
			region2: userProfile.region2 || '',
			age: userProfile.age,
			inActive: false,
			isDeleted: false,
			blockType: user2BlockType,
		};

		// 기존 방 데이터 가져오기
		let roomData = groupedMessages[roomId];

		// 방이 실제로 존재하는 경우
		if (isExistingRoom) {
			const isUser1 = myProfile.userUid === roomData.user1Id;
			const navigationState = {
				billingStatus: roomData.billingStatus,
				myPaymentStatus: isUser1
					? roomData.billingStatus === 1
					: roomData.billingStatus === 2,
				otherPaymentStatus: isUser1
					? roomData.billingStatus === 2
					: roomData.billingStatus === 1,
				dmRoomType: roomData.dmRoomType,
				roomId: roomId,
				visibleTo: roomData.visibleTo,
				isUserLeft: isUser1
					? roomData.visibleTo === 1
					: roomData.visibleTo === 2,
				isOtherUserLeft: isUser1
					? roomData.visibleTo === 2
					: roomData.visibleTo === 1,
				user1Info: roomData.user1Info || user1Info,
				otherUserInfo: isUser1
					? roomData.user2Info || user2Info
					: roomData.user1Info || user1Info,
				createdAt: roomData.createdAt || new Date().toString(),
			};

			// 기존 방으로 이동
			navigate(PATH.LIKE.MATCHED, {
				state: {
					navigationState,
				},
			});
			openMessageRoom();
			return;
		}

		// 새로운 방인 경우
		const newNavigationState = {
			billingStatus: 0,
			myPaymentStatus: true,
			otherPaymentStatus: false,
			dmRoomType: 'LIKE',
			roomId: roomId,
			visibleTo: 0,
			isUserLeft: false,
			isOtherUserLeft: false,
			user1Info: user1Info,
			otherUserInfo: user2Info,
			createdAt: new Date().toString(),
		};

		if (!relatedRoomIds.includes(roomId)) {
			dmEventEmitter.emit('react-create-dm-room', {
				billingStatus: 0,
				dmRoomType: 'LIKE',
				roomId: roomId,
				user1Id: myProfile.userUid,
				user2Id: userProfile.userUid,
				user1Info: user1Info,
				user2Info: user2Info,
				createdAt: new Date().toString(),
				productType: 'MESSENGER',
				matchedAt: null,
			});
		}

		navigate(PATH.LIKE.MATCHED, {
			state: {
				navigationState: newNavigationState,
			},
		});

		openMessageRoom();
	};

	return {
		srcPrefix,
		matchedItem,
		checkAndGetRoomId,
		openMessageRoom,
		setMatchingSuccess,
		createNavigationState,
	};
};
