import {useState} from "react";

export const useTagInviteCodeStateRepo = () => {
    // 공통 관심사 리스트 상태 관리
    const [interestList, setInterestList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 공통 라이프 스타일 리스트 상태 관리
    const [lifeStyleList, setLifeStyleList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 흡연 여부
    const [drinkingValue, setDrinkingValue] = useState<string>("");

    // 음주 여부
    const [smokingValue, setSmokingValue] = useState<string>("");

    const [initDrinkingState, setInitDrinkingState] = useState<{code: string, value: string}[] | string[]>([]);
    const [initSmokingState, setInitSmokingState] = useState<{code: string, value: string}[] | string[]>([]);

    // 선택한 공통 관심사
    const [interestValue, setInterestValue] = useState<Record<string, boolean>>({});

    // 선택한 공통 라이프스타일
    const [lifeStyleValue, setLifeStyleValue] = useState<Record<string, boolean>>({});

    // 입력한 추천인 코드
    const [recommendCode, setRecommendCode] = useState<string>("");

    // 추천인 코드 validation message
    const [codeFailed, setCodeFailed] = useState(false);

    // 다음 버튼 비활성화 여부
    const [btnActivate, setBtnActivate] = useState(true);

    // 건너뛰기 버튼 비활성화 여부
    const [skipBtnActivate, setSkipBtnActivate] = useState(false);

    // 추천인코드 error message
    const [codeErrorMessage, setCodeErrorMessage] = useState<string | boolean>('');

    return {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        lifeStyleList,
        setLifeStyleList,
        interestList,
        setInterestList,
        initDrinkingState,
        setInitDrinkingState,
        initSmokingState,
        setInitSmokingState,
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        btnActivate,
        setBtnActivate,
        skipBtnActivate,
        setSkipBtnActivate,
        codeErrorMessage,
        setCodeErrorMessage,
    };
}