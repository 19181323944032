import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useState } from 'react';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';
import type { JwtCIVO } from '@client/site/api';
import { useTranslation } from 'react-i18next';

export const useAccountSettingService = () => {
	const { t } = useTranslation('setting');
	const title = t('accountSettings');
	const [responseLogout, setResponseLogout] = useState<boolean>(false);

	const { userSettings } = useSettingRepo();

	const { myProfile } = useDatingRepo();

	const {
		authToken,
		initAuthToken,
		deviceId,
		fcmToken,
		setDeviceId,
		setFcmToken,
	} = useAuthRepo();

	const { logout } = useSettingAdapter();
	/////////////////////////////////////////////////

	/*
	 * fnLogout
	 * 로그아웃
	 */
	const fnLogout = () => {
		if (authToken.accessJmt != '' && authToken.refreshJmt != '') {
			logout(authToken.accessJmt, authToken.refreshJmt)
				.then((value) => {
					if (value) {
						setResponseLogout(true);
					}
				})
				.catch((reason) => {
					console.log(reason.body);
				});
		}
	};

	/*
	 * initUserData
	 * 토큰 및 기기정보 초기화
	 */
	const initUserData = () => {
		initAuthToken();
		setDeviceId('');
		setFcmToken('');
	};

	return {
		title,
		myProfile,
		fnLogout,
		responseLogout,
		userSettings,
		initAuthToken,
		authToken,
		deviceId,
		fcmToken,
		initUserData,
	};
};
