import {
	unregisterGameObjects,
	registerGameObjects,
} from '@virtual-space/metaverse/characters/phaserFactoryRegister';
import GameScene from '../GameScene';
import { AssetLoader } from './AssetLoader';
import { GameSceneHandler } from './GameSceneHandlers';
import { GameSceneNetwork } from './GameSceneNetwork';
import { GameSceneSetup } from './GameSceneSetup';

export class SceneInitializer {
	private scene: GameScene;

	constructor(scene: GameScene) {
		this.scene = scene;
	}

	init(data: { roomTheme: any }) {
		this.scene.roomTheme = data.roomTheme;
		unregisterGameObjects();
		this.shutdown();
		registerGameObjects();
		this.scene.sceneSetup = new GameSceneSetup(
			this.scene,
			this.scene.roomTheme,
		);
		this.scene.network = new GameSceneNetwork(this.scene);
		this.scene.handler = new GameSceneHandler(this.scene);
		this.scene.assetLoader = new AssetLoader(this.scene, this.scene.roomTheme);
	}

	initializeScene() {
		this.scene.network.networkCallback();
		this.scene.map = this.scene.sceneSetup.setupTileMap();
		this.scene.sceneSetup.setupPlayer();
		this.scene.sceneSetup.setupCollisionLayer();
		this.scene.sceneSetup.setupObjectLayer();
		this.scene.sceneSetup.setupWall();
		this.scene.sceneSetup.setupCamera();
		this.scene.sceneSetup.setupKeyboard();
		this.scene.phaserEventController.onGameSceneReady();
	}

	shutdown() {
		if (this.scene.myPlayer) {
			this.scene.myPlayer.destroy();
		}

		if (this.scene.otherPlayers) {
			this.scene.otherPlayerMap.clear();
		}

		this.scene.chairMap.clear();
		this.scene.computerMap.clear();
		this.scene.whiteboardMap.clear();

		this.scene.roomReceiveProcess?.resetListeners();
		this.scene.events.off('shutdown', this.shutdown, this);
	}
}
