import {useTranslation} from "react-i18next";
import {CSSTransition} from 'react-transition-group';

interface props {
    focusItem: { label: string, value: string | string[] };
    closeEditPopup: (key: string) => void;
    interestList: string[] | { id: string, styleValue: string }[];
    interestValue: { id: string, styleValue: string }[];
    interestValueHandler: (id: string) => void;
    fnModifyProfileInterest: () => void;
    openPop: boolean;
}

const EditInterestPopView = ({
                                 focusItem,
                                 closeEditPopup,
                                 interestList,
                                 interestValue,
                                 interestValueHandler,
                                 fnModifyProfileInterest,
                                 openPop,
                             }: props) => {
    const {t} = useTranslation('register')
    return (
        <div className="listAddPop" id="lifestyle">
            <div className="listAddDim" onClick={() => {
                fnModifyProfileInterest();
                closeEditPopup(focusItem.label);
            }}/>
            <CSSTransition
                in={openPop}
                classNames="listAddInfo-transition"
                timeout={100}
            >
                <div className="listAddInfo">
                    <p className="listAddTit">
                        <span className="cateTit01 like">{t('modifyProfile.like')}</span>
                    </p>
                    <div className="listAdd">
                        <li className="padding-0 on">
                            <div className="myCateList">
                                {
                                    interestList.length > 0 ?
                                        interestList.map((item) => {
                                            return (
                                                <button
                                                    key={item.id + item.styleValue}
                                                    type="button"
                                                    className={
                                                        interestValue[item.id] ? "active" : ""
                                                    }
                                                    onClick={() => {
                                                        interestValueHandler(item.id);
                                                    }}
                                                >
                                                    {item.styleValue}
                                                </button>
                                            );
                                        })
                                        : null
                                }
                            </div>
                        </li>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
};

export default EditInterestPopView;