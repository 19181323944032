import React from 'react';
import { Link } from 'react-router-dom';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import MessageTimeView from './MessageTimeView';
import { MessageItemViewProps } from '../../types/navigationTypes';
import { useTranslation } from 'react-i18next';

/**
 * 개별 메시지 아이템 컴포넌트
 * 하나의 채팅 메시지를 렌더링합니다.
 */
const MessageItemView: React.FC<MessageItemViewProps> = ({
	message,
	isMyMessage,
	isFirstInGroup,
	isLastInGroup,
	srcPrefix,
	isOtherUserLeft,
	blockStatus,
	setUserProfileOpen,
	navigationRoom,
	myProfile,
	handleRoomMessageClick,
}) => {
	const { t } = useTranslation('messenger');

	const renderMessageContent = () => {
		// 온라인 채팅에서만 사용되는 기능 (handleRoomMessageClick이 있을 때만)
		if (handleRoomMessageClick && message.content === t('goToMatching')) {
			return (
				<span
					onClick={() => handleRoomMessageClick(message)}
					style={{
						cursor: 'pointer',
						color: '#000',
						textDecoration: 'underline',
					}}
				>
					{t('goToMatching')}
				</span>
			);
		}
		return <span>{message.content}</span>;
	};

	return (
		<div key={`${message.messageId}-${message.createdAt}`}>
			<div className={`chatBox ${isMyMessage ? 'chatRight' : 'chatLeft'}`}>
				{!isMyMessage && isFirstInGroup ? (
					<div className="proImg Sm">
						{isOtherUserLeft || (blockStatus && blockStatus !== 'none') ? (
							<img src="/assets/img/common/profile_no.svg" alt="테스트이미지" />
						) : (
							<Link
								to={PATH.PROFILE.USER_PROFILE}
								onClick={() => {
									setUserProfileOpen(navigationRoom.otherUserInfo.userUid);
								}}
								state={{
									isOtherProfile:
										navigationRoom.otherUserInfo?.userUid !== myProfile.userUid,
								}}
							>
								<img
									src={srcPrefix + message.sender?.profileUrl}
									alt={message.sender?.nickName}
								/>
							</Link>
						)}
					</div>
				) : (
					<div className="proImg Sm" />
				)}
				<div className="chat">
					<div className={`bubbleBox ${isFirstInGroup ? 'tail' : ''}`}>
						<div className="bubble">{renderMessageContent()}</div>
						{isLastInGroup && <MessageTimeView time={message.createdAt} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageItemView;
