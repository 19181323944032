import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useEffect } from 'react';

export const useOfflineManagerMessageService = () => {
	const {
		setIsOnline,
		isOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setIsFirst,
		isFirst,
		setShowDirectMessageList,
	} = useDirectMessageRepo();

	useEffect(() => {
		setShowDirectMessageList(true);
		setIsOnline(true);
	}, []);

	return {
		setIsOnline,
		isOnline,
		setShowOnlineMessageList,
		setShowOfflineMessageList,
		setIsFirst,
		isFirst,
	};
};
