import i18next from 'i18next';

/**
 * 날짜를 YYYY-MM-DD 형식의 문자열로 포맷팅
 * @param timestamp 날짜를 나타내는 타임스탬프 (밀리초)
 * @returns 포맷팅된 날짜 문자열 (예: "2025-03-24")
 */
export const formatDate = (timestamp: number) => {
	const date = new Date(timestamp);
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
		2,
		'0',
	)}-${String(date.getDate()).padStart(2, '0')}`;
};

/**
 * 메시지 배열을 날짜순으로 정렬
 * @param messages 정렬할 메시지 배열
 * @param ascending 오름차순 정렬 여부 (기본값: true)
 * @returns 날짜순으로 정렬된 새 메시지 배열
 */
export const sortMessagesByDate = (messages, ascending = true) => {
	return [...messages].sort((a, b) => {
		const dateA = a.createdAt.includes('T')
			? new Date(a.createdAt).getTime()
			: Number(a.createdAt);
		const dateB = b.createdAt.includes('T')
			? new Date(b.createdAt).getTime()
			: Number(b.createdAt);
		return ascending ? dateA - dateB : dateB - dateA;
	});
};

/**
 * 날짜 문자열을 시간 형식으로 포맷팅
 * @param dateString 날짜 문자열 또는 숫자 (밀리초)
 * @returns 포맷팅된 시간 문자열 (예: "오후 2:30") 또는 오류 메시지
 */
export const formatTime = (dateString: string | number | undefined): string => {
	if (dateString === undefined || dateString === null) {
		return i18next.t('invalidDate', { ns: 'messenger' });
	}

	let timestamp: number;

	if (typeof dateString === 'number') {
		timestamp = dateString;
	} else if (typeof dateString === 'string') {
		if (!isNaN(Number(dateString))) {
			timestamp = Number(dateString);
		} else {
			// UTC 시간으로 처리
			const date = new Date(dateString);
			// ISO 문자열에서 UTC 시간을 유지하면서 타임스탬프로 변환
			timestamp = date.getTime();
		}
	} else {
		return i18next.t('invalidDate', { ns: 'messenger' });
	}

	if (isNaN(timestamp) || timestamp < 0) {
		return i18next.t('invalidDate', { ns: 'messenger' });
	}

	// UTC 시간으로 변환하여 일관성 유지
	const date = new Date(timestamp);

	// UTC 시간 추출 (getUTC* 메서드 사용)
	let hours = date.getUTCHours();
	const minutes = date.getUTCMinutes().toString().padStart(2, '0');

	// 12시간제로 변환
	const period = hours < 12 ? 'AM' : 'PM';
	hours = hours % 12 || 12;

	return `${period} ${hours}:${minutes}`;
};

/**
 * 경과 시간을 상대적인 형식으로 포맷팅 (예: "1분 전", "3시간 전", "2일 전")
 * @param matchingTimeStr 매칭된 시간 (타임스탬프 문자열)
 * @returns 상대적 시간 표시 문자열
 */
export const formatMatchingTime = (matchingTimeStr: string): string => {
	const matchingTime = Number(matchingTimeStr);
	const now = Date.now();

	const diffInMs = now - matchingTime;
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

	if (diffInHours < 1) {
		if (diffInMinutes < 1) {
			return i18next.t('justNow', { ns: 'messenger' });
		}
		return i18next.t('minutesAgo', {
			count: diffInMinutes,
			ns: 'messenger',
		});
	} else if (diffInHours < 24) {
		return i18next.t('hoursAgo', {
			count: diffInHours,
			ns: 'messenger',
		});
	} else {
		return i18next.t('daysAgo', {
			count: diffInDays,
			ns: 'messenger',
		});
	}
};

/**
 * 타임스탬프를 오전/오후 형식의 시간으로 변환
 * @param timestamp 변환할 타임스탬프 문자열
 * @returns 오전/오후 포함된 시간 문자열 (예: "오전 9:30")
 */
export const formatMatchedTime = (timestamp: string) => {
	const date = new Date(timestamp);

	// 시간과 분을 가져옴
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// 오전/오후 구분
	const period =
		hours < 12
			? i18next.t('morning', { ns: 'messenger' })
			: i18next.t('afternoon', { ns: 'messenger' });
	// 12시간제로 변환
	const formattedHours = hours % 12 || 12;

	// 분이 한 자리 수일 경우 앞에 0을 붙임
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	// 최종 형식으로 조합
	return `${period} ${formattedHours}:${formattedMinutes}`;
};

/**
 * 다양한 형식의 날짜 입력값을 타임스탬프로 정규화
 * @param date 변환할 날짜 (문자열, 숫자, 또는 다른 형식)
 * @returns 밀리초 단위의 타임스탬프
 */
export const normalizeToTimestamp = (date: any): number => {
	if (!date) return Date.now();

	// 이미 숫자(타임스탬프)인 경우
	if (typeof date === 'number') return date;

	// 문자열인 경우
	if (typeof date === 'string') {
		// 이미 타임스탬프 문자열인 경우
		if (!isNaN(Number(date))) return Number(date);

		// Date 문자열인 경우
		const parsed = Date.parse(date);
		if (!isNaN(parsed)) return parsed;
	}

	// 다른 모든 경우
	return Date.now();
};
