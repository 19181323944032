/*
 * getStringArrByRecord
 * key: value list를 string list로 반환
 */
export const getStringArrByRecord = (list: Record<string, boolean>): string[] => {
    let result: string[] = [];
    for (const key in list) {
        if (list[key]) {
            result.push(key);
        }
    }
    return result;
};