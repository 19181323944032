import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import { StateParams } from './MyPlayerState';
import { ItemType } from '@virtual-space/types/Item';

export class CheckState {
	constructor(private param: StateParams) {}

	// 현재 플레이어가 앉아있는지 확인하는 함수
	isSitting() {
		return this.param.behavior === PlayerBehavior.SITTING;
	}

	// UnChairSittingState로 상태 변경하는 조건을 만족하는지 확인하는 함수
	isChangeUnSitting() {
		return (
			// (this.isSitting() && this.param.myKeyboard.downF) ||
			this.isSitting() && this.param.myKeyboard.clickActionF
		);
	}

	// SittingState로 상태 변경하는 조건을 만족하는지 확인하는 함수
	isChangeSitting() {
		return (
			// (this.param.behavior === PlayerBehavior.IDLE &&
			//   this.param.myKeyboard.downF &&
			//   this.param.item?.itemType === ItemType.CHAIR) ||
			this.param.behavior === PlayerBehavior.IDLE &&
			this.param.myKeyboard.clickActionF &&
			this.param.item?.itemType === ItemType.CHAIR
		);
	}

	// F 키를 눌렀을 때 아이템과 충돌했는지 확인하는 함수
	isOverlapF() {
		return (
			// this.param.myKeyboard.downF ||
			this.param.myKeyboard.clickActionF && this.param.item
		);
	}

	// 현재 플레이어가 인사하는 행동을 하는지 확인하는 함수
	defineHelloType() {
		return this.param.myPlayer.playerBehavior === PlayerBehavior.HELLO;
	}

	stopHello() {
		const { vx, vy } = this.param.myPlayer.myMove.velocity;
		return (
			// (this.defineHelloType() &&
			//   (this.param.myKeyboard.downE ||
			//     this.param.myKeyboard.downR ||
			//     this.param.myKeyboard.downSpace)) ||
			(this.defineHelloType() &&
				(this.param.myKeyboard.clickActionE ||
					this.param.myKeyboard.clickActionR ||
					this.param.myKeyboard.clickSpaceBar)) ||
			(this.defineHelloType() && (vx !== 0 || vy !== 0))
		);
	}

	sayHello() {
		return (
			// (this.param.behavior === PlayerBehavior.IDLE &&
			//   this.param.myKeyboard.downR) ||
			this.param.behavior === PlayerBehavior.IDLE &&
			this.param.myKeyboard.clickActionR
		);
	}

	// 현재 플레이어가 춤추는 행동을 하는지 확인하는 함수
	defineDanceType() {
		return this.param.myPlayer.playerBehavior === PlayerBehavior.DANCING;
	}

	stopDance() {
		const { vx, vy } = this.param.myPlayer.myMove.velocity;
		return (
			// (this.defineDanceType() &&
			//   (this.param.myKeyboard.downE ||
			//     this.param.myKeyboard.downR ||
			//     this.param.myKeyboard.downSpace)) ||
			(this.defineDanceType() &&
				(this.param.myKeyboard.clickActionE ||
					this.param.myKeyboard.clickActionR ||
					this.param.myKeyboard.clickSpaceBar)) ||
			(this.defineDanceType() && (vx !== 0 || vy !== 0))
		);
	}

	dancing() {
		return (
			// (this.param.behavior === PlayerBehavior.IDLE &&
			//   this.param.myKeyboard.downE) ||
			this.param.behavior === PlayerBehavior.IDLE &&
			this.param.myKeyboard.clickActionE
		);
	}

	jumping() {
		return (
			// (this.param.behavior === PlayerBehavior.IDLE &&
			//   this.param.myKeyboard.downSpace) ||
			this.param.behavior === PlayerBehavior.IDLE &&
			this.param.myKeyboard.clickSpaceBar
		);
	}
}
