import { useMetaverse } from '@virtual-space/MetaverseProvider';
import { MetaRoomChatCIVO, MetaRoomUserMatchCIVO } from '@client/site/api';
import { useChatRepo } from '@virtual-space/stores/useChatRepo';
import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useMetaRoomAdapter } from '@client/site/dating/adapter/meta/useMetaRoomAdapter';
import { useCallback } from 'react';

export const useChatService = () => {
	const {
		focused,
		showChat,
		chatMessages,
		chatActive,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		setShowChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	} = useChatRepo();
	const { isInMetaverseRoot } = useMetaverse();
	const { joinedRoomData } = useRoomRepo();
	const { saveRoomChat, matchUser } = useMetaRoomAdapter();
	const { myProfile } = useDatingRepo();
	const handleSendChat = useCallback(
		async (content: string) => {
			try {
				if (joinedRoomData?.id) {
					const param: MetaRoomChatCIVO = {
						roomId: joinedRoomData.id,
						content,
						userUid: myProfile.userUid,
					};

					// saveRoomChat이 완료되길 기다림
					const chatResult = await saveRoomChat(param);

					// saveRoomChat이 성공하면 matchUser 실행
					if (chatResult) {
						const matchUserParam: MetaRoomUserMatchCIVO = {
							metaRoomId: joinedRoomData.id,
						};
						await matchUser(matchUserParam);
					}
				}
			} catch (error) {
				console.error('Error in handleSendChat:', error);
			}
		},
		[joinedRoomData, saveRoomChat, matchUser, myProfile.userUid],
	);
	const handleCloseChat = () => {
		setShowChat(false);
	};
	return {
		focused,
		showChat,
		chatMessages,
		chatActive,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		isInMetaverseRoot,
		handleSendChat,
		setShowChat,
		handleCloseChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	};
};
