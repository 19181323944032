import { useMyManagerOnlineListService } from './service/useMyManagerOnlineListService';
import { useTranslation } from 'react-i18next';
import MyManagerMessageItemView from '../../views/MyManagerMessageItemView';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';

const MyManagerOnlineListContainer = () => {
	const { t } = useTranslation('messenger');
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const { sortedMessages, myProfile } = useMyManagerOnlineListService();

	return (
		<div className="chatRoomList">
			{sortedMessages.length > 0 ? (
				sortedMessages.map((message) => (
					<MyManagerMessageItemView
						key={message.roomId}
						message={message}
						myProfile={myProfile}
						setOpenDirectMessageDetail={setOpenDirectMessageDetail}
						pathPrefix={PATH.MESSENGER.MANAGER_ONLINE}
						isOnline={true}
					/>
				))
			) : (
				<div className="chatStartBox">
					<p className="partnerStart2 onoffLineBg">
						{t('noMatchHistory', { text: t('online') })}
					</p>
				</div>
			)}
		</div>
	);
};

export default MyManagerOnlineListContainer;
