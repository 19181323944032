import { useState } from 'react';
import { ReportUiCOVO } from '@client/site/api';

export const useBlockReportStateRepo = () => {
	const [openReport, setOpenReport] = useState<boolean>(false);

	const [reportValue, setReportValue] = useState<string>('');

	const [commonReportCodes, setCommonReportCodes] = useState<
		Array<ReportUiCOVO>
	>([]);

	return {
		openReport,
		setOpenReport,
		reportValue,
		setReportValue,
		commonReportCodes,
		setCommonReportCodes,
	};
};
