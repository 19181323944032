import WebRTC from './WebRTC';

declare global {
	interface HTMLButtonElement {
		clickHandler?: (this: HTMLButtonElement, ev: MouseEvent) => any;
	}
}
export default class WebRTCButtons {
	private cameraBtn: NodeListOf<HTMLButtonElement>;
	private voiceBtn: NodeListOf<HTMLButtonElement>;
	private audioTrack?: MediaStreamTrack;
	private videoTrack?: MediaStreamTrack;
	private webRTCInstance: WebRTC;

	constructor(myStream: MediaStream | undefined, webRTCInstance: WebRTC) {
		this.cameraBtn = document.querySelectorAll('.cameraBtn');
		this.voiceBtn = document.querySelectorAll('.voiceBtn');
		this.webRTCInstance = webRTCInstance;

		// 현재 스트림의 트랙 설정 (스트림이 있는 경우에만)
		if (myStream) {
			this.updateTracks(myStream);
		}

		// 버튼 설정
		this.setupButtons();
	}

	// 트랙 업데이트
	updateTracks(stream: MediaStream | undefined) {
		if (stream) {
			this.audioTrack = stream.getAudioTracks()[0];
			this.videoTrack = stream.getVideoTracks()[0];
		} else {
			this.audioTrack = undefined;
			this.videoTrack = undefined;
		}
	}

	// 모든 버튼에 이벤트 리스너 설정
	setupButtons() {
		// 카메라 버튼 설정
		this.setupCameraButtons();

		// 마이크 버튼 설정
		this.setupVoiceButtons();
	}

	// 카메라 버튼 설정
	private setupCameraButtons() {
		this.cameraBtn.forEach((button) => {
			// 기존 핸들러가 있으면 제거
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
			}

			const clickHandler = () => {
				if (this.videoTrack) {
					// 비디오 트랙이 있으면 활성화/비활성화 토글
					this.videoTrack.enabled = !this.videoTrack.enabled;
				} else {
					// 비디오 트랙이 없으면 권한 재요청
					this.requestMediaPermissions();
				}
			};

			button.addEventListener('click', clickHandler);
			button.clickHandler = clickHandler;
		});
	}

	// 마이크 버튼 설정
	private setupVoiceButtons() {
		this.voiceBtn.forEach((button) => {
			// 기존 핸들러가 있으면 제거
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
			}

			const clickHandler = () => {
				if (this.audioTrack) {
					// 오디오 트랙이 있으면 활성화/비활성화 토글
					this.audioTrack.enabled = !this.audioTrack.enabled;
				} else {
					// 오디오 트랙이 없으면 권한 재요청
					this.requestMediaPermissions();
				}
			};

			button.addEventListener('click', clickHandler);
			button.clickHandler = clickHandler;
		});
	}

	// 미디어 권한 재요청
	private requestMediaPermissions() {
		if (this.webRTCInstance) {
			// 강제로 새 권한 요청
			this.webRTCInstance
				.forceNewPermissionRequest()
				.then((stream) => {
					console.log('권한 재요청 성공, 새 스트림 설정');
					// 새 스트림의 트랙으로 업데이트
					this.updateTracks(stream);
				})
				.catch((error) => {
					console.error('권한 재요청 실패:', error);
				});
		}
	}

	// 리소스 정리
	cleanup() {
		// 오디오 버튼 이벤트 리스너 제거
		this.voiceBtn.forEach((button) => {
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
				delete button.clickHandler;
			}
		});

		// 비디오 버튼 이벤트 리스너 제거
		this.cameraBtn.forEach((button) => {
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
				delete button.clickHandler;
			}
		});

		// 트랙 정리 (정지하지 않고 참조만 제거, 실제 정지는 WebRTC 클래스에서 관리)
		this.audioTrack = undefined;
		this.videoTrack = undefined;
	}
}
