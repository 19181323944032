import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useEffect } from 'react';
import { type BlockUserCIVO } from '@client/site/api';
import { useSettingRepo } from '@dating/repository/setting/useSettingRepo';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { dmEventEmitter } from '@virtual-space/events/ReactDMEventEmitter';
import { useTranslation } from 'react-i18next';

export const useBlockListService = () => {
	const { t } = useTranslation('setting');
	const title = t('blockList');

	const { blockUsers, setBlockUsers } = useSettingRepo();

	const { myProfile, srcPrefix } = useDatingRepo();

	const {
		getBlockUser,
		saveUnblockUser,
		statusSaveUnblockUser,
		dataSaveUnblockUser,
	} = useSettingAdapter();

	const { isMessageRoom } = useMessageAdapter();

	const getCurrentRoomId = async (targetUid: string) => {
		try {
			// 기존 방이 있는지 먼저 확인
			const existingRoomData = await isMessageRoom(targetUid);

			if (existingRoomData && existingRoomData.roomId) {
				return existingRoomData.roomId;
			}
		} catch (error) {
			console.error('방 ID 가져오기 실패:', error);
			return;
		}
	};

	const fnGetBlockUser = () => {
		getBlockUser()
			.then((response) => {
				setBlockUsers(response);
			})
			.catch((reason) => {});
	};

	useEffect(() => {
		fnGetBlockUser();
	}, []);

	/*
	 * fnSaveUnblockUser
	 * 유저 차단 해제 하기
	 */
	const fnSaveUnblockUser = async (blockUserUid: string) => {
		const map: BlockUserCIVO = {
			blockUserUid: blockUserUid,
		};
		saveUnblockUser(map);
		const currentDMRoomId = await getCurrentRoomId(blockUserUid);
		if (currentDMRoomId) {
			dmEventEmitter.emit('react-block-user', {
				roomId: currentDMRoomId,
				blockType: 'none',
				blockerUuid: myProfile.userUid,
				targetUid: blockUserUid,
			});
		}
	};

	useEffect(() => {
		if (statusSaveUnblockUser === 'success' && dataSaveUnblockUser) {
			alert(t('unblockedUser'));
			fnGetBlockUser();
		}
	}, [statusSaveUnblockUser, dataSaveUnblockUser]);

	useEffect(() => {}, [blockUsers]);

	return {
		blockUsers,
		srcPrefix,
		fnSaveUnblockUser,
		title,
	};
};
