import { ReportUiCOVO } from '@client/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	reportPopClose: () => void;
	selectedOption: string;
	handleOptionSelect: (option: string) => void;
	commonReportCodes: Array<ReportUiCOVO>;
	reportValue: string;
	reportInputHandler: (value: string) => void;
	inputDisabled: boolean;
	fnSaveReport: () => void;
}

const ReportPopView = ({
	reportPopClose,
	selectedOption,
	handleOptionSelect,
	commonReportCodes,
	reportValue,
	reportInputHandler,
	inputDisabled,
	fnSaveReport,
}: props) => {
	const { t } = useTranslation('common');
	return (
		<div className="popWrap">
			<div className="popupInfo">
				<p className="fz20 bold-500 text-c padding-t8">{t('report.report')}</p>
				<ul className="popCheckList margin-b0">
					{commonReportCodes != undefined &&
					typeof commonReportCodes === 'object' &&
					commonReportCodes.length > 0
						? commonReportCodes.map((item) => {
								return (
									<li key={item.code}>
										<span className="bold-500">{item.value}</span>
										<div>
											<input
												id={'checkbox' + item.code}
												className="checkbox"
												name="checkbox-group"
												type="checkbox"
												checked={selectedOption === item.code}
												value={item.code}
												onChange={() => {
													handleOptionSelect(item.code);
												}}
											/>
											<label
												htmlFor={'checkbox' + item.code}
												className="checkbox-label"
											></label>
										</div>
									</li>
								);
						  })
						: null}
					<div className="input">
						<input
							type="text"
							placeholder={t('report.placeholder')}
							value={reportValue}
							disabled={inputDisabled}
							onChange={(e) => {
								reportInputHandler(e.target.value);
							}}
						/>
					</div>
				</ul>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							reportPopClose();
						}}
					>
						<span>{t('report.cancel')}</span>
					</button>
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={() => {
							fnSaveReport();
						}}
					>
						<span>{t('report.confirm')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ReportPopView;
