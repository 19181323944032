import { create } from 'zustand';
import type { OrderResultCOVO, ProductSOVO } from '@client/site/api';

type ProductsState = {
	// index: 좋아요[0], 대화방열기[1], 메세지보내기[2]
	commonProducts: ProductSOVO[];
	setCommonProducts: (commonProducts: ProductSOVO[]) => void;

	// index: 오프라인3회[0], 오프라인5회[1], 오프라인10회[2], 온라인3회[3], 온라인5회[4], 온라인10회[5]
	managerProducts: ProductSOVO[];
	setManagerProducts: (managerProducts: ProductSOVO[]) => void;

	// index: 100[0], 500[1], 1000[2], 3000[3], 6000[4], 10000[5]
	pointProducts: ProductSOVO[];
	setPointProducts: (pointProducts: ProductSOVO[]) => void;

	// 추천 구매목록 useHomeRepo 참고
	recommendProducts: ProductSOVO[];
	setRecommendProducts: (recommendProducts: ProductSOVO[]) => void;
};

export const useProductsRepo = create<ProductsState>((set) => ({
	// 좋아요, 메세지보내기
	commonProducts: [],
	setCommonProducts: (commonProducts: ProductSOVO[]) => set({ commonProducts }),

	// 온/오프라인 매니저
	managerProducts: [],
	setManagerProducts: (managerProducts: ProductSOVO[]) =>
		set({ managerProducts }),

	// 포인트 구매목록
	pointProducts: [],
	setPointProducts: (pointProducts: ProductSOVO[]) => set({ pointProducts }),

	// 추천 구매목록
	recommendProducts: [],
	setRecommendProducts: (recommendProducts: ProductSOVO[]) =>
		set({ recommendProducts }),
}));
