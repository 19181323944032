/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateProfileCOVO } from '../models/ActivateProfileCOVO';
import type { DeviceAndFcmSIVO } from '../models/DeviceAndFcmSIVO';
import type { LocationDeviceInfoCIVO } from '../models/LocationDeviceInfoCIVO';
import type { MobileCheckCIVO } from '../models/MobileCheckCIVO';
import type { MobileCheckCOVO } from '../models/MobileCheckCOVO';
import type { MobilePhoneOccupancyCIVO } from '../models/MobilePhoneOccupancyCIVO';
import type { MobilePhoneOccupancyVerificationCIVO } from '../models/MobilePhoneOccupancyVerificationCIVO';
import type { MobilePhoneOccupancyVerificationCOVO } from '../models/MobilePhoneOccupancyVerificationCOVO';
import type { StartSignupCOVO } from '../models/StartSignupCOVO';
import type { UserBirthdayCIVO } from '../models/UserBirthdayCIVO';
import type { UserGenderCIVO } from '../models/UserGenderCIVO';
import type { UserHeightCIVO } from '../models/UserHeightCIVO';
import type { UserJobCIVO } from '../models/UserJobCIVO';
import type { UserLocationShareCIVO } from '../models/UserLocationShareCIVO';
import type { UserNicknameCIVO } from '../models/UserNicknameCIVO';
import type { UserPreferCIVO } from '../models/UserPreferCIVO';
import type { UserRecommenderCIVO } from '../models/UserRecommenderCIVO';
import type { UserResultCOVO } from '../models/UserResultCOVO';
import type { UserSignUpCOVO } from '../models/UserSignUpCOVO';
import type { UserStyleCIVO } from '../models/UserStyleCIVO';
import type { UserStyleCOVO } from '../models/UserStyleCOVO';
import type { UserTermsCIVO } from '../models/UserTermsCIVO';
import type { UserWaitingInfoCOVO } from '../models/UserWaitingInfoCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
    /**
     * 핸드폰 점유 인증 번호 발송
     * @param requestBody 핸드폰 점유 VO
     * @returns boolean 인증 번호 발송 결과
     * @throws ApiError
     */
    public static sendVerificationCode(
        requestBody: MobilePhoneOccupancyCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/occupancy/send-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 핸드폰 점유 인증 번호 검증
     * @param requestBody 핸드폰 점유 인증 VO
     * @returns MobilePhoneOccupancyVerificationCOVO 핸드폰 점유 인증 결과 VO
     * @throws ApiError
     */
    public static verifyVerificationCode(
        requestBody: MobilePhoneOccupancyVerificationCIVO,
    ): CancelablePromise<MobilePhoneOccupancyVerificationCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/occupancy/verify-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 임시 액세스 토큰 발급
     * @param phoneNumber 휴대폰 번호
     * @returns string 임시 액세스 토큰
     * @throws ApiError
     */
    public static generateTemporaryAccessToken(
        phoneNumber: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/occupancy/generate-temporary-access-token',
            query: {
                'phoneNumber': phoneNumber,
            },
        });
    }
    /**
     * 회원 약관 동의 정보 저장
     * @param requestBody 회원 약관 동의 정보 VO
     * @returns UserSignUpCOVO
     * @throws ApiError
     */
    public static saveUserTerms(
        requestBody: UserTermsCIVO,
    ): CancelablePromise<UserSignUpCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/saveUserTerms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 성별 저장
     * @param requestBody 회원 성별 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveUserGender(
        requestBody: UserGenderCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveUserGender',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 닉네임 중복체크 및 저장
     * @param requestBody 회원 닉네임 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveNickname(
        requestBody: UserNicknameCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveNickname',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 나이체크 및 생년월일 저장
     * @param requestBody 회원 생년월일 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveBirthday(
        requestBody: UserBirthdayCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveBirthday',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 위치정보제공 동의 저장
     * @param requestBody 회원 위치정보제공 동의 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveLocationShare(
        requestBody: UserLocationShareCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveLocationShare',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 키 정보 저장
     * @param requestBody 회원 키 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveHeight(
        requestBody: UserHeightCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveHeight',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 직업 정보 저장
     * @param requestBody 회원 직업 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveJob(
        requestBody: UserJobCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveJob',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 이상형 정보 저장
     * @param requestBody 회원 이상형 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveUserPrefer(
        requestBody: UserPreferCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/saveUserPrefer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 추천인 코드 건너뛰기
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static skipRecommender(): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/skipRecommender',
        });
    }
    /**
     * 회원 추천인 코드 저장
     * @param requestBody 회원 추천인 정보 VO
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveRecommender(
        requestBody: UserRecommenderCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/user/signUp/saveRecommender',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 내 추천인 코드 조회
     * @returns string 내 추천인 코드 조회
     * @throws ApiError
     */
    public static getMyRecommender(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/signUp/getMyRecommender',
        });
    }
    /**
     * 선택된 나의 관심, 라이프 스타일
     * @returns UserStyleCOVO 선택된 나의 관심, 라이프 스타일
     * @throws ApiError
     */
    public static getUserStyle(): CancelablePromise<Array<UserStyleCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/signUp/getUserStyle',
        });
    }
    /**
     * 회원 선택 나의관심,라이프스타일 저장
     * @param requestBody 회원 선택 나의관심 & 라이프스타일 정보
     * @returns UserResultCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static saveUserStyle(
        requestBody: UserStyleCIVO,
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/saveUserStyle',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원 프로필 사진 업로드
     * @param formData
     * @returns UserResultCOVO 회원 정보 저장 결과VO
     * @throws ApiError
     */
    public static saveUserProfile(
        formData: {
            files?: Array<Blob>;
        },
    ): CancelablePromise<UserResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/profileUpload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * 회원가입 완료 Waiting 정보 조회
     * @returns UserWaitingInfoCOVO 회원가입 완료 Waiting 정보
     * @throws ApiError
     */
    public static getUserWaiting(): CancelablePromise<UserWaitingInfoCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/signUp/getUserWaiting',
        });
    }
    /**
     * 회원가입 시작 클릭
     * @returns StartSignupCOVO 회원 정보 저장 결과 VO
     * @throws ApiError
     */
    public static startSignUp(): CancelablePromise<StartSignupCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/startSignUp',
        });
    }
    /**
     * 유저 위치 정보, 디바이스 아이디 저장
     * @param requestBody 유저 위치 정보, 디바이스 아이디
     * @returns boolean 유저 위치 정보, 디바이스 아이디 저장 결과
     * @throws ApiError
     */
    public static saveUserLocation(
        requestBody: LocationDeviceInfoCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/saveUserLocation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 휴대폰 번호로 회원 체크
     * @param requestBody 유저의 휴대폰 번호
     * @returns MobileCheckCOVO 휴대폰 번호로 회원 체크 결과 : 존재 true, 없음 false
     * @throws ApiError
     */
    public static mobileCheck(
        requestBody: MobileCheckCIVO,
    ): CancelablePromise<MobileCheckCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/mobileCheck',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * deviceID, fcmToken 저장
     * @param requestBody fcmToken, deviceID
     * @returns boolean deviceID, fcmToken 저장 결과
     * @throws ApiError
     */
    public static saveDeviceAndFcm(
        requestBody: DeviceAndFcmSIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/saveDeviceAndFCM',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 회원가입 정보 입력 완료 시 자동승인 처리 및 프로필 활성화
     * @returns ActivateProfileCOVO 프로필 활성화 처리 여부 및 토큰
     * @throws ApiError
     */
    public static activateProfile(): CancelablePromise<ActivateProfileCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/signUp/activateProfile',
        });
    }
}
