import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModifyUserProfileCOVO} from "@client/site/api/models/ModifyUserProfileCOVO";

interface props {
    myModifyProfile: ModifyUserProfileCOVO;
    openPopupHandler: (detail: {}) => void;
    recommendCode: string;
    setRecommendCode: (recommendCode: string) => void;
    codeFailed: boolean;
    codeErrorMessage: string | boolean;
    getUserStyleValue: (id: string) => string[];
}

const EditProfileDetailView = ({
                                   myModifyProfile,
                                   openPopupHandler,
                                   recommendCode,
                                   setRecommendCode,
                                   codeFailed,
                                   codeErrorMessage,
                                   getUserStyleValue,
                               }: props) => {
    const {t} = useTranslation('register');

    // 항목별 style(title, class) hadler
    const details = [
        {
            id: 'user',
            title: t('idealDetail.nickname'),
            value: myModifyProfile?.nickName,
            key: 'nickName',
            isNull: myModifyProfile?.nickName ? false : true,
            editable: true,
        },
        {
            id: 'birthday',
            title: t('idealDetail.birth'),
            value: myModifyProfile?.birthday ? myModifyProfile?.birthday : 'null',
            key: 'birthday',
            isNull: true,
            editable: false,
        },
        {
            id: 'gender',
            title: t('idealDetail.gender'),
            value:
                myModifyProfile?.gender == '남성'
                    ? t('genderStep.man')
                    : t('genderStep.woman'),
            key: 'gender',
            isNull: true,
            editable: false,
        },
        {
            id: 'location',
            title: t('idealDetail.location'),
            value:
                myModifyProfile?.region1 && myModifyProfile.region2
                    ? `${myModifyProfile.region1} ${myModifyProfile.region2}`
                    : t('idealDetail.noLocationQuote'),
            key: 'location',
            isNull: true,
            editable: false,
        },
        {
            id: 'job',
            title: t('idealDetail.job'),
            value: myModifyProfile?.job,
            key: 'job',
            isNull: myModifyProfile?.job ? false : true,
            editable: true,
        },
        {
            id: 'height',
            title: t('idealDetail.height'),
            value: myModifyProfile?.height,
            key: 'height',
            isNull: myModifyProfile?.height ? false : true,
            editable: true,
        },
        {
            id: 'religion',
            title: t('idealDetail.religion'),
            value: myModifyProfile?.religion
                ? myModifyProfile?.religion
                : t('idealDetail.selectReligion'),
            key: 'religion',
            isNull: myModifyProfile?.religion ? false : true,
            editable: true,
        },
        {
            id: 'pen',
            title: t('idealDetail.intro'),
            value: myModifyProfile?.introduce
                ? myModifyProfile?.introduce
                : t('idealDetail.inputIntro'),
            key: 'introduce',
            isNull: myModifyProfile?.introduce ? false : true,
            editable: true,
        },
        {
            id: 'life',
            title: t('idealDetail.life'),
            value:
                myModifyProfile?.lifeStyle?.concat(myModifyProfile?.extraLifeStyle)
                    ?.length > 0
                    ? myModifyProfile.lifeStyle
                        .concat(myModifyProfile?.extraLifeStyle)
                        .join(', ')
                    : t('idealDetail.inputLife'),
            code:
                myModifyProfile?.lifeStyle?.length > 0
                    ? myModifyProfile?.lifeStyleCode
                    : [],
            key: 'lifeStyle',
            isNull: myModifyProfile?.lifeStyle?.length > 0 ? false : true,
            editable: true,
        },
        {
            id: 'like',
            title: t('idealDetail.like'),
            value:
                myModifyProfile?.interest?.concat(myModifyProfile?.extraInterest)
                    ?.length > 0
                    ? myModifyProfile.interest
                        .concat(myModifyProfile?.extraInterest)
                        .join(', ')
                    : t('idealDetail.inputLike'),
            code:
                myModifyProfile?.interest?.length > 0
                    ? myModifyProfile?.interestCode
                    : [],
            key: 'interest',
            isNull: myModifyProfile?.interest?.length > 0 ? false : true,
            editable: true,
        },
        {
            id: 'drink',
            title: t('idealDetail.drink'),
            value: myModifyProfile?.drink
                ? myModifyProfile?.drink
                : t('idealDetail.selectDrink'),
            key: 'drinking',
            isNull: myModifyProfile?.drink ? false : true,
            editable: true,
        },
        {
            id: 'smoking',
            title: t('idealDetail.smoking'),
            value:
                myModifyProfile?.smoking !== ''
                    ? myModifyProfile?.smoking
                    : t('idealDetail.selectSmoking'),
            key: 'smoking',
            isNull: myModifyProfile?.smoking !== '' ? false : true,
            editable: true,
        },
    ];

    return (
        <>
            <div className="stepLastBox">
                <ul className="infoList">
                    {details.map((detail, i) => {
                        if (detail.id === 'pen') {
                            // 자기소개
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li>
                                        <p
                                            className={`bold-500 ${detail.isNull ? '' : 'color-000'}`}
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else if (['like', 'life', 'smoking', 'drink'].includes(detail.id)) {
                            // interest
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt margin-b8 on`}>
                                        <p>{detail.title}</p>
                                        <img src="/assets/img/common/ico-add-btn.svg"
                                             onClick={() => {
                                                 openPopupHandler(detail);
                                             }}
                                        />
                                    </li>
                                    <li className="padding-0 on">
                                        <div className="myCateList_mp">
                                            {
                                                getUserStyleValue(detail.id).map((item, i) => {
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={item + i}
                                                            className="active"
                                                        >
                                                            {item}
                                                        </button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </li>
                                </React.Fragment>
                            );
                        } else {
                            return (
                                // 닉네임 ~ 종교
                                <li
                                    key={detail.id}
                                    className={`ic-${detail.id} leftRightTxt${detail.isNull ? '' : ' on'}`}
                                >
                                    <p>{detail.title}</p>
                                    <p
                                        onClick={() => {
                                            openPopupHandler(detail);
                                        }}
                                    >
                                        {detail.value}
                                    </p>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>

            <div className="codeCon margin-t8">
                <div className="contentsWrap">
                    <div className="titleWrap">
                        {/*추천인 코드*/}
                        <p className="titleSub01 margin-b8">{t('codeStep.title')}</p>
                    </div>
                    <div className="content">
                        <div className="inputWrap">
                            <div className="input">
                                {/*추천인 코드를 입력해 주세요 (선택)*/}
                                <input id="firstFocus" type="text" placeholder={t('codeStep.inputCode')}
                                       value={recommendCode}
                                       onChange={(e) => {
                                           setRecommendCode(e.target.value)
                                       }}
                                />
                            </div>
                            {
                                codeFailed ?
                                    (
                                        <p className="sub-color fz12 padding-t8">
                                            {codeErrorMessage}
                                        </p>
                                    )
                                    : ""
                            }
                        </div>
                        <div className="exTxtWrap margin-t24">
                            <ul className="exTxtList margin-t8">
                                <li>{t('codeStep.codeGuide')}</li>
                                {/*가입 완료후에는 마이페이지에서 관심, 스타일, 추천인 코드 입력이 가능합니다.*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditProfileDetailView;
