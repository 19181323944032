import {
	BothLikeUserCIVO,
	MessageRoomCIVO,
	UserLikeCIVO,
} from '@client/site/api';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import { usePaymentRepo } from '@dating/stores/PaymentStore';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';
import { useHushHomeRepo } from '@dating/repository/hushHome/useHushHomeRepo';
import { useLikeRepo } from '@dating/repository/like/useLikeRepo';
import { useUserProfileRepo } from '@dating/repository/useUserProfileRepo';
import { useDirectMessageRepo } from '@virtual-space/stores/useDirectMessageRepo';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useOrderUtils } from '@client/utils/orderUtils';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';

export const usePaymentService = () => {
	const {
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		activeItem,
		setActiveItem,
		clearActiveItem,
		setMatchedItem,
		messageTargetUser,
		setMessageTargetUser,
		matchingSuccess,
		setMatchingSuccess,
		likedLocation,
		setLikedLocation,
		existingRoomData,
		setExistingRoomData,
	} = usePaymentRepo();

	const { fnValidateOrder } = useOrderUtils();

	const { openMessageRoom } = useMessageAdapter();

	const { savedLikeToday, setSavedLikeToday, savedLikePick, setSavedLikePick } =
		useHushHomeRepo();

	const { setSavedLikeReceive, setSavedLikeHighRated, setSavedLikeSend } =
		useLikeRepo();

	const { setSavedLikeProfile } = useUserProfileRepo();

	const { srcPrefix, userProfile, setUserProfile } = useDatingRepo();

	const { setMessageInputValue } = useDirectMessageRepo();

	const { commonProducts, managerProducts, pointProducts, recommendProducts } =
		useProductsRepo();

	const { saveUserLike, checkUserBothLike } = useLikeAdapter();

	const { checkAndGetRoomId, createNavigationState } = useLikeMatchingService();
	/////////////////////////////////////////

	/*
	 * fnOpenMessageRoom
	 * 좋아요 매칭 후, "대화 열기" 버튼 클릭시 결제 함수
	 */
	const fnOpenMessageRoom = (param: {
		roomId: string;
		billingStatus: number;
		orderId: number;
		productId: number;
	}) => {
		const data: MessageRoomCIVO = {
			roomId: param.roomId,
			billingStatus: param.billingStatus,
			orderId: param.orderId,
		};
		openMessageRoom(data);
	};

	/*
	 * fnSaveUserLike
	 * 프로필, 좋아요 탭에서 좋아요 보내기 (과금)
	 */
	const fnSaveUserLike = async (param: {
		userUid: string;
		state: boolean;
		orderId: number;
	}) => {
		const map: UserLikeCIVO = {
			likeUserUid: param.userUid,
			likeYn: param.state,
			orderId: param.orderId,
		};

		try {
			const result = await saveUserLike(map);
			if (result) {
				setUserProfile({ ...userProfile, likeYn: true });
				await fnCheckUserBothLike(param.userUid, param.orderId);
			}
		} catch (e: any) {
			console.error('Error in fnSaveUserLike:', e.body);
		}
	};

	/*
	 * fnCheckUserBothLike
	 * saveUserLike 성공 시 두 유저가 매칭되었는 지 확인
	 */
	const fnCheckUserBothLike = async (userUid: string, orderId: number) => {
		const map: BothLikeUserCIVO = {
			userUid: userUid,
			orderId: orderId,
		};
		checkUserBothLike(map)
			.then(async (value) => {
				if (value.matchingYn === 'success') {
					// matchedItem 설정을 먼저 하고
					setMatchedItem({
						roomId: value.roomId,
						myProfileUrl: value.myProfileUrl,
						toProfileUrl: value.toProfileUrl,
						matchingYn: value.matchingYn,
					});

					// roomId를 가져오고
					const finalRoomId = await checkAndGetRoomId(value.roomId);

					// matchingSuccess 설정
					setMatchingSuccess(true);

					// 약간의 지연을 주어 상태 업데이트가 반영되도록 함
					await new Promise((resolve) => setTimeout(resolve, 100));

					// createMatingDMRoom 호출
					createNavigationState(finalRoomId);
				}

				// HushHome 컨테이너에 좋아요 처리완료 알리기
				switch (activeItem.location) {
					case 'receive':
						setSavedLikeReceive(true);
						break;
					case 'highRated':
						setSavedLikeHighRated(true);
						break;
					case 'send':
						setSavedLikeSend(true);
						break;
					case 'hushPick':
						setSavedLikePick(true);
						break;
					case 'today':
						setSavedLikeToday(true);
						break;
					case 'profile':
						setSavedLikeProfile(true);
						break;
				}
			})
			.catch((reason) => {
				console.error('Error in fnCheckUserBothLike:', reason.body);
			});
	};

	/*
	 * fnSendLikePopOpen
	 * 좋아요 과금 팝업 호출
	 */
	const fnSendLikePopOpen = (
		userUid: string,
		profileUrl: string,
		nickName: string,
		location:
			| ''
			| 'hushPick'
			| 'today'
			| 'receive'
			| 'highRated'
			| 'send'
			| 'profile',
	) => {
		setActiveItem({
			userUid: userUid,
			profileUrl: profileUrl,
			nickName: nickName,
			location: location,
		});

		setOpenLikeConfirm(true);
	};

	/*
	 * fnSendLikePopClose
	 * 좋아요 과금 팝업 닫기 및 activeItem 초기화
	 */
	const fnSendLikePopClose = () => {
		clearActiveItem();
		setOpenLikeConfirm(false);
	};

	/*
	 * fnCloseMessagePop
	 * 베리드로 메세지 보내기 팝업 닫기 및 메세지 초기화
	 */
	const fnCloseMessagePop = () => {
		setMessageInputValue('');
		setExistingRoomData(null);
		setOpenPaymentPop(false);
	};

	return {
		fnSaveUserLike,
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		srcPrefix,
		fnSendLikePopOpen,
		fnSendLikePopClose,
		activeItem,
		matchingSuccess,
		messageTargetUser,
		setMessageTargetUser,
		likedLocation,
		setLikedLocation,
		fnCloseMessagePop,
		commonProducts,
		managerProducts,
		pointProducts,
		recommendProducts,
		fnValidateOrder,
		fnOpenMessageRoom,
		existingRoomData,
		setExistingRoomData,
	};
};
