import { CSSTransition } from 'react-transition-group';
import { useInactiveUserService } from '@dating/ui/components/auth/inactive/useInactiveUserService';
import { InactiveAlertPopView } from '@dating/ui/components/auth/inactive/views/InactiveAlertPopView';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

export const InactiveUserContainer = () => {
	const {
		fnInactiveUserOff,
		offInactive,
		fnActivatedUserLogin,
	} = useInactiveUserService();

	const navigate = useNavigate();
	const { t } = useTranslation('setting');
	return (
		<>
			<div className="wrap rightShow active noFooter">
				<header>
					<div className="headerWrap">
						<div className="h-left">
							<button type="button" className="btnBack" onClick={() => {
								navigate(PATH.REGISTER.REGISTER, { replace: true });
							}} />
						</div>
						<div className="h-center">
							<p className="title02"></p>
						</div>
						<div className="h-right">
						</div>
					</div>
				</header>

				<div className="popConWrap hiddenCon pb148">
					<div className="inner padding-t8">
						<div className="titleWrap">
							<h2 className="title01">{t('accountInactive')}
								{/* 계정이 휴면 상태 입니다. */}
							</h2>
							<p className="titleSub01">
								{t('accountInactiveWarning1')}
								{/* 휴면계정이 되어 HUSH 이용을 할 수 없습니다. */}
								<br />
								{t('accountInactiveWarning2')}
								{/* HUSH를 사용하기 위해서 계정을 해제하여 주시기 바랍니다. */}
							</p>
						</div>
						<div className="icoCenter text-c">
							<img src="/assets/img/common/ico-id-delete.svg" width="100" alt="" />
						</div>
					</div>
				</div>
				<footer>
					<div className="f-btnWrap">
						<button
							className="btn btnBig btnBlack popOnBtn"
							onClick={() => {
								fnInactiveUserOff();
							}}
						>
                            <span>{t('reactivateAccount')}
								{/* 휴면해제하기 */}
                            </span>
						</button>
					</div>
				</footer>
			</div>

			<div
				className={`${
					offInactive
						? 'settingPopOn'
						: 'settingPop'
				}`}
			>
				<div className="settingDim" />
				<CSSTransition
					in={offInactive}
					classNames="withdrawalWrap-transition"
					timeout={100}
				>
					<InactiveAlertPopView
						fnActivatedUserLogin={fnActivatedUserLogin}
					/>
				</CSSTransition>
			</div>
		</>
	);
};
