import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { create } from 'zustand';
import {
	IDirectMessage,
	IDMRoom,
	IUser,
} from '../../../types/IDirectMessageState';

interface DirectMessageRepoState {
	dmSessionId: string;
	currentRoomId: string | null;
	relatedRoomIds: string[];
	groupedMessages: Record<string, any>;
	directMessages: Array<IDirectMessage>;

	// 전체 메시지 룸 리스트
	directMessageRoomList: Array<IDMRoom>;

	// 전체 그룹화된 메시지 리스트
	directMessageList: any[];
	// 전체 상세 메시지 리스트
	detailDirectMessages: Record<string, any>;

	// 일반 유저와 일반 유저 간의 메시지 리스트
	normalDirectMessageList: any[];

	// 일반 유저와 매니저 간의 ONLINE 메시지 리스트
	managerOnlineDirectMessageList: any[];

	// 일반 유저와 매니저 간의 OFFLINE 메시지 리스트
	managerOfflineDirectMessageList: any[];

	startDirectMessageList: any[];
	groupDirectMessageList: any[];
	focused: boolean;
	openDirectMessageDetail: {
		isOpen: boolean;
		roomId: string;
	};
	newDirectMessage: Partial<IDirectMessage>;
	showDirectMessageList: boolean;
	managerEmpty: boolean;
	leaveDirectMessage: Record<
		string,
		{
			roomId: string;
			leaverId: string;
		}
	>;
	exitDirectMessage: Record<
		string,
		{
			roomId: string;
			exit: boolean;
		}
	>;
	mutualPaymentCompleted: Record<
		string,
		{
			roomId: string;
			paid: boolean;
		}
	>;
	changeDirectRoomType: Record<
		string,
		{
			roomId: string;
			dmRoomType: string;
		}
	>;
	messageInputValue: string;
	unreadMessageCounts: Record<string, number>;
	userSessionStatus: Record<
		string,
		{
			targetUid: string;
			isOnline: string;
		}
	>;
	setUserSessionStatus: (status: Record<string, any>) => void;
	setChangeDirectRoomType: (changeDirectRoomType: Record<string, any>) => void;
	setMutualPaymentCompleted: (completed: Record<string, any>) => void;
	setGroupedMessages: (groupedMessages: Record<string, any>) => void;

	setDirectMessageRoomList: (rooms: Array<IDMRoom>) => void;

	setDirectMessageList: (list: any[]) => void;
	setDetailDirectMessages: (detailDirectMessages: Record<string, any>) => void;

	setNormalDirectMessageList: (list: any[]) => void;
	setManagerOnlineDirectMessageList: (list: any[]) => void;
	setManagerOfflineDirectMessageList: (list: any[]) => void;

	setCurrentRoom: (roomId: string) => void;
	setRelatedRooms: (roomIds: string[]) => void;
	addRelatedRoom: (roomId: string) => void;
	updateUnreadMessageCount: (roomId: string, count: number) => void;
	setDmSessionId: (dmSessionId: string) => void;
	addDirectMessageRoom: (room: IDMRoom) => void;
	updateDirectMessageRoom: (room: IDMRoom) => void;
	removeDirectMessageRoom: (roomId: string) => void;
	setDirectMessages: (message: IDirectMessage[]) => void;
	addDirectMessage: (message: IDirectMessage) => void;

	setStartDirectMessageList: (list: any[]) => void;
	setGroupDirectMessageList: (list: any[]) => void;
	setFocused: (focused: boolean) => void;
	setOpenDirectMessageDetail: (isOpen: boolean, roomId: string) => void;
	setShowDirectMessageList: (show: boolean) => void;
	setManagerEmpty: (empty: boolean) => void;
	setLeaveDirectMessage: (leave: Record<string, any>) => void;
	setExitDirectMessage: (exit: Record<string, any>) => void;
	setMessageInputValue: (value: string) => void;
	isOnline: boolean;
	setIsOnline: (isOnline: boolean) => void;
	showOnlineMessageList: boolean;
	setShowOnlineMessageList: (show: boolean) => void;
	showOfflineMessageList: boolean;
	setShowOfflineMessageList: (show: boolean) => void;
	isMessenger: boolean;
	setIsMessenger: (isMessenger: boolean) => void;

	isFirst: boolean;
	setIsFirst: (isFirst: boolean) => void;

	totalUnreadCount: number;
	setTotalUnreadCount: (count: number) => void;

	resetTotalUnread: () => void;
	setResetTotalUnread: (resetFn: () => void) => void;
}

export const useDirectMessageRepo = create<DirectMessageRepoState>((set) => ({
	resetTotalUnread: () => {}, // 초기값은 빈 함수
	setResetTotalUnread: (resetFn) => set({ resetTotalUnread: resetFn }),

	totalUnreadCount: 0,
	setTotalUnreadCount: (count: number) => set({ totalUnreadCount: count }),

	isFirst: false,
	setIsFirst: (isFirst: boolean) => set({ isFirst }),

	isMessenger: true,
	setIsMessenger: (isMessenger: boolean) => set({ isMessenger }),

	showOnlineMessageList: false,
	setShowOnlineMessageList: (showOnlineMessageList: boolean) =>
		set({ showOnlineMessageList }),

	showOfflineMessageList: false,
	setShowOfflineMessageList: (showOfflineMessageList: boolean) =>
		set({ showOfflineMessageList }),

	isOnline: true,
	setIsOnline: (isOnline: boolean) => set({ isOnline }),

	currentRoomId: null,
	relatedRoomIds: [],
	dmSessionId: '',

	// 전체 메시지 룸 리스트
	directMessageRoomList: [],

	// 그룹화된 메시지 리스트
	directMessageList: [],
	detailDirectMessages: {},

	// 일반 유저와 일반 유저 간의 메시지 리스트
	normalDirectMessageList: [],

	// 일반 유저와 매니저 간의 ONLINE 메시지 리스트
	managerOnlineDirectMessageList: [],

	// 일반 유저와 매니저 간의 OFFLINE 메시지 리스트
	managerOfflineDirectMessageList: [],

	groupedMessages: {},
	directMessages: [],
	startDirectMessageList: [],
	groupDirectMessageList: [],
	focused: false,
	openDirectMessageDetail: {
		isOpen: false,
		roomId: '',
	},
	newDirectMessage: {
		roomId: '',
		sender: {} as IUser,
		receiver: {} as IUser,
		takerId: '',
		content: '',
		read: false,
	},
	showDirectMessageList: false,
	managerEmpty: true,
	leaveDirectMessage: {},
	exitDirectMessage: {},
	mutualPaymentCompleted: {},
	changeDirectRoomType: {},
	messageInputValue: '',
	unreadMessageCounts: {},
	userSessionStatus: {},

	// Message 맵으로 만들고 새로 만든 함수들
	setUserSessionStatus: (status) => set({ userSessionStatus: status }),
	setGroupedMessages: (groupedMessages) => set({ groupedMessages }),
	setDetailDirectMessages: (detailDirectMessages) =>
		set({ detailDirectMessages }),
	updateUnreadMessageCount: (roomId, count) =>
		set((state) => ({
			unreadMessageCounts: {
				...state.unreadMessageCounts,
				[roomId]: count ?? 0,
			},
		})),
	setCurrentRoom: (roomId: string) => set({ currentRoomId: roomId }),

	setRelatedRooms: (roomIds: string[]) => set({ relatedRoomIds: roomIds }),

	addRelatedRoom: (roomId: string) =>
		set((state) => ({
			relatedRoomIds: [...new Set([...state.relatedRoomIds, roomId])],
		})),
	setDmSessionId: (dmSessionId: string) => set({ dmSessionId }),

	setDirectMessageRoomList: (rooms: Array<IDMRoom>) =>
		set({ directMessageRoomList: rooms }),

	addDirectMessageRoom: (room: IDMRoom) =>
		set((state) => ({
			directMessageRoomList: [...state.directMessageRoomList, room],
		})),

	updateDirectMessageRoom: (updatedRoom: IDMRoom) =>
		set((state) => ({
			directMessageRoomList: state.directMessageRoomList.map((room) =>
				room.roomId === updatedRoom.roomId ? updatedRoom : room,
			),
		})),

	removeDirectMessageRoom: (roomId: string) =>
		set((state) => ({
			directMessageRoomList: state.directMessageRoomList.filter(
				(room) => room.roomId !== roomId,
			),
		})),

	setDirectMessages: (messages: IDirectMessage[]) =>
		set({ directMessages: messages }),
	addDirectMessage: (message: IDirectMessage) =>
		set((state) => ({
			directMessages: [...state.directMessages, message],
		})),

	setDirectMessageList: (list) => set({ directMessageList: list }),

	setNormalDirectMessageList: (list) => set({ normalDirectMessageList: list }),
	setManagerOnlineDirectMessageList: (list) =>
		set({ managerOnlineDirectMessageList: list }),
	setManagerOfflineDirectMessageList: (list) =>
		set({ managerOfflineDirectMessageList: list }),

	setStartDirectMessageList: (list) => set({ startDirectMessageList: list }),
	setGroupDirectMessageList: (list) => set({ groupDirectMessageList: list }),
	setFocused: (focused) => {
		set({ focused });
		reactEventEmitter.emit('react-enable-key', !focused);
	},
	setOpenDirectMessageDetail: (isOpen, roomId) =>
		set({ openDirectMessageDetail: { isOpen, roomId } }),
	setNewDirectMessage: (message) => set({ newDirectMessage: message }),
	setShowDirectMessageList: (show) => set({ showDirectMessageList: show }),
	setManagerEmpty: (empty) => set({ managerEmpty: empty }),
	setLeaveDirectMessage: (leave) => set({ leaveDirectMessage: leave }),
	setExitDirectMessage: (exit) => set({ exitDirectMessage: exit }),
	setMutualPaymentCompleted: (paid) => set({ mutualPaymentCompleted: paid }),
	setChangeDirectRoomType: (changeDirectRoomType) =>
		set({ changeDirectRoomType }),
	setMessageInputValue: (value) => set({ messageInputValue: value }),
}));
