import {CSSTransition} from 'react-transition-group';
import {useEditProfilePopService} from "@dating/ui/components/editProfilePop/useEditProfilePopService";
import EditNicknamePopView from "@dating/ui/pages/profile/editPop/EditNicknamePopView";
import EditJobPopView from "@dating/ui/pages/profile/editPop/EditJobPopView";
import EditHeightPopView from "@dating/ui/pages/profile/editPop/EditHeightPopView";
import EditIntroducePopView from "@dating/ui/pages/profile/editPop/EditIntroducePopView";
import EditInterestPopView from "@dating/ui/pages/profile/editPop/EditInterestPopView";
import EditLifeStylePopView from "@dating/ui/pages/profile/editPop/EditLifeStylePopView";
import EditDrinkingPopView from "@dating/ui/pages/profile/editPop/EditDrinkingPopView";
import EditSmokingPopView from "@dating/ui/pages/profile/editPop/EditSmokingPopView";
import EditReligionPopView from "@dating/ui/pages/profile/editPop/EditReligionPopView";

export const EditProfilePopContainer = () => {
    const {
        focusItem,
        editPop,
        setEditPop,
        handleHeightChange,
        handleHeightKeyDown,
        heightValue,
        setHeightValue,
        activeHeightIndex,
        setActiveHeightIndex,
        interestList,
        lifeStyleList,
        jobList,
        selectedJob,
        setSelectedJob,
        nicknameValue,
        setNicknameValue,
        introduceValue,
        setIntroduceValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        fnModifyProfileNickname,
        fnModifyProfileJob,
        fnModifyProfileHeight,
        fnModifyProfileDrink,
        fnModifyProfileSmoking,
        fnModifyProfileReligion,
        fnModifyProfileIntroduce,
        interestValueHandler,
        interestValue,
        closeEditPopup,
        lifeStyleValue,
        lifeStyleValueHandler,
        fnModifyProfileInterest,
        fnModifyProfileLifeStyle,
        handleInputChange,
        isValueEntered,
        lengthOver,
        isAvailable,
        nickError,
        smokingList,
        drinkingList,
    } = useEditProfilePopService();

    return (
        <>
            {/*닉네임*/}
            <CSSTransition
                in={editPop.nickName}
                classNames="popWrap-transition"
                timeout={100}
            >
                <EditNicknamePopView
                    focusItem={focusItem}
                    nicknameValue={nicknameValue}
                    fnModifyProfileNickname={fnModifyProfileNickname}
                    closeEditPopup={closeEditPopup}
                    handleInputChange={handleInputChange}
                    lengthOver={lengthOver}
                    isAvailable={isAvailable}
                    isValueEntered={isValueEntered}
                    nickError={nickError}
                />
            </CSSTransition>

            {/*직업*/}
            <CSSTransition
                in={editPop.job}
                classNames="popWrap-transition"
                timeout={100}
            >
                <EditJobPopView
                    focusItem={focusItem}
                    editPop={editPop}
                    setEditPop={setEditPop}
                    setSelectedJob={setSelectedJob}
                    jobList={jobList}
                    selectedJob={selectedJob}
                    fnModifyProfileJob={fnModifyProfileJob}
                    closeEditPopup={closeEditPopup}
                />
            </CSSTransition>

            {/*키*/}
            <CSSTransition
                in={editPop.height}
                classNames="popWrap-transition"
                timeout={100}
            >
                <EditHeightPopView
                    focusItem={focusItem}
                    editPop={editPop}
                    setEditPop={setEditPop}
                    heightValue={heightValue}
                    activeHeightIndex={activeHeightIndex}
                    setHeightValue={setHeightValue}
                    handleHeightChange={handleHeightChange}
                    handleHeightKeyDown={handleHeightKeyDown}
                    setActiveHeightIndex={setActiveHeightIndex}
                    fnModifyProfileHeight={fnModifyProfileHeight}
                    closeEditPopup={closeEditPopup}
                />
            </CSSTransition>

            {/*음주*/}
            <CSSTransition
                in={editPop.drinking}
                classNames="listAddPop-transition"
                timeout={100}
            >
                <EditDrinkingPopView
                    focusItem={focusItem}
                    drinkingValue={drinkingValue}
                    setDrinkingValue={setDrinkingValue}
                    fnModifyProfileDrink={fnModifyProfileDrink}
                    closeEditPopup={closeEditPopup}
                    openPop={editPop.drinking}
                    drinkingList={drinkingList}
                />
            </CSSTransition>

            {/*흡연*/}
            <CSSTransition
                in={editPop.smoking}
                classNames="listAddPop-transition"
                timeout={100}
            >
                <EditSmokingPopView
                    focusItem={focusItem}
                    smokingValue={smokingValue}
                    setSmokingValue={setSmokingValue}
                    fnModifyProfileSmoking={fnModifyProfileSmoking}
                    closeEditPopup={closeEditPopup}
                    openPop={editPop.smoking}
                    smokingList={smokingList}
                />
            </CSSTransition>

            {/*종교*/}
            <CSSTransition
                in={editPop.religion}
                classNames="popWrap-transition"
                timeout={100}
            >
                <EditReligionPopView
                    focusItem={focusItem}
                    editPop={editPop}
                    setEditPop={setEditPop}
                    religionValue={religionValue}
                    setReligionValue={setReligionValue}
                    fnModifyProfileReligion={fnModifyProfileReligion}
                    closeEditPopup={closeEditPopup}
                />
            </CSSTransition>

            {/*자기소개*/}
            <CSSTransition
                in={editPop.introduce}
                classNames="popWrap-transition"
                timeout={100}
            >
                <EditIntroducePopView
                    focusItem={focusItem}
                    editPop={editPop}
                    setEditPop={setEditPop}
                    introduceValue={introduceValue}
                    setIntroduceValue={setIntroduceValue}
                    fnModifyProfileIntroduce={fnModifyProfileIntroduce}
                    closeEditPopup={closeEditPopup}
                />
            </CSSTransition>

            {/*관심사*/}
            <CSSTransition
                in={editPop.interest}
                classNames="listAddPop-transition"
                timeout={100}
            >
                <EditInterestPopView
                    focusItem={focusItem}
                    closeEditPopup={closeEditPopup}
                    interestList={interestList}
                    interestValue={interestValue}
                    interestValueHandler={interestValueHandler}
                    fnModifyProfileInterest={fnModifyProfileInterest}
                    openPop={editPop.interest}
                />
            </CSSTransition>

            {/*라이프스타일*/}
            <CSSTransition
                in={editPop.lifeStyle}
                classNames="listAddPop-transition"
                timeout={100}
            >
                <EditLifeStylePopView
                    focusItem={focusItem}
                    closeEditPopup={closeEditPopup}
                    lifeStyleList={lifeStyleList}
                    lifeStyleValue={lifeStyleValue}
                    lifeStyleValueHandler={lifeStyleValueHandler}
                    fnModifyProfileLifeStyle={fnModifyProfileLifeStyle}
                    openPop={editPop.lifeStyle}
                />
            </CSSTransition>
        </>
    );
};