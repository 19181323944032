import { create } from 'zustand';
import { PointUsageHistoryCOVO, PointUsageHistorySOVO } from '@client/site/api';

type BerithPack = {
	origin: string;
	sale: string;
	price: string;
	best: boolean;
};

type StoreState = {
	myBerith: number;
	setMyBerith: (myBerith: number) => void;

	berithPacks: BerithPack[];
	setBerithPacks: (berithPacks: BerithPack[]) => void;

	managerPack: { type: string; hot: boolean }[];
	setManagerPack: (managerPack: { type: string; hot: boolean }[]) => void;

	// 베리드 사용 내역
	myBerithHistory: PointUsageHistorySOVO[];
	setMyBerithHistory: (myBerithHistory: PointUsageHistorySOVO[]) => void;
};

export const useStoreRepo = create<StoreState>((set) => ({
	myBerith: 0,
	setMyBerith: (myBerith: number) => set({ myBerith }),

	berithPacks: [
		{
			origin: '100',
			sale: '',
			price: '10000',
			best: false,
		},
		{
			origin: '300',
			sale: '315',
			price: '30000',
			best: true,
		},
	],
	setBerithPacks: (berithPacks: BerithPack[]) => set({ berithPacks }),

	managerPack: [
		{
			type: 'off',
			hot: false,
		},
		{
			type: 'screen',
			hot: true,
		},
	],
	setManagerPack: (managerPack: { type: string; hot: boolean }[]) =>
		set({ managerPack }),

	// 베리드 사용 내역
	myBerithHistory: [],
	setMyBerithHistory: (myBerithHistory: PointUsageHistorySOVO[]) =>
		set({ myBerithHistory }),
}));
