import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { useDeleteUserService } from "@dating/ui/pages/settings/deleteUser/useDeleteUserService";
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { PATH } from "@dating/constants/RoutingEndPoints";
import { useTranslation } from 'react-i18next';

const DeleteUserContainer = () => {

    const {
        fnSaveDeleteAccount,
        deleted,
        pointLeft,
    } = useDeleteUserService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    const navigate = useNavigate();
    const { t } = useTranslation('setting');

    useEffect(() => {
        if (deleted) {
            fnCommonAlertPopOn(t('deletedAccount'));
            // 계정이 삭제되었습니다.
            navigate(PATH.REGISTER.REGISTER);
        }
    }, [deleted]);

    useEffect(() => {
        if (pointLeft) {
            fnCommonAlertPopOn(t('checkPoints'));
            // 남은 포인트, 매니저 남은 횟수를 확인하신 후 계정삭제를 진행해 주세요.
            navigate(-1);
        }
    }, [pointLeft]);

    return (
        <>
            <div className="wrap rightShow active">
                <ButtonHeaderView right={false} />
                <div className="popConWrap hiddenCon padding-b0 margin-b0">
                    <div className="inner padding-t8">
                        <div className="titleWrap">
                            <h2 className="title01">
                                {t('deleteAccountConfirm')}
                                {/* 정말 계정을 삭제하시겠어요? */}
                            </h2>
                            <p className="titleSub01">
                                {t('deleteWarning1')}
                                {/* 계정을 삭제하면 7일 동안 재가입이 불가하며, */}
                                <br />
                                {t('deleteWarning2')}
                                {/* 잔여 허쉬 포인트를 포함한 모든 데이터가 삭제되어 복구가 불가합니다. 신중히 결정해주세요. */}
                                <br />
                                {t('deleteWarning3')}
                                {/* 단, 구매 포인트, 매니저 사용횟수가 남은 경우는 계정 삭제가 불가하며 소진 후 삭제가 가능합니다. */}
                            </p>
                        </div>
                        <div className="icoCenter text-c">
                            <img src="/assets/img/common/ico-id-delete.svg" width="100" alt={t('deleteIconAlt')} />
                            {/* 아이디 삭제 아이콘 */}
                        </div>
                    </div>
                </div>

                <div className="inner padding-t16 padding-b16">
                    <button
                        className="btn btnBig btnBlack popOnBtn"
                        onClick={() => {
                            fnSaveDeleteAccount();
                        }}
                    >
                        <span>
                            {t('deleteBtn')}
                            {/* 계정 삭제 */}
                        </span>
                    </button>
                    <button
                        className="btn btnBig btnCCC margin-t8"
                        type="button"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <span>
                            {t('cancelBtn')}
                            {/* 취 소 */}
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default DeleteUserContainer;
