import { usePaymentPopRepo } from '@dating/repository/payment/usePaymentPopRepo';
import { useProductsRepo } from '@dating/repository/products/useProductsRepo';
import { useOrderAdapter } from '@dating/adapter/order/useOrderAdapter';
import type { InAppResultInfoCIVO } from '@client/site/api';

export const usePaymentPopService = () => {
	const { chargeAlertPopOn, setChargeAlertPopOn, chargePopOn, setChargePopOn } =
		usePaymentPopRepo();

	const { pointProducts } = useProductsRepo();

	const { saveInAppPurchase } = useOrderAdapter();
	/////////////////////////////////////

	/*
	 * openChargeAlertPop
	 * 충전 알림 팝업 열기
	 */
	const openChargeAlertPop = () => {
		setChargeAlertPopOn(true);
	};

	/*
	 * closeChargeAlertPop
	 * 충전 알림 팝업 닫기
	 */
	const closeChargeAlertPop = () => {
		setChargeAlertPopOn(false);
	};

	/*
	 * confirmChargeAlertPop
	 * 충전 알림 팝업 확인
	 */
	const confirmChargeAlertPop = () => {
		setChargeAlertPopOn(false);
		openChargePop();
	};

	/*
	 * openChargePop
	 * 충전 팝업 열기
	 */
	const openChargePop = () => {
		setChargePopOn(true);
	};

	/*
	 * closeChargePop
	 * 충전 팝업 닫기
	 */
	const closeChargePop = () => {
		setChargePopOn(false);
	};

	/*
	 * saveInAppPurchase
	 * 인앱 결제 정보 저장
	 */
	const fnSaveInAppPurchase = (response) => {
		alert(JSON.parse(response));
		const map: InAppResultInfoCIVO = {
			productId: 12,
			purchaseId: 'purchaseId',
			status: 'status',
			transactionDate: 'date',
			verificationData: {},
		};
		// saveInAppPurchase(map).then(value => {
		// 	if (value) {}
		//
		// }).catch(reason => {
		// 	alert(reason.body.error);
		// });
	};

	return {
		chargeAlertPopOn,
		chargePopOn,
		openChargeAlertPop,
		closeChargeAlertPop,
		confirmChargeAlertPop,
		openChargePop,
		closeChargePop,
		pointProducts,
		fnSaveInAppPurchase,
	};
};
