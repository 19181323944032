import useLifeStyleService from "@dating/ui/pages/register/lifeStyle/service/useLifeStyleService";
import LifeStyleContentsView from "./views/LifeStyleContentsView";
import LifeStyleFooterView from "./views/LifeStyleFooterView";
import LifeStyleHeaderView from "./views/LifeStyleHeaderView";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const LifeStyleContainer = () => {
    const {
        fnSaveUserStyle,
        interestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setDrinkingValue,
        lifeStyleValue,
        interestValue,
    } = useLifeStyleService();

    const navigate = useNavigate();
    useEffect(() => {
        // 라이프스타일 저장 후 가입승인상태 다시 요청해서 true면 시작하기, false 면 waiting
        if (statusSaveUserStyle === "success" && dataSaveUserStyle) {
            // navigate(PATH.REGISTER.WAITING);
        }
    }, [statusSaveUserStyle, dataSaveUserStyle]);

    return (
        <div className="wrap noFooter ">
            <LifeStyleHeaderView/>
            <LifeStyleContentsView
                interestList={interestList}
                interestHandler={interestHandler}
                lifeStyleList={lifeStyleList}
                lifeStyleHandler={lifeStyleHandler}
                drinkingValue={drinkingValue}
                smokingValue={smokingValue}
                initDrinkingState={initDrinkingState}
                initSmokingState={initSmokingState}
                setSmokingValue={setSmokingValue}
                setDrinkingValue={setDrinkingValue}
                lifeStyleValue={lifeStyleValue}
                interestValue={interestValue}
            />
            <LifeStyleFooterView
                fnSaveUserStyle={fnSaveUserStyle}
            />
        </div>
    )
};

export default LifeStyleContainer;