import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import type { UserPreferCIVO } from '@client/site/api';
import { useEffect } from 'react';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useIdealSettingEditStateRepo } from '@dating/ui/pages/settings/idealSettingEdit/useIdealSettingEditStateRepo';
import { useTranslation } from 'react-i18next';
import { useAuthRepo } from '@dating/repository/auth/useAuthRepo';

export const useIdealSettingEditService = () => {
	const {
		isPopupVisible,
		setIsPopupVisible,
		popupType,
		setPopupType,
		ageRange,
		setAgeRange,
		nearRange,
		setNearRange,
		heightRange,
		setHeightRange,
		religion,
		setReligion,
		drinkingState,
		setDrinkingState,
		smokingState,
		setSmokingState,
		initAgeRange,
		setInitAgeRange,
		initNearRange,
		setInitNearRange,
		initHeightRange,
		setInitHeightRange,
		initReligion,
		setInitReligion,
		initDrinkingState,
		setInitDrinkingState,
		initSmokingState,
		setInitSmokingState,
		setUserPrefer,
		userPrefer,
		nearOverRange,
		setNearOverRange,
		ageOverRange,
		setAgeOverRange,
		heightOverRange,
		setHeightOverRange,
	} = useIdealSettingEditStateRepo();

	const { saveUserPrefer, statusSaveUserPrefer, dataSaveUserPrefer } =
		useRegisterAdapter();

	const { getPrefer } = useSettingAdapter();

	const { getUserPrefer } = useProfileAdapter();

	const { langCode } = useAuthRepo();

	const { t } = useTranslation('register');
	////////////////////////////////////////

	useEffect(() => {
		getPrefer(langCode)
			.then((value) => {
				setInitAgeRange({ min: value.ageMin, max: value.ageMax });
				setInitNearRange({ min: value.distanceMin, max: value.distanceMax });
				setInitHeightRange({ min: value.heightMin, max: value.heightMax });

				setInitReligion(value.religion);
				setInitDrinkingState(value.drink);
				setInitSmokingState(value.smoke);
			})
			.catch((reason) => {});
	}, []);

	const showPopup = () => {
		setIsPopupVisible(true);
	};

	const hidePopup = (action: string, type: string) => {
		if (action === 'cancel' && userPrefer) {
			//취소 버튼 일 때 db에 저장된 값으로 set
			if (type === 'religion') {
				setReligion({
					code: userPrefer.religionCode,
					value: userPrefer.religionValue,
				});
			} else if (type === 'drinking') {
				setDrinkingState({
					code: userPrefer.drinkingCode,
					value: userPrefer.drinkingValue,
				});
			} else if (type === 'smoking') {
				setSmokingState({
					code: userPrefer.smokingCode,
					value: userPrefer.smokingValue,
				});
			}
		}

		//팝업 닫기
		setIsPopupVisible(false);
	};

	const fnSaveUserPrefer = () => {
		const map: UserPreferCIVO = {
			ageMin: ageRange.min,
			ageMax: ageRange.max,
			distanceMin: nearRange.min,
			distanceMax: nearRange.max,
			heightMin: heightRange.min,
			heightMax: heightRange.max,
			religion: religion.code,
			drinking: drinkingState.code,
			smoking: smokingState.code,
		};
		saveUserPrefer(map);
	};

	const handleAgeChange = (value: { min: number; max: number }) => {
		setAgeRange(value);
	};

	const formatAgeValue = (value: number) => {
		if (value === initAgeRange.max) {
			setAgeOverRange(true);
		} else {
			setAgeOverRange(false);
		}
		return `${value}${t('modifyProfile.howOld')}`;
	};
	const handleNearChange = (value: { min: number; max: number }) => {
		setNearRange(value);
	};

	const formatDistanceValue = (value: number) => {
		if (value === initNearRange.max) {
			setNearOverRange(true);
		} else {
			setNearOverRange(false);
		}
		return `${value}km`;
	};
	const handleHeightChange = (value: { min: number; max: number }) => {
		setHeightRange(value);
	};

	const formatHeightValue = (value: number) => {
		if (value === initHeightRange.max) {
			setHeightOverRange(true);
		} else {
			setHeightOverRange(false);
		}
		return `${value}cm`;
	};

	const handleReligionSelect = (option: { code: string; value: string }) => {
		setReligion({
			code: option.code,
			value: option.value,
		});
	};

	const handleDrinkingStateSelect = (option: {
		code: string;
		value: string;
	}) => {
		setDrinkingState({
			code: option.code,
			value: option.value,
		});
	};

	const handleSmokingStateSelect = (option: {
		code: string;
		value: string;
	}) => {
		setSmokingState({
			code: option.code,
			value: option.value,
		});
	};

	useEffect(() => {
		getUserPrefer()
			.then((value) => {
				if (Object.keys(value).length !== 0) {
					setUserPrefer(value);
					setAgeRange({ min: value.ageMin, max: value.ageMax });
					setNearRange({ min: value.distanceMin, max: value.distanceMax });
					setHeightRange({ min: value.heightMin, max: value.heightMax });

					setReligion({ code: value.religionCode, value: value.religionValue });
					setDrinkingState({
						code: value.drinkingCode,
						value: value.drinkingValue,
					});
					setSmokingState({
						code: value.smokingCode,
						value: value.smokingValue,
					});
				} else {
					alert('object is null');
					return;
				}
			})
			.catch((reason) => {});
	}, []);

	return {
		isPopupVisible,
		setIsPopupVisible,
		popupType,
		setPopupType,
		showPopup,
		hidePopup,
		fnSaveUserPrefer,
		formatAgeValue,
		ageRange,
		handleAgeChange,
		formatDistanceValue,
		nearRange,
		handleNearChange,
		formatHeightValue,
		heightRange,
		handleHeightChange,
		religion,
		handleReligionSelect,
		drinkingState,
		handleDrinkingStateSelect,
		smokingState,
		handleSmokingStateSelect,
		initAgeRange,
		initNearRange,
		initHeightRange,
		initReligion,
		initDrinkingState,
		initSmokingState,
		statusSaveUserPrefer,
		dataSaveUserPrefer,
		nearOverRange,
		ageOverRange,
		heightOverRange,
	};
};
