import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import MissionListView from './views/MissionListView';
import { useTranslation } from 'react-i18next';

const MissionContainer = () => {
	const { t } = useTranslation('profile');
	const title = t('mission.title');
	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<MissionListView />
		</div>
	);
};

export default MissionContainer;
