import { MetaUserAvatarCIVO } from '@client/site/api/models/MetaUserAvatarCIVO';
import { useMetaUserAdapter } from '@client/site/dating//adapter/meta/useMetaUserAdapter';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import {
	avatarType,
	parseAvatarString,
	generateCharacterImages,
	make,
	randomAvatar,
} from '@virtual-space/utils/avatarUtils';

export const useCharacterService = () => {
	const navigate = useNavigate();
	const {
		myMetaUser,
		customizeAvatarOpen,
		modifyCostumeColor,
		modifyFaceColor,
		modifyHairColor,
		setCostumeColor,
		setFaceColor,
		setHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		setModifyHairColor,
		setCustomizeAvatarOpen,
		setMyMetaUser,
	} = useMetaUserRepo();
	const { t } = useTranslation('metaverse');

	const { saveMetaUserAvatar, getMyMetaUser } = useMetaUserAdapter();

	const [character, setCharacter] = useState<avatarType>(
		parseAvatarString(myMetaUser.avatarName),
	);
	const [activeTab, setActiveTab] = useState(0);
	const [showColorPicker, setShowColorPicker] = useState(false);

	useEffect(() => {
		// customizeAvatarOpen 상태와 관계없이 항상 modify* 상태 업데이트
		setModifyCostumeColor(`costumes${character.cc}`);
		setModifyFaceColor(`faces${character.fc}`);
		setModifyHairColor(`hairs${character.hc}`);

		if (!customizeAvatarOpen) {
			setCostumeColor(`costumes${character.cc}`);
			setFaceColor(`faces${character.fc}`);
			setHairColor(`hairs${character.hc}`);
		}
	}, [character, customizeAvatarOpen]);

	// UI 즉시 업데이트를 위한 캐릭터 이미지 생성
	const characterImages = useMemo(() => {
		const images = generateCharacterImages(
			make(character),
			modifyCostumeColor,
			modifyFaceColor,
			modifyHairColor,
		);

		return images;
	}, [make(character), modifyCostumeColor, modifyFaceColor, modifyHairColor]);

	const onClickBack = () => {
		navigate(-1);
	};

	const handleTabClick = (index: number) => {
		setActiveTab(index);
	};

	const handleChangeAvatar = (type: keyof avatarType, idx: number) => {
		setCharacter((prevAvatar) => {
			const newAvatar = { ...prevAvatar, [type]: idx + 1 };

			// 색상 변경 시 즉시 색상 상태도 업데이트
			if (type === 'hc') {
				setModifyHairColor(`hairs${idx + 1}`);
			} else if (type === 'cc') {
				setModifyCostumeColor(`costumes${idx + 1}`);
			} else if (type === 'fc') {
				setModifyFaceColor(`faces${idx + 1}`);
			}

			return newAvatar;
		});
	};
	const handleClick = (event: React.MouseEvent) => {
		event.preventDefault();
		const randomCharacter = randomAvatar();
		setCharacter(randomCharacter);
		setModifyHairColor(`hairs${randomCharacter.hc}`);
		setModifyCostumeColor(`costumes${randomCharacter.cc}`);
		setModifyFaceColor(`faces${randomCharacter.fc}`);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			const newAvatarName = make(character);

			// 1. 서버에 저장
			const map: MetaUserAvatarCIVO = {
				avatarName: newAvatarName,
				userUid: myMetaUser.userUid,
			};
			saveMetaUserAvatar(map);

			// 2. UI 상태 업데이트
			setHairColor(modifyHairColor);
			setCostumeColor(modifyCostumeColor);
			setFaceColor(modifyFaceColor);

			// 3. 새로운 데이터를 받아와서 상태 업데이트하기 전에 이벤트 발생
			reactEventEmitter.emit('react-my-player-change-avatar', newAvatarName);

			// 4. 새로운 유저 데이터 가져오기
			const userData = await getMyMetaUser();
			if (userData) {
				if (userData.avatarName !== newAvatarName) {
					console.warn('Warning: Server avatar name differs from saved one', {
						saved: newAvatarName,
						received: userData.avatarName,
					});
				}
				setMyMetaUser({
					...userData,
					avatarName: newAvatarName,
				});
			}

			// 5. UI 정리 및 네비게이션
			setCustomizeAvatarOpen(false);
			onClickBack();
		} catch (error) {
			console.error('Failed to submit avatar changes:', error);
		}
	};

	useEffect(() => {
		setShowColorPicker(activeTab === 0 || activeTab === 1 || activeTab === 2);
	}, [activeTab]);

	return {
		title: t('avatar'),
		link: t('createRoom'),
		characterImages,
		character,
		activeTab,
		showColorPicker,
		modifyHairColor,
		modifyCostumeColor,
		modifyFaceColor,
		setModifyHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		onClickBack,
		handleTabClick,
		handleChangeAvatar,
		handleClick,
		handleSubmit,
	};
};
