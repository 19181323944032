import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCommonAlertPopService } from '@client/site/dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';

interface props {
	isOnline: boolean;
}
const PurchaseButtonView = ({ isOnline }: props) => {
	const navigate = useNavigate();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const { t } = useTranslation('store');

	return (
		<div className="mgFoot">
			<button
				type="button"
				className="mgPayBtn"
				onClick={() => {
					if (import.meta.env.MODE == 'prd') {
						// 운영
						fnCommonAlertPopOn(t('serviceReady'));
					} else {
						// 개발
						const path = isOnline ? PATH.STORE.SCREEN : PATH.STORE.OFFLINE;
						navigate(path);
					}
				}}
			/>
		</div>
	);
};

export default PurchaseButtonView;
