import Phaser from 'phaser';
import { PlayerDialogBubble } from './PlayerDialogBubble';
import { PlayerName } from './PlayerName';
import { PlayerReactionBubble } from './PlayerReactionBubble';
import { createCharacterAnims } from '@virtual-space/metaverse/anims/CharacterAnims';
import {
	useMetaUserRepo,
	OtherPlayerInfo,
} from '@virtual-space/stores/useMetaUserRepo';
import { PlayerBehavior } from '@virtual-space/types/PlayerBehavior';
import { sanitizeId } from '@virtual-space/utils/util';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
export default class Player extends Phaser.Physics.Arcade.Sprite {
	playerId: string;
	playerBehavior = PlayerBehavior.IDLE;
	playerUUID!: string;
	playerType!: string;
	readyToConnect = false;
	mediaConnected = false;
	playerName?: PlayerName;
	playerContainer?: Phaser.GameObjects.Container;
	playerDialogBubble?: PlayerDialogBubble;
	playerReactionBubble?: PlayerReactionBubble;

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		public playerTexture: string,
		public id: string,
		public uuid: string,
		public name: string,
		public playerStatusMessage: string,
		public audioStatus: boolean,
		public profileUrl: string,
		public role: string,
		frame?: string | number,
	) {
		super(scene, x, y, playerTexture, frame);
		this.playerId = id;
		this.playerType = role;
		this.playerUUID = uuid;
		this.width = 48;
		this.height = 64;
		this.setDepth(this.y);
		if (this.scene.sys.isActive()) {
			this.initializePlayer();
		} else {
			this.scene.events.once('create', this.initializePlayer, this);
		}

		// 클릭 이벤트 리스너는 scene 생성 여부와 관계없이 추가
		this.setInteractive();
		this.on('pointerover', this.handleMouseHover, this);
		this.on('pointerout', this.handleMouseOut, this);
		this.on('pointerdown', this.handlePlayerClick, this);
	}

	protected initializePlayer() {
		if (!this.scene || !this.scene.add) {
			console.error('Invalid scene object provided to Player');
			return;
		}

		// scene이 완전히 로드되었는지 확인
		if (!this.scene.sys.isActive()) {
			this.scene.events.once('create', () => this.initializePlayer(), this);
			return;
		}

		// 기존 오브젝트 정리
		this.cleanupExistingObjects();
		this.playerType = this.type;
		this.playerUUID = this.uuid;

		// 플레이어를 담을 컨테이너 생성
		this.playerContainer = this.scene.add
			.container(this.x, this.y - 150)
			.setDepth(5000);

		// 플레이어 이름 생성
		this.playerName = new PlayerName(this.scene, this.playerContainer);

		// 플레이어 대화 버블 생성
		this.playerDialogBubble = new PlayerDialogBubble(
			this.scene,
			this.playerContainer,
		);

		// 이모지 버블 생성
		this.playerReactionBubble = new PlayerReactionBubble(
			this.scene,
			this.playerContainer,
		);

		// 컨테이너에 물리 엔진 적용
		this.scene.physics.world.enable(this.playerContainer);

		// 컨테이너의 충돌 범위 설정
		const playContainerBody = this.playerContainer
			.body as Phaser.Physics.Arcade.Body;
		const collisionScale = [0.1, 0.1];
		playContainerBody.setSize(
			this.width * collisionScale[0] * 0.5,
			this.height * collisionScale[1],
		);
	}

	private cleanupExistingObjects() {
		if (this.playerContainer) {
			this.playerContainer.destroy();
			this.playerContainer = undefined;
		}
		if (this.playerName) {
			this.playerName.destroy();
			this.playerName = undefined;
		}
	}

	private handleMouseHover() {
		document.body.style.cursor = 'pointer';
	}

	private handleMouseOut() {
		document.body.style.cursor = 'default';
	}

	private async handlePlayerClick() {
		const {
			sessionId,
			playerNameMap,
			setOtherMetaUser,
			setOpenOtherPlayerProfile,
		} = useMetaUserRepo.getState();
		const { setUserProfileOpen } = useDatingRepo.getState();
		const cleanPlayerId = sanitizeId(this.playerId);
		if (cleanPlayerId === sessionId) return;
		const otherPlayerInfo = playerNameMap.get(cleanPlayerId);
		const otherPlayerUUID = otherPlayerInfo!.uuid;
		if (otherPlayerInfo) {
			const formattedOtherPlayerInfo: OtherPlayerInfo = {
				playerId: this.playerId,
				playerInfo: otherPlayerInfo,
			};
			setOpenOtherPlayerProfile(true, formattedOtherPlayerInfo);
			setOtherMetaUser(otherPlayerUUID);
			setUserProfileOpen(otherPlayerUUID);
		}
	}

	updateDialogBubble(message: string) {
		if (this.playerDialogBubble) {
			this.playerDialogBubble.updateDialogBubble(message);
		} else {
			console.error('PlayerDialogBubble not initialized');
		}
	}

	updateReactionBubble(emoji: string) {
		if (this.playerReactionBubble) {
			this.playerReactionBubble.updateReactionBubble(emoji);
		} else {
			console.error('PlayerReactionBubble not initialized');
		}
	}

	protected preUpdate(time: number, delta: number): void {
		super.preUpdate(time, delta);
		if (this.playerContainer) {
			this.playerContainer.setPosition(this.x, this.y - 25);
		}
	}

	async createAnimation(imageName: string) {
		await this.playWrab(`${this.playerTexture}_idle_down`, false);
	}

	playWrab = async (
		key: string,
		ignoreIfPlaying?: boolean,
	): Promise<Phaser.GameObjects.GameObject> => {
		const texture = key.split('_')[0];

		await createCharacterAnims(this.scene, texture);

		if (key.indexOf('right') > 0) {
			this.flipX = true;
		} else {
			this.flipX = false;
		}

		if (this.play && typeof this.play === 'function') {
			this.play(key, ignoreIfPlaying);
		} else {
			console.error('play method is not available');
		}

		return this;
	};
}
