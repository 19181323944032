import {useEditProfileRepo} from '@dating/repository/profile/useEditProfileRepo';
import {useEffect} from 'react';
import {useSettingAdapter} from '@dating/adapter/setting/useSettingAdapter';
import {useEditProfilePopStateRepo} from '@dating/ui/components/editProfilePop/useEditProfilePopStateRepo';
import {useProfileAdapter} from '@dating/adapter/profile/useProfileAdapter';
import type {
    ProfileDrinkCIVO,
    ProfileHeightCIVO,
    ProfileInterestCIVO,
    ProfileIntroCIVO,
    ProfileJobCIVO,
    ProfileLifeStyleCIVO,
    ProfileNicknameCIVO,
    ProfileReligionCIVO,
    ProfileSmokingCIVO,
} from '@client/site/api';
import {reactEventEmitter} from '@virtual-space/events/ReactEventEmitter';
import {useMetaUserAdapter} from '@dating/adapter/meta/useMetaUserAdapter';
import {useMetaUserRepo} from '@virtual-space/stores/useMetaUserRepo';
import {isCensored} from '@client/utils/censor';
import {useCommonAlertPopService} from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import {useTranslation} from 'react-i18next';
import {useAuthRepo} from '@dating/repository/auth/useAuthRepo';

export const useEditProfilePopService = () => {
    const {t} = useTranslation('messenger');
    const {
        drinkingList,
        setDrinkingList,
        smokingList,
        setSmokingList,
        nickError,
        setNickError,
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        heightValue,
        setHeightValue,
        activeHeightIndex,
        setActiveHeightIndex,
        interestList,
        setInterestList,
        lifeStyleList,
        setLifeStyleList,
        jobList,
        setJobList,
        selectedJob,
        setSelectedJob,
        nicknameValue,
        setNicknameValue,
        introduceValue,
        setIntroduceValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        lengthOver,
        setLengthOver,
        isValueEntered,
        setIsValueEntered,
        isAvailable,
        setIsAvailable,
    } = useEditProfilePopStateRepo();

    const {focusItem, editPop, setEditPop, myModifyProfile} =
        useEditProfileRepo();

    const {langCode} = useAuthRepo();

    const {getJobList, getStyles} = useSettingAdapter();

    const {
        modifyJob,
        statusModifyJob,
        dataModifyJob,
        modifyHeight,
        statusModifyHeight,
        dataModifyHeight,
        modifyDrink,
        statusModifyDrink,
        dataModifyDrink,
        modifySmoking,
        statusModifySmoking,
        dataModifySmoking,
        modifyReligion,
        statusModifyReligion,
        dataModifyReligion,
        modifyIntro,
        statusModifyIntro,
        dataModifyIntro,
        modifyProfileInterest,
        statusModifyProfileInterest,
        dataModifyProfileInterest,
        modifyProfileLifeStyle,
        statusModifyProfileLifeStyle,
        dataModifyProfileLifeStyle,
        modifyNickName,
        statusModifyNickname,
        dataModifyNickname,
    } = useProfileAdapter();

    const {getMyMetaUser} = useMetaUserAdapter();

    const {setMyMetaUser} = useMetaUserRepo();

    const {fnCommonAlertPopOn} = useCommonAlertPopService();
    ///////////////////////////////////////////////////
    /*
     * fnModifyProfileLifeStyle
     * 라이프스타일 수정
     */
    const fnModifyProfileLifeStyle = () => {
        const commonLifeStyleCodes: string[] = [];
        for (const key in lifeStyleValue) {
            if (lifeStyleValue[key]) {
                commonLifeStyleCodes.push(key);
            }
        }

        const map: ProfileLifeStyleCIVO = {
            commonLifeStyleCode: commonLifeStyleCodes,
            extraLifeStyleValue: [],
        };
        modifyProfileLifeStyle(map);
    };

    /*
     * fnModifyProfileInterest
     * 관심사 수정
     */
    const fnModifyProfileInterest = () => {
        const commonInterestCodes: string[] = [];
        for (const key in interestValue) {
            if (interestValue[key]) {
                commonInterestCodes.push(key);
            }
        }

        const map: ProfileInterestCIVO = {
            commonInterestCode: commonInterestCodes,
            extraInterestValue: [],
        };
        modifyProfileInterest(map);
    };

    /*
     * fnModifyProfileIntroduce
     * 자기소개 수정
     */
    const fnModifyProfileIntroduce = () => {
        // 금칙어 포함되어 있는 경우 값 초기화 및 안내 팝업 호출
        const val = isCensored(introduceValue.replace(/\s/g, ''));
        // const val2 = censor(introduceValue.trim());
        if (val) {
            fnCommonAlertPopOn(t('forbiddenWord'));
            setIntroduceValue('');
        } else if (introduceValue != undefined && introduceValue != '') {
            const map: ProfileIntroCIVO = {
                intro: introduceValue,
            };
            modifyIntro(map);
            reactEventEmitter.emit(
                'react-my-player-change-status-message',
                introduceValue,
            );
        }
    };

    /*
     * fnModifyProfileSmoking
     * 흡연 정보 수정
     */
    const fnModifyProfileSmoking = () => {
        if (smokingValue != '') {
            const map: ProfileSmokingCIVO = {
                smokeCode: smokingValue,
            };
            modifySmoking(map);
        }
    };

    /*
     * fnModifyProfileReligion
     * 종교 정보 수정
     */
    const fnModifyProfileReligion = () => {
        if (religionValue != '') {
            const map: ProfileReligionCIVO = {
                religionCode: religionValue,
            };
            modifyReligion(map);
        }
    };

    /*
     * fnModifyProfileDrink
     * 음주 정보 수정
     */
    const fnModifyProfileDrink = () => {
        if (drinkingValue != '') {
            const map: ProfileDrinkCIVO = {
                drinkCode: drinkingValue,
            };
            modifyDrink(map);
        }
    };

    /*
     * fnModifyProfileNickname
     * 닉네임 수정
     */
    const fnModifyProfileNickname = () => {
        if (nicknameValue != '') {
            const map: ProfileNicknameCIVO = {
                nickname: nicknameValue,
            };
            modifyNickName(map);
            reactEventEmitter.emit('react-my-player-change-name', nicknameValue);
        }
    };

    /*
     * fnModifyProfileJob
     * 직업 수정
     */
    const fnModifyProfileJob = () => {
        if (selectedJob != '') {
            const map: ProfileJobCIVO = {
                job: selectedJob,
            };
            modifyJob(map);
        }
    };

    /*
     * fnModifyProfileHeight
     * 키 수정
     */
    const fnModifyProfileHeight = () => {
        if (heightValue != null) {
            const map: ProfileHeightCIVO = {
                height: Number(heightValue.join('')),
            };
            modifyHeight(map);
        }
    };

    /*
     * closeEditPopup
     * 팝업 닫기
     */
    const closeEditPopup = (key: string) => {
        const newItem = {
            ...editPop,
            [key]: false,
        };
        setEditPop(newItem);

        if (key === 'interest') {
            setInterestValue([]);
        } else if (key === 'lifeStyle') {
            setLifeStyleValue([]);
        }
    };

    useEffect(() => {
        if (statusModifyIntro === 'success' && dataModifyIntro) {
            closeEditPopup('introduce');
        }
    }, [statusModifyIntro, dataModifyIntro]);

    useEffect(() => {
        if (statusModifyReligion === 'success' && dataModifyReligion) {
            closeEditPopup('religion');
        }
    }, [statusModifyReligion, dataModifyReligion]);

    useEffect(() => {
        if (statusModifySmoking === 'success' && dataModifySmoking) {
            closeEditPopup('smoking');
        }
    }, [statusModifySmoking, dataModifySmoking]);

    useEffect(() => {
        if (statusModifyDrink === 'success' && dataModifyDrink) {
            closeEditPopup('drinking');
        }
    }, [statusModifyDrink, dataModifyDrink]);

    useEffect(() => {
        if (statusModifyHeight === 'success' && dataModifyHeight) {
            closeEditPopup('height');
        }
    }, [statusModifyHeight, dataModifyHeight]);

    useEffect(() => {
        if (statusModifyJob === 'success' && dataModifyJob) {
            closeEditPopup('job');
        }
    }, [statusModifyJob, dataModifyJob]);

    useEffect(() => {
        if (statusModifyNickname === 'success' && dataModifyNickname) {
            if (dataModifyNickname.result) {
                setIsAvailable(true);
                closeEditPopup('nickName');
                getMyMetaUser().then((value) => {
                    setMyMetaUser(value);
                });
            } else {
                setIsAvailable(false);
                setNickError(true);
            }
        }
    }, [statusModifyNickname, dataModifyNickname]);

    useEffect(() => {
        if (
            statusModifyProfileInterest === 'success' &&
            dataModifyProfileInterest
        ) {
            closeEditPopup('interest');
        }
    }, [statusModifyProfileInterest, dataModifyProfileInterest]);

    useEffect(() => {
        if (
            statusModifyProfileLifeStyle === 'success' &&
            dataModifyProfileLifeStyle
        ) {
            closeEditPopup('lifeStyle');
        }
    }, [statusModifyProfileLifeStyle, dataModifyProfileLifeStyle]);

    useEffect(() => {
        if (editPop.job) {
            if (jobList.length === 0) {
                getJobList(langCode)
                    .then((response) => {
                        if (response.length > 0) {
                            setJobList(response);
                        }
                    })
                    .catch((reason) => {
                    });
            }
            setSelectedJob(myModifyProfile?.jobCode);
        }

        if (editPop.nickName) {
            setNicknameValue(myModifyProfile?.nickName);
            setIsAvailable(true);
            setLengthOver(false);
        }

        if (editPop.introduce) {
            setIntroduceValue(myModifyProfile?.introduce);
        }

        if (editPop.drinking) {
            setDrinkingValue(myModifyProfile?.drinkCode.toString());
        }

        if (editPop.smoking) {
            setSmokingValue(myModifyProfile?.smokingCode.toString());
        }

        if (editPop.religion) {
            setReligionValue(myModifyProfile?.religionCode.toString());
        }

        if (editPop.interest) {
            if (editPop.interest && typeof focusItem.value === 'object') {
                const newItem = interestValue;
                focusItem.value.forEach((el) => {
                    newItem[el] = true;
                });
                setInterestValue(newItem);
            }
        }

        if (editPop.lifeStyle && typeof focusItem.value === 'object') {
            const newItem = lifeStyleValue;
            focusItem.value.forEach((el) => {
                newItem[el] = true;
            });
            setLifeStyleValue(newItem);
        }
    }, [editPop]);

    useEffect(() => {
        getStyles(langCode)
            .then((response) => {
                if (response.interest.length > 0) {
                    // interest list set
                    setInterestList(response.interest);
                }
                if (response.lifeStyle.length > 0) {
                    // lifeStyle list set
                    setLifeStyleList(response.lifeStyle);
                }
                if (response.smoke.length > 0) {
                    // smoke list set
                    setSmokingList(response.smoke);
                }
                if (response.drink.length > 0) {
                    // drink list set
                    setDrinkingList(response.drink);
                }
            })
            .catch((reason) => {
            });
    }, []);

    /*
     * handleHeightChange
     * 키 입력값 변경 시 빈 줄 제거 후 set, input index값 수정
     */
    const handleHeightChange = (index: number, newValue: string) => {
        if (
            /^\d$/.test(newValue) &&
            !(index == 0 && (Number(newValue) < 1 || Number(newValue) > 3))
        ) {
            setHeightValue((preValues) => {
                const newInputValues = [...preValues];
                newInputValues[index] = newValue;
                return newInputValues;
            });

            if (index < heightValue.length - 1 && newValue !== '') {
                setActiveHeightIndex(index + 1);
            }
        }
    };

    /*
     * handleHeightKeyDown
     * 키 입력 input에 Backspace 또는 Delete 키 입력 시 키 값 수정 및 input index 수정
     */
    const handleHeightKeyDown = (index: number, key: string) => {
        if (key === 'Backspace' || key === 'Delete') {
            setHeightValue((preValues) => {
                const newInputValues = [...preValues];
                newInputValues[index] = '';
                return newInputValues;
            });
            setActiveHeightIndex(index - 1);
        }

        if (key === 'ArrowLeft' && index > 0) {
            setActiveHeightIndex(index - 1);
        } else if (key === 'ArrowRight' && index < heightValue.length - 1) {
            setActiveHeightIndex(index + 1);
        }
    };

    /*
     * interestValueHandler
     * 관심사 선택 시 useState set, UI 변경
     */
    const interestValueHandler = (id: string) => {
        const newInterest = {...interestValue};
        if (interestValue[id]) {
            newInterest[id] = !newInterest[id];
        } else {
            newInterest[id] = true;
        }
        setInterestValue(newInterest);
    };

    /*
     * lifeStyleValueHandler
     * 라이프스타일 선택 시 useState set, UI 변경
     */
    const lifeStyleValueHandler = (id: string) => {
        const newLifeStyle = {...lifeStyleValue};
        if (lifeStyleValue[id]) {
            newLifeStyle[id] = !newLifeStyle[id];
        } else {
            newLifeStyle[id] = true;
        }
        setLifeStyleValue(newLifeStyle);
    };

    /*
     * handleInputChange
     * 입력값이 변경될 때 호출되는 핸들러
     */
    const handleInputChange = (newValue: string) => {
        setNicknameValue(newValue);

        // 닉네임 길이 2~8자 제한
        if (newValue.length < 2 || newValue.length > 8) {
            setLengthOver(true);
        } else {
            setLengthOver(false);
        }

        if (!isAvailable) {
            setIsAvailable(true);
        }
    };

    return {
        focusItem,
        editPop,
        setEditPop,
        handleHeightChange,
        handleHeightKeyDown,
        heightValue,
        setHeightValue,
        activeHeightIndex,
        setActiveHeightIndex,
        interestList,
        lifeStyleList,
        jobList,
        selectedJob,
        setSelectedJob,
        nicknameValue,
        setNicknameValue,
        introduceValue,
        setIntroduceValue,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        fnModifyProfileNickname,
        fnModifyProfileJob,
        fnModifyProfileHeight,
        fnModifyProfileDrink,
        fnModifyProfileSmoking,
        fnModifyProfileReligion,
        fnModifyProfileIntroduce,
        interestValueHandler,
        interestValue,
        closeEditPopup,
        lifeStyleValue,
        lifeStyleValueHandler,
        fnModifyProfileInterest,
        fnModifyProfileLifeStyle,
        handleInputChange,
        isValueEntered,
        lengthOver,
        isAvailable,
        nickError,
        smokingList,
        drinkingList,
    };
};
