import {useRegisterAdapter} from '@dating/adapter/register/useRegisterAdapter';
import {useRegisterRepo} from '@dating/repository/register/useRegisterRepo';
import {LocationDeviceInfoCIVO} from '@client/site/api';
import axios from 'axios';
import {useAuthRepo} from '@dating/repository/auth/useAuthRepo';
import {coords} from '@dating/constants/TempGpsCoordinates';
import {bridgeRequestLocation} from '@client/utils/bridgeCallHandler';
import {PATH} from '@dating/constants/RoutingEndPoints';
import {useNavigate} from 'react-router-dom';
import {useEffect} from "react";

export default function useLocationInfoService() {
    const {setStep, registerSteps} = useRegisterRepo();

    const {langCode, setAuthToken} = useAuthRepo();

    const {
        saveUserLocation,
        activateProfile,
        statusActivateProfile,
        dataActivateProfile,
    } = useRegisterAdapter();

    const navigate = useNavigate();

    /*
     * fnSaveUserLocation
     * 위치정보공유 동의 시 좌표값 저장
     */
    const fnSaveUserLocation = async (map: {
        latitude: string;
        longitude: string;
    }) => {
        const regions = await fnGetGeocode2(map);
        const locParam: LocationDeviceInfoCIVO = {...regions, ...map};
        const res = await saveUserLocation(locParam);
        if (res) {
            activateProfile();
        }
    };

    /*
     * @param loginUpdate 앱에서는 회원가입, 로그인 시 업데이트
     */
    const fnFormatLocation = (loginUpdate: boolean) => {
        if (!(window as any).flutter_inappwebview) {
            if (loginUpdate) {
                const randomIdx = Math.floor(Math.random() * coords.length);
                fnSaveUserLocation(coords[randomIdx]);
            }
        } else {
            bridgeRequestLocation().then((response) => {
                if (response == null) {
                    navigate(PATH.REGISTER.LOCATION_UNABLE);
                    return;
                } else {
                    const map: { latitude: string; longitude: string } = {
                        latitude: response.latitude,
                        longitude: response.longitude,
                    };
                    fnSaveUserLocation(map);
                }
            });
        }
    };
    /**
     * 기존 국내 kakao geocode api
     * @param map
     */
    const fnGetGeocode = async (map: { latitude: string; longitude: string }) => {
        const header = {
            url: `https://dapi.kakao.com/v2/local/geo/coord2address?x=${map.longitude}&y=${map.latitude}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `KakaoAK ${import.meta.env.KAKAO_REST_API_KEY}`,
            },
        };
        try {
            const response = await axios.request(header);
            const document = response?.data?.documents[0];
            let regions: { region1: string; region2: string } = {};
            if (document?.road_address) {
                regions = {
                    region1: document?.road_address?.region_1depth_name,
                    region2: document?.road_address?.region_2depth_name,
                };
            } else if (document?.address) {
                regions = {
                    region1: document?.address?.region_1depth_name,
                    region2: document?.address?.region_2depth_name?.split(' ')[0],
                };
            }
            return regions;
        } catch (e) {
            console.log('KAKAOAK GEOCODE ERROR ---> ', e);
        }
    };

    /**
     * 변경 국제 google geocode api
     * @param lang
     * @param long
     */
    const fnGetGeocode2 = async (map: {
        latitude: string;
        longitude: string;
    }) => {
        const header = {
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
                map.latitude
            },${map.longitude}&language=${langCode}&key=${
                import.meta.env.GOOGLE_MAP_API_KEY
            }`,
            method: 'GET',
        };
        try {
            const response = await axios.request(header);
            const resShort = response?.data?.results[0]?.address_components;
            const length = resShort?.length;
            const hasNumericEnd = !isNaN(resShort[length - 1]?.long_name);
            // Google Geocode API 응답에서 리스트의 마지막 요소가 숫자인 경우가 있어, 데이터 일관성을 위해 분기 처리함
            const regions: { region1: string; region2: string } = {
                region1: hasNumericEnd
                    ? resShort[length - 3]?.long_name ?? ''
                    : resShort[length - 2]?.long_name ?? '',
                region2: hasNumericEnd
                    ? resShort[length - 4]?.long_name ?? ''
                    : resShort[length - 3]?.long_name ?? '',
            };
            return regions;
        } catch (e) {
            console.log('GEOCODE FOREIGN TEST ERROR ---> ', e);
        }
    };

    useEffect(() => {
        if (statusActivateProfile === "success" && dataActivateProfile) {
            if (dataActivateProfile.result && dataActivateProfile.accessToken && dataActivateProfile.refreshToken) {
                const token = {
                    accessJmt: dataActivateProfile.accessToken,
                    refreshJmt: dataActivateProfile.refreshToken,
                };
                setAuthToken(token);
                navigate(PATH.AUTH.SUCCESS);
            }
        }
    }, [statusActivateProfile, dataActivateProfile]);

    return {
        fnSaveUserLocation,
        fnFormatLocation,
        setStep,
        registerSteps,
    };
}
