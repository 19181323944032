import { Link } from 'react-router-dom';
import {
	ReceiveUserHighScoreCOVO,
	SendUserHighScoreCOVO,
} from '@client/site/api';
import { MutableRefObject } from 'react';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

interface props {
	highlyRatedUsers: SendUserHighScoreCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (userUid: string) => void;
	fnRemoveSendHighlyRatedUsers: (id: string, type: string) => void;
	sendIndex: MutableRefObject<number>;
	fnSendLikePopOpen: (
		userUid: string,
		profileUrl: string,
		nickName: string,
		location:
			| ''
			| 'hushPick'
			| 'today'
			| 'receive'
			| 'highRated'
			| 'send'
			| 'profile',
	) => void;
}

const HighlySentRatedListView = ({
	highlyRatedUsers,
	srcPrefix,
	setUserProfileOpen,
	fnRemoveSendHighlyRatedUsers,
	fnSendLikePopOpen,
	sendIndex,
}: props) => {
	const { t } = useTranslation('like');

	return (
		<div className="likeProfileList">
			{/*content*/}
			{highlyRatedUsers.length > 0 ? (
				highlyRatedUsers.map((item, i) => {
					return (
						<div key={item.scoreUserUid + i} className="profileType">
							<div className="proLeft">
								<div className="proImg">
									<Link
										to={PATH.PROFILE.USER_PROFILE}
										onClick={() => {
											setUserProfileOpen(item.scoreUserUid);
										}}
										state={{
											isOtherProfile: true,
										}}
									>
										<img src={srcPrefix + item.profileUrl} alt="profile-img" />
									</Link>
								</div>
								<div className="proName margin-l12">
									<p className="bold padding-b4">{item.nickName}</p>
									<p className="fz14 color-666">
										{item.age +
											t('common.age') +
											', ' +
											(item.region1 && item.region2
												? `${item.region1} ${item.region2}`
												: t('common.noRegion'))}
									</p>
								</div>
							</div>
							<div className="ProRight">
								<button
									type="button"
									className="likeBtn fixOn"
									onClick={() => {
										if (item.scoreUserUid != '') {
											sendIndex.current = i;
											fnSendLikePopOpen(
												item.scoreUserUid,
												item.profileUrl,
												item.nickName,
												'send',
											);
										}
									}}
								/>
								<button
									type="button"
									className="closeBtn2 margin-l5"
									onClick={() => {
										fnRemoveSendHighlyRatedUsers(item.likeViewId, 'send');
									}}
								></button>
							</div>
						</div>
					);
				})
			) : (
				<div className="dataNoneBox">
					<p
						className="txt01"
						dangerouslySetInnerHTML={{ __html: t('highlyRate.quote') }}
					/>
					<Link to={PATH.DATING.HOME}>{t('common.homeBtn')}</Link>
				</div>
			)}
		</div>
	);
};

export default HighlySentRatedListView;
