import { useTranslation } from 'react-i18next';

const MissionListView = () => {
	const { t } = useTranslation('profile');

	return (
		<div className="popConWrap">
			<div className="inner">
				<div className="missionImg margin-top223">
					<img src={t('mission.imgSrc')} alt="미션 안내 이미지" />
				</div>
				<ul className="missionList">
					<li className="msbox msfin">
						좋아요 보내기 <p className="num bold">1 {t('mission.cnt')}</p>
						<div className="msfinck">
							<div className="checkfn">완료</div>
						</div>
					</li>
					<li className="msbox">
						Đánh giá mức độ hấp dẫn của đối tác ngay hôm nay{' '}
						<p className="num bold">5 {t('mission.cnt')}</p>
					</li>
					<li className="msbox">
						라운지 작성하기 <p className="num bold">1 {t('mission.cnt')}</p>
					</li>
					<li className="msbox">
						메타버스 캐릭터 변경{' '}
						<p className="num bold">1 {t('mission.cnt')}</p>
					</li>
					<li className="msbox">
						메시지 보내기 <p className="num bold">1 {t('mission.cnt')}</p>
					</li>
				</ul>
			</div>
			{/* TODO: 
			1. 모든 미션이 수행되기 전까진 아래 버튼 숨김 처리
			2. 모든 미션이 완료 되면 받기 버튼 활성화
			3. 받기 버튼 눌러서 포인트를 받았다면 미션 완료 버튼 활성화
			 */}
			<div className="chatInput input padding-16 bg-fff">
				<div className="misment">{t('mission.missionComment')}</div>
				{/* 받기 버튼 누른 뒤 complete 클래스 추가 */}
				<button className="btn btnBig btnBlack complete">
					<span className="s01">{t('mission.receive')}</span>
					<span className="s02">{t('mission.missionComplete')}</span>
				</button>
			</div>
		</div>
	);
};

export default MissionListView;
