import { useMetaRoomAdapter } from '@client/site/dating/adapter/meta/useMetaRoomAdapter';
import { useCallback, useEffect } from 'react';
import { useMetaRoomCommonService } from '../../service/useMetaRoomCommonService';
import { useRoomStateService } from '../../service/useRoomStateService';
import {
	RoomType,
	useRoomEventService,
} from '../../service/useRoomEventService';
import { useRoomManagementService } from '../../service/useRoomManagementService';
import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';

export const usePublicRoomService = () => {
	const {
		isMetaOpen,
		availableRooms,
		joinedRoomData,
		preLeftRoomId,
		setIsMetaOpen,
	} = useRoomRepo();
	const { statusSaveCreateRoom, dataSaveCreateRoom } = useMetaRoomAdapter();
	const { handleSaveRoomUserOut } = useMetaRoomCommonService();
	const {
		isRoomDataReady,
		shouldCreateRoom,
		setShouldCreateRoom,
		joinedRoomId,
	} = useRoomStateService();
	const { emitStartRoom, emitJoinRoom } = useRoomEventService();
	const { createRoom, joinRoom, disposeRoom } = useRoomManagementService();

	const publicRoom = availableRooms.find(
		(room) => room.metadata?.name?.toUpperCase() === 'PUBLIC',
	);

	// 메타버스 입장 함수
	const handleEnterMeta = useCallback(() => {
		const isPublicRoomExists = availableRooms.some(
			(room) =>
				room.metadata?.name?.toUpperCase() === 'PUBLIC' && room.clients > 0,
		);
		if (isPublicRoomExists) return;
		emitStartRoom({
			roomType: 'PUBLIC' as RoomType,
			x: 1024,
			y: 1376,
		});
		emitJoinRoom();
		setShouldCreateRoom(true);
	}, [availableRooms, emitStartRoom, emitJoinRoom]);

	// 공개 방 참여 함수
	const handleJoinPublicRoom = useCallback(async () => {
		if (!publicRoom) return;

		try {
			await joinRoom({
				roomId: publicRoom.roomId,
				roomType: 'PUBLIC',
				x: 1024,
				y: 1376,
			});
			setShouldCreateRoom(false);
		} catch (error) {
			console.error('공개 방 참여 실패:', error);
		}
	}, [publicRoom, joinRoom, setShouldCreateRoom]);

	// 공개 방 생성 함수
	const handleCreatePublicRoom = useCallback(async () => {
		if (!joinedRoomData?.id) return;

		try {
			await createRoom({
				roomName: 'PUBLIC',
				isSecret: false,
				roomMaxUsers: Infinity,
				roomThemeId: 4,
			});
		} catch (error) {
			console.error('공개 방 생성 실패:', error);
		}
	}, [joinedRoomData?.id, createRoom]);

	useEffect(() => {
		if (preLeftRoomId) {
			const roomExists = availableRooms.some(
				(room) => room.roomId === preLeftRoomId,
			);
			if (!roomExists) {
				disposeRoom(preLeftRoomId);
			}
		}
	}, [preLeftRoomId, availableRooms, disposeRoom]);

	// 메타버스 클릭 함수
	const handleClickMeta = useCallback(
		async (forcePublic = false) => {
			// 현재 참여중인 룸이 있고, PUBLIC이 아닌 경우에는 아무 동작도 하지 않음
			if (!forcePublic && joinedRoomData?.id) {
				const currentRoom = availableRooms.find(
					(room) => room.roomId === joinedRoomData.id,
				);
				if (
					currentRoom &&
					currentRoom.metadata?.name?.toUpperCase() !== 'PUBLIC'
				) {
					return;
				}
			}
			// 현재 시점의 최신 퍼블릭룸 상태 확인
			const currentPublicRoom = availableRooms.find(
				(room) => room.metadata?.name?.toUpperCase() === 'PUBLIC',
			);

			if (
				joinedRoomData?.id &&
				currentPublicRoom?.roomId === joinedRoomData.id
			) {
				await handleSaveRoomUserOut();
				// 상태가 업데이트될 때까지 잠시 대기
				await new Promise((resolve) => setTimeout(resolve, 100));
			}

			// 퍼블릭룸 상태 재확인
			const updatedPublicRoom = availableRooms.find(
				(room) => room.metadata?.name?.toUpperCase() === 'PUBLIC',
			);

			if (updatedPublicRoom?.roomId) {
				handleJoinPublicRoom();
			} else {
				handleEnterMeta();
			}
		},
		[
			joinedRoomData,
			publicRoom,
			handleJoinPublicRoom,
			handleEnterMeta,
			handleSaveRoomUserOut,
		],
	);

	useEffect(() => {
		if (shouldCreateRoom && isRoomDataReady && joinedRoomData?.id) {
			handleCreatePublicRoom();
			setShouldCreateRoom(false);
		}
	}, [
		joinedRoomData,
		handleCreatePublicRoom,
		isRoomDataReady,
		shouldCreateRoom,
	]);

	// 방 생성 상태 변경 처리
	useEffect(() => {
		const processRoomChanges = async () => {
			if (
				statusSaveCreateRoom === 'success' &&
				dataSaveCreateRoom &&
				joinedRoomId
			) {
				await joinRoom({
					roomId: joinedRoomId,
					roomType: 'PUBLIC',
					x: 1024,
					y: 1376,
				});
			}
		};

		processRoomChanges();
	}, [statusSaveCreateRoom, dataSaveCreateRoom, joinedRoomId, joinRoom]);

	return {
		isMetaOpen,
		setIsMetaOpen,
		handleJoinPublicRoom,
		handleClickMeta,
		handleCreatePublicRoom,
	};
};
