import { Link } from 'react-router-dom';
import { ReceiveUserHighScoreCOVO } from '@client/site/api';
import { MutableRefObject } from 'react';
import { PATH } from '@dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';

interface props {
	highlyRatedUsers: ReceiveUserHighScoreCOVO[];
	highlyRatedIndex: MutableRefObject<number>;
	srcPrefix: string;
	setUserProfileOpen: (userProfileOpen: string) => void;
	fnRemoveReceiveHighlyRatedUsers: (id: string, type: string) => void;
	fnSendLikePopOpen: (
		userUid: string,
		profileUrl: string,
		nickName: string,
		location:
			| ''
			| 'hushPick'
			| 'today'
			| 'receive'
			| 'highRated'
			| 'send'
			| 'profile',
	) => void;
}

const HighlyRatedListView = ({
	highlyRatedUsers,
	srcPrefix,
	setUserProfileOpen,
	fnRemoveReceiveHighlyRatedUsers,
	fnSendLikePopOpen,
	highlyRatedIndex,
}: props) => {
	const { t } = useTranslation('profile');
	return (
		<div
			className={
				highlyRatedUsers.length === 0
					? 'likeProfileList-empty'
					: 'likeProfileList'
			}
		>
			{highlyRatedUsers.map((item, i) => {
				return (
					<div key={item.nickName + i} className="profileType">
						<div className="proLeft">
							<div className="proImg">
								<Link
									to={PATH.PROFILE.USER_PROFILE}
									onClick={() => {
										setUserProfileOpen(item.scoreUserUid);
									}}
									state={{
										isOtherProfile: true,
									}}
								>
									<img src={srcPrefix + item.profileUrl} />
								</Link>
							</div>
							<div className="proName margin-l12">
								<p className="bold padding-b4">{item.nickName}</p>
								<p className="fz14 color-666">
									{item.age +
										t('profileBox.age') +
										', ' +
										(item.region1 && item.region2
											? `${item.region1} ${item.region2}`
											: t('noLocation'))}
								</p>
							</div>
						</div>
						<div className="ProRight">
							<button
								type="button"
								className="likeBtn fixOn"
								onClick={() => {
									if (item.scoreUserUid != '') {
										highlyRatedIndex.current = i;
										fnSendLikePopOpen(
											item.scoreUserUid,
											item.profileUrl,
											item.nickName,
											'highRated',
										);
									}
								}}
							></button>
							<button
								type="button"
								className="closeBtn2 margin-l5"
								onClick={() => {
									fnRemoveReceiveHighlyRatedUsers(item.likeViewId, 'received');
								}}
							></button>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default HighlyRatedListView;
