import Phaser from 'phaser';
import { phaserEventEmitter } from '../../../events/PhaserEventEmitter';
import { RoomService } from '../../../services/Network';
import Player from '../player/Player';
import { MyDirectionCursor } from './MyDirectionCursor';
import { MyKeyboard } from './MyKeyboard';
import { MyMove } from './MyMove';
import { MyPlayerCollision } from './MyPlayerCollision';
import { MyPlayerStateFactory } from './state/MyPlayerStateFactory';
import Chair from '../../items/impl/Chair';
import { createCharacterAnims } from '@virtual-space/metaverse/anims/CharacterAnims';

export default class MyPlayer extends Player {
	public collision!: MyPlayerCollision;
	private myKeyboard!: MyKeyboard;
	public chairOnSit?: Chair;
	public myMove!: MyMove;
	private initialized = false;
	myPlayerStateFactory = new MyPlayerStateFactory();

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		texture: string,
		id: string,
		uuid: string,
		name: string,
		statusMessage: string,
		audioStatus: boolean,
		profileUrl: string,
		role: string,
		frame?: string | number,
	) {
		super(
			scene,
			x,
			y,
			texture,
			id,
			uuid,
			name,
			statusMessage,
			audioStatus,
			profileUrl,
			role,
			frame,
		);
		this.initialize();
	}

	public initialize() {
		if (this.scene.sys.isActive()) {
			this.initializePlayer();
		} else {
			this.scene.events.once('create', this.initializePlayer, this);
		}
	}

	protected override initializePlayer() {
		super.initializePlayer();
		if (!this.scene || !this.scene.input || !this.scene.input.keyboard) {
			console.error('Scene or input not available');
			return;
		}

		const myCursor = new MyDirectionCursor(this.scene.input.keyboard);
		this.collision = new MyPlayerCollision(this.scene, 0, 0, 20, 20, myCursor);
		this.myMove = new MyMove(myCursor);
		this.myKeyboard = new MyKeyboard(this.scene.input.keyboard);
		this.initialized = true;
	}

	setPlayerName(name: string) {
		if (!this.initialized || !this.playerName) {
			return;
		}
		this.playerName.textName = name;
		phaserEventEmitter.emit('my-player-name-change', name);
	}

	async setPlayerTexture(texture: string) {
		if (!this.initialized || !this.scene || !this.scene.textures) {
			return;
		}

		this.playerTexture = texture;
		const aniKey = `${texture}_idle_down`;

		if (!this.scene.textures.exists(texture)) {
			try {
				await createCharacterAnims(this.scene, texture);
			} catch (error) {
				console.error('Error creating character animations:', error);
				return;
			}
		}

		this.setTexture(texture);

		phaserEventEmitter.emit('my-player-texture-change', {
			x: this.x,
			y: this.y,
			texture: aniKey,
		});

		try {
			await this.playWrab(aniKey, true);
		} catch (e) {
			console.error('Error playing animation:', e);
		}
	}

	setStatusMessage(statusMessage: string) {
		if (!this.initialized) {
			console.error('Player not initialized');
			return;
		}
		this.playerStatusMessage = statusMessage;
		phaserEventEmitter.emit('my-player-status-message-change', statusMessage);
	}

	setAudioStatus(audioStatus: boolean) {
		if (!this.initialized) {
			console.error('Player not initialized');
			return;
		}
		this.audioStatus = audioStatus;
		phaserEventEmitter.emit('player-audio-status-change', audioStatus);
	}

	setProfileUrl(profileUrl: string) {
		if (!this.initialized) {
			console.error('Player not initialized');
			return;
		}
		this.profileUrl = profileUrl;
		phaserEventEmitter.emit('player-profile-url-change', profileUrl);
	}

	update(roomService: RoomService) {
		if (!this.initialized) {
			return;
		}

		this.collision.update(this);
		const item = this.collision.selectedItem;

		const playerState = this.myPlayerStateFactory.createState({
			myPlayer: this,
			myKeyboard: this.myKeyboard,
			item,
			behavior: this.playerBehavior,
			roomService: roomService,
			scene: this.scene,
		});
		playerState.update();

		super.update();
	}

	updateMove(key?: string) {
		if (!this.initialized || !this.anims.currentAnim) {
			console.error(
				'Player not initialized or current animation not available',
			);
			return;
		}
		const texture = key ?? this.anims.currentAnim.key;
		phaserEventEmitter.emit('my-player-texture-change', {
			x: this.x,
			y: this.y,
			texture,
		});
	}

	playWrab = async (
		key: string,
		ignoreIfPlaying?: boolean,
	): Promise<Phaser.GameObjects.GameObject> => {
		if (!this.initialized || !this.scene || !this.scene.anims) {
			console.error('Player not initialized or scene/anims not available');
			return this;
		}

		const texture = key.split('_')[0];

		if (!this.scene.anims.exists(key)) {
			try {
				await createCharacterAnims(this.scene, texture);
			} catch (e) {
				console.error('Error creating animation:', e);
				return this;
			}
		}

		this.flipX = key.indexOf('right') > 0;

		if (this.play && typeof this.play === 'function') {
			this.play(key, ignoreIfPlaying);
		} else {
			console.error('play method is not available');
		}

		this.updateMove(key);
		return this;
	};
}
