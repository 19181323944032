import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { create } from 'zustand';
import { IChatMessage } from '../../../types/IMetaverseState';

type ChatRepoState = {
	chatMessages: Array<IChatMessage>;
	focused: boolean;
	showChat: boolean;
	chatActive: boolean;
	mobileChatInputClick: boolean;
	isInputFilled: boolean;
	chatInputValue: string;

	setFocused: (focused: boolean) => void;
	setShowChat: (showChat: boolean) => void;
	setChatActive: (chatActive: boolean) => void;
	setMobileChatInputClick: (mobileChatInputClick: boolean) => void;
	setIsInputFilled: (isInputFilled: boolean) => void;
	pushChatMessage: (message: IChatMessage) => void;
	setChatInputValue: (value: string) => void;
	clearChatMessages: () => void;
};

export const useChatRepo = create<ChatRepoState>((set) => ({
	chatMessages: [],
	focused: false,
	showChat: false,
	chatActive: true,
	mobileChatInputClick: false,
	isInputFilled: false,
	chatInputValue: '',

	setFocused: (focused) => {
		set({ focused });
		reactEventEmitter.emit('react-enable-key', !focused);
	},
	setShowChat: (showChat) => set({ showChat }),
	setChatActive: (chatActive) => set({ chatActive }),
	setMobileChatInputClick: (mobileChatInputClick) =>
		set({ mobileChatInputClick }),
	setIsInputFilled: (isInputFilled) => set({ isInputFilled }),
	pushChatMessage: (message) =>
		set((state) => ({
			chatMessages: [...state.chatMessages, message],
			focused: true,
			showChat: true,
		})),
	setChatInputValue: (value) => set({ chatInputValue: value }),
	clearChatMessages: () => {
		set({
			chatMessages: [],
			focused: false,
			showChat: false,
		});
		reactEventEmitter.emit('react-enable-key', true);
	},
}));
