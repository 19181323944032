import { useEffect } from 'react';
import { bridgeRequestContacts } from '@client/utils/bridgeCallHandler';
import { useTranslation } from 'react-i18next';

interface props {
	fnCommonConfirmPopOn: (
		message: string,
		okFunc: any,
		okParam: any,
		noFunc: any,
		noParam: any,
	) => any;
	fnSaveBlockAcquaintance: (res: any) => void;
	fnCommonAlertPopOn: (message: string) => void;
	fnSaveUnblockPhoneNumberUser: () => number;
	blockedNumberList: { blockName: string; blockPhoneNumber: string }[];
	blockCount: number;
	blockTf: boolean;
	unBlockCount: number;
	unBlockTf: boolean;
}

const BlockAcquaintanceFooterView = ({
	fnCommonConfirmPopOn,
	fnSaveBlockAcquaintance,
	fnCommonAlertPopOn,
	fnSaveUnblockPhoneNumberUser,
	blockedNumberList,
	blockCount,
	blockTf,
	setBlockTf,
	unBlockCount,
	unBlockTf,
	setUnBlockTf,
}: props) => {
	const { t } = useTranslation('setting');

	useEffect(() => {
		if (blockTf && blockCount > 0 && blockedNumberList > 0) {
			fnCommonAlertPopOn(t('blockedCount', { blockCount: blockCount }));
			setBlockTf(false);
		}
	}, [blockTf, blockCount, blockedNumberList]);

	useEffect(() => {
		if (unBlockTf && unBlockCount > 0 && !(blockedNumberList > 0)) {
			fnCommonAlertPopOn(t('unblockedCount', { count: unBlockCount }));
			setUnBlockTf(false);
		}
	}, [unBlockTf, unBlockCount, blockedNumberList]);

	return (
		<div className="inner padding-t16 padding-b16">
			{!(blockedNumberList && blockedNumberList.length > 0) && (
				<button
					type="button"
					className="btn btnBig btnBlack popOnBtn"
					onClick={() => {
						bridgeRequestContacts().then((res) => {
							if (res && res.length > 0) {
								// setBlockedNumberList(res);
								fnCommonConfirmPopOn(
									t('contactCount', { count: res.length }),
									fnSaveBlockAcquaintance(res),
									null,
									null,
									null,
								);
							} else {
								fnCommonAlertPopOn(t('noContactsToBlock'));
							}
						});
					}}
				>
					<span>{t('blockAllContacts')}</span>
				</button>
			)}
			{blockedNumberList && blockedNumberList.length > 0 && (
				<button
					type="button"
					className="btn btnBig btnBlack popOnBtn"
					onClick={() => {
						fnSaveUnblockPhoneNumberUser();
					}}
				>
					<span>{t('unblockAllContacts')}</span>
				</button>
			)}
		</div>
	);
};

export default BlockAcquaintanceFooterView;
