import { Link } from 'react-router-dom';
import StartOpenChatButtonView from './StartOpenChatButtonView';
import { PATH } from '@client/site/dating/constants/RoutingEndPoints';
import { useTranslation } from 'react-i18next';
import {
	formatMatchingTime,
	normalizeToTimestamp,
} from '@virtual-space/utils/messageUtils';

const PartialPaymentMessageView = ({
	myProfile,
	groupedMessagesDetail,
	srcPrefix,
	navigationRoom,
	handlePaymentClick,
	setUserProfileOpen,
	matchingTime,
	currentMessageLength,
	isOtherUserLeft,
}) => {
	const { t } = useTranslation('messenger');

	const renderLastMessage = () => {
		if (groupedMessagesDetail.other && groupedMessagesDetail.other.length > 0) {
			const lastGroup =
				groupedMessagesDetail.other[groupedMessagesDetail.other.length - 1];
			if (lastGroup.messages && lastGroup.messages.length > 0) {
				return lastGroup.messages[lastGroup.messages.length - 1].content;
			}
		}
		return;
	};
	const createdTime = normalizeToTimestamp(matchingTime);
	const formattedTime = formatMatchingTime(createdTime.toString());
	const blockStatus = navigationRoom.otherUserInfo.blockType;
	const getStatusMessage = (blockType: string) => {
		if (isOtherUserLeft) {
			return t('disconnectMsg');
		}
		switch (blockType) {
			case 'block':
				return t('disabledChatMessage');
			case 'blocked':
				return t('blockedUserMessage');
			case 'none':
			default:
				return t('startWarmChatMessage');
		}
	};
	return (
		<div className="inner chatRoom">
			<div className="chatList">
				<div className="chatStartBox">
					<div className="startTxt text-c">
						{(!blockStatus || blockStatus === 'none') && !isOtherUserLeft && (
							<p className="txt01">
								{t('matchedAlready', {
									matchingTime: formattedTime,
									nickName: navigationRoom.otherUserInfo.nickName,
								})}
							</p>
						)}
						<p
							className="txt02 margin-t5"
							dangerouslySetInnerHTML={{
								__html: getStatusMessage(blockStatus),
							}}
						/>
					</div>
					{(!blockStatus || blockStatus === 'none') && !isOtherUserLeft ? (
						<Link
							to={PATH.PROFILE.USER_PROFILE}
							onClick={() => {
								setUserProfileOpen(navigationRoom.otherUserInfo.userUid);
							}}
							state={{
								isOtherProfile:
									navigationRoom.otherUserInfo.userUid !== myProfile.userUid,
							}}
						>
							<div className="profileBigImg margin-t10">
								<img
									src={srcPrefix + navigationRoom.otherUserInfo.profileUrl}
									alt={navigationRoom.otherUserInfo.nickName}
								/>
							</div>
						</Link>
					) : !isOtherUserLeft ? (
						<div className="profileBigImg margin-t10">
							<img src="/assets/img/common/profile_no.svg" alt="테스트이미지" />
						</div>
					) : (
						<div className="profileBigImg margin-t10">
							<img src="/assets/img/common/profile_no.svg" alt="테스트이미지" />
						</div>
					)}
					{(!blockStatus || blockStatus === 'none') &&
						!isOtherUserLeft &&
						currentMessageLength !== 0 && (
							<div className="startMsg margin-t8">
								<p>{renderLastMessage()}</p>
							</div>
						)}
				</div>
			</div>
			<StartOpenChatButtonView
				handlePaymentClick={handlePaymentClick}
				blockStatus={blockStatus}
				isOtherUserLeft={isOtherUserLeft}
			/>
		</div>
	);
};

export default PartialPaymentMessageView;
