import { useEffect } from 'react';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useManagerLikeStateRepo } from '@dating/ui/pages/like/managerLike/useManagerLikeStateRepo';

export const useManagerLikeSerivce = () => {
	const limit = 3;

	const {
		likeUsers,
		setLikeUsers,
		likeUserPage,
		setLikeUserPage,
		setNoDataUserLike,
		setNoDataHighlyRated,
		setHighlyRatedUsers,
		highlyRatedPage,
		setHighlyRatedPage,
		setNoDataPost,
		setLikedPosts,
		likedPostPage,
		setLikedPostPage,
		stopGetReceivedLike,
		setStopGetReceivedLike,
		stopGetReceivedRating,
		setStopGetReceivedRating,
		highlyRatedUsers,
		likedPosts,
		fadeIn,
		setFadeIn,
		noDataUserLike,
		noDataHighlyRated,
		noDataPost,
	} = useManagerLikeStateRepo();

	const { setSingleFeedId } = useLoungeRepo();

	const { setUserProfileOpen } = useDatingRepo();

	const { getReceiveUserLike, getReceiveUserHighScore, getLikedPosts } =
		useLikeAdapter();
	///////////////////////////////////////

	/*
	 * fnGetReceiveUserLike
	 * 나에게 좋아요 보낸 유저 리스트 조회
	 */
	const fnGetReceiveUserLike = () => {
		if (!stopGetReceivedLike) {
			const dateTime = new Date().getTime();
			getReceiveUserLike(likeUserPage, limit, dateTime).then((value) => {
				if (value.length > 0) {
					const newItem = likeUsers;
					const list = newItem.concat(value);
					setLikeUserPage(likeUserPage + 1);
					setNoDataUserLike(value[0].addButtonOnOff);
					setLikeUsers(list);
				} else {
					setStopGetReceivedLike(true);
				}
			});
		}
	};

	useEffect(() => {
		if (likeUsers.length === 0 && likeUserPage === 1 && !stopGetReceivedLike) {
			fnGetReceiveUserLike();
		}
	}, [likeUsers, likeUserPage, stopGetReceivedLike]);

	/*
	 * fnGetReceiveUserHighScore
	 * 나를 높게 평가한 유저 리스트 조회
	 */
	const fnGetReceiveUserHighScore = () => {
		if (!stopGetReceivedRating) {
			const dateTime = new Date().getTime();
			getReceiveUserHighScore(highlyRatedPage, limit, dateTime)
				.then((value) => {
					if (value.length > 0) {
						const newItem = highlyRatedUsers;
						const list = newItem.concat(value);
						setHighlyRatedUsers(list);
						setHighlyRatedPage(highlyRatedPage + 1);
						setNoDataHighlyRated(value[0].addButtonOnOff);
					} else {
						setStopGetReceivedRating(true);
					}
				})
				.catch((reason) => {});
		}
	};

	useEffect(() => {
		if (
			highlyRatedUsers.length === 0 &&
			highlyRatedPage === 1 &&
			!stopGetReceivedRating
		) {
			fnGetReceiveUserHighScore();
		}
	}, [highlyRatedUsers, highlyRatedPage, stopGetReceivedRating]);

	/*
	 * fnGetLikedPosts
	 * 좋아요 보낸 게시물 조회
	 */
	const fnGetLikedPosts = () => {
		const dateTime = new Date().getTime();
		getLikedPosts(likedPostPage, limit, dateTime).then((value) => {
			if (value.length > 0) {
				const newItem = likedPosts;
				const list = newItem.concat(value);
				setLikedPosts(list);
				setLikedPostPage(likeUserPage + 1);
				setNoDataPost(value[0].addButtonOnOff);
			}
		});
	};

	useEffect(() => {
		fnGetLikedPosts();
		setFadeIn(true);
	}, []);

	return {
		likeUsers,
		highlyRatedUsers,
		likedPosts,
		fadeIn,
		setUserProfileOpen,
		fnGetReceiveUserLike,
		noDataUserLike,
		noDataHighlyRated,
		noDataPost,
		fnGetReceiveUserHighScore,
		setSingleFeedId,
		fnGetLikedPosts,
	};
};
