import GameScene from '@virtual-space/metaverse/scenes/GameScene';
import {
	PHASER_UPDATE_EMOJI_BUBBLE,
	PHASER_PLAYER_JOINED,
	PHASER_PLAYER_UPDATED,
	PHASER_UPDATE_DIALOG_BUBBLE,
	phaserEventEmitter,
} from '../../events/PhaserEventEmitter';
import { RoomService } from '../Network';

export class RoomReceiveProcess {
	constructor(private game: GameScene, private roomService: RoomService) {}

	listen() {
		phaserEventEmitter.on('player-joined', this.handlePlayerJoined, this);
		phaserEventEmitter.on('player-left', this.handlePlayerLeft, this);
		phaserEventEmitter.on('my-player-ready', this.handleMyPlayerReady, this);
		phaserEventEmitter.on(
			'my-player-video-connected',
			this.handleMyMediaConnected,
			this,
		);
		phaserEventEmitter.on('player-updated', this.handlePlayerUpdated, this);
		phaserEventEmitter.on(
			'update-dialog-bubble',
			this.handleChatMessageAdded,
			this,
		);
		phaserEventEmitter.on('update-emoji-bubble', this.handleEmojiAdded, this);
	}
	// 새로 추가된 메서드
	resetListeners() {
		phaserEventEmitter.shutdown();
		this.listen();
	}

	handlePlayerJoined(arg: PHASER_PLAYER_JOINED) {
		this.game.handlePlayerJoined(arg);
	}

	handlePlayerLeft(id: string) {
		this.game.handlePlayerLeft(id);
	}

	handleMyPlayerReady() {
		this.game.handleMyPlayerReady();
	}

	handleMyMediaConnected() {
		this.game.handleMyMediaConnected();
	}

	handlePlayerUpdated(arg: PHASER_PLAYER_UPDATED) {
		this.game.handlePlayerUpdated(arg);
	}

	handlePlayersOverlap(myPlayer: any, otherPlayer: any) {
		this.game.handlePlayersOverlap(myPlayer, otherPlayer);
	}

	handleChatMessageAdded(arg: PHASER_UPDATE_DIALOG_BUBBLE) {
		this.game.handleChatMessageAdded(arg);
	}

	handleEmojiAdded(arg: PHASER_UPDATE_EMOJI_BUBBLE) {
		this.game.handleEmojiAdded(arg);
	}
}
