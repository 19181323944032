import { RouteObject } from 'react-router-dom';
import LoungeRouter from './LoungeRouter';
import LikeRouter from './LikeRouter';
import SettingRouter from './SettingRouter';
import SideBarRouter from './SideBarRouter';
import RegisterRouter from './RegisterRouter';
import { HushHomeRouter } from '@dating/routers/HushHomeRouter';
import MyProfileRouter from '@dating/routers/MyProfileRouter';
import { ContentRouter } from '@dating/routers/ContentRouter';
import ImgCropContainer from '@dating/ui/pages/imgCrop/ImgCropContainer';
import StoreRouter from '@dating/routers/StoreRouter';
import AuthRouter from '@dating/routers/AuthRouter';
import UserProfileRouter from '@dating/routers/UserProfileRouter';
import ManagerProfileRouter from '@dating/routers/ManagerProfileRouter';

const datingRoutes: RouteObject[] = [
	{
		// 로그인 및 회원(휴면/삭제)처리
		path: 'auth/*',
		element: <AuthRouter />,
	},
	{
		// 회원가입
		path: 'register/*',
		element: <RegisterRouter />,
	},
	{
		// 홈
		path: 'home/*',
		element: <HushHomeRouter />,
	},
	{
		// 라운지
		path: 'lounge/*',
		element: <LoungeRouter />,
	},
	{
		// 좋아요
		path: 'like/*',
		element: <LikeRouter />,
	},
	{
		//프로필
		path: 'profile/*',
		children: [
			{
				path: 'myProfile/*',
				element: <MyProfileRouter />,
			},
			{
				path: 'userProfile/*',
				element: <UserProfileRouter />,
			},
			{
				path: 'managerProfile/*',
				element: <ManagerProfileRouter />,
			},
		],
	},
	{
		// 스토어
		path: 'store/*',
		element: <StoreRouter />,
	},
	{
		// 설정
		path: 'settings/*',
		element: <SettingRouter />,
	},
	{
		// 공지사항, 이벤트, 미션
		path: 'content/*',
		element: <ContentRouter />,
	},
	{
		// 이미지크롭
		path: 'imgCrop/*',
		element: <ImgCropContainer />,
	},
	{
		path: 'sideBar/*',
		element: <SideBarRouter />,
	},
];

export default datingRoutes;
