import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWaitingService } from './service/useWaitingService';

const RegisterWaitingContainer = () => {
	const {
		approvedAccount,
		userWaiting,
		srcPrefix,
		clickStartBtn,
		sliderSettings,
	} = useWaitingService();

	const { t } = useTranslation('register');

	return (
		<div
			className="wrap noFooter"
			style={{ height: '100%', overflowY: 'auto' }}
		>
			<div className="contentsWrap padding-t24 padding-b0">
				<div className="titleWrap">
					<h2 className="title01">
						{approvedAccount ? t('waiting.complete') : t('waiting.wait')}
					</h2>
					<p className="titleSub01">
						{approvedAccount
							? t('waiting.completeQuote')
							: t('waiting.waitQuote')}
					</p>
				</div>

				<div className="content margin-t24 padding-b0">
					<div className="profileBox">
						<div className="profileSlide">
							<Slider {...sliderSettings}>
								{userWaiting?.profileUrl.map((img, i) => {
									return (
										<img
											key={i}
											src={srcPrefix + img}
											alt={userWaiting?.nickName}
											className="imgHlg"
										/>
									);
								})}
							</Slider>
						</div>
						<div className="txtBox">
							<p className="txt01 fz20 color-fff">{userWaiting?.nickName}</p>
							<p className="txt02 fz16 color-fff">
								{/*36세, 162cm, 서울시 영등포구*/}
								{userWaiting.age + t('waiting.howOld')},{' '}
								{userWaiting.region1 && userWaiting.region2
									? `${userWaiting.region1} ${userWaiting.region2}`
									: t('waiting.noRegion')}
							</p>
						</div>
					</div>
					<div className="stepLastBox">
						<ul className="infoList">
							{/*직업*/}
							<li className={`ic-job ${userWaiting?.job ? 'on' : ''}`}>
								{userWaiting?.job ? userWaiting?.job : '개발자'}
							</li>

							{/*키*/}
							<li className={`ic-height ${userWaiting?.height ? 'on' : ''}`}>
								{(userWaiting?.height ? userWaiting?.height : '') + 'cm'}
							</li>

							{
								// 	라이프스타일
								userWaiting?.lifeStyle.length > 0 ? (
									<li className="ic-life on">
										{userWaiting?.lifeStyle.length > 0
											? userWaiting?.lifeStyle.join(', ')
											: null}
									</li>
								) : null
							}

							{
								// 	관심사
								userWaiting?.interest.length > 0 ? (
									<li className="ic-like on">
										{userWaiting?.interest.length > 0
											? userWaiting?.interest.join(', ')
											: null}
									</li>
								) : null
							}

							{
								// 	흡연
								userWaiting?.smoke != '' ? (
									<li className="ic-smoking on">{userWaiting?.smoke}</li>
								) : null
							}

							{
								// 	음주
								userWaiting?.drink != '' ? (
									<li className="ic-drink on">{userWaiting?.drink}</li>
								) : null
							}
						</ul>
					</div>
				</div>
			</div>

			<div className="f-btnWrap noFixed">
				<Link
					to="/metaverse/dating/register/lifeStyle"
					className="btn btnBig margin-b10"
				>
					<span
						className={`underline fz16 bold-500 ${approvedAccount ? 'dn' : ''}`}
					>
						{t('waiting.goMakeDetail')}
					</span>
				</Link>
				<button
					type="button"
					className="btn btnBig btnBlack"
					disabled={!approvedAccount}
					onClick={() => {
						clickStartBtn();
					}}
				>
					<span>{t('waiting.startBtn')}</span>
				</button>
			</div>
		</div>
	);
};

export default RegisterWaitingContainer;
