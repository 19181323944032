import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMetaRoomAdapter } from '@client/site/dating/adapter/meta/useMetaRoomAdapter';
import { useDatingRepo } from '@client/site/dating/repository/dating/useDatingRepo';
import { useTranslation } from 'react-i18next';
import { useRoomStateService } from '../../service/useRoomStateService';
import { useRoomEventService } from '../../service/useRoomEventService';
import { useRoomManagementService } from '../../service/useRoomManagementService';
import { useRoomRepo } from '@virtual-space/stores/useRoomRepo';
import { IRoomData, RoomType } from '../../../../../../../types/Rooms';

export const useMakeRoomService = () => {
	const location = useLocation();
	const { t } = useTranslation('metaverse');
	const { myProfile } = useDatingRepo();
	const {
		headCountSelectedList,
		initialRoomTheme,
		roomThemeSelected,
		roomThemeSelectedList,
		joinedRoomData,
		setRoomName,
		setRoomHeadCount,
		setRoomThemeSelected,
	} = useRoomRepo();
	const { statusSaveCreateRoom, dataSaveCreateRoom } = useMetaRoomAdapter();
	const { isRoomDataReady, shouldCreateRoom, setShouldCreateRoom } =
		useRoomStateService();
	const { emitStartRoom, emitJoinRoom } = useRoomEventService();

	const { createRoom, joinRoom } = useRoomManagementService();
	const [activeHeadCountIndex, setActiveHeadCountIndex] = useState(() => {
		const initialIndex = myProfile.role === 'MANAGER' ? 0 : 1;
		return headCountSelectedList[initialIndex].headCount;
	});
	const [customRoomNameResult, setCustomRoomNameResult] = useState(0);
	const [activeRoomThemeIndex, setActiveRoomThemeIndex] = useState(0);
	const [roomPwdResult, setRoomPwdResult] = useState(0);
	const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
	const [values, setValues] = useState<IRoomData>({
		type: RoomType.CUSTOM,
		name: '',
		password: undefined,
		autoDispose: true,
		headCount:
			myProfile.role === 'MANAGER'
				? headCountSelectedList[0].headCount
				: headCountSelectedList[1].headCount,
		roomTheme: roomThemeSelected,
		remainingTime: 7200,
		createdBy: myProfile.role,
	});

	// 헤드카운트 필터링
	const filteredHeadCountList = headCountSelectedList.filter((item) => {
		if (myProfile.role === 'NORMAL') {
			return item.headCount !== 3; // NORMAL 타입일 경우 1:1(headCount: 3) 제외
		}
		if (myProfile.role === 'MANAGER') {
			return item.headCount === 3; // MANAGER 타입일 경우 1:1(headCount: 3)만 표시
		}
		return true; // 다른 타입의 경우 모든 옵션 표시
	});

	useEffect(() => {
		if (filteredHeadCountList && filteredHeadCountList.length > 0) {
			const initialHeadCount = filteredHeadCountList[0].headCount;
			setActiveHeadCountIndex(initialHeadCount);
			handleHeadCountChange(initialHeadCount);
		}
	}, [myProfile.role]);

	// 방 이름 변경 핸들러
	const handleRoomNameChange =
		(prop: keyof IRoomData) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			const parsedValue = newValue.length;
			setCustomRoomNameResult(parsedValue);
			setRoomName(newValue);
			setValues({ ...values, [prop]: newValue });
		};

	const validatePassword = (password: number | null): boolean => {
		if (!password) return false;
		const passwordString = password.toString();
		return passwordString.length === 6;
	};

	// 비밀번호 유효성 검사
	const handleRoomPwdChange =
		(prop: keyof IRoomData) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			// 숫자만 입력 받도록 처리
			if (!/^\d*$/.test(newValue)) {
				return;
			}
			// 6자리로 제한
			const limitedValue = newValue.slice(0, 6);
			const parsedValue = limitedValue.length;
			const numberValue = limitedValue ? parseInt(limitedValue, 10) : null;

			setRoomPwdResult(parsedValue);
			setValues({ ...values, [prop]: numberValue });
		};

	// 인원수 변경 핸들러
	const handleHeadCountChange = (selectedValue) => {
		setActiveHeadCountIndex(selectedValue);
		setRoomHeadCount(selectedValue);

		// headCount가 3일 때는 24시간(86400초), 그 외에는 2시간(7200초)
		const timeLimit = selectedValue === 3 ? 86400 : 7200;

		// headCount 변경 시 이전이 3이었거나 새로 3이 되는 경우 비밀번호 초기화
		if (values.headCount === 3 || selectedValue === 3) {
			setValues((prevValues) => ({
				...prevValues,
				headCount: selectedValue,
				password: undefined,
				remainingTime: timeLimit,
			}));
			setRoomPwdResult(0);
		} else {
			setValues((prevValues) => ({
				...prevValues,
				headCount: selectedValue,
				remainingTime: timeLimit,
			}));
		}
	};

	// 테마 변경 핸들러
	const handleRoomThemeChange = (index: number) => {
		setActiveRoomThemeIndex(index);
		const selectedTheme = roomThemeSelectedList[index];
		const translatedTheme = {
			...selectedTheme,
			label: t(selectedTheme.label),
		};
		setRoomThemeSelected(translatedTheme);
		setValues((prevValues) => ({
			...prevValues,
			roomTheme: translatedTheme,
		}));
	};

	// 폼 유효성 검사
	const isFormValid = (): boolean => {
		const hasValidName = values.name.trim() !== '';
		if (values.headCount === 3) {
			return hasValidName && validatePassword(values.password ?? null);
		}
		// 그 외의 경우는 이름만 있으면 됨
		// 단, 비밀번호를 입력했다면 6자리여야 함
		return (
			hasValidName &&
			(values.password === undefined || validatePassword(values.password))
		);
	};

	// 폼 제출 핸들러
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!isFormValid()) return;

		emitStartRoom({
			roomType: 'CUSTOM',
			name: values.name,
			password: values.password,
			isSecret: values.headCount === 3 ? true : values.password !== null,
			autoDispose: values.autoDispose,
			headCount: values.headCount,
			roomTheme: values.roomTheme,
			remainingTime: values.remainingTime,
			room: values,
			x: roomThemeSelected.setUpPlayer.x,
			y: roomThemeSelected.setUpPlayer.y,
		});
		emitJoinRoom();
		setShouldCreateRoom(true);
	};

	useEffect(() => {
		if (shouldCreateRoom && isRoomDataReady && joinedRoomData?.id) {
			const handleCreateCustomRoom = async () => {
				await createRoom({
					roomName: values.name,
					isSecret: values.headCount === 3 || values.password !== null,
					password: values.password,
					roomMaxUsers: values.headCount,
					roomThemeId: values.roomTheme.id,
				});
			};
			handleCreateCustomRoom();
			setShouldCreateRoom(false);
		}
	}, [joinedRoomData, isRoomDataReady, shouldCreateRoom]);

	const resetRoomSettings = useCallback(() => {
		setRoomThemeSelected(initialRoomTheme);
		setValues((prevValues) => ({
			...prevValues,
			roomTheme: initialRoomTheme,
		}));
		setActiveRoomThemeIndex(
			roomThemeSelectedList.findIndex(
				(theme) => theme.id === initialRoomTheme.id,
			),
		);
		// 필요한 다른 초기화 로직 추가
		setRoomName('');
		setRoomHeadCount(headCountSelectedList[0].headCount);
	}, [initialRoomTheme, roomThemeSelectedList, headCountSelectedList]);

	// 초기 설정 리셋
	useEffect(() => {
		resetRoomSettings();
	}, [location.pathname, resetRoomSettings]);

	// 방 생성 상태 처리
	useEffect(() => {
		const processRoomChanges = async () => {
			if (statusSaveCreateRoom === 'success' && dataSaveCreateRoom) {
				resetRoomSettings();
				await joinRoom({
					roomId: joinedRoomData.id,
					roomType: 'CUSTOM',
					roomTheme: values.roomTheme,
					password: values.password,
					x: values.roomTheme.setUpPlayer.x,
					y: values.roomTheme.setUpPlayer.y,
				});
			}
		};
		processRoomChanges();
	}, [statusSaveCreateRoom, dataSaveCreateRoom]);

	return {
		title: t('roomSettings'),
		values,
		role: myProfile.role,
		customRoomNameResult,
		activeHeadCountIndex,
		headCountSelectedList: filteredHeadCountList,
		roomThemeSelected,
		roomThemeSelectedList,
		nameFieldEmpty,
		roomPwdResult,
		activeRoomThemeIndex,
		handleRoomNameChange,
		handleHeadCountChange,
		handleRoomThemeChange,
		handleRoomPwdChange,
		handleSubmit,
		isFormValid,
	};
};
