import { Room } from 'colyseus.js';
import { IMetaverseState } from '../../../../types/IMetaverseState';
import { MetaMessageType } from '../../../../types/MetaMessageType';
import {
	PHASER_MY_PLAYER_TEXTURE_CHANGE,
	PHASER_PLAYER_DISCONNECTED,
	PHASER_PLAYER_PROFILE_URL_CHANGE,
	PHASER_PLAYER_UPDATED,
	phaserEventEmitter,
} from '@virtual-space/events/PhaserEventEmitter';
import WebRTC from '@virtual-space/web/WebRTC';

export class PlayerEventProcess {
	constructor(private room: Room<IMetaverseState>, private webRTC: WebRTC) {}

	// 이벤트 리스너 등록 메소드
	listen() {
		phaserEventEmitter.on('my-player-name-change', this.updatePlayerName, this);
		phaserEventEmitter.on('my-player-texture-change', this.updatePlayer, this);
		phaserEventEmitter.on(
			'player-disconnected',
			this.playerStreamDisconnect,
			this,
		);
		phaserEventEmitter.on(
			'my-player-status-message-change',
			this.updatePlayerStatusMessage,
			this,
		);
		phaserEventEmitter.on(
			'player-audio-status-change',
			this.updatePlayerAudioStatus,
			this,
		);
		phaserEventEmitter.on(
			'player-profile-url-change',
			this.updatePlayerProfileUrl,
			this,
		);
	}

	removeListeners() {
		phaserEventEmitter.off('my-player-name-change', this.updatePlayerName);
		phaserEventEmitter.off('my-player-texture-change', this.updatePlayer);
		phaserEventEmitter.off('player-disconnected', this.playerStreamDisconnect);
		phaserEventEmitter.off(
			'my-player-status-message-change',
			this.updatePlayerStatusMessage,
		);
		phaserEventEmitter.off(
			'player-audio-status-change',
			this.updatePlayerAudioStatus,
		);
		phaserEventEmitter.off(
			'player-profile-url-change',
			this.updatePlayerProfileUrl,
		);
	}

	// 플레이어 이름 업데이트 메소드, 현재 이름을 Colyseus 서버로 전송
	updatePlayerName(currentName: string) {
		this.room.send(MetaMessageType.UPDATE_PLAYER_NAME, { name: currentName });
	}

	// 플레이어 아바타 업데이트 메소드, 현재 플레이어의 위치와 애니메이션을 Colyseus 서버로 전송
	updatePlayer({ x, y, texture }: PHASER_MY_PLAYER_TEXTURE_CHANGE) {
		this.room.send(MetaMessageType.UPDATE_PLAYER, { x, y, anim: texture });
	}

	// 플레이어 상태 메시지 업데이트 메소드
	updatePlayerStatusMessage(message: string) {
		this.room.send(MetaMessageType.UPDATE_PLAYER_STATUS_MESSAGE, {
			statusMessage: message,
		});
	}

	// 플레이어 오디오 상태 변경 메소드
	updatePlayerAudioStatus(message: boolean) {
		this.room.send(MetaMessageType.AUDIO_STATUS, { audioStatus: message });
	}

	// 플레이어 프로필 URL 업데이트 메소드
	updatePlayerProfileUrl(message: PHASER_PLAYER_PROFILE_URL_CHANGE) {
		this.room.send(MetaMessageType.UPDATE_PLAYER_PROFILE_URL, {
			profileUrl: message,
		});
	}

	onPlayerUpdated(
		callback: ({ field, value, key }: PHASER_PLAYER_UPDATED) => void,
		context?: any,
	) {
		phaserEventEmitter.on('player-updated', callback, context);
	}

	// 플레이어 스트림 연결 해제 메소드
	// 해당 플레이어의 스트림 연결 해제 신호를 Colyseus 서버로 전송하고 WebRTC 인스턴스에서 해당 비디오 스트림을 삭제
	playerStreamDisconnect(id: PHASER_PLAYER_DISCONNECTED) {
		this.room.send(MetaMessageType.DISCONNECT_STREAM, { clientId: id });
		this.webRTC.deleteVideoStream(id);
	}
}
