import { EditType } from '@dating/repository/profile/useEditProfileRepo';
import { JobListCOVO } from '@client/site/api';
import { useTranslation } from 'react-i18next';

interface props {
	editPop: EditType;
	setEditPop: (editPop: EditType) => void;
	focusItem: { label: string; value: string | Array<string> };
	setSelectedJob: (selectedJob: string) => void;
	jobList: Array<JobListCOVO>;
	selectedJob: string;
	fnModifyProfileJob: () => void;
	closeEditPopup: (key: string) => void;
}

const EditJobPopView = ({
	focusItem,
	editPop,
	setEditPop,
	setSelectedJob,
	jobList,
	selectedJob,
	fnModifyProfileJob,
	closeEditPopup,
}: props) => {
	const { t } = useTranslation('register');
	return (
		<div className="popWrap editProfilePop">
			<div className="popupInfo padding-b16">
				<div className="frameWrap">
					<div className="titleWrap">
						<h2 className="title01">{t('modifyProfile.job')}</h2>
						<p className="titleSub01">{t('modifyProfile.jobQuote')}</p>
					</div>
					<div className="content">
						<div className="txtRadio d-flex gap-16 h-300 overflow-y">
							{jobList != undefined &&
							typeof jobList === 'object' &&
							jobList.length > 0
								? jobList.map((job, i) => {
										return (
											<div key={job.code}>
												<input
													type="radio"
													id={job.code}
													name="job"
													value={job.code}
													checked={job.code == selectedJob}
													onChange={() => {
														setSelectedJob(job.code);
													}}
												/>
												<label htmlFor={job.code}>{job.value}</label>
											</div>
										);
								  })
								: null}
						</div>
					</div>
				</div>

				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							closeEditPopup(focusItem.label);
						}}
					>
						<span>{t('common.cancel')}</span>
					</button>
					<button
						className="popCloseBtn btn btnBig btnBlack"
						type="button"
						onClick={() => {
							fnModifyProfileJob();
						}}
					>
						<span>{t('common.confirm')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditJobPopView;
