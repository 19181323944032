import Chair from '@virtual-space/metaverse/items/impl/Chair';
import GameScene from '../GameScene';
import { CreateObjectLayer } from '../layer/CreateLayer';

export class SetupObjectLayer {
	constructor() {}
	execute(scene: GameScene) {
		// 오브젝트 레이어 생성
		const objectLayer = new CreateObjectLayer();

		// 의자 그룹 추가
		objectLayer.addGroupFromTiled(
			Chair,
			scene,
			'Chair', // Tiled에서 설정한 오브젝트 레이어 이름
			'chairs', // Tiled에서 설정한 오브젝트 그룹 이름
			'chair', // 오브젝트 이름
		);

		// 팝업 그룹 추가
		// objectLayer.addGroupFromTiled(
		//   PopUp,
		//   scene,
		//   "PopUp", // Tiled에서 설정한 오브젝트 레이어 이름
		//   "popUps", // Tiled에서 설정한 오브젝트 그룹 이름
		//   "popUp" // 오브젝트 이름
		// );

		// 유튜브 그룹 추가
		// objectLayer.addGroupFromTiled(
		//   YouTube,
		//   scene,
		//   "YouTube", // Tiled에서 설정한 오브젝트 레이어 이름
		//   "youTubes", // Tiled에서 설정한 오브젝트 그룹 이름
		//   "youTube" // 오브젝트 이름
		// );

		// // 워들 그룹 추가
		// objectLayer.addGroupFromTiled(
		//   Wordle,
		//   scene,
		//   "Wordle", // Tiled에서 설정한 오브젝트 레이어 이름
		//   "wordles", // Tiled에서 설정한 오브젝트 그룹 이름
		//   "wordle" // 오브젝트 이름
		// );

		// // 화이트보드 그룹 추가
		// objectLayer.addGroupFromTiled(
		//   Whiteboard,
		//   scene,
		//   "Whiteboard", // Tiled에서 설정한 오브젝트 레이어 이름
		//   "whiteboards", // Tiled에서 설정한 오브젝트 그룹 이름
		//   "whiteboard" // 오브젝트 이름
		// );

		// // 컴퓨터 그룹 추가
		// objectLayer.addGroupFromTiled(
		//   Computer,
		//   scene,
		//   "Computer", // Tiled에서 설정한 오브젝트 레이어 이름
		//   "computers", // Tiled에서 설정한 오브젝트 그룹 이름
		//   "computer" // 오브젝트 이름
		// );
	}
}
