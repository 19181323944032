import {CSSTransition} from 'react-transition-group';
import {useTranslation} from "react-i18next";

interface props {
    focusItem: { label: string, value: string | string[] };
    closeEditPopup: (key: string) => void;
    lifeStyleList: string[] | { id: string; styleValue: string }[];
    lifeStyleValue: { id: string; styleValue: string }[];
    lifeStyleValueHandler: (id: string) => void;
    fnModifyProfileLifeStyle: () => void;
    openPop: boolean;
}

const EditLifeStylePopView = ({
                                  focusItem,
                                  closeEditPopup,
                                  lifeStyleList,
                                  lifeStyleValue,
                                  lifeStyleValueHandler,
                                  fnModifyProfileLifeStyle,
                                  openPop,
                              }: props) => {
    const {t} = useTranslation('register')
    return (
        <>
            <div className="listAddPop" id="lifestyle">
                <div className="listAddDim" onClick={() => {
                    fnModifyProfileLifeStyle();
                    closeEditPopup(focusItem.label);
                }}/>
                <CSSTransition
                    in={openPop}
                    classNames="listAddInfo-transition"
                    timeout={100}
                >
                    <div className="listAddInfo">
                        <p className="listAddTit">
                            <span className="cateTit01 life">{t('modifyProfile.life')}</span>
                        </p>
                        <div className="listAdd">
                            <li className="padding-0 on">
                                <div className="myCateList">
                                    {
                                        lifeStyleList.length > 0 ?
                                            lifeStyleList.map((item) => {
                                                return (
                                                    <button
                                                        key={item.id + item.styleValue}
                                                        type="button"
                                                        className={
                                                            lifeStyleValue[item.id] ? "active" : ""
                                                        }
                                                        onClick={() => {
                                                            lifeStyleValueHandler(item.id);
                                                        }}
                                                    >
                                                        {item.styleValue}
                                                    </button>
                                                );
                                            })
                                            : null
                                    }
                                </div>
                            </li>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </>
    )
};

export default EditLifeStylePopView;