import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from '@dating/routers/RouteTransition';
import EventContainer from '@dating/ui/pages/sideBar/event/EventContainer';
import NoticeContainer from '@dating/ui/pages/sideBar/notice/NoticeContainer';
import { AttendCheckContainer } from '@dating/ui/pages/content/event/attendCheck/AttendCheckContainer';
import { ReferralCodeContainer } from '@dating/ui/pages/content/event/referralCode/ReferralCodeContainer';
import { errorHandlerUtils } from '@client/utils/errorHandlerUtils';
import { ErrorBoundary } from 'react-error-boundary';
import MissionContainer from '@dating/ui/pages/content/mission/MissionContainer';

export const ContentRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();
	const { fallbackRender, logErrorToService } = errorHandlerUtils();
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={logErrorToService}>
			<RouteTransition location={location} navigationType={navigationType}>
				<Routes location={location}>
					<Route path="/event" element={<EventContainer />} />
					<Route path="/event/attend" element={<AttendCheckContainer />} />
					<Route path="/event/referral" element={<ReferralCodeContainer />} />
					<Route path="/notice" element={<NoticeContainer />} />
					<Route path="/mission" element={<MissionContainer />} />
				</Routes>
			</RouteTransition>
		</ErrorBoundary>
	);
};
