import { reactEventEmitter } from '@virtual-space/events/ReactEventEmitter';
import { useMetaUserRepo } from '@virtual-space/stores/useMetaUserRepo';
import { useVideoRepo } from '@virtual-space/stores/useVideoRepo';
import { useEffect } from 'react';

export const useMediaBtnService = () => {
	const {
		mediaConnected,
		videoControl,
		audioControl,
		setVideoControl,
		setAudioControl,
	} = useMetaUserRepo();
	const { myVideoStream } = useVideoRepo();

	const handleVideoConnected = () => {
		if (!mediaConnected) {
			return;
		}
		setVideoControl(!videoControl);
	};

	const handleAudioStatus = () => {
		if (!mediaConnected) {
			return;
		}
		reactEventEmitter.emit('react-audio-status', !audioControl);
		setAudioControl(!audioControl);
	};

	useEffect(() => {
		if (myVideoStream) {
			const audioTrack = myVideoStream.getAudioTracks()[0];
			const videoTrack = myVideoStream.getVideoTracks()[0];
		}
	});
	useEffect(() => {
		if (mediaConnected) {
			setVideoControl(true);
			setAudioControl(true);
			reactEventEmitter.emit('react-audio-status', true);
		}
	}, [mediaConnected]);
	return {
		mediaConnected,
		videoControl,
		audioControl,
		handleVideoConnected,
		handleAudioStatus,
	};
};
