import { useState } from 'react';
import { UserPreferCOVO } from '@client/site/api';

export const useIdealSettingEditStateRepo = () => {
	const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
	const [popupType, setPopupType] = useState<string>('');

	const [initAgeRange, setInitAgeRange] = useState({ min: 18, max: 70 });
	const [initNearRange, setInitNearRange] = useState({ min: 0, max: 200 });
	const [initHeightRange, setInitHeightRange] = useState({
		min: 130,
		max: 230,
	});

	const [ageRange, setAgeRange] = useState({ min: 28, max: 38 });
	const [nearRange, setNearRange] = useState({ min: 0, max: 20 });
	const [heightRange, setHeightRange] = useState({ min: 157, max: 167 });

	const [initReligion, setInitReligion] = useState<
		{ code: string; value: string }[] | string[]
	>([]);
	const [initDrinkingState, setInitDrinkingState] = useState<
		{ code: string; value: string }[] | string[]
	>([]);
	const [initSmokingState, setInitSmokingState] = useState<
		{ code: string; value: string }[] | string[]
	>([]);

	const [religion, setReligion] = useState({ code: '', value: '' });
	const [drinkingState, setDrinkingState] = useState({ code: '', value: '' });
	const [smokingState, setSmokingState] = useState({ code: '', value: '' });

	const [userPrefer, setUserPrefer] = useState<UserPreferCOVO>();

	const [nearOverRange, setNearOverRange] = useState<boolean>(false);
	const [ageOverRange, setAgeOverRange] = useState<boolean>(false);
	const [heightOverRange, setHeightOverRange] = useState<boolean>(false);

	return {
		nearOverRange,
		setNearOverRange,
		ageOverRange,
		setAgeOverRange,
		heightOverRange,
		setHeightOverRange,
		setUserPrefer,
		userPrefer,
		initReligion,
		setInitReligion,
		initDrinkingState,
		setInitDrinkingState,
		initSmokingState,
		setInitSmokingState,
		isPopupVisible,
		setIsPopupVisible,
		popupType,
		setPopupType,
		ageRange,
		setAgeRange,
		nearRange,
		setNearRange,
		heightRange,
		setHeightRange,
		religion,
		setReligion,
		drinkingState,
		setDrinkingState,
		smokingState,
		setSmokingState,
		initAgeRange,
		setInitAgeRange,
		initNearRange,
		setInitNearRange,
		initHeightRange,
		setInitHeightRange,
	};
};
