import Slider from 'react-slick';
import { PostInfoCOVO } from '@client/site/api';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';

interface props {
	post: PostInfoCOVO;
	sliderSettings: {};
	srcPrefix: string;
	fnSavePostLike: (postId: string, likeYn: boolean) => void;
}
const FeedImgView = ({
	post,
	sliderSettings,
	srcPrefix,
	fnSavePostLike,
}: props) => {
	const { setFocusingId } = useLoungeRepo();
	return (
		<>
			<div className="profileBox">
				<div className="profileSlide">
					<Slider {...sliderSettings}>
						{post.filesUrl.map((img, i) => {
							return (
								<img
									key={i}
									src={srcPrefix + img}
									alt="테스트이미지"
									className="imgHlg"
								/>
							);
						})}
					</Slider>
				</div>
				<div className="txtBox">
					<div className="profileLikeBox">
						<button
							type="button"
							className={`likeBtn ${post.postLikeYn ? 'on' : ''}`}
							onClick={(e) => {
								let likeYn: boolean = false;
								if (e.currentTarget.classList.contains('on')) {
									likeYn = false;
								} else {
									likeYn = true;
								}
								setFocusingId(post.postId);
								fnSavePostLike(post.postId, likeYn);
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default FeedImgView;
